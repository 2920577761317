import React, { useEffect, useState } from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import styled from 'styled-components';
import Colors from '../../styles/Colors';
  
export default function KLineChart({ width, data }) {
	const [chartData,  setChartDate] = useState();

	useEffect(() => {
		setChartDate(data)
	}, [data])

	const hasKey = (key) => {
		if(chartData) {
			const element = chartData[0]
	
			if(element[key] !== undefined) {
				return true;
			} else {
				return false;
			}
		}
	}

	const CustomizedDot = (props) => {
		const { cx, cy, stroke, payload, value } = props;

		if (payload.visible) {
		  return (
				<svg x={cx - 4} y={cy - 4} width={8} height={8} fill="white">
			  <g transform="translate(4 4)">
						<circle r="4" fill="black" />
						<circle r="2" fill="white" />
			  </g>
				</svg>
		  );
		}
	  
		return null;
	};

	return (
		<ResponsiveContainer width={'100%'} height={300}>
			<LineChart style={{ marginLeft: -25 }} data={chartData}>
				<CartesianGrid vertical={false} strokeDasharray="0" />
				<XAxis dataKey="date" />
				<YAxis />
				<Tooltip />
				<Legend />
				{hasKey('Oprettet pårørende') && <Line strokeWidth={2} dot={<CustomizedDot />} type="monotone" dataKey="Oprettet pårørende" stroke={Colors.darkPurple} />}
				{hasKey('Oprettet personale') && <Line strokeWidth={2} dot={<CustomizedDot />} type="monotone" dataKey="Oprettet personale" stroke={Colors.mainGreen} /> }
				{hasKey('Oprettet af pårørende') && <Line strokeWidth={2} dot={<CustomizedDot />} type="monotone" dataKey="Oprettet af pårørende" stroke={Colors.darkPurple} />}
				{hasKey('Oprettet af personale') && <Line strokeWidth={2} dot={<CustomizedDot />} type="monotone" dataKey="Oprettet af personale" stroke={Colors.mainGreen} /> }
			</LineChart>
		</ResponsiveContainer>
	)
}

const StyledLineChart = styled(LineChart)(() => ({
	marginLeft: -25, 
	marginTop: 20
}))