import React, { useEffect, useState } from 'react'
import { getResidentFullName } from '../../../utils/residentUtils';
import { getRoleTypeName } from '../../../utils/roles'
import _ from 'lodash';
import KModalConfirm from '../../../components/modals/KConfirmModal';
import KButton from '../../../components/KButton';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import KModal from '../../../components/KModal';
import EditRelative from './EditRelative';
import AddRelative from './AddRelative';
import AddExistingRelatives from '../profileSubmenus/relatives/AddExistingRelatives';
import { classNames } from '../../../utils/tailwindUtils';

export default function Relatives({ relatives, setRelatives, existingRelatives, error, setExistingRelatives = () => { } }) {
	const [activeRelative, setActiveRelative] = useState();

	const [showDeleteRelative, setShowDeleteRelative] = useState(false)
	const [deleteIndex, setDeleteIndex] = useState()

	const [showCreateRelativeModal, setShowCreateRelativeModal] = useState(false)
	const [showEditRelativeModal, setShowEditRelativeModal] = useState(false)
	const [relativeType, setRelativeType] = useState(0)

	const handleRemoveRelative = () => {
		const newArr = [...relatives];
		newArr.splice(deleteIndex, 1)

		setRelatives(newArr)
		setShowDeleteRelative(false)
	}

	const onDeleteRelative = (index) => {
		setDeleteIndex(index)
		setShowDeleteRelative(true)
	}

	const handleEditRelative = (index) => {
		setActiveRelative(index)
		setShowEditRelativeModal(true)
	}

	const onExistingRelativeAdded = (relativeData) => {
		// only add if not already added
		if (existingRelatives?.some(e => e?.relative?.id === relativeData?.relative?.id)) {
			setShowCreateRelativeModal(false)
			return
		}
		setExistingRelatives([...existingRelatives, relativeData])
		setShowCreateRelativeModal(false)
	}

	const handleRemoveExistingRelative = (deleteIndex) => {
		const newArr = [...existingRelatives];
		newArr.splice(deleteIndex, 1)
		setExistingRelatives(newArr)
	}


	return (
		<div>
			<KModal
				width="60%"
				closeIcon
				open={showCreateRelativeModal}
				// title="Tilføj pårørende"
				handleChange={() => setShowCreateRelativeModal(!showCreateRelativeModal)}
			>
				<div className="">
					<SwitchBtn value={relativeType} setValue={setRelativeType} />
					<div className='pt-4' >
						{relativeType === 0 ?
							<AddRelative
								relatives={relatives}
								setRelatives={setRelatives}
								setShowCreateRelativeModal={setShowCreateRelativeModal}
							/>
							: <AddExistingRelatives
								onRelativeAdded={(rd) => onExistingRelativeAdded(rd)}
								disableTitle
								disableConfirm
							/>}
					</div>
				</div>
			</KModal>
			<KModal
				width="60%"
				closeIcon
				open={showEditRelativeModal}
				title="Rediger pårørende"
				handleChange={() => setShowEditRelativeModal(!showEditRelativeModal)}
			>
				<div className="pt-5">
					<EditRelative
						setRelatives={setRelatives}
						relatives={relatives}
						activeRelativeIndex={activeRelative}
						setShowEditRelativeModal={setShowEditRelativeModal}
					/>
				</div>
			</KModal>
			<KModalConfirm
				open={showDeleteRelative}
				setOpen={() => setShowDeleteRelative(!showDeleteRelative)}
				title="Fjern pårørende"
				description="Er du sikker på, at du vil fjerne pårørende?"
				handleConfirm={() => handleRemoveRelative()}
				confirmText="FJERN"
			/>
			<div className="flex justify-start mb-3">
				<KButton onClick={() => setShowCreateRelativeModal(true)} label="Tilføj pårørende" />
			</div>

			{relatives?.length > 0 &&

				<div className='pt-4' >
					<h1 className='mb-2' >Nye pårørende</h1>
					<table className="min-w-full divide-y divide-gray-300">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
								>
									Navn
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Email
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Mobilnummer
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Klassifikation
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Login metode
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Send invitation
								</th>
								<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
									<span className="sr-only">Rediger</span>
								</th>
								<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
									<span className="sr-only">Fjern</span>
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{relatives?.map((relative, index) => (
								<tr key={relative?.email} className={classNames(
									relative?.email === error?.email ? 'border border-red-300' : null,"cursor-default")}>
									<td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6 lg:pl-8">
										<div className="">
											<div className="font-medium text-gray-900">{getResidentFullName(relative)}</div>
											<div className="text-gray-500">{relative?.relation}</div>
										</div>
									</td>

									<td 
										className={classNames(
											relative?.email === error?.email ? 'border-red-300' : 'border-gray-300',
											'px-3 py-4 text-sm text-gray-500 whitespace-nowrap')}>
										{relative?.email ? relative?.email : '-'} 
										{relative?.email === error?.email &&
											<p className="mt-1 ml-1 text-sm text-red-600" id="email-error">
												{error.text}
											</p>
										}
									</td>
									<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{relative?.mobile && !relative?.mobile?.isEmpty ? relative?.mobile?.value : '-'}</td>

									<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{getRoleTypeName(relative)}</td>
									<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{relative?.loginMethod?.name}</td>
									<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
										{relative?.sendInvitation
											? <CheckCircleIcon className="w-6 h-6 text-green-700" />
											: <XCircleIcon className="w-6 h-6 text-red-700" />
										}
									</td>
									<td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 lg:pr-8">
										<div onClick={() => handleEditRelative(index)} className="text-indigo-600 cursor-pointer hover:text-indigo-900">
											Rediger
										</div>
									</td>
									<td className="py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 lg:pr-8 ">
										<div onClick={() => onDeleteRelative(index)} className="text-indigo-600 cursor-pointer hover:text-indigo-900">
											Fjern
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			}

			{existingRelatives?.length > 0 &&
				<div className='pt-6' >
					<h1 className='mb-2' >Eksisterende pårørende</h1>
					<table className="min-w-full divide-y divide-gray-300">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
								>
									Navn
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Email
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Mobilnummer
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Klassifikation
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Inviteret
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
									Aktiv
								</th>
								<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
									<span className="sr-only">Rediger</span>
								</th>
								<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
									<span className="sr-only">Fjern</span>
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{existingRelatives?.map((d, index) => {
								console.log('d', d)
								return (
									<tr key={d?.relative?.email} className="cursor-default">
										<td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6 lg:pl-8">
											<div className="">
												<div className="font-medium text-gray-900">{getResidentFullName(d?.relative)}</div>
												<div className="text-gray-500">{d?.relative?.relation}</div>
											</div>
										</td>

										<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{d?.relative?.email ? d?.relative?.email : '-'} </td>
										<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{d?.relative?.mobile ? d?.relative?.mobile : '-'}</td>
										<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{getRoleTypeName(d?.relative)}</td>

										<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
											{d?.relative?.invitationSend
												? <CheckCircleIcon className="w-6 h-6 text-green-700" />
												: <XCircleIcon className="w-6 h-6 text-red-700" />
											}
										</td>
										<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
											{d?.relative?.active
												? <CheckCircleIcon className="w-6 h-6 text-green-700" />
												: <XCircleIcon className="w-6 h-6 text-red-700" />
											}
										</td>

										<td className="py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 lg:pr-8 ">
											<div onClick={() => handleRemoveExistingRelative(index)} className="text-indigo-600 cursor-pointer hover:text-indigo-900">
												Fjern
											</div>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			}
		</div>
	)
}




const SwitchBtn = ({ value = 0, setValue = () => { }, items = [{ value: 0, label: "Tilføj ny pårørende" }, { value: 1, label: "Tilføj eksisterende pårørende" }] }) => {
	return (
		<div className='flex pt-6 gap-x-2' >
			{items.map((item, index) => (
				<div key={index}
					className={`flex items-center justify-center w-1/2 h-10 rounded-md cursor-pointer ${value === item.value ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-500'}`}
					onClick={() => setValue(item.value)}>
					{item.label}
				</div>
			))}
		</div>
	)
}