export default function KBreadcrumb({ pages, onClick = () => { } }) {
	const handleClick = (page, index) => {
		if (index !== pages.length - 1) {
			onClick(page, index)
		}
	}

	return (
		<nav className="flex pb-1 " aria-label="Breadcrumb">
			<ol role="list" className="flex space-x-2 bg-white rounded-md ">
				{pages.map((page, index) => (
					<li onClick={() => handleClick(page, index)} key={index} className={`flex ${index !== pages.length - 1 && "cursor-pointer"}`}>
						<div className="flex items-center">
							{index !== 0 &&
								<svg
									className="flex-shrink-0 w-5 h-5 text-gray-300"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
									aria-hidden="true"
								>
									<path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
								</svg>
							}

							<a
								className="ml-2 text-sm text-gray-500 hover:text-gray-700"
							>
								{page?.name}
							</a>
						</div>
					</li>
				))}
			</ol>
		</nav>)
}
