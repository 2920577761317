import { useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import KLoadingButton from '../../../components/KLoadingButton';
import KPageWrapper from '../../../components/KPageWrapper'
import KTextField from '../../../components/KTextField';
import { CREATE_DEPARTMENT } from '../../../graphql/graphqlMutations';
import { GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID } from '../../../graphql/graphqlQueries';
import { departmentGroupRoute } from '../../../routes/mainRoutes'
import { validateNewDepartment } from '../../../utils/validators';

export default function CreateDepartment({ history }) {
	const user = useSelector(state => state.user);
	const organizationId = user?.data?.organization?.id;

	const [title, setTitle] = useState();
	const [department, setDepartment] = useState({});
	const [errors, setErrors] = useState({});

	const [createRoom, { loading: submitLoading }] = useMutation(CREATE_DEPARTMENT, {
		refetchQueries: [GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID]
	})

	const handleChange = (key, setter, value) => {
		setErrors({ ...errors, [key]: false });
		setter(value)
	}

	const onCreateRoom = () => {
		const validated = validateNewDepartment({
			title: title,
			organizationId: organizationId
		})

		if (!validated.success) {
			return setErrors(validated.errors)
		}

		createRoom({
			variables: {
				input: {
					name: title,
					organizationId: parseInt(organizationId),
				}
			}
		}).then(res => {
			history.push(departmentGroupRoute);
		}).catch(err => {
			console.log(JSON.stringify(err, 2, null))
			setErrors({ ...errors, serverError: err.message });
		})
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Alle afsnit',
				route: departmentGroupRoute
			}, {
				title: 'Opret afsnit',
			}]}
			loading={submitLoading}
			errors={errors?.serverError}
		>
			<div className='flex pt-5 ' >
				<div className='flex flex-col flex-1 max-w-md gap-8 mb-6' >

					<KTextField
						error={errors?.title}
						value={title}
						label="Navn"
						required
						onChange={(value) => handleChange('title', setTitle, value)}
					/>
					<div>
						<KLoadingButton onClick={() => onCreateRoom()} title="OPRET AFSNIT" />
					</div>
				</div>
			</div>
		</KPageWrapper>
	)
}
