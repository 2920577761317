import React, { useState } from 'react'
import { FaPlayCircle } from 'react-icons/fa';
import styled from 'styled-components';
import KIcon from '../../components/KIcon';
import KPageWrapper from '../../components/KPageWrapper'
import KVideoPlayer from '../../components/KVideoPlayer';
import { dashboardRoute } from '../../routes/mainRoutes'
import Colors from '../../styles/Colors';
import { relativeGuides, workerGuides } from '../../utils/dashboardUtils';
import { Document, Page, pdfjs } from 'react-pdf';

import WorkerComputer from '../../assets/manualer/Brugermanual_Personale_Computer 29.11.2022.pdf';
import WorkerApp from '../../assets/manualer/Brugermanual_Personale 30.11.2022 App.pdf';
import RelativeApp from '../../assets/manualer/Brugermanual_Pårørende 25.11.2022.pdf';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export default function Guides() {
	const [videoUrl, setVideoUrl] = useState();
	const [showVideoPlayer, setShowVideoPlayer] = useState(false)

	const handlePlayVideo = (url) => {
		setVideoUrl(url)
		setShowVideoPlayer(true)
	}

	const onClickTest = (url) => {
		window.open(url, '_blank');
	}
	return (
		<KPageWrapper
			paths={[{
				title: 'Overblik',
				route: dashboardRoute
			}, {
				title: 'Hjælp'
			}]}
		>
			<Container>
				<Content>
					<Title>Brugermanualer</Title>
					<VideoContainer onClick={() => onClickTest(WorkerComputer)}>
						<p>Brugermanual Personale Computer</p>
						<KIcon style={{ color: Colors.darkPurple }} size={16} Icon={FaPlayCircle} />
					</VideoContainer>
					<VideoContainer onClick={() => onClickTest(WorkerApp)}>
						<p>Brugermanual Personale App</p>
						<KIcon style={{ color: Colors.darkPurple }} size={16} Icon={FaPlayCircle} />
					</VideoContainer>
					<VideoContainer onClick={() => onClickTest(RelativeApp)}>
						<p>Brugermanual Pårørende</p>
						<KIcon style={{ color: Colors.darkPurple }} size={16} Icon={FaPlayCircle} />
					</VideoContainer>

					<Title marginTop={20}>Teknisk support</Title>
					<p> <EmailIcon fontSize='inherit' />  support@kintella.com</p>
					<p> <PhoneIcon fontSize='inherit' />  93 98 03 34</p>

				</Content>
				<Content>
					<Title>Personale videoer</Title>
					{workerGuides?.map(guide => (
						<VideoContainer onClick={() => handlePlayVideo(guide.url)}>
							<p>{guide?.title}</p>
							<KIcon style={{ color: Colors.darkPurple }} size={16} Icon={FaPlayCircle} />
						</VideoContainer>
					))}
					<Title marginTop={20} >Pårørende videoer</Title>
					{relativeGuides?.map(guide => (
						<VideoContainer onClick={() => handlePlayVideo(guide.url)}>
							<p>{guide?.title}</p>
							<KIcon style={{ color: Colors.darkPurple }} size={16} Icon={FaPlayCircle} />
						</VideoContainer>
					))}
				</Content>
			</Container>
			<KVideoPlayer open={showVideoPlayer} setOpen={setShowVideoPlayer} url={videoUrl} />
		</KPageWrapper>
	)
}

const Container = styled.div(() => ({
	display: 'flex',
	gap: 50,
	marginTop: 10
}))

const Content = styled.div(() => ({
	flex: 1
}))

const Title = styled.p(({ marginTop }) => ({
	fontSize: 20,
	fontFamily: 'MetricSemiBold',
	color: Colors.mediumBlack,
	marginTop
}))

const VideoContainer = styled.div(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	border: '1px solid #c8c8c8',
	padding: '5px 10px',
	cursor: 'pointer',
	marginTop: 10
}))
