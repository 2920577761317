import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	GET_ALL_RESIDENTS_BY_DEPARTMENT_ID,
	GET_ALL_RESIDENTS_BY_ORGANIZATION_ID,
	GET_RESIDENT_BY_ID,
} from "../../graphql/graphqlQueries";
import {
	residentSortingOptions, residentSortingOptionsList, residentStatusOptions, residentStatusOptionsList, residentStatusOptionsListIds, residentTimelineOptionsList,
} from "../../utils/residentUtils";
import Avatar from "@mui/material/Avatar";
import KButton from "../../components/KButton";
import {
	createResidentRoute,
	residentInfomationRoute,
} from "../../routes/residentRoutes";
import KPageWrapper from "../../components/KPageWrapper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import KSection from "../../components/cards/KSection";
import KGlobalFilterTable from "../../components/graphql/KGlobalFilterTable";
import { classNames } from "../../utils/tailwindUtils";
import ReactTooltip from 'react-tooltip'
import { unixToDateTimeString } from "../../utils/dateUtils";
import KSearchInput from "../../components/inputs/KSearchInput";
import _ from "lodash";
import KTimeline from "../../components/stepper/KTimeline";
import { setPageSize, setResidentStatusFilter } from "../../redux/slices/residentsFilterSlice";

export default function Residents({ filter, match }) {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const residentsFilter = useSelector(state => state.residentsFilter)

	console.log('Filter state', residentsFilter.pageSize);

	const history = useHistory();

	const [search, setSearch] = useState();
	const [sorting, setSorting] = useState(residentSortingOptions.NAME_ASC);
	// const [filters, setFilters] = useState(residentStatusOptionsList);
	// const [activeFilter, setActiveFilter] = useState(residentStatusOptions.ALL)

	const setActiveFilter = (filter) => {
		dispatch(setResidentStatusFilter(filter));
	}

	const RenderItem = ({ item, index }) => {
		const [relativeStatus, setRelativeStatus] = useState();

		const residentTimeline = residentTimelineOptionsList(item);
		const isFinish = residentTimeline?.every(e => e?.tasks?.every(t => t?.complete))

		// console.log(item)

		useEffect(() => {
			if (item?.relatives?.some(e => e?.user?.active)) return setRelativeStatus('Aktiv')
			if (item?.relatives?.some(e => e?.user?.invitationSend)) return setRelativeStatus('Inviteret')
			setRelativeStatus('Afventer')
		}, [item])

		const handleRelativeClick = (e) => {
			e.stopPropagation();

			history.push(`${residentInfomationRoute}/${item.id}`, { relatives: true })
		}

		return (
			<tr
				className={classNames("cursor-pointer hover:bg-gray-50")}
				key={index}
				onClick={() =>
					history.push(`${residentInfomationRoute}/${item.id}`)
				}
			>

				<td className="py-2 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
					<div className="flex-shrink-0 w-8 h-8">
						<Avatar className="w-8 h-8 rounded-full" src={item?.imageUrl} alt="" />
					</div>
				</td>
				<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
					<div className="text-gray-900">{item?.fullName ? item?.fullName : '-'}</div>
				</td>
				<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
					<div className="text-gray-900">{item?.room ? item?.room?.department?.name : '-'}</div>
				</td>
				<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
					<div className="text-gray-900">{item?.room ? item?.room?.name : '-'}</div>
				</td>
				<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
					<div className="text-gray-900">{unixToDateTimeString(item?.createdAt)}</div>
				</td>
				<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
					{item?.status === residentStatusOptions.ACTIVE?.id &&
						<div style={{ minWidth: 160 }} className="inline-flex justify-center py-1 bg-green-500 rounded">
							<p className={classNames(
								"text-white font-medium"
							)}>Aktiv</p>
						</div>
					}

					{item?.status !== residentStatusOptions.ACTIVE?.id &&
						<div
							data-for={item?.id}
							data-tip
							style={{ minWidth: 160 }}
							className={classNames(
								isFinish ? "bg-green-200" : "bg-yellow-200",
								"items-center justify-center flex-1 inline-block gap-2 px-6 py-1 rounded cursor-pointer text-center"
							)}
						>
							<div>
								<p className="font-medium text-gray-700">{isFinish ? 'Klar til aktivering' : 'Afventer handling'}</p>
								<ReactTooltip place="right" className="shadow-lg opacity-100" effect="solid" backgroundColor="#fff" id={item?.id} aria-haspopup="true">
									<KTimeline
										steps={residentTimeline}
									/>
								</ReactTooltip>
							</div>
						</div>
					}
				</td>
				<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
					<div onClick={handleRelativeClick} style={{ minWidth: 160 }} className={classNames(
						relativeStatus === 'Aktiv' ? 'bg-green-500' : '',
						relativeStatus === 'Inviteret' ? 'bg-yellow-200' : '',
						relativeStatus === 'Afventer' ? 'bg-gray-300' : '',
						"inline-flex justify-center py-1 rounded"
					)}>
						<p className={classNames(
							relativeStatus === 'Aktiv' ? 'text-white' : 'text-gray-700',
							"font-medium"
						)}>{relativeStatus}</p>
					</div>
				</td>
			</tr >
		)
	}


	const handleSortChange = (sort) => {
		if (sort?.id === sorting?.id) {
			const foundSort = residentSortingOptionsList?.find(e => e?.name === sorting?.name && e?.asc !== sorting?.asc)
			return setSorting(foundSort)
		}

		setSorting(sort)
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Borgeroversigt'
			}]}
			buttons={[
				<KButton
					label="OPRET BORGER"
					onClick={() => history.push(createResidentRoute)}
				/>,
			]}
		>
			<div className="p-4">
				<div className="grid grid-cols-1 gap-y-5">
					<KSection
						title="Borgere i Kintella"
						description="Her kan du se alle de borgere, der er i Kintella. Trykker du på Aktive, får du vist de borgere, der er oprettet i Kintella, og hvor der er afsendt en invitation til minimum én pårørende. Trykker du på Afventer handling, ser du de borgere, hvor du mangler at udføre en eller flere handlinger, før borgeren kan blive aktiveret i Kintella."
					>
						<div>
							<div className="flex gap-x-2">
								{residentStatusOptionsList?.map(filter => {
									return (
										<div
											onClick={() => setActiveFilter(filter)}
											className={classNames(
												filter?.id === residentsFilter.residentStatus?.id ? 'bg-darkPurple' : '',
												"inline-block px-5 w-36 py-0.5 bg-gray-100 border border-darkPurple rounded cursor-pointer"
											)}
										>
											<div className="flex items-center justify-center">
												<p className={classNames(
													filter?.id === residentsFilter.residentStatus?.id ? 'text-gray-50' : 'text-gray-700',
													"text-sm font-semibold "
												)}>
													{filter?.name}
												</p>
											</div>
										</div>
									)
								})}
							</div>
							<div className="grid grid-cols-2 mt-4">
								<div>
									<KSearchInput
										placeholder={filter?.search}
										height="h-8"
										value={search}
										onChange={(v) => setSearch(v)}
									/>
								</div>
							</div>

							<KGlobalFilterTable
								headers={['Billede', 'Navn', 'Afsnit', 'Bolig', 'Oprettet', 'Borger status', 'Pårørende status']}
								RenderItem={RenderItem}
								residentQuery={GET_RESIDENT_BY_ID}
								departmentQuery={GET_ALL_RESIDENTS_BY_DEPARTMENT_ID}
								organizationQuery={
									GET_ALL_RESIDENTS_BY_ORGANIZATION_ID
								}
								pageSize={residentsFilter.pageSize}
								setPageSize={(e) => dispatch(setPageSize(e))}
								type="borgere"
								ordering={sorting}
								setOrdering={(e) => handleSortChange(e)}
								searchValue={search}
								filters={residentsFilter.residentStatus?.id === residentStatusOptions.ALL.id ? residentStatusOptionsListIds : [residentsFilter.residentStatus.id]}
							/>
						</div>
					</KSection>
				</div>
			</div>
		</KPageWrapper>
	);
}
