import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getKmdToken, loginKmd } from "../../utils/KmdUtils";
import { useKMD } from "../../config";
import { useDispatch } from "react-redux";
import { setKMDAuth, setLoggedIn } from "../../redux/slices/userSlice";
import { dashboardRoute, selectOrganizationRoute, residentsRoute } from "../../routes/mainRoutes";

export default function KmdLoginPage({ history }) {
	const user = useSelector(state => state.user);

	const dispatch = useDispatch();

	// Listens on redux user state, and pushes to dashboard if user changes to authenticated
	useEffect(() => {
		if (user.loggedIn && !user.KMDAuth && useKMD) {
			return loginKmd(user.data.municipality);
		}

		if (user.loggedIn) {
			if (user?.data?.organization === null) {
				history.push(selectOrganizationRoute, {
					user: user,
					role: user.data.role.name
				})
			} else {
				history.push(residentsRoute);
			}
		}
	}, [user.loggedIn]);

	// listen for KMD token, and set loggedIn to redirect to home
	useEffect(() => {
		const token = getKmdToken();
		if (token?.isValid) {
			dispatch(setKMDAuth(token));
			dispatch(setLoggedIn(true));
		}
	}, []);

	return (
		<div className="flex flex-row flex-1 h-screen">

		</div>
	);
}
