import React, { useEffect, useState } from 'react'
import KFileCard from '../cards/KFileCard';
import KSection from '../cards/KSection'
import KImageDrop from '../inputs/KImageDrop'
import KButton from '../KButton';
import KModal from '../modals/KModal';
import KResidentSelect from '../selectors/KResidentSelect';
import DummyImage from '../../assets/dummyPerson.jpeg'
import { allowedImageFiles, allowedImageTypes, allowedVideoFiles, allowedVideoTypes } from '../../utils/fileUtils';
import { GET_ALL_RESIDENTS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries';
import { useQuery } from '@apollo/client';
import { residentStatusOptions } from '../../utils/residentUtils';
import { useSelector } from 'react-redux';

export default function KImageForm({ images, setImages, taggedResidents, setTaggedResidents, selectedResidents }) {
	const user = useSelector(state => state.user);

	const [showTagResident, setShowTagResident] = useState();
	const [residents, setResidents] = useState();

	const { data: residentsData, loading: residentsLoading } = useQuery(GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, {
		variables: { id: user.data.organization.id, size: 999, filters: residentStatusOptions.ACTIVE.id },
	});

	useEffect(() => {
		if (residentsData) {
			const residents = residentsData[Object.keys(residentsData)[0]]?.results;
			setResidents(residents)
		}
	}, [residentsData])

	const handleImageChange = (values) => {
		if (images) {
			const allImages = [...images, ...values?.map(val => {
				return {
					file: val,
					obj: URL.createObjectURL(val)
				}
			})];

			setImages(allImages)
		} else {
			setImages(values?.map(val => {
				return {
					file: val,
					obj: URL.createObjectURL(val)
				}
			}))
		}
	}

	const handleTagResident = (sResidents) => {
		const newResidents = [...sResidents];
		newResidents.sort((a, b) => (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0))
		setTaggedResidents(newResidents)
	}

	const removeImage = (index) => {
		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages)
	}

	const validateSelectShown = (resident) => {
		if (!resident.residentConsent.useStatus) {
			return false
		}

		return true;
	}

	return (
		<KSection
			title="Billeder"
			description="Her kan du tilføje billeder."
			loading={residentsLoading}
		>
			<KModal open={showTagResident} setOpen={setShowTagResident} disableCloseOutside={true}>
				<KResidentSelect
					residents={residents}
					selected={taggedResidents}
					onSelect={handleTagResident}
					title={taggedResidents?.length > 0 ? 'Rediger tydelige modtagere' : 'Vælg tydelige modtagere'}
					setOpen={(e) => setShowTagResident(e)}
					validation={validateSelectShown}
					selectedText="Valgt modtager"
					selectedValidation={(id) => selectedResidents?.some(e => e.id == id)}
					hideOptions={true}
				/>
			</KModal>
			<div className="grid grid-cols-1 divide-y gap-x-4 gap-y-4">
				<KImageDrop
					height={150}
					handleDrop={(file) => handleImageChange(file)}
					title="Tilføj billeder"
					acceptedFiles={[...allowedImageTypes, ...allowedVideoTypes].map(e => e?.toUpperCase())}
					acceptedSize="20MB"
					accept={allowedImageFiles.concat(allowedVideoFiles)}
				/>
				<div className="grid grid-cols-4 pt-6 gap-x-2 gap-y-2">
					{images?.map((image, index) => {
						return <KFileCard isImage={true} file={image} handleRemove={() => removeImage(index)} />
					})}
				</div>
				{images && images?.length > 0 && setTaggedResidents &&
					<div>

						<div className="mt-4 mb-2">
							<p className="text-lg font-semibold text-gray-700">Marker tydelige personer</p>
							<p className="mb-4 text-base text-gray-500">Hvis du tydeligt kan identificere borgere på nogle af billederne (fx portrætbillede), skal du aktivt angive (tagge) disse personer. Dette skyldes lovgivning om personfølsom data</p>
							<KButton
								label={taggedResidents?.length > 0 ? 'Rediger tydelige modtagere' : 'Vælg tydelige personer'}
								onClick={() => setShowTagResident(true)}
							/>
							<div className="grid grid-cols-5 mt-4 gap-x-2">
								{taggedResidents?.map(resident => {
									return (
										<div className="flex items-center p-2 border rounded gap-x-2">
											<img className="w-8 h-8 rounded-full" src={resident?.imageUrl ? resident?.imageUrl : DummyImage} />
											<div>
												<p>{resident?.fullName}</p>
												<div className="flex gap-x-2">
													{/* <p className="text-xs text-gray-500">{resident?.room?.name},</p> */}
													<p className="text-xs text-gray-500">{resident?.room?.department?.name}</p>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				}
			</div>

		</KSection>
	)
}
