import { useMutation } from '@apollo/client';
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux';
import KSection from '../../components/cards/KSection';
import KSubWrapper from '../../components/containers/KSubWrapper';
import KButton from '../../components/KButton'
import KSnackbar from '../../components/KSnackbar';
import KConfirmModal from '../../components/modals/KConfirmModal'
import { DELETE_RESIDENT_BY_ID } from '../../graphql/graphqlMutations';
import { GET_ALL_RESIDENTS_BY_DEPARTMENT_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, GET_RESIDENT_BY_ID } from '../../graphql/graphqlQueries';
import { residentsRoute } from '../../routes/mainRoutes';
import { alertWarningMessages } from '../../utils/globalTypes';

export default function DeleteResident({ history, resident }) {
	const user = useSelector(state => state.user);

	const [showDelete, setShowDelete] = useState(false);
	const [message, setMessage] = useState({ msg: "", type: "" });

	const [doDeleteResident, { loading: deleteLoading }] = useMutation(DELETE_RESIDENT_BY_ID, {
		variables: { input: { executorId: user?.data?.id, residentId: resident?.id } },
		refetchQueries: [GET_RESIDENT_BY_ID, GET_ALL_RESIDENTS_BY_DEPARTMENT_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID]
	})

	const handleDelete = async () => {
		try {
			await doDeleteResident()
			setMessage(alertWarningMessages.DELETED('Borger'))
			history.push(residentsRoute)
		} catch (err) {

		}
	}

	return (
		<KSubWrapper
			title="Afvikling"
			hideSubmit={true}
		>
			<KSection
				title="Fjern borger fra Kintella"
				description="Her kan du afvikle en borger. Dette sker ikke automatisk, når borgeren slettes i omsorgssystemet (fx KMD Nexus).
				Tryk på Fjern borger fra Kintella og bekræft efterfølgende dit valg.
				Derefter vil borgeren være slettet fra Kintella.
				Bemærk: Hvis du afvikler en borger, kan de indtastede data, pårørendeoversigt mv. ikke genskabes."
			>
				<KButton onClick={() => setShowDelete(true)} label="Fjern borger fra Kintella" />
			</KSection>
			<KConfirmModal
				open={showDelete}
				setOpen={setShowDelete}
				confirmText="Fjern borger fra Kintella"
				title="Afvikling af borger"
				description="Er du sikker på at du vil afvikle borgeren?"
				handleConfirm={() => handleDelete()}
			/>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KSubWrapper>
	)
}
