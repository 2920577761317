export const getActivityStatistics = (statistics) => [{
	title: 'Nyheder',
	value: statistics?.news
}, {
	title: 'Opgaver',
	value: statistics?.todos
}, {
	title: 'Kalenderaktiviteter',
	value: statistics?.calendar
}]

export const getUserStatistics = (statistics, key) => [{
	title: 'Total',
	// value: statistics ? statistics[key] : null
	value: 0
}, {
	title: 'Aktive (Denne uge)',
	value: 0
}, {
	title: 'Aktive (Denne måned)',
	value: 0
}]

export const guidesPreview = [{
	title: 'Kom i gang med Kintella fra app som personale',
	url: 'videos/Kom i gang med Kintella fra app som personale.mp4'
}, {
	title: 'Grundfunktioner i Kintella for personale',
	url: 'videos/Grundfunktioner i Kintella for personale.mp4'
}]

export const workerGuides = [{
	title: 'Kom i gang med Kintella fra app som personale',
	url: 'videos/Kom i gang med Kintella fra app som personale.mp4'
}, {
	title: 'Kom i gang med Kintella fra computer som personale',
	url: 'videos/Kom i gang med Kintella fra computer som personale.mp4'
},
// {
// 	title: 'Opret en borger i Kintella som personale',
// 	url: 'videos/Opret en borger i Kintella som personale.mp4'
// }, 
{
	title: 'Grundfunktioner i Kintella for personale',
	url: 'videos/Grundfunktioner i Kintella for personale .mp4'
}, {
	title: 'Opret kalenderaftale i Nexus til Kintella',
	url: 'videos/Opret kalenderaftale i Nexus til Kintella.mp4'
}, {
	title: 'Opret kalenderaktivtet i Kintella',
	url: 'videos/Opret kalenderaktivtet i Kintella.mp4'
}]

export const relativeGuides = [{
	title: 'Gør klar til Kintella som pårørende',
	url: 'videos/Goer klar til Kintella som paaroerende.mp4'
}, {
	title: 'Grundfunktioner i Kintella for pårørende',
	url: 'videos/Grundfunktioner i Kintella for paaroerende.mp4'
}, {
	title: 'Kom i gang med Kintella som pårørende',
	url: 'videos/Kom i gang med Kintella som paaroerende.mp4'
}]

export const statisticLevels = {
	ALL: { label: 'Alle', value: 'ALL' },
	RELATIVES: { label: 'Pårørende', value: 'RELATIVES' },
	WORKERS: { label: 'Personale', value: 'WORKERS' },
	RESIDENT: { label: 'Borger', value: 'RESIDENT' },
}

export const statisticLevelsList = [
	statisticLevels.ALL,
	statisticLevels.RELATIVES,
	statisticLevels.WORKERS,
	statisticLevels.RESIDENT,
]

export const statisticTypes = {
	USER: { label: 'Brugere', value: 'USER' },
	CALENDAR: { label: 'Kalender', value: 'CALENDAR' },
	TODO: { label: 'Opgaver', value: 'TODO' },
	NEWS: { label: 'Nyheder', value: 'NEWS' },
}

export const statisticTypesNoUsers = {
	CALENDAR: { label: 'Kalender', value: 'CALENDAR' },
	TODO: { label: 'Opgaver', value: 'TODO' },
	NEWS: { label: 'Nyheder', value: 'NEWS' },
}

export const statisticTypesList = [
	statisticTypes.USER,
	statisticTypes.CALENDAR,
	statisticTypes.TODO,
	statisticTypes.NEWS,
]

export const statisticTypesNoUsersList = [
	statisticTypesNoUsers.CALENDAR,
	statisticTypesNoUsers.TODO,
	statisticTypesNoUsers.NEWS,
]

