import { useMutation, useQuery } from '@apollo/client'
import { Button, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import KPageWrapper from '../../components/KPageWrapper'
import KTextField from '../../components/KTextField'
import KImageDrop from '../../components/KImageDrop'
import {
	GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID,
	GET_CALENDAR_ACTIVITY_TEMPLATE_BY_ID,
} from '../../graphql/graphqlQueries'
import { calendarActivityTemplatesRoute } from '../../routes/mainRoutes'
import moment from 'moment';
import { UPDATE_CALENDAR_ACTIVITY_TEMPLATE, DELETE_CALENDAR_ACTIVITY_TEMPLATE_BY_ID } from '../../graphql/graphqlMutations'
import { validateActivityTemplate } from '../../utils/validators'
import ImageUploadQuery from '../../graphql/ImageUploadQuery'
import { UPLOAD_IMAGE } from '../../graphql/graphqlMutations';
import KButton from '../../components/KButton'
import _ from 'lodash'
import KModal from '../../components/KModal'
import Colors from '../../styles/Colors'
export default function CalendarActivityTemplate({ match, history, activity }) {

	const user = useSelector(state => state.user);

	const [template, setTemplate] = useState();
	const [templateCopy, setTemplateCopy] = useState();
	const [showModal, setShowModal] = useState('')
	const [images, setImages] = useState(activity?.imageUrl);
	const [errors, setErrors] = useState();

	const { data } = useQuery(GET_CALENDAR_ACTIVITY_TEMPLATE_BY_ID, {
		variables: { id: match?.params?.id }
	})

	const [doUploadImage, { loading: uploadLoading }] = useMutation(UPLOAD_IMAGE);

	const [doUpdateActivityTemplate, { loading: submitLoading }] = useMutation(UPDATE_CALENDAR_ACTIVITY_TEMPLATE, {
		refetchQueries: [GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID]
	})

	const [doDeleteActivityTemplate, { loading: deleteLoading }] = useMutation(DELETE_CALENDAR_ACTIVITY_TEMPLATE_BY_ID, {
		refetchQueries: [GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID]
	})

	useEffect(() => {
		const d = data?.getCalendarActivityTemplateById;
		console.log(typeof d?.startDate)
		setTemplate(d)
		d?.imageUrl && setImages([{ obj: d?.imageUrl, file: null }])
		setTemplateCopy(d)
	}, [data])


	const handleChange = (key, value) => {
		// console.log("handleChange", key, value)
		setErrors({
			...errors,
			[key]: null
		});
		setTemplate({
			...template,
			[key]: value
		});
	}

	const handleDelete = async () => {
		await doDeleteActivityTemplate({ variables: { id: templateCopy?.id } })
		history.goBack()
	}


	const handleImageChange = (values) => {
		setTemplate({
			...template,
			imageUrl: values
		})
		setImages(values?.map(val => {
			return {
				file: val,
				obj: URL.createObjectURL(val)
			}
		}))
	}


	const makeUpdatedCalendarActivityTemplate = (url) => {

		const validated = validateActivityTemplate({
			title: template?.title,
			description: template?.title,
			templateName: template?.templateName
		});
		if (!validated.success) {
			return setErrors(validated.errors)
		}

		const { title, description, templateName, actionRequired, startDate, endDate, note, id } = template;

		const variables = {
			input: {
				id: id,
				creatorId: user?.data?.id,
				departmentId: 1, // TODO
				organizationId: user?.data?.organization?.id,
				title: title,
				templateName: templateName,
				description: description,
				actionRequired: actionRequired,
				imageUrl: url,
				note: note
			}
		}
		doUpdateActivityTemplate({ variables })
			.then(res => {
				// console.log(res)
				history.goBack();
			}).catch(err => {
				console.log(err)
				console.log(JSON.stringify(err, 2, null))
			})
	}


	const updateActivity = (isDraft) => {
		const validated = validateActivityTemplate({
			title: template?.title,
			description: template?.title,
			templateName: template?.templateName
		});

		if (!validated.success) {
			return setErrors(validated.errors)
		}

		if (images) {
			const imgObj = images[0]
			if (imgObj.file) {
				ImageUploadQuery(makeUpdatedCalendarActivityTemplate, images[0]?.file, doUploadImage)
			}
			if (imgObj.obj && !imgObj.file) {
				makeUpdatedCalendarActivityTemplate(imgObj.obj)
			}
		} else {
			makeUpdatedCalendarActivityTemplate(null)
		}

	};

	return (
		<KPageWrapper
			paths={[{
				title: 'Skabeloner',
				route: calendarActivityTemplatesRoute
			}, {
				title: 'Opdater skabelon'
			}]}
			buttons={[
				!_.isEqual(template, templateCopy) &&
				<KButton
					label="GEM"
					onClick={() => {
						updateActivity(false)
					}}
				/>,
				<KButton
					label="SLET SKABELON"
					onClick={() => setShowModal(true)}
				/>,
			]}
		// loading={[submitLoading, uploadLoading, calendarActivityTemplatesLoading].some(val => val === true)}
		>
			<Container>
				<KModal
					open={showModal}
					title={"Slet skabelon"}
					subTitle={"Er du sikker på at du vil slette skabelonen?"}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						<ModalButton fullWidth onClick={() => handleDelete()}>Slet</ModalButton>
						<ModalButton fullWidth onClick={() => setShowModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>
				<Content>
					<KTextField
						error={errors?.templateName}
						value={template?.templateName}
						label="Navn på skabelon"
						required
						onChange={(value) => handleChange('templateName', value)}
					/>
					<KTextField
						error={errors?.title}
						value={template?.title}
						label="Titel"
						required
						onChange={(value) => handleChange('title', value)}
					/>
					<KTextField
						error={errors?.description}
						value={template?.description}
						label="Beskrivelse"
						onChange={(value) => handleChange('description', value)}
						multiline
						rows={6.2}
					/>
					<KTextField
						value={template?.note}
						label="Medarbejdernote (Vil ikke blive vist for pårørende)"
						onChange={(value) => handleChange('note', value)}
						multiline
						rows={4}
					/>

					<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
						<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
							<Checkbox checked={template ? template?.actionRequired : false} onChange={() => handleChange('actionRequired', !template?.actionRequired)} />
							<p>Kræver svar fra pårørende</p>
						</div>
					</div>
				</Content>
				<Content>

					<Content>
						<KImageDrop
							height={370}
							handleDrop={(file) => handleImageChange(file)}
							onClear={() => handleImageChange(null)}
							files={images}
						/>
					</Content>


				</Content>
			</Container>
		</KPageWrapper>
	)
}

const Container = styled.div({
	display: 'flex',
	gap: 20,
	paddingTop: 20
})

const Content = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	gap: 20
})

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,

		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))