const jwt = require("jsonwebtoken");
const axios = require('axios');

const KOMBIT_LOGIN_URL = process.env.REACT_APP_KOMBIT_LOGIN_URL;
const KOMBIT_LOGOUT_URL = process.env.REACT_APP_KOMBIT_LOGOUT_URL;
// const SECRET = "supersecret";
const SECRET = "3zf&25gSf2E#Kx^3Zg%@KmPCq$y@";

// const jwt = require("jsonwebtoken");

// const KOMBIT_LOGIN_URL = process.env.REACT_APP_KOMBIT_LOGIN_URL;
// const KOMBIT_LOGOUT_URL = process.env.REACT_APP_KOMBIT_LOGOUT_URL;
// const SECRET = "3zf&25gSf2E#Kx^3Zg%@KmPCq$y@";

export const loginKombit = () => {
	var url = `${KOMBIT_LOGIN_URL}?RelayState=${window.location.href}`;
	window.open(url, "_self");
};

export const logoutKombit = (nameID, sessionIndex) => {
	var url = KOMBIT_LOGOUT_URL;

	const finalUrl = `${url}?nameID=${nameID}&sessionIndex=${sessionIndex}&RelayState=${window.location.href}`
	return finalUrl
};

export const getKombitDetails = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const login = urlParams.get("login");
	try {
		const payload = jwt.verify(login, SECRET);
		return { verified: true, payload: payload };
	} catch (err) {
		console.log(err)
		return { verified: false, payload: null };
	}
};
