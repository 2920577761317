import React from "react";
import { IconContext } from "react-icons";

export default function KIcon({ Icon, className, size, style }) {
	return (
		<IconContext.Provider value={{ size: size ? size : 24, className: "global-class-name" }}>
			<div>
				<Icon style={style} className={className} />
			</div>
		</IconContext.Provider>
	);
}
