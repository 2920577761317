import { useState, useEffect } from 'react'

import KSnackbar from '../../../../components/KSnackbar';
import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_RESIDENT_CONSENT } from '../../../../graphql/graphqlMutations';
import { GET_CHANGELOG_RESIDENT_CONSENT_BY_RESIDENT_ID, GET_RESIDENT_BY_ID, GET_RESIDENT_CONSENT_STATUS_BY_RESIDENT_ID } from '../../../../graphql/graphqlQueries';
import _ from 'lodash';
import KSubWrapper from '../../../../components/containers/KSubWrapper';
import KFeed from '../../../../components/feeds/KFeed';
import { defaultResidentConsent, residentConsentSectionList } from '../../../../utils/residentUtils';
import { isRelativeNoData } from '../../../../utils/roles';
import KSection from '../../../../components/cards/KSection';
import KToggle from '../../../../components/pickers/toggle/KToggle';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { alertWarningMessages } from '../../../../utils/globalTypes';

export default function ConsentSub({ resident }) {
	const user = useSelector(state => state.user);

	const [message, setMessage] = useState({ msg: "", type: "" });
	const [changeLog, setChangeLog] = useState();

	const [residentConstentStatus, setResidentConstentStatus] = useState(defaultResidentConsent);
	const [residentConstentStatusCopy, setResidentConstentStatusCopy] = useState(defaultResidentConsent);

	const [doResidentConsentUpdate, { loading: updateLoading }] = useMutation(UPDATE_RESIDENT_CONSENT, {
		refetchQueries: [GET_RESIDENT_BY_ID, GET_RESIDENT_CONSENT_STATUS_BY_RESIDENT_ID, GET_CHANGELOG_RESIDENT_CONSENT_BY_RESIDENT_ID]
	});

	const { loading: consentLoading, data: consentData } = useQuery(GET_RESIDENT_CONSENT_STATUS_BY_RESIDENT_ID, {
		variables: { id: resident?.id },
		fetchPolicy: "no-cache"
	});

	const { data: changeLogData, error: changeLogError, loading: changeLogLoading } = useQuery(GET_CHANGELOG_RESIDENT_CONSENT_BY_RESIDENT_ID, {
		variables: { residentId: resident?.id },
		fetchPolicy: "no-cache"
	})

	useEffect(() => {
		setChangeLog(changeLogData?.getResidentConsentChangeLogByResidentId?.map((e) => {
			return {
				...e,
				title: e?.user?.fullName,
				description: 'opdaterede samtykke',
				badgeText: `${isRelativeNoData(e?.user) ? 'Pårørende' : 'Personale'}`,
				color: `${isRelativeNoData(e?.user) ? 'bg-darkPurple' : 'bg-gray-200'}`,
				footerText: moment(parseInt(e?.createdAt)).format('DD-MM-YYYY HH:mm'),
			}
		}))
	}, [changeLogData])

	useEffect(() => {
		if (consentData) {
			setResidentConstentStatus(consentData?.getResidentConsentById);
			setResidentConstentStatusCopy(consentData?.getResidentConsentById);
		}
	}, [consentData]);

	const updateConsent = async (consent) => {
		try {
			await doResidentConsentUpdate({
				variables: {
					input: {
						executorId: user.data.id,
						residentId: parseInt(resident.id),
						useStatus: residentConstentStatus.useStatus,
						websiteStatus: residentConstentStatus.websiteStatus,
						newsStatus: residentConstentStatus.newsStatus,
						inviteStatus: residentConstentStatus.inviteStatus,
						infoStatus: residentConstentStatus.infoStatus,
					},
				},
			})

			setMessage(alertWarningMessages.UPDATED('Borgerens samtykke'));
		} catch (error) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const handleChange = (key, value) => {
		setResidentConstentStatus({ ...residentConstentStatus, [key]: value });
	}

	return (
		<KSubWrapper
			title="Samtykke"
			loading={consentLoading || updateLoading || changeLogLoading}
			onSubmit={updateConsent}
			showSubmit={!_.isEqual(residentConstentStatus, residentConstentStatusCopy)}
		>
			<div>
				<div class="grid grid-cols-10 h-full 	">
					<div class="col-span-7 mr-4 divide-y divide-gray-200">
						<div className="flex flex-col gap-10">
							{residentConsentSectionList?.map(section => (
								<KSection title={section?.title} description={section?.description}>
									<div className="mt-2 divide-y divide-gray-200 ">
										{section?.options?.map((setting, index) =>
											<div className="flex items-center justify-between gap-4 py-4 mt-2">
												<div className="">
													<p className="font-semibold text-gray-900 text-md">{setting?.title}</p>
													<p className="text-sm text-gray-500">{setting?.description}</p>
												</div >
												<KToggle
													checked={residentConstentStatus[setting.key]}
													onChange={(value) => handleChange(setting.key, value)}
												/>
											</div>
										)}
									</div>
								</KSection>

							))}
						</div>

					</div>
					<div class="col-span-3 pl-2 pr-2">
						<p className="text-lg font-bold">Historik</p>
						{!changeLog || changeLog?.length === 0 &&
							<p>Der blev ikke fundet nogen historik.</p>
						}
						<KFeed elements={changeLog} />
					</div>
				</div>

				<KSnackbar
					open={message.msg}
					setOpen={(v) => { setMessage({ ...message, msg: v }) }}
					title={message.msg}
					duration={6000}
					verticalPosition="bottom"
					horizontalPosition="center"
					type={message.type}
				/>
			</div>
		</KSubWrapper>
	)
}
