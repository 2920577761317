import { createSlice } from "@reduxjs/toolkit";
import { residentStatusOptions } from "../../utils/residentUtils";

export const residentsFilterSlice = createSlice({
	name: "residentsFilter",
	initialState: { pageSize: 25, residentStatus: residentStatusOptions.ALL },
	reducers: {
		setPageSize: (state, action) => {
			state.pageSize = action.payload;
		},
		setResidentStatusFilter: (state, action) => {
			state.residentStatus = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setPageSize,
	setResidentStatusFilter,
} = residentsFilterSlice.actions;

export default residentsFilterSlice.reducer;
