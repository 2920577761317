/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
	globalFilterTypes,
	getGlobalFilterType,
} from "../utils/globalFilterUtils";
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";

import KLoader from "../components/KLoader";

export default function GlobalFilterQuery({
	RenderItem,
	residentQuery,
	departmentQuery,
	organizationQuery,
	pageSize,
	ordering,
	columns,
	filterMethod,
	sortMethod,
	TableToolBar,
	customQuery,
	filters
}) {
	const user = useSelector((state) => state.user);
	const globalFilter = useSelector((state) => state.globalFilter);
	const [page] = useState(0);
	const size = pageSize ? pageSize : 20;
	const order = ordering ? ordering : ["updatedAt", "desc"];
	var gType = "";


	const [rows, setRows] = useState()
	const [rowsPerPage] = useState(5)
	const [tablePage, setTablePage] = useState(0);

	const getId = () => {
		if (customQuery) return customQuery?.id;
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return globalFilter.residentFilter.id;
			case globalFilterTypes.DEPARTMENT:
				return globalFilter.departmentFilter.id;
			case globalFilterTypes.ORGANIZATION:
				return user.data.organization.id;
		}
	};

	const pickQurey = () => {
		if (customQuery) return customQuery?.query;
		else {
			gType = getGlobalFilterType(globalFilter);
			switch (gType) {
				case globalFilterTypes.RESIDENT:
					if (residentQuery !== null) {
						return residentQuery;
					}
					return organizationQuery;
				case globalFilterTypes.DEPARTMENT:
					return departmentQuery;
				case globalFilterTypes.ORGANIZATION:
					return organizationQuery;
			}
		}
	};

	const { loading, data, error } = useQuery(pickQurey(), {
		variables: { size: size, page: page, order: order, id: getId(), filters: filters },
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		if (data) {
			const dataObj = data[Object.keys(data)[0]];
			if (dataObj?.results) {
				setRows(dataObj?.results)
			} else {
				setRows([dataObj])
			}
		}
	}, [data])

	const getList = () => {
		const filtered = filterMethod
			? rows?.filter((item) => filterMethod(item))
			: rows;

		sortMethod && filtered?.sort((a, b) => sortMethod(a, b));
		return filtered;
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = tablePage > 0 ? Math.max(0, (1 + tablePage) * rowsPerPage - getList().length) : 0;

	if (loading) {
		return <KLoader />
	}

	if (error) {
		return <p>{JSON.stringify(error, null, 2)}</p>;
	}

	return (
		<div>
			{TableToolBar ? <TableToolBar /> : null}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							{columns?.map(column => {
								return <TableCell style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, fontFamily: 'MetricBold' }}>{column}</TableCell>
							})}
						</TableRow>
					</TableHead>

					<TableBody>
						{getList()?.slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage).map((row, i) => (
							<RenderItem key={i} data={row} />
						))}

						{emptyRows > 0 && (
							<TableRow style={{ height: 57.03 * emptyRows }}>
								<TableCell colSpan={columns?.length} />
							</TableRow>
						)}
					</TableBody>

					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5]}
								colSpan={columns?.length}
								count={getList()?.length}
								rowsPerPage={rowsPerPage}
								page={tablePage}
								onPageChange={(e, newPage) => setTablePage(newPage)}
								labelDisplayedRows={
									({ from, to, count }) => {
										return '' + from + '-' + to + ' af ' + count
									}
								}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	);
}
