import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, user, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				return user.loggedIn === true ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: '/', state: { prevPath: props?.location?.pathname + props?.location?.search } }} />
				)
			}
			}
		/>
	)
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(PrivateRoute);