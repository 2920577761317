import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { allowedPDFObjTypes } from '../../utils/fileUtils';
import KFileCard from '../cards/KFileCard';
import KSection from '../cards/KSection'
import KImageDrop from '../inputs/KImageDrop'

export default function KFileForm({ files, setFiles }) {

	const handleFileChange = (values) => {
		if (files) {
			const allFiles = [...files, ...values?.map(val => {
				return {
					file: val,
					obj: URL.createObjectURL(val)
				}
			})];

			setFiles(allFiles)
		} else {
			setFiles(values?.map(val => {
				return {
					file: val,
					obj: URL.createObjectURL(val)
				}
			}))
		}
	}

	const removeFile = (index) => {
		const newFiles = [...files];
		newFiles.splice(index, 1);
		setFiles(newFiles)
	}

	return (
		<KSection
			title="Vedhæftninger"
			description="Her kan du tilføje PDF-filer."
		>
			<div className="grid grid-cols-1 divide-y gap-y-4 gap-x-4">
				<KImageDrop
					height={150}
					handleDrop={(file) => handleFileChange(file)}
					onClear={() => setFiles()}
					title="Tilføj PDF-filer"
					acceptedFiles={['PDF']}
					acceptedSize="50MB"
					accept={allowedPDFObjTypes}
					Icon={() => (
						<div className="flex justify-center mb-1">
							<DocumentPlusIcon className="text-gray-400 stroke-1 w-11 h-11" />
						</div>
					)}
				/>
				<div className="grid grid-cols-4 pt-6 gap-x-2 gap-y-2 ">
					{files?.map((image, index) => {
						return <KFileCard isImage={false} file={image} handleRemove={() => removeFile(index)} />
					})}
				</div>
			</div>
		</KSection>
	)
}
