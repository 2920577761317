export default function KintellaSvg() {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 150 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.0915 30.4184L3.18518 16.5123C-0.490599 12.8365 -0.490599 6.85527 3.18518 3.17949C4.96578 1.39909 7.3332 0.418395 9.85138 0.418395C12.3696 0.418395 14.737 1.39909 16.5176 3.17949L18.3278 4.98992C19.0107 5.67259 19.0105 6.77958 18.3276 7.46245C17.6447 8.1447 16.538 8.1449 15.8551 7.46224L14.0451 5.65202C11.7328 3.34013 7.9702 3.33993 5.6577 5.65222C3.3452 7.96452 3.3452 11.7273 5.6577 14.0396L17.0915 25.4736L30.4228 12.142C31.0596 11.5052 31.4103 10.6588 31.4103 9.75829C31.4103 8.8578 31.0596 8.01121 30.4228 7.37441C29.1083 6.05989 26.97 6.06009 25.6553 7.37421L17.0912 15.9376L10.4248 9.27185C10.2181 9.06534 9.97665 9.03428 9.85118 9.03428C9.72551 9.03428 9.48424 9.06534 9.27753 9.27185C8.96119 9.58839 8.96119 10.1032 9.27753 10.4198L17.0912 18.2335L26.8028 8.52172C27.4857 7.83926 28.5927 7.83926 29.2753 8.52172C29.9582 9.20438 29.9582 10.3116 29.2753 10.9942L17.0912 23.1783L6.805 12.8921C5.12539 11.2123 5.12539 8.47914 6.8048 6.79973C7.61848 5.98605 8.70017 5.53766 9.85097 5.53766C11.0018 5.53766 12.0835 5.98584 12.8971 6.79932L17.0912 10.9928L23.183 4.90148C25.8608 2.22368 30.2178 2.2243 32.8952 4.90168C34.1926 6.19893 34.907 7.92379 34.907 9.75829C34.907 11.5928 34.1924 13.3172 32.8952 14.6145L17.0915 30.4184Z"
				fill="white"
			></path>
			<path
				d="M50.4633 15.8257C53.5412 18.7826 55.7235 21.0056 57.0109 22.494C57.6748 23.2383 58.0066 23.8667 58.0066 24.3797C58.0066 24.8927 57.604 25.4961 56.7998 26.1901C55.9952 26.8841 55.3362 27.2311 54.8234 27.2311C54.3104 27.2311 53.6917 26.8084 52.9677 25.9637L46.3297 18.3904V24.274C46.3297 24.7569 46.3147 25.109 46.2847 25.3302C46.2542 25.5517 46.1586 25.8131 45.9977 26.1144C45.7159 26.6576 44.9315 26.9291 43.6445 26.9291C42.2362 26.9291 41.4116 26.5474 41.1701 25.7827C41.0494 25.4612 40.9891 24.9484 40.9891 24.2439V7.46796C40.9891 7.00538 41.0043 6.65859 41.0344 6.42699C41.0646 6.19581 41.16 5.92903 41.3211 5.6273C41.6027 5.08429 42.3869 4.81279 43.6745 4.81279C45.0824 4.81279 45.9173 5.18507 46.1789 5.92903C46.2793 6.27129 46.3297 6.79434 46.3297 7.49819V13.261C49.0651 10.1834 51.278 7.65924 52.9677 5.68776C53.6716 4.86318 54.2849 4.45058 54.8084 4.45058C55.3312 4.45058 55.9952 4.79777 56.7998 5.49154C57.604 6.18552 58.0066 6.789 58.0066 7.30197C58.0066 7.81474 57.7048 8.42357 57.1014 9.12742C55.9347 10.4753 54.1243 12.3258 51.6703 14.6792L50.4633 15.8257Z"
				fill="white"
			></path>
			<path
				d="M59.5152 13.6834C59.5152 13.2208 59.5302 12.874 59.5605 12.6424C59.5905 12.4115 59.6759 12.1447 59.817 11.8429C60.0983 11.3201 60.8828 11.0585 62.1704 11.0585C63.1361 11.0585 63.8196 11.2296 64.2223 11.5714C64.5639 11.8534 64.7449 12.2856 64.7653 12.8689C64.7851 13.0701 64.7953 13.3621 64.7953 13.7439V24.3041C64.7953 24.7671 64.7803 25.1143 64.7503 25.3453C64.7201 25.5769 64.6242 25.843 64.4636 26.1448C64.2019 26.668 63.4273 26.9292 62.1404 26.9292C60.8729 26.9292 60.0983 26.6577 59.817 26.1145C59.6759 25.813 59.5905 25.5518 59.5605 25.3302C59.5302 25.1089 59.5152 24.7568 59.5152 24.2741V13.6834ZM64.4788 8.08634C64.3276 8.3782 64.061 8.5843 63.6791 8.70503C63.2967 8.82577 62.7891 8.88604 62.1554 8.88604C61.5217 8.88604 61.0134 8.82577 60.6317 8.70503C60.2493 8.5843 59.9827 8.37306 59.832 8.07132C59.6812 7.76979 59.5905 7.50323 59.5605 7.27183C59.5302 7.04085 59.5152 6.69386 59.5152 6.23086C59.5152 5.76828 59.5302 5.42129 59.5605 5.1899C59.5905 4.95892 59.6759 4.70243 59.817 4.42044C60.0983 3.87743 60.8828 3.60593 62.1704 3.60593C63.4376 3.60593 64.2019 3.87743 64.4636 4.42044C64.6242 4.72217 64.7201 4.98895 64.7503 5.22013C64.7803 5.45153 64.7953 5.79852 64.7953 6.26089C64.7953 6.72389 64.7803 7.07108 64.7503 7.30186C64.7201 7.53346 64.6296 7.79509 64.4788 8.08634"
				fill="white"
			></path>
			<path
				d="M76.0494 11.0283C78.0606 11.0283 79.7353 11.7977 81.0733 13.3365C82.4108 14.8752 83.0797 16.751 83.0797 18.9636V24.2739C83.0797 24.7369 83.0645 25.0841 83.0345 25.3149C83.0042 25.5465 82.9185 25.803 82.7782 26.0843C82.4962 26.6275 81.7115 26.899 80.4245 26.899C78.9961 26.899 78.1713 26.5272 77.9502 25.7826C77.8296 25.441 77.7694 24.928 77.7694 24.2439V18.9335C77.7694 18.1089 77.5326 17.4654 77.0602 17.0024C76.5875 16.54 75.9536 16.3084 75.1595 16.3084C74.3645 16.3084 73.7211 16.5501 73.2283 17.0326C72.7353 17.5153 72.4891 18.1488 72.4891 18.9335V24.2739C72.4891 24.7369 72.4738 25.0841 72.444 25.3149C72.4136 25.5465 72.3179 25.803 72.1573 26.0843C71.8957 26.6275 71.1209 26.899 69.8339 26.899C68.5667 26.899 67.7921 26.6275 67.5105 26.0843C67.3696 25.7826 67.2844 25.5162 67.254 25.2848C67.2242 25.0537 67.209 24.7069 67.209 24.2439V13.5627C67.209 13.1205 67.2242 12.7836 67.254 12.552C67.2844 12.321 67.3797 12.0645 67.5407 11.7825C67.8223 11.28 68.5969 11.0283 69.8641 11.0283C71.0908 11.0283 71.8453 11.2597 72.1271 11.7223C72.3278 12.0645 72.4288 12.4767 72.4288 12.9592C72.509 12.819 72.69 12.6176 72.972 12.356C73.2532 12.0948 73.5249 11.8835 73.7865 11.7223C74.4702 11.2597 75.2244 11.0283 76.0494 11.0283Z"
				fill="white"
			></path>
			<path
				d="M94.4244 16.3688L92.4632 16.2481V20.412C92.4632 20.9552 92.5486 21.3474 92.7197 21.5887C92.8904 21.8302 93.2123 21.9509 93.6852 21.9509C94.1574 21.9509 94.5199 21.9659 94.7714 21.9959C95.0228 22.0264 95.2891 22.122 95.5709 22.2827C96.0335 22.5443 96.2649 23.2179 96.2649 24.3041C96.2649 25.5713 95.9829 26.3461 95.4201 26.6275C95.1184 26.7686 94.8516 26.854 94.6206 26.884C94.389 26.9142 94.0519 26.9293 93.6097 26.9293C91.5175 26.9293 89.9288 26.4568 88.8426 25.5111C87.7564 24.566 87.2131 22.9668 87.2131 20.7137V16.2481C86.8308 16.3084 86.4184 16.3388 85.9762 16.3388C85.5333 16.3388 85.166 16.1679 84.8747 15.8256C84.5829 15.484 84.4372 14.9009 84.4372 14.0757C84.4372 13.2511 84.4872 12.653 84.5882 12.2805C84.6886 11.9084 84.8297 11.6422 85.0105 11.4808C85.3322 11.2198 85.7349 11.0885 86.2176 11.0885L87.2131 11.2093V8.10159C87.2131 7.639 87.2284 7.29737 87.2584 7.07564C87.2888 6.85453 87.3839 6.61326 87.5451 6.35163C87.8061 5.84894 88.5807 5.59718 89.8685 5.59718C91.2361 5.59718 92.0508 5.96968 92.3123 6.71363C92.4128 7.03573 92.4632 7.5485 92.4632 8.25235V11.2093C93.3483 11.1293 94.0172 11.0885 94.4699 11.0885C94.9224 11.0885 95.264 11.1037 95.4956 11.1338C95.7266 11.164 95.9934 11.2597 96.2953 11.4205C96.818 11.6821 97.0796 12.4567 97.0796 13.7439C97.0796 15.0109 96.8081 15.7857 96.2649 16.0671C95.9631 16.208 95.6966 16.2934 95.4656 16.3234C95.2342 16.3538 94.887 16.3688 94.4244 16.3688Z"
				fill="white"
			></path>
			<path
				d="M107.097 17.9681C107.861 17.9681 108.244 17.6466 108.244 17.0024C108.244 16.54 108.067 16.168 107.716 15.8862C107.363 15.6046 106.86 15.4637 106.207 15.4637C105.553 15.4637 104.904 15.7305 104.261 16.2632C103.617 16.7965 103.295 17.3644 103.295 17.9681H107.097ZM113.463 17.0779C113.463 18.3151 113.157 19.2306 112.543 19.8236C111.929 20.417 111.25 20.7138 110.506 20.7138H103.356C103.356 21.2771 103.687 21.75 104.351 22.1318C105.015 22.5141 105.679 22.705 106.343 22.705C107.509 22.705 108.425 22.5843 109.088 22.343L109.42 22.2225C109.903 22.0011 110.305 21.8905 110.627 21.8905C111.271 21.8905 111.824 22.343 112.286 23.2482C112.548 23.7914 112.679 24.2542 112.679 24.6362C112.679 26.4266 110.537 27.3216 106.252 27.3216C104.763 27.3216 103.441 27.0651 102.284 26.5521C101.128 26.0391 100.228 25.3602 99.5841 24.5154C98.3168 22.886 97.6829 21.0659 97.6829 19.0541C97.6829 16.5197 98.5026 14.4935 100.142 12.9745C101.781 11.4559 103.828 10.6964 106.282 10.6964C109.078 10.6964 111.13 11.6822 112.438 13.6533C113.121 14.6994 113.463 15.8407 113.463 17.0779"
				fill="white"
			></path>
			<path
				d="M129.093 21.4077C129.254 21.5282 129.666 21.5887 130.33 21.5887C130.994 21.5887 131.547 21.7598 131.989 22.1016C132.431 22.4439 132.653 23.1477 132.653 24.2138C132.653 25.2801 132.416 25.9891 131.944 26.3408C131.471 26.6929 130.812 26.8688 129.968 26.8688C125.703 26.8688 123.571 24.7568 123.571 20.5327L123.541 6.14017C123.541 5.6778 123.556 5.33081 123.586 5.09921C123.616 4.86843 123.712 4.60145 123.873 4.29971C124.175 3.77707 124.959 3.51524 126.226 3.51524C127.453 3.51524 128.218 3.79723 128.52 4.36018C128.74 4.80282 128.851 5.40629 128.851 6.17041V20.6532C128.851 21.0356 128.932 21.2869 129.093 21.4077ZM120.976 21.5887C121.64 21.5887 122.133 21.7598 122.455 22.1016C122.776 22.4439 122.937 23.1477 122.937 24.2138C122.937 25.2801 122.766 25.9891 122.424 26.3408C122.082 26.6929 121.59 26.8688 120.946 26.8688C118.914 26.8688 117.486 26.3815 116.662 25.4055C115.837 24.43 115.425 22.8059 115.425 20.5327V6.14017C115.425 5.6778 115.44 5.33081 115.47 5.09921C115.5 4.86843 115.585 4.60145 115.726 4.29971C116.008 3.77707 116.792 3.51524 118.08 3.51524C119.306 3.51524 120.071 3.79723 120.373 4.36018C120.534 4.64197 120.629 4.89846 120.66 5.12944C120.69 5.36104 120.705 5.70803 120.705 6.17041V20.6532C120.705 21.2771 120.795 21.5887 120.976 21.5887"
				fill="white"
			></path>
			<path
				d="M139.14 18.9939C139.14 19.6579 139.401 20.2564 139.924 20.7891C140.447 21.3222 141.081 21.5886 141.826 21.5886C142.569 21.5886 143.188 21.3171 143.681 20.7739C144.174 20.2309 144.42 19.6326 144.42 18.9789C144.42 18.3254 144.184 17.7166 143.711 17.1534C143.238 16.5903 142.605 16.3083 141.81 16.3083C141.016 16.3083 140.372 16.5903 139.879 17.1534C139.386 17.7166 139.14 18.33 139.14 18.9939ZM144.631 12.7179C144.752 11.5918 145.546 11.0282 147.015 11.0282C147.8 11.0282 148.383 11.0885 148.765 11.2092C149.147 11.3299 149.414 11.5412 149.565 11.8429C149.716 12.1446 149.806 12.4116 149.836 12.6424C149.866 12.874 149.882 13.221 149.882 13.6834V24.2438C149.882 24.7068 149.866 25.0538 149.836 25.2849C149.806 25.5163 149.72 25.7827 149.58 26.0844C149.298 26.6077 148.609 26.8687 147.513 26.8687C146.416 26.8687 145.682 26.7332 145.31 26.4617C144.938 26.19 144.722 25.793 144.662 25.2699C143.917 26.336 142.675 26.8687 140.935 26.8687C139.195 26.8687 137.581 26.0746 136.093 24.4853C134.604 22.8965 133.86 21.0507 133.86 18.9485C133.86 16.847 134.609 15.0063 136.108 13.4269C137.606 11.848 139.25 11.0584 141.041 11.0584C141.724 11.0584 142.348 11.1792 142.912 11.4206C143.475 11.6619 143.867 11.8882 144.088 12.0994C144.31 12.3106 144.491 12.5171 144.631 12.7179"
				fill="white"
			></path>
		</svg>
	);
}
