import React, { useRef } from 'react'
import _ from 'lodash';
import DummyImage from '../assets/dummyPerson.jpeg'
import { PhotoIcon, TrashIcon } from '@heroicons/react/24/solid';
import KLoader from './KLoader';
import { classNames } from '../utils/tailwindUtils';

export default function KAvatar({ image, handleChange, handleRemove, loading, sizeClass }) {

	const fileRef = useRef(null)

	return (
		<div>
			{loading &&
				<div className={sizeClass ? sizeClass : "h-28 w-28"}>
					<KLoader maxHeight={30} />
				</div>
			}

			{!loading && <img className={classNames(sizeClass ? sizeClass : "w-28 h-28", "border")} src={_.isObject(image) ? URL.createObjectURL(image) : image ? image : DummyImage} />}
			<div className={classNames(
				// loading ? 'invisible' : 'visible',
				"w-full mt-2 font-semibold cursor-pointer gap-x-2"
			)}>
				<div className='flex items-center gap-x-2'>
					<PhotoIcon className={classNames(loading ? 'text-gray-200' : 'text-darkPurple', "w-4 h-4")} />
					<p
						onClick={() => fileRef.current.click()}
						className={classNames(
							loading ? 'text-gray-300' : 'text-darkPurple',
							"text-sm font-light"
						)}

					>
						{!image
							? 'Tilføj borgerbillede'
							: 'Rediger borgerbillede'
						}
					</p>
					<input onChange={(e) => handleChange(e)} type='file' id='file' ref={fileRef} style={{ display: 'none' }} />
				</div>
				{image &&
					<div className="flex items-center mt-1 gap-x-2">
						<TrashIcon className={classNames(loading ? 'text-gray-200' : 'text-gray-700', "w-4 h-4")} />
						<p onClick={handleRemove}
							className={classNames(
								loading ? 'text-gray-300' : 'text-gray-700',
								"text-sm font-light"
							)}
						>
							Fjern borgerbillede
						</p>
					</div>
				}

			</div>
		</div >
	)
}
