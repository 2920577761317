import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import KButton from '../../components/KButton'
import KPageWrapper from '../../components/KPageWrapper'
import { GET_CALENDAR_ACTIVITY_BY_ID } from '../../graphql/graphqlQueries'
import { calendarRoute } from '../../routes/mainRoutes'
import Colors from '../../styles/Colors'
import { convertToNewsDate } from '../../utils/dateUtils'
import moment from 'moment';
import { findParticipants, getCalendarColor } from '../../utils/calendarUtils'
import { getResidentFullName } from '../../utils/residentUtils'
import { DELETE_CALENDAR_ACTIVITY } from '../../graphql/graphqlMutations'
import { checkIfUrlInText } from '../../utils/validators'
import { canEditAndDelete } from '../../utils/roles'
import KImageCarousel from '../../components/media/imageCarousel/KImageCarousel'
import KFileCard from '../../components/cards/KFileCard'
import KModal from '../../components/modals/KModal'
import KTitle from '../../components/KTitle'

export default function CalendarActivity({ match, history }) {
	const user = useSelector(state => state.user);

	const [activity, setActivity] = useState();
	const [attending, setAttending] = useState();
	const [totalAttending, setTotalAttending] = useState();
	const [notAttending, setNotAttending] = useState();
	const [awaiting, setAwaiting] = useState();
	const [showInvitedResidents, setShowInvitedResidents] = useState(false);
	const [showInvitedRelatives, setShowInvitedRelatives] = useState(false);
	const [showAttending, setShowAttending] = useState(false);
	const [showNotAttending, setShowNotAttending] = useState(false);
	const [showAwaiting, setShowAwaiting] = useState(false);

	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const { data, loading } = useQuery(GET_CALENDAR_ACTIVITY_BY_ID, { variables: { id: match?.params?.id }, fetchPolicy: 'no-cache' })

	const [deleteActivity] = useMutation(DELETE_CALENDAR_ACTIVITY)

	useEffect(() => {
		setActivity(data?.getCalendarActivityById)
		setAttending(findParticipants('Deltager', data?.getCalendarActivityById))
		setTotalAttending(findParticipants('Samlet antal deltagere', data?.getCalendarActivityById))
		setNotAttending(findParticipants('Deltager ikke', data?.getCalendarActivityById))
		setAwaiting(findParticipants('Afventer svar', data?.getCalendarActivityById))
	}, [data])


	const handleDeleteActivity = async (all) => {
		try {
			await deleteActivity({
				variables: {
					input: {
						executorId: user?.data?.id,
						id: activity?.id,
						deleteAll: all,
						repetitionId: activity?.repetitionId,
					}
				}
			})

			history.goBack()
		} catch (err) {

		}
	}


	console.log(attending)
	return (
		<KPageWrapper
			paths={[{
				title: 'Kalender',
				route: calendarRoute
			}, {
				title: 'Aktivitet'
			}]}
			buttons={[
				canEditAndDelete(user, activity?.creator) &&
				<KButton
					label="REDIGER AKTIVITET"
					onClick={() => history.push(`/aktivitet/rediger/${activity?.id}`)}
				/>,
				canEditAndDelete(user, activity?.creator) &&
				<KButton
					label="SLET AKTIVITET"
					onClick={() => setShowDeleteModal(true)}
				/>
			]}
			loading={loading}
		>
			<div>
				<KModal open={showDeleteModal} setOpen={setShowDeleteModal}>
					<div className="p-5">
						<div>
							<div className="flex flex-col items-center text-center">
								<KTitle className="text-center" title="Slet aktivitet" />
								<div className="mt-3">
									<p className="text-gray-600">
										Vælg venligst hvordan du vil slette aktiviteten
									</p>
								</div>
							</div>
						</div>
						{activity?.repetitionType !== null &&
							<div className="grid grid-cols-3 mt-5 gap-x-4 ">
								<KButton className="w-full" label="Slet kun denne" onClick={() => handleDeleteActivity(false)} />
								<KButton className="w-full" label="Slet alle" onClick={() => handleDeleteActivity(true)} />
								<KButton className="w-full" label="Annuller" onClick={() => setShowDeleteModal(false)} />
							</div>
						}
						{activity?.repetitionType === null &&
							<div className="grid grid-cols-2 mt-5 gap-x-4">
								<KButton className="w-full" label="Slet" onClick={() => handleDeleteActivity(false)} />
								<KButton className="w-full" label="Annullerr" onClick={() => setShowDeleteModal(false)} />
							</div>
						}
					</div>
				</KModal>

				<div className="grid grid-cols-2 gap-x-10">
					<div>
						<p className="text-xs font-bold text-gray-600">Oprettet: {convertToNewsDate(activity?.createdAt)}</p>
						<TitleWrapper>
							{activity?.title}
						</TitleWrapper>
						{activity?.description &&
							<div className="text-lg">
								{checkIfUrlInText(activity?.description)?.map(e => {
									if (e?.type === 'link') {
										return <a target="_blank" style={{ textDecoration: 'auto', color: Colors.darkPurple }} href={e?.text}>{e?.text}</a>
									}
									return (
										<span>{e?.text}</span>
									)
								})}
							</div>
						}
						<div className="flex justify-between py-4 mt-10 border-t border-gray-200">
							<div>
								<p>Starter</p>
								<p>{moment(parseInt(activity?.startDate)).format('DD MMM. HH:mm')}</p>
							</div>
							<div>
								<p>Slutter</p>
								<p>{moment(parseInt(activity?.endDate)).format('DD MMM. HH:mm')}</p>
							</div>
							<div>
								<p>Status</p>
								<div style={{ height: 20, width: 100, backgroundColor: getCalendarColor(activity) }} />
							</div>
						</div>
						<div className="grid grid-cols-1 py-2 border-t border-gray-200">
							<div>
								<p>Oprettet af</p>
								<p>{getResidentFullName(activity?.creator)}</p>
							</div>
							{activity?.note &&
								<div className="mt-5 ">
									<p>Medarbejdernote</p>
									<p>{checkIfUrlInText(activity?.note)?.map(e => {
										if (e?.type === 'link') {
											return <a target="_blank" style={{ textDecoration: 'auto', color: Colors.darkPurple }} href={e?.text}>{e?.text}</a>
										}
										return (
											<span>{e?.text}</span>
										)
									})}</p>
								</div>
							}

						</div>

						<div className="flex justify-between py-4 border-t border-gray-200">
							<p>Inviterede borgere: {activity?.participants?.length}</p>
							<p onClick={() => setShowInvitedResidents(true)} className="font-bold text-gray-600 cursor-pointer">Vis borgere</p>
							<KModal open={showInvitedResidents} setOpen={setShowInvitedResidents}>
								<div className="px-8 py-4">
									<p className="pb-1 text-lg font-bold text-gray-700 border-b border-gray-200">Inviterede borgere:</p>
									<div className="grid grid-cols-1 divide-y">
										{activity?.participants?.map(p => (
											<p className="py-2">{p?.resident?.fullName}</p>
										))}
									</div>
								</div>
							</KModal>
						</div>
						<div className="flex justify-between py-4 border-t border-b border-gray-100">
							<p>Inviterede pårørende: {activity?.participants?.map(p => p?.relativeStatus?.length).reduce((a, b) => a + b, 0)}</p>
							<p onClick={() => setShowInvitedRelatives(true)} className="font-bold text-gray-600 cursor-pointer">Vis pårørende</p>
							<KModal open={showInvitedRelatives} setOpen={setShowInvitedRelatives}>
								<div className="px-8 py-4">
									<p className="pb-1 text-lg font-bold text-gray-700 border-b border-gray-200">Inviterede pårørende:</p>
									<div className="grid grid-cols-1 divide-y">
										{activity?.participants?.map(p => (
											<div className="grid grid-cols-1 divide-y">
												{p?.relativeStatus?.map(r => (
													<p className="py-2">{r?.user?.fullName} ({p?.resident?.fullName})</p>
												))}
											</div>
										))}
									</div>
								</div>
							</KModal>
						</div>
						{activity?.actionRequired &&
							<div className="pb-10">
								<div className="flex justify-between py-4 border-t border-b border-gray-100">
									<p>Deltager: {attending?.length}</p>
									<p onClick={() => setShowAttending(true)} className="font-bold text-gray-600 cursor-pointer">Vis deltagere</p>
									<KModal open={showAttending} setOpen={setShowAttending}>
										<div className="px-8 py-4">
											<p className="pb-1 text-lg font-bold text-gray-700 border-b border-gray-200">Deltager:</p>
											<div className="grid grid-cols-1 divide-y">
												{attending?.map(p => (
													<div className="py-2">
														<p>{p?.user?.fullName} ({p?.resident?.fullName})</p>
														<p className="text-sm">Deltager med {p?.extraParticipants} gæster</p>
													</div>
												))}
												{attending?.length === 0 && <p className="pt-2">Ingen pårørende fundet.</p>}
											</div>
										</div>
									</KModal>
								</div>
								<div className="flex justify-between py-4 border-t border-b border-gray-100">
									<p>Deltager ikke: {notAttending?.length}</p>
									<p onClick={() => setShowNotAttending(true)} className="font-bold text-gray-600 cursor-pointer">Vis deltager ikke</p>
									<KModal open={showNotAttending} setOpen={setShowNotAttending}>
										<div className="px-8 py-4">
											<p className="pb-1 text-lg font-bold text-gray-700 border-b border-gray-200">Deltager ikke:</p>
											<div className="grid grid-cols-1 divide-y">
												{notAttending?.map(p => (
													<p className="py-2">{p?.user?.fullName} ({p?.resident?.fullName})</p>
												))}
												{notAttending?.length === 0 && <p className="pt-2">Ingen pårørende fundet.</p>}
											</div>
										</div>
									</KModal>
								</div>
								<div className="flex justify-between py-4 border-t border-b border-gray-100">
									<p>Afventer svar: {awaiting?.length}</p>
									<p onClick={() => setShowAwaiting(true)} className="font-bold text-gray-600 cursor-pointer">Vis afventer svar</p>
									<KModal open={showAwaiting} setOpen={setShowAwaiting}>
										<div className="px-8 py-4">
											<p className="pb-1 text-lg font-bold text-gray-700 border-b border-gray-200">Afventer svar:</p>
											<div className="grid grid-cols-1 divide-y">
												{awaiting?.map(p => (
													<p className="py-2">{p?.user?.fullName} ({p?.resident?.fullName})</p>
												))}
												{awaiting?.length === 0 && <p className="pt-2">Ingen pårørende fundet.</p>}
											</div>
										</div>
									</KModal>
								</div>
								<div className="flex justify-between py-4 border-t border-b border-gray-100">
									<p>Samlet antal deltagere (med borger og gæster):</p>
									<p className="font-bold text-gray-600">{totalAttending}</p>
								</div>
							</div>
						}
					</div>
					<div>
						{activity?.images &&
							<KImageCarousel
								images={activity?.images}
							/>
						}
						{activity?.files && activity?.files?.length > 0 &&
							<div className="mt-6 border border-gray-300 rounded">
								<div className="flex items-center justify-start h-10 p-2 ">
									<p>Vedhæftninger</p>
								</div>
								<div className="pb-4 pl-4 pr-4 border-t">
									{activity?.files && activity?.files?.length > 0 &&
										<div className="mt-2">
											<div className="grid grid-cols-1 mt-4 gap-x-2 gap-y-2">
												{activity?.files?.map(file => {
													return <a href={file?.url} without rel="noopener noreferrer" target="_blank"><KFileCard file={file} /></a>
												})}
											</div>
										</div>
									}

								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</KPageWrapper>
	)
}


const TitleWrapper = styled.p({
	fontSize: 30,
	fontFamily: 'MetricBold',
	color: Colors.mainGreen
});

