import React, { useEffect, useState } from 'react'
import KPageWrapper from '../../components/KPageWrapper'
import Colors from '../../styles/Colors';

import { useQuery } from '@apollo/client';
import { GET_CORE_STATISTICS_BY_ORGANIZATION_ID, GET_DAILY_OVERVIEW_BY_ORGANIZATION_ID, GET_OVERDUE_TODOS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries';
import { useSelector } from 'react-redux';
import KVideoPlayer from '../../components/KVideoPlayer';
import KOverview from '../../components/KOverview';
import { guidesPreview, statisticLevels, statisticTypes } from '../../utils/dashboardUtils';
import KDateRangePicker from '../../components/KDateRangePicker';
import KIcon from '../../components/KIcon';
import moment from 'moment';
import KModal from '../../components/KModal';
import { assignmentRoute, calendarActivityRoute, guidesRoute, overdueAssignmentRoute, statisticRoute } from '../../routes/mainRoutes';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Card, CardContent } from '@mui/material';
import { FaPlayCircle } from 'react-icons/fa';
import { getCurrentMonth, getCurrentWeek } from '../../utils/dateUtils';
import KDivider from '../../components/KDivider';
import { getResidentFullName } from '../../utils/residentUtils';
import { residentInfomationRoute } from '../../routes/residentRoutes';
import KDatePicker from '../../components/KDatePicker';

export default function Dashboard({ history }) {
	const user = useSelector(state => state.user);

	const [selectedChartLevel] = useState(statisticLevels.ALL)
	const [selectedChartType] = useState(statisticTypes.USER)
	const [dailyDate, setDailyDate] = useState(new Date());

	const [coreStatistics, setCoreStatistics] = useState()
	const [overdueTodos, setOverDueTodos] = useState([])
	const [dailyOverview, setDailyOverview] = useState([])

	const { data, loading } = useQuery(GET_CORE_STATISTICS_BY_ORGANIZATION_ID, { 
		variables: { 
			input: {
				organizationId: user?.data?.organization?.id, 
				level: selectedChartLevel.value,
				type: selectedChartType.value
			}

		},
		fetchPolicy: "no-cache",
	})

	const { data: todosData, loading: todosLoading } = useQuery(GET_OVERDUE_TODOS_BY_ORGANIZATION_ID, { 
		variables: { 
			id:  user?.data?.organization?.id, 
			date: moment().format('DD/MM/YYYY')
		},
		fetchPolicy: "no-cache",
	})

	const { data: dailyData, loading: dailyLoading } = useQuery(GET_DAILY_OVERVIEW_BY_ORGANIZATION_ID, { 
		variables: { 
			input: {
				organizationId: user?.data?.organization?.id, 
				startDate: moment(dailyDate).format('DD-MM-YYYY HH:mm:ss'),
				endDate: moment(dailyDate).format('DD-MM-YYYY HH:mm:ss'),
			}
		},
		fetchPolicy: "no-cache",
	})


	useEffect(() => {
		if(data) {
			setCoreStatistics(data?.getCoreStatisticsByOrganizationId?.item)
		}
	}, [data])

	useEffect(() => {
		if(todosData) {
			setOverDueTodos(todosData?.getOverdueTodosByOrganizationId)
		}
	}, [todosData])

	useEffect(() => {
		if(dailyData) {
			setDailyOverview(dailyData?.getDailyOverviewByOrganizationId)
		}
	}, [dailyData])

	const handleStatisticClick = (dates) => {
		history.push({
			pathname: statisticRoute,
			state: { 
				date: dates
			}
		});
	}

	const getDailyActivityRoute = (type, id) => {
		switch(type) {
			case 'CALENDAR':
				return calendarActivityRoute(id)
			case 'RESIDENT':
				return `${residentInfomationRoute}/${id}`
		}
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Overblik'
			}]}
			loading={[loading, todosLoading, dailyLoading].some(e => e === true)}
		>
			<div style={{ marginTop: 10 }}>
				<KDivider />
				<div style={{ display: 'flex', marginTop: 20, gap: 20  }}>
					<div style={{ flex: 1.025,  }}>
						<Card sx={{ flex: 0.2, minHeight: 300 }}>
							<CardContent>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
									<p style={{ fontSize: 20, fontFamily: 'MetricSemiBold', color: Colors.mediumBlack}}>Dagens overblik</p>
									<div style={{ display: 'flex', gap: 5, alignItems: 'center'}}>
										<KDatePicker 
											// label="Frist"
											value={dailyDate}
											onChange={setDailyDate}
										/> 
									</div>
								</div>
								<div style={{ marginTop: 20, display: 'flex', gap: 20, alignItems: 'flex-start', justifyContent:'space-between', flexWrap: 'wrap'  }}>
									{dailyOverview?.map(e => {
										return (
											<div style={{ width: 'calc(50% - 10px)', }}>
												<p style={{ fontFamily: 'MetricSemiBold'}}>Begivenheder på {e.name}</p>
												{e.events.length === 0 && <p>Ingen begivenheder fundet.</p>}
												{e.events?.map(event => {
													return (
														<p onClick={() => history.push(getDailyActivityRoute(event.type, event.id))} style={{ cursor: 'pointer'}}>{event.title}</p>
													)
												})}
											</div>
										)
									})}
								</div>
							</CardContent>
						</Card>
					</div>
					<Card sx={{ flex: 0.5, minHeight: 300, backgroundColor: Colors.mainGrey }}>
						<CardContent>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
								<p style={{ fontSize: 20, fontFamily: 'MetricSemiBold', color: Colors.mediumBlack}}>Forfaldne opgaver</p>
								<div style={{ display: 'flex', gap: 5, alignItems: 'center'}}>
									<p style={{ fontSize: 16, fontFamily: 'MetricSemiBold', color: Colors.mediumBlack }}>{moment.utc().format('DD-MM-YYYY')}</p>
									{/* <ArrowRightAltIcon style={{ fontSize: 16, marginTop: 3 }} /> */}
								</div>
							</div>
							{overdueTodos?.length === 0 &&
								<p style={{ marginTop: 10 }}>Der blev ikke fundet nogle forfaldne opgaver.</p>
							}
							{overdueTodos?.map(todo => (
								<Card style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => history.push(assignmentRoute(todo.id))}>
									<CardContent>
										<p>{todo?.title}</p>
										<div style={{ display: 'flex', fontSize: 14, gap: 5 }}>
											<p style={{ fontFamily: 'MetricSemiBold'}}>Omhandler:</p>
											<p>{getResidentFullName(todo.resident)}</p>
										</div>
									</CardContent>
								</Card>
							))}
						</CardContent>
					</Card>
				</div>
				<KDivider />
				<div style={{ display: 'flex', gap: 50 }}>
					<div style={{ flex: 1 }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
							<p style={{ fontSize: 20, fontFamily: 'MetricSemiBold', color: Colors.mediumBlack}}>Statistik</p>
							<div onClick={() => history.push(statisticRoute)} style={{ display: 'flex', gap: 5, alignItems: 'center'}}>
								<p style={{ fontSize: 16, fontFamily: 'MetricSemiBold', color: Colors.mediumBlack, cursor: 'pointer'}}>Mere info</p>
								<ArrowRightAltIcon style={{ fontSize: 16, marginTop: 3 }} />
							</div>
							
						</div>
						<div style={{ display: 'flex', flexDirection:'row', marginTop: 20, gap: 20 }}>
							<div style={{ flex: 1}}>
								<KOverview
									onClick={() => handleStatisticClick()} 
									title="Samlet antal brugere"
									value={coreStatistics?.totalCount}
								/>
							</div>
							<div style={{ flex: 1}}>
								<KOverview 
									onClick={() => handleStatisticClick(getCurrentWeek())} 
									title="Aktive brugere den her uge"
									value={coreStatistics?.weeklyCount}
									infoText={getCurrentWeek().text}
								/>
							</div>
							<div style={{ flex: 1}}>
								<KOverview 
									onClick={() => handleStatisticClick(getCurrentMonth())} 
									title="Aktive brugere i den her måned"
									value={coreStatistics?.monthlyCount}
									infoText={getCurrentMonth().text}
								/>
							</div>
						</div>

					</div>
				</div>
			</div>
		</KPageWrapper>
	)
}
