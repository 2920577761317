export const allowedImageTypes = [
	"png",
	"jpg",
	"jpeg",
	"heic",
	"jfif"
]

const allowedFileTypes = [
	'pdf'
];

export const allowedPDFObjTypes = [
	'application/pdf'
]

export const allowedImageFiles = [
	'image/png',
	'image/jpg',
	'image/jpeg',
	'image/heic',
	'image/jfif'
]

export const allowedVideoFiles = [
	'video/mp4',
	'video/quicktime',
	'video/webm'
]

export const allowedVideoTypes = [
	'mp4',
	'webm',
	'mov'
]

export const isImageType = (file) => {
	console.log(file)
	if (allowedImageTypes.includes(getFileExt(file)) || file === null || file === undefined) {
		return true
	} else {
		return false
	}
}


export function getFileExt(file) {
	return file?.split('.')?.pop()?.toLowerCase();
}

export function isImageObj(file) {
	console.log(file)
	if (!allowedPDFObjTypes.includes(file?.type) || file?.type === null || file?.type === undefined) {
		return true;
	} else {
		return false
	}
}

export const convertBase64ToFile = async function (base64File) {
	const test = await fetch(base64File)
		.then(res => res.blob())
		.then(blob => {
			const file = new File([blob], "File name", { type: "image/png" })
			return file;
		})

	return test;
};

export function generateS3File(uri, name, mimetype) {
	return uri
		? {
			uri: uri,
			type: mimetype,
			name,
		}
		: null;
}