import { Card, CardContent } from '@mui/material'
import React from 'react'
import styled from 'styled-components';

import TestIMG from '../assets/headerBackground.jpeg';
import Colors from '../styles/Colors';

export default function KOverview({ title, value, position, onClick, infoText }) {
	return (
		<div>
			<StyledCard onClick={() => onClick()}>
				<StyledContainer imgUrl={TestIMG}>
					<Title>{title}</Title>
					<Value>{value}</Value>
					{infoText && 
						<InfoText>{infoText}</InfoText>
					}		
				</StyledContainer>
			</StyledCard>
		</div>

	)
}

const StyledCard = styled(Card)(() => ({
	cursor: 'pointer'
}))

const StyledContainer = styled.div(({ imgUrl }) => ({
	padding: '10px 20px', 
	backgroundImage: `url(${imgUrl})`, 
	backgroundSize: 400, 
	backgroundRepeat: 'no-repeat', 
	boxShadow: 'inset 0 0 0 2000px rgb(0 0 0 / 20%)', 
	position: 'relative' 
}))

const Title = styled.p(() => ({
	color: Colors.white,
	fontSize: 18
}))

const Value = styled.p(() => ({
	color: Colors.white,
	fontSize: 30,
	fontFamily: 'MetricBold'
}))

const InfoText = styled.p(() => ({
	position: 'absolute', 
	right: 10, 
	bottom: 5, 
	color: '#fff', 
	fontSize: 13
}))