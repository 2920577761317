import React from 'react'
import Colors from '../../styles/Colors';
import KLoadingButton from '../KLoadingButton';

export default function FrontPageCard({ icon, title, description, onClick = () => { } }) {
	return (
		<div className="flex flex-col justify-between border-2 border-solid rounded border-sky-400 bg-gray-50">
			<div className='relative flex justify-center px-6 py-4 text-center'>
				<dl className="">
					<div className='mb-2' >
						{icon}
					</div>
					<div>
						<dt className="sr-only">Title</dt>
						<dd className="mb-1 text-lg font-semibold"
							style={{
								fontFamily: 'MetricSemiBold',
								color: Colors.lightBlack
							}} >
							{title}
						</dd>
					</div>
					<div>
						<dt className="sr-only">Description</dt>
						<dd className="text-gray-400">{description}</dd>
					</div>
				</dl>
			</div>
			<div className='bottom-5 p-4'>
				<KLoadingButton className="relative" onClick={onClick} title="Administrér" />
			</div>
		</div>
	)
}