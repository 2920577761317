import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Colors from '../../../styles/Colors';
import KModal from '../../../components/KModal';
import KTextField from '../../../components/KTextField';
import { roomGroupRoute } from '../../../routes/mainRoutes';
import { useSelector } from 'react-redux';
import { DELETE_ROOM_BY_ID, UPDATE_ROOM } from '../../../graphql/graphqlMutations';
import {
	GET_ROOM_BY_ID,
	GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID,
	GET_ALL_ROOMS_BY_ORGANZATION_ID,
} from '../../../graphql/graphqlQueries';
import KPageWrapper from '../../../components/KPageWrapper';
import KButton from '../../../components/KButton';
import KSelect from '../../../components/KSelect';
import { validateNewRoom } from '../../../utils/validators';
import StackedList from '../../../components/lists/stackedList';
import KTitle from '../../../components/KTitle';
import { getPrevoiusRouteData } from '../../../utils/navigationUtils';

export default function Room({ match, history }) {
	const user = useSelector(state => state.user);

	const [room, setRoom] = useState({});
	const [departments, setDepartments] = useState([]);
	const [originalRoom, setOriginalRoom] = useState();
	const [errors, setErrors] = useState({});

	const { data: roomData, loading } = useQuery(GET_ROOM_BY_ID, { variables: { id: match?.params?.id } })
	const { data: allDepartments, loading: departmentLoading } = useQuery(
		GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, { variables: { organizationId: user?.data?.organization?.id }, fetchPolicy: 'no-cache' },
	);
	const [doDeleteRoom, { loading: deleteLoading }] = useMutation(DELETE_ROOM_BY_ID, {
		refetchQueries: [GET_ALL_ROOMS_BY_ORGANZATION_ID]
	})
	const [doUpdateRoom, { loading: updateLoading }] = useMutation(UPDATE_ROOM, {
		refetchQueries: [GET_ALL_ROOMS_BY_ORGANZATION_ID]
	})

	const [modalTitle, setModalTitle] = useState('')
	const [modalSubTitle, setModalSubTitle] = useState('')
	const [showModal, setShowModal] = useState('')
	const [showErrorModal, setShowErrorModal] = useState('')

	const [modalType, setModalType] = useState('')


	const action = {
		delete: "Slet",
		update: "Opdater",
	}


	const handleChange = (key, value) => {
		console.log(key, value);
		setRoom({
			...room,
			[key]: value,
			departmentId: parseInt(value?.id) || room?.departmentId
		})
	}

	useEffect(() => {
		setRoom(roomData?.getRoomById)
		setOriginalRoom(roomData?.getRoomById)
		console.log('Got room data', roomData?.getRoomById);
	}, [roomData?.getRoomById])

	useEffect(() => {
		setDepartments(allDepartments?.getAllDepartmentsByOrganizationId)
	}, [allDepartments])

	const handleModalText = (action, modalTitle, subTitle) => {
		setModalType(action)
		setModalTitle(modalTitle)
		setModalSubTitle(subTitle)
		setShowModal(true)
	}

	const handleDelete = async () => {
		await doDeleteRoom({ variables: { id: room.id } })
		history.goBack()
	}
	const handleUpdate = () => {
		const validated = validateNewRoom({
			title: room.name,
			department: room.department,
		})

		if (!validated.success) {
			return setErrors(validated.errors)
		}
		doUpdateRoom({
			variables: {
				input: {
					id: room.id,
					name: room.name,
					departmentId: parseInt(room.department.id),
				}
			}
		}).then(res => {
			history.goBack();
		}).catch(err => {
			console.log(JSON.stringify(err, 2, null))
			setErrors({ ...errors, serverError: err?.message })
		})
	}

	// handle accept action for modal
	const handleAccept = async (type) => {
		if (type === action.delete) await handleDelete()
		if (type === action.update) handleUpdate()
		setShowModal(false)
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Boliger',
				route: roomGroupRoute,
			}, {
				title: 'Bolig'
			}]
			}
			errors={errors?.serverError}
			loading={[loading, departmentLoading, deleteLoading, updateLoading].some(e => e === true)}
			buttons={[
				!_.isEqual(room, originalRoom) && <KButton
					label="GEM"
					onClick={() => handleModalText(action.update, "Gem ændringer", `Er du sikker på at du vil gemme dine ændringer for bolig '${originalRoom.name}'?`)}
				/>,
				<KButton
					label="SLET BOLIG"
					onClick={() => handleModalText(action.delete, room?.resident ? "OBS" : "Slet bolig", room?.resident ? "Du kan ikke slette en bolig med en beboer" : `Er du sikker på at du vil slette bolig '${originalRoom.name}'?`)}
				/>
			]}
		>
			<div>
				<KModal
					open={showModal}
					title={modalTitle}
					subTitle={modalSubTitle}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						{room?.resident ? modalType != action.delete && <ModalButton fullWidth onClick={() => handleAccept(modalType)}>{modalType}</ModalButton> : <ModalButton fullWidth onClick={() => handleAccept(modalType)}>{modalType}</ModalButton>}
						<ModalButton fullWidth onClick={() => setShowModal(false)} >Annuller</ModalButton>
					</ModalContainer>
				</KModal>
			</div>

			<div className='flex flex-col pt-5 ' >
				<KTitle title={"Oplysninger"} />

				<div className='flex flex-col flex-1 max-w-md gap-8 my-6' >
					{room && <KTextField
						error={errors?.title}
						value={room?.name}
						required
						fullWidth={true}
						label="Bolignavn"
						onChange={(/** @type {any} */ value) => handleChange('name', value)}
					/>}
					{departments?.length && room &&
						<KSelect
							error={errors?.department}
							label="Afdeling"
							options={departments}
							value={departments.find(e => e.id === room?.department?.id)}
							optionKey="name"
							handleChange={(value) => handleChange('department', value)}
						/>}


				</div>
				{room?.resident &&
					< Item >
						<KTitle title={"Borger"} />
						<StackedList residents={[room.resident]} />
					</Item>}
			</div>
		</KPageWrapper >
	)
}

const Item = ({ children }) => {
	return (
		<div className='flex flex-col gap-6 mb-6 flex-1gap-8' >
			{children}
		</div>
	)
}

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,
		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))