import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range';
import { da } from 'react-date-range/dist/locale';
import {
	defaultStaticRanges,
	defaultInputRanges
} from "react-date-range/dist/defaultRanges";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import KButton from './KButton';
import styled from 'styled-components';
import Colors from '../styles/Colors';
export default function KDateRangePicker({ dateSelection, onChange, setOpen }) {
	const [value, setValue] = useState([]);
	const [newValue, setNewValue] = useState();

	const staticRangesLabels = {
		"Today": "I dag",
		"Yesterday": "I morgen",
		"This Week": "Denne uge",
		"Last Week": "Sidste uge",
		"This Month": "Denne måned",
		"Last Month": "Sidste måned"
	};
      
	const inputRangesLabels = {
		"days up to today": "dage op til i dag",
		"days starting today": "dage fra i dag"
	};
      
	function translateRange(dictionary) {
		return (item) =>
			dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
	}
      
	const ruStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
	const ruInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));
	
	useEffect(() => {
		setValue(dateSelection)
	}, [])

	const handleChange = (e) => {
		setValue(e.selection)
	}

	return (
		<div>
			<StyledDateRangePicker
				staticRanges={ruStaticRanges}
				inputRanges={ruInputRanges}
				locale={da}
				ranges={[value]}
				onChange={(e) => handleChange(e)}
				startDatePlaceholder="Start"
				endDatePlaceholder="Slut"
				color={Colors.darkPurple}
				rangeColors={[Colors.darkPurple, Colors.lightPurple]}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
				<p style={{ cursor: 'pointer'}} onClick={() => setOpen(false)}>Annuller</p>
				<KButton type="submit" onClick={(e) => onChange(value)} label="Færdig" />
			</div>
		</div>


	)
}

const StyledDateRangePicker = styled(DateRangePicker)(() => ({
	'& .rdrCalendarWrapper': {
		fontSize: '14px !important'
	},
	'& .rdrDefinedRangesWrapper': {
		fontSize: 14
	}
}))
