import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Colors from './Colors';

export default createTheme({
	palette: {
		primary: {
			main: Colors.darkPurple
		},
		secondary: { 
			main: Colors.lightPurple
		},
		mainGradient: {
			main: `linear-gradient(45deg, #000 30%, #ff8e53 90%)`
		},
		greenGradient: {
			main: `linear-gradient(45deg, #000 30%, #ff8e53 90%)`
		},
		white: Colors.white
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .Mui-error': {
						'& fieldset': {
							borderColor: 'red !important',
						},
					},
					'& .MuiOutlinedInput-root': {
						// fontSize: 14,
						'& fieldset': {
							borderColor: Colors.grey,
						},
						'&:hover fieldset': {
							borderColor: Colors.lightPurple,
						},
						'&.Mui-focused fieldset': {
							borderColor: Colors.darkPurple,
						},
					},
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderColor: Colors.darkPurple
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: Colors.darkGrey,
					'&.Mui-focused': {
						color: Colors.darkPurple,
					}
				},
			}
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: Colors.darkPurple,
						'& .MuiInputLabel-root': {
							top: -6
						}
					}
				}
			}
		},
		MuiLoadingButton: {
			styleOverrides: {
				root: {
					'&.MuiLoadingButton-root': {
						backgroundColor: Colors.darkPurple,
						color: Colors.white,
						fontSize: 14,
						paddingLeft: 25,
						paddingRight: 25,
						fontFamily: 'MetricBold'
					},
				}
			}
		},
		// MuiButton: {
		// 	styleOverrides: {
		// 		root: {
		// 			'&.MuiButton-root': {
		// 				backgroundColor: Colors.darkPurple,
		// 				color: Colors.white,
		// 				fontSize: 12,
		// 				paddingLeft: 25,
		// 				paddingRight: 25
		// 			},
		// 		}
		// 	}
		// },
		MuiPagination: {
			styleOverrides: {
				root: {
					'&.MuiPaginationItem-root': {
						color: '#fff',
						'&.Mui-selected': {
							backgroundColor: Colors.mainGreen
						}
					}
				}
			}
		},
		MuiFab: {
			styleOverrides: {
				root: {
					'&.MuiButtonBase-root': {
						backgroundColor: Colors.darkPurple,
					}
				}
			}
		},
		// MuiSvgIcon: {
		// 	styleOverrides: {
		// 		root: {
		// 			'&.MuiSvgIcon-root': {
		// 				color: Colors.darkPurple
		// 			}
		// 		}
		// 	}
		// }
	}
});