export const relationArray = [
	"Andet",
	"Barn",
	"Barnebarn",
	"Bedsteforælder",
	"Bekendt",
	"Bror",
	"Datter",
	"Eks. Samlever",
	"Eks. Ægtefælle",
	"Far",
	"Farbror",
	"Farfar",
	"Farmor",
	"Forælder",
	"Fætter",
	"Ingen relationer",
	"Kusine",
	"Mor",
	"Morbror",
	"Morfar",
	"Mormor",
	"Nabo",
	"Nevø",
	"Niece",
	"Oldebarn",
	"Onkel",
	"Overbo",
	"Partsrepræsentant",
	"Plejebarn",
	"Registreret partner",
	"Samboende",
	"Samlever",
	"Steddatter",
	"Svigerbarn",
	"Svigerdatter",
	"Svigersøn",
	"Søn",
	"Søskende",
	"Søster",
	"Tante",
	"Underbo",
	"Uspecificeret pårørende",
	"Ven",
	"Veninde",
	"Værge",
	"Ægtefælle",
	"Øvrig familie",
];
