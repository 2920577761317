import { useMutation } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import KInput from '../../../../components/inputs/KInput';
import KPhoneInput from '../../../../components/inputs/KPhoneInput';
import KButton from '../../../../components/KButton';
import KLoader from '../../../../components/KLoader';
import KSnackbar from '../../../../components/KSnackbar';
import KTitle from '../../../../components/KTitle';
import KSelect from '../../../../components/selectors/KSelect';
import KSelectBox from '../../../../components/selectors/KSelectBox';
import { REGISTER_RELATIVE } from '../../../../graphql/graphqlMutations';
import { GET_RESIDENT_BY_ID } from '../../../../graphql/graphqlQueries';
import { alertWarningMessages } from '../../../../utils/globalTypes';
import { uploadMobileFormat } from '../../../../utils/KmdUtils';
import { relationArray } from '../../../../utils/relationUtil';
import { authenticationOptions, relativeClassifications, relativeInvitationTypes } from '../../../../utils/residentUtils';
import { classNames } from '../../../../utils/tailwindUtils';
import { validate } from '../../../../utils/validators/index';
import { validateNewRelative } from '../../../../utils/validators/relativeValidators';

export default function CreateRelative({ residentId, relatives, onSubmit, setOpen }) {
	const user = useSelector(state => state.user);

	const [message, setMessage] = useState({ msg: "", type: "", timeStamp: null });
	const [errors, setErrors] = useState();

	const [relative, setRelative] = useState({
		loginMethod: authenticationOptions[0],
		role: relatives?.some(e => e?.user?.role?.id == relativeClassifications[0].id) ? relativeClassifications[1] : relativeClassifications[0],
		sendInvitation: true
	});

	const [registerRelative, { loading }] = useMutation(REGISTER_RELATIVE, {
		refetchQueries: [GET_RESIDENT_BY_ID]
	})

	const handleRelativeChange = (key, value) => {
		setRelative({
			...relative,
			[key]: value
		})
	}

	const handleSubmit = async () => {
		const validated = validate(
			validateNewRelative,
			{ relative, relatives },
			setErrors
		)

		if (!validated) {
			return;
		}

		const input = {
			firstName: relative?.firstName,
			lastName: relative?.lastName,
			email: relative?.email ? relative?.email : null,
			relation: relative?.relation,
			sendInvitation: relative?.sendInvitation,
			mobile: relative?.mobile ? uploadMobileFormat(relative?.mobile?.value) : null,
			roleId: relative?.role?.id,
			organizationId: parseInt(user.data.organization.id),
			loginMethod: relative?.loginMethod?.id,
			password: 'NMjQu74mChR0oSoZG7iZ',
			executorId: user.data.id,
			residentId
		}

		registerRelative({
			variables: { input }
		}).then(res => {
			onSubmit()
		}).catch(err => {
			if (err?.message === 'Error: Email already in use') {
				return setErrors({ email: 'Denne email er allerede i brug af en anden pårørende.' })
			}

			setMessage(alertWarningMessages.UNKNOWN)
		})
	}

	return (
		<div className="w-full">
			{loading &&
				<div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
					<KLoader loading={loading} />
				</div>
			}
			<div className={classNames(
				loading ? 'invisible' : 'visible',
				""
			)}>
				<div className="flex items-center justify-between px-4 py-4 border-b">
					<KTitle title="Rediger pårørende" />
					<XMarkIcon className="w-6 h-6 cursor-pointer stroke-2" onClick={() => setOpen(false)} />
				</div>
				<div className="px-8 py-8">
					<KSelect
						error={errors?.role}
						label="Klassifikation"
						required
						elements={relativeClassifications}
						handleSelect={(value) => handleRelativeChange('role', value)}
						selected={relativeClassifications?.find(e => e.id == relative?.role?.id)}
					/>
					<div className="grid grid-cols-2 mt-3 gap-y-3 gap-x-3">
						<KSelect
							label="Login metode"
							required
							elements={authenticationOptions}
							handleSelect={(value) => handleRelativeChange('loginMethod', value)}
							selected={authenticationOptions?.find(e => e.id === relative?.loginMethod?.id)}
						/>
						<KSelect
							label="Relation"
							selected={relationArray.find(e => e === relative?.relation)}
							elements={relationArray}
							handleSelect={(value) => handleRelativeChange('relation', value)}
						/>
						<KInput
							value={relative?.firstName}
							required
							fullWidth={true}
							label="Fornavn"
							onChange={(value) => handleRelativeChange('firstName', value)}
							error={errors?.firstName}
						/>

						<KInput
							value={relative?.lastName}
							required
							fullWidth={true}
							label="Efternavn"
							onChange={(value) => handleRelativeChange('lastName', value)}
							error={errors?.lastName}
						/>
						<KInput
							value={relative?.email}
							fullWidth={true}
							label="Email"
							onChange={(value) => handleRelativeChange('email', value)}
							error={errors?.email}
						/>
						<KPhoneInput
							showInfoText={relative?.loginMethod?.id === authenticationOptions[1].id}
							value={relative?.mobile}
							error={errors?.mobile}
							label="Mobilnummer"
							onChange={(value) => handleRelativeChange('mobile', value)}

						/>
					</div>

					<KSelectBox
						error={errors?.invitation}
						handleSelect={(e) => handleRelativeChange('sendInvitation', e.id === relativeInvitationTypes[0].id ? true : false)}
						className="grid-cols-2"
						list={relativeInvitationTypes}
						selected={relative?.sendInvitation ? relativeInvitationTypes[0] : relativeInvitationTypes[1]}
					/>

					<div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
						<KButton onClick={() => handleSubmit()} label="Tilføj pårørende" />
					</div>
				</div>
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
				key={message.timeStamp}
			/>
		</div>
	)
}
