export const sortingOptions = [
	{ name: "Alfabetisk", id: ['name', 'ASC'] },
	{ name: "Nyeste først", id: ["createdAt", "ASC"] },
	{ name: "Ældste først", id: ["createdAt", "DESC"] },
	{ name: "Senest opdateret", id: ["updatedAt", "ASC"] },
]

export const getFolderName = (allFolders) => {
	const name = 'Ny mappe';
	const similarNamed = allFolders?.filter(f => f?.name?.includes("Ny mappe"));
	const postFix = similarNamed && similarNamed.length > 0 ? ` (${similarNamed.length + 1})` : ''
	return name + postFix;
}