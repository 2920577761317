import Colors from "../styles/Colors"
import { getResidentFullName } from "./residentUtils"
import { isRelativeNoData } from "./roles"
import moment from 'moment';

export const getRenderTitle = (activity,) => {
	if (activity?.participants?.length === 1) {
		return `${activity?.title} (${getResidentFullName(activity?.participants[0]?.resident)})`
	} else {
		return activity?.title
	}
}
export const participantStatusOptions = {
	YES: { name: 'Deltager', value: 'YES' },
	NO: { name: 'Deltager ikke', value: 'NO' },
	AWAITING_STATUS: { name: 'Afventer svar', value: 'AWAITING_STATUS' },
	TOTAL: { name: 'Samlet antal deltagere', value: 'TOTAL' }
}

export const getCalendarColorWorker = (activity) => {
	if (activity?.type === 'KMD') return Colors.grey;
	if (isRelativeNoData(activity?.creator)) return Colors.darkPurple;
	if (!activity?.actionRequired) return Colors.mainGreen;
	if (moment().isAfter(moment(parseInt(activity?.startDate)))) return Colors.red;

	const status = activity?.participants?.map(p => {
		return p?.relativeStatus?.every(e => (
			e.attendingStatus === participantStatusOptions.YES.value ||
			e.attendingStatus === participantStatusOptions.NO.value
		))
	})

	if (status?.every(e => e === true)) {
		return Colors.mainGreen;
	} else {
		return Colors.yellow;
	}
}

export const getCalendarColor = (activity) => {
	if (activity?.type === 'KMD') return Colors.grey;
	if (activity?.actionRequired && moment().isAfter(moment(parseInt(activity?.startDate)))) return Colors.red;

	const status = activity?.calendarStatus?.status;

	if (status === 'YELLOW') {
		return Colors.yellow
	} else if (status === 'PURPLE') {
		return Colors.darkPurple
	} else if (status === 'GREEN') {
		return Colors.mainGreen
	}


	// if (isRelativeNoData(activity?.creator)) return Colors.darkPurple;
	// if (!activity?.actionRequired) return Colors.mainGreen;

	// if (activity?.status?.status === 'EVERY_PARTICIPANT_ANSWERED') {
	// 	return Colors.mainGreen
	// } else {
	// 	return Colors.yellow
	// }
}


const findParticipantsByStatus = (activity, status) => {
	const foundParticipants = activity?.participants?.reduce(function (p, t) {
		const filtered = t?.relativeStatus?.map(relative => {
			if (status(relative.attendingStatus)) {
				return { ...relative, resident: t.resident }
			}
		});

		p.push(...[].concat(filtered))
		return p;
	}, [])

	return foundParticipants?.filter(e => e !== undefined)
}


export const findParticipants = (activeStatus, activity) => {
	if (activeStatus === participantStatusOptions.YES.name) {
		return findParticipantsByStatus(activity, (attending => {
			return attending === participantStatusOptions.YES.value
		}));
	} else if (activeStatus === participantStatusOptions.NO.name) {
		return findParticipantsByStatus(activity, (attending => {
			return attending === participantStatusOptions.NO.value
		}));
	} else if (activeStatus === participantStatusOptions.TOTAL.name) {
		let total = 0;
		const participants = findParticipantsByStatus(activity, (attending => {
			return attending === participantStatusOptions.YES.value
		}));

		participants?.map(participant => {
			const all = 1 + participant?.extraParticipants;

			total += all;
		})

		return total + activity?.participants?.length;
	} else {
		return findParticipantsByStatus(activity, (attending => {
			return attending === participantStatusOptions.AWAITING_STATUS.value
		}));
	}
}

export const getParticipantCalendarColor = (relatives, actionRequired, startDate) => {
	const status = relatives?.map(r => r?.attendingStatus);

	if (!actionRequired) {
		return Colors.mainGreen;
	} else if (moment().isAfter(moment(parseInt(startDate)))) {
		return Colors.red;
	} else if (status.some(s => s === participantStatusOptions.AWAITING_STATUS.value)) {
		return Colors.yellow
	} else {
		return Colors.mainGreen
	}
}

export const getRelativeParticipantColor = (status, actionRequired, startDate) => {
	console.log(status.attendingStatus)
	// const status = relatives?.map(r => r?.attendingStatus);

	if (!actionRequired) {
		return Colors.mainGreen;
	} else if (moment().isAfter(moment(parseInt(startDate)))) {
		return Colors.red;
	} else if (status.attendingStatus === participantStatusOptions.AWAITING_STATUS.value) {
		return Colors.yellow;
	} else if (status.attendingStatus === participantStatusOptions.NO.value) {
		return Colors.red;
	} else {
		return Colors.mainGreen
	}
}

export const repeatOptions = {
	NEVER: { label: "Aldrig", value: "NEVER" },
	DAILY: { label: "Dagligt", value: "DAILY" },
	WEEKLY: { label: "Ugentligt", value: "WEEKLY" },
	SECONDWEEK: { label: "Hver anden uge", value: "SECONDWEEK" },
	MONTHLY: { label: "Månedligt", value: "MONTHLY" },
	YEARLY: { label: "Årligt", value: "YEARLY" },
}
export const repeatOptionsList = [
	repeatOptions.NEVER,
	repeatOptions.DAILY,
	repeatOptions.WEEKLY,
	repeatOptions.SECONDWEEK,
	repeatOptions.MONTHLY,
	repeatOptions.YEARLY,
];
