import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ReactTooltip from 'react-tooltip'
import { mainNavigation } from '../../utils/globalTypes'
import { residentNavigation } from '../../utils/residentUtils'
import { classNames } from '../../utils/tailwindUtils'
import KSidebar from './KSidebar'

export default function KMainSidebar() {
	const [active, setActive] = useState(mainNavigation[1]);

	const history = useHistory();

	const handleClick = (item) => {
		setActive(item)
		history.push(item.route)
	}

	// const isActive = (route) => {
	// 	console.log(history, route)
	// 	if (history?.location?.pathname == route) {
	// 		return true;
	// 	} else {
	// 		return false
	// 	}
	// }

	useEffect(() => {
		console.log(history, 'yes	')
	}, [history])

	const RenderItem = ({ item }) => {
		return (
			<div>
				<div
					data-for={item?.name}
					data-tip
					key={item?.name}
					onClick={() => handleClick(item)}
					className={classNames(
						item?.name === active?.name
							? 'border-mainPurple text-mainPurple'
							: 'border-transparent text-gray-600 hover:bg-gray-200 hover:text-gray-900',
						'relative group flex items-center px-6 py-2 text-base font-bold border-l-8 cursor-pointer select-none'
					)}
				>
					{item?.icon &&
						<item.icon
							className={classNames(
								item?.name === active?.name ? 'text-mainPurple' : 'text-gray-400 group-hover:text-gray-600',
								item?.className ? item?.className : 'mr-3 flex-shrink-0 h-7 w-7',
							)}
							aria-hidden="true"
						/>
					}
					<p className="mr-16">{item.name}</p>
				</div>
			</div>
		)
	}

	const SmallRenderItem = ({ item }) => {
		return (
			<div>
				<div
					data-for={item?.name}
					data-tip
					key={item?.name}
					onClick={() => handleClick(item)}
					className={classNames(
						item?.name === active?.name
							? 'border-mainPurple text-mainPurple'
							: 'border-transparent text-gray-600 hover:bg-gray-200 hover:text-gray-900',
						'relative group flex items-center px-6 py-2 text-base font-bold border-l-8 cursor-pointer select-none'
					)}
				>
					{item?.icon &&
						<item.icon
							className={classNames(
								item?.name === active?.name ? 'text-mainPurple' : 'text-gray-400 group-hover:text-gray-600',
								item?.className ? item?.className : 'mr-3 flex-shrink-0 h-7 w-7',
							)}
							aria-hidden="true"
						/>
					}

					<ReactTooltip delayShow={100} place="right" className="px-5 opacity-90" id={item?.name} aria-haspopup="true">
						<p className="text-xs">{item.name}</p>
					</ReactTooltip>
				</div>
			</div>
		)
	}

	return (
		<div className="pt-16">
			<div className="hidden desktop:inline-block">
				<KSidebar
					RenderItem={RenderItem}
					elements={mainNavigation}
				/>
			</div>
			<div className="visible desktop:hidden">
				<KSidebar
					RenderItem={SmallRenderItem}
					elements={mainNavigation}
				/>
			</div>
		</div>
	)
}
