
import { useEffect, useState } from 'react'

import KSubWrapper from '../../../components/containers/KSubWrapper'
import { defaultResidentTimelineObjects, getResidentTimeLine3, residentNavigation, residentStatusOptions, residentTimelineOptionsList } from '../../../utils/residentUtils'
import KButton from '../../../components/KButton'
import { isPrimaryNoData } from '../../../utils/roles'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_RESIDENT } from '../../../graphql/graphqlMutations'
import { GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, GET_ALL_ROOMS_BY_ORGANZATION_ID, GET_RESIDENT_BY_ID } from '../../../graphql/graphqlQueries'
import { classNames } from '../../../utils/tailwindUtils'
import KSection from '../../../components/cards/KSection'
import { useSelector } from 'react-redux'
import KTimeline from '../../../components/stepper/KTimeline'
import KConfirmModal from '../../../components/modals/KConfirmModal'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { alertWarningMessages } from '../../../utils/globalTypes';

import NexusLogo from '../../../assets/nexus.png'

export default function Onboarding({ resident, warnings, setActiveMenu }) {
	const user = useSelector(state => state.user);

	const [timeline, setTimeline] = useState()
	const [activeTimelineItem, setActiveTimelineItem] = useState();
	const [isFinish, setIsFinish] = useState()

	const [primaryRelative, setPrimaryRelative] = useState();
	const [residentReady, setResidentReady] = useState();
	const [showSubmit, setShowSubmit] = useState();

	const [doUpdateResident, { loading: updateLoading }] = useMutation(UPDATE_RESIDENT, {
		refetchQueries: [GET_RESIDENT_BY_ID]
	})

	useEffect(() => {
		setPrimaryRelative(resident?.relatives?.find(r => isPrimaryNoData(r?.user)));
	}, [resident])


	useEffect(() => {
		if (warnings?.some(e => e?.required)) {
			setResidentReady(false)
		} else {
			setResidentReady(true)
		}
	}, [warnings])

	const handleUpdate = async (url) => {
		try {
			await doUpdateResident({
				variables: {
					input: {
						id: resident?.id,
						status: residentStatusOptions.ACTIVE.id
					}
				}
			})

			setShowSubmit(false)
			setMessage(alertWarningMessages.SUCCESS('Borgeren blev aktiveret.'))
		} catch (err) {

		}
	}

	const handleTimelineClick = (timeline) => {
		setActiveTimelineItem(timeline)
	}

	useEffect(() => {
		const fTimeline = getResidentTimeLine3(resident)

		setTimeline(fTimeline?.all)
		setIsFinish(fTimeline?.isFinish)
		setActiveTimelineItem(fTimeline?.active)
	}, [resident])


	const TopBar = () => (
		<div className="flex items-center justify-between h-12 pr-4 border-b">
			<p className="ml-4 text-lg font-bold text-gray-600">Aktivering af borger</p>
			{resident?.status !== residentStatusOptions.ACTIVE.id &&
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton disabled={!residentReady} disabledText="Borgeren er ikke klar til aktivering. Udfyld venligtst alle påkrævet oplysninger." className="min-w-full" label="AKTIVER BORGER" onClick={() => setShowSubmit(true)} />
				</div>
			}

		</div>
	)

	return (
		<KSubWrapper TopBar={TopBar} title="Indflytning">
			<KConfirmModal
				open={showSubmit}
				setOpen={setShowSubmit}
				title="Aktiver borger i Kintella"
				description="Er du sikker på du vil aktivere borgere i Kintella?"
				confirmText="Aktiver"
				handleConfirm={() => handleUpdate()}
			/>

			<div>
				<div className="pb-10 mt-2">
					<KSection
						title="Status"
						description="Her kan du se status for de handlinger, der er nødvendige for at kunne aktivere borgeren i Kintella."
						Content={() => (
							<div>
							</div>
						)}
					>
						<div>
							<p className="mb-2 text-sm text-gray-600">Du kan klikke på de forskellige steps, for at få yderligere information omkring hvad der er krævet for at løse opgaven</p>
							<KTimeline
								activeElement={activeTimelineItem}
								onClick={(e) => handleTimelineClick(e)}
								steps={timeline}
							/>


							<div className={classNames(
								updateLoading ? 'invisible' : 'visible',
								""
							)}>
								<h2 id="applicant-information-title" className="text-lg font-semibold leading-6 text-gray-600">
									{activeTimelineItem?.name}
								</h2>
								<div className="grid grid-cols-1 mt-2 gap-y-3">
									{activeTimelineItem?.tasks?.map(task => (

										<div className="flex items-start gap-x-2">
											<CheckCircleIcon className={classNames(
												task?.complete ? 'text-darkPurple' : 'text-gray-400',
												"w-5 h-5 mt-1"
											)} />
											<div className="mt-1">
												<p className="text-base text-gray-500">
													{task?.name}
												</p>
												<p className="text-xs text-gray-400">
													{task?.required ? 'Påkrævet' : 'Ikke påkrævet'}
												</p>
											</div>
										</div>
									))}

									{activeTimelineItem?.id === defaultResidentTimelineObjects.FINISH.id &&
										<div >
											<p className="max-w-2xl mt-1 text-base text-gray-500">{activeTimelineItem?.description}</p>
											<p className="max-w-2xl mt-5 text-base text-gray-500">
												Når du klikker på aktiver borger vil der automatisk blive sendt en invitation ud til de pårørende hvor de er gemt som send invitation med det samme.
											</p>
											{resident?.status !== residentStatusOptions.ACTIVE.id &&
												<KButton disabled={!residentReady} disabledText="Borgeren er ikke klar til aktivering. Udfyld venligtst alle påkrævet oplysninger." className="mt-5" label="AKTIVER BORGER" onClick={() => setShowSubmit(true)} />
											}
										</div>
									}

									{activeTimelineItem?.id === defaultResidentTimelineObjects.ROOM.id &&
										<div >
											<p className="max-w-2xl mt-1 text-base text-gray-500">{activeTimelineItem?.description}</p>
											<div >
												<p className="mt-2 text-base text-gray-500 "> For at gøre dette nu, kan du klikke  <span onClick={() => setActiveMenu(residentNavigation[1])} className="border-b border-gray-600 cursor-pointer ">her</span> eller gå ind under Profil i menuen til venstre.</p>
											</div>
										</div>
									}
									{activeTimelineItem?.id === defaultResidentTimelineObjects.CONSENT.id &&
										<div >
											<p className="max-w-2xl mt-1 text-base text-gray-500">{activeTimelineItem?.description}</p>
											<div>
												<p className="max-w-2xl mt-1 text-base text-gray-500">
													For at læse mere om samtykke i Kintella, kan du klikke
													<span
														onClick={() => setActiveMenu(residentNavigation[4])}
														className="border-b border-gray-600 cursor-pointer ">
														{` her `}
													</span>
													eller gå ind under Samtykke i menuen til venstre.
												</p>
											</div>
										</div>
									}
									{activeTimelineItem?.id === defaultResidentTimelineObjects.INFORMATION.id &&
										<div >
											<p className="max-w-2xl mt-1 text-base text-gray-500">{activeTimelineItem?.description}</p>
											<div>
												<p className="text-base text-gray-500">Borgeren er nu integeret med Nexus KMD. Det vil sige at der bliver vekslet data mellem Kintella og Nexus KMD. Der vil i Kintella være markeret med logoet vist nedenfor hvis data bliver vekslet med Nexus KMD</p>
												<img className="mt-5 ml-2 h-7" src={NexusLogo} />
												<div>
													<p className="mt-5 text-base text-gray-500 ">For at redigere i borgerens stamdata, kan du klikke <span onClick={() => setActiveMenu(residentNavigation[1])} className="border-b border-gray-600 cursor-pointer ">her</span> eller gå ind under Profil i menuen til venstre.</p>
												</div>
											</div>
										</div>
									}
									{activeTimelineItem?.id === defaultResidentTimelineObjects.RELATIVES.id &&
										<div >
											<p className="max-w-2xl mt-1 text-base text-gray-500">{activeTimelineItem?.description}</p>
											<p className="max-w-2xl mt-1 text-base text-gray-500">
												For at gøre dette nu, kan du klikke <span onClick={() => setActiveMenu(residentNavigation[2])} className="border-b border-gray-600 cursor-pointer ">her</span> eller gå ind under Pårørende i menuen til venstre
											</p>

										</div>
									}


									{/* {activeTimelineItem?.Content && activeTimelineItem?.Content({ setActiveMenu })} */}
								</div>

							</div>
						</div>
					</KSection>
				</div >
			</div >
		</KSubWrapper >
	)
}