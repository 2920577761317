import React from 'react'
import styled from 'styled-components'
import Colors from '../styles/Colors'

export default function KDivider({ height, color }) {
	return (
		<StyledDivider height={height} color={color} />
	)
}

const StyledDivider = styled.div(({ height, color }) => ({
	height: height ? height : 1, 
	width: '100%', 
	backgroundColor: color ? color : Colors.darkPurple, 
	marginTop: 20, 
	marginBottom: 20
}))