import React, { useState, useEffect, useRef } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';


import {
	DELETE_ORGANIZATION_DOCUMENT_BY_ID,
	DELETE_RESIDENT_DOCUMENT_BY_ID,
	UPDATE_ORGANIZATION_DOCUMENT,
	UPDATE_RESIDENT_DOCUMENT
} from '../../../graphql/graphqlMutations';

import {
	GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID,
	GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID,
	GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID,
	GET_ALL_RESIDENT_DOCUMENTS_BY_RESIDENT_ID
} from '../../../graphql/graphqlQueries';
import KDocumentDropdown from '../../selectors/dropdowns/KDocumentDropdown'
import { alertWarningMessages } from '../../../utils/globalTypes'
import { getFileExt } from '../../../utils/fileUtils'

export default function KDocumentCard({ title, url, document, allDocuments, setSnackMessage, 

	onError = () => { },
	onChange = () => { },
	onSuccess = () => { },
	residentDocument = true,
	canEdit = true,

}) {
	const inputRef = useRef(null);
	const menuRef = useRef(null);

	const [open, setOpen] = useState(false);
	const [rename, setRename] = useState(false);
	const [name, setName] = useState(document.name);
	const [documentParentId, setdocumentParentId] = useState(false);
	const [moveToDropDownOpen, setMoveToDropDownOpen] = useState(false);

	const [deleteDocument, { loading: documentDeleteLoading }] = residentDocument 
		? useMutation(DELETE_RESIDENT_DOCUMENT_BY_ID, {
			refetchQueries: [GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID, GET_ALL_RESIDENT_DOCUMENTS_BY_RESIDENT_ID]
		}) 
		: useMutation(DELETE_ORGANIZATION_DOCUMENT_BY_ID, {
			refetchQueries: [GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID, GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID]
		});

	// update document
	const [updateDocument, { loading: documentUpdateLoading }] = residentDocument 
		? useMutation(UPDATE_RESIDENT_DOCUMENT, {
			refetchQueries: [GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID, GET_ALL_RESIDENT_DOCUMENTS_BY_RESIDENT_ID]
		})
		: useMutation(UPDATE_ORGANIZATION_DOCUMENT, {
			refetchQueries: [GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID, GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID]
		});


	// handle delete
	const handleDelete = async (e) => {
		try {
			await deleteDocument({
				variables: {
					id: document.id
				}
			})

			setSnackMessage(alertWarningMessages.DELETED('Dokumentet'));
		} catch (error) {
			setSnackMessage(alertWarningMessages.UNKNOWN)
		}
	}

	// // handle update
	// const handleUpdate = async (e) => {
	// 	try {
	// 		await updateDocument({
	// 			variables: {
	// 				input: {
	// 					id: document.id,
	// 					name: name,
	// 					parentId: documentParentId
	// 				}
	// 			}
	// 		})
	// 		onChange("Dokumentet blev opdateret");
	// 	} catch (error) {
	// 		console.log("handleUpdate", error);
	// 		onError(error.message);
	// 	}
	// }

	const handleUpdateDocument = async (folder) => {
		try {
			await updateDocument({
				variables: {
					input: {
						id: document.id,
						name: name,
						parentId: document?.parentId ? document?.parentId : null
					}
				}
			})

			setRename(false);

			setSnackMessage(alertWarningMessages.UPDATED('Dokumentet'))
		} catch (err) {
			setSnackMessage(alertWarningMessages.UNKNOWN)
		}
	}


	const handleUpdate = (e) => {
		if (!name) return;

		if (allDocuments.some(f => f.name === name)) {
			return setSnackMessage(alertWarningMessages.ERROR("Et dokument med dette navn eksisterer allerede"));
		}

		if (name !== document?.name) {
			handleUpdateDocument({ ...document, name: name })
		}
	}


	// listen in parent id update
	useEffect(async () => {
		if (documentParentId !== false && documentParentId !== document.parentId) {
			// onUpdate({ ...folder, parentId: folderParentId });

			try {
				await handleUpdate();
				onChange("Dokumentet blev flyttet");
			} catch (error) {
				console.log("useEffect", error);
				onError(error.message);
			}
		}
	}, [documentParentId]);



	const resetName = () => {
		setName(document.name);
		setRename(false);
	}

	const handleRename = (e) => {
		inputRef?.current?.focus()
		setRename(true);
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleUpdateDocument()
		}
		if (event.key === 'Escape') {
			inputRef.current.blur();
			resetName();
		}
	}

	return (
		<div
			className="relative rounded-lg w-28 "
		>
			{canEdit && <KDocumentDropdown
				element={document}
				elements={allDocuments}
				handleDelete={(e) => handleDelete(e)}
				handleEdit={() => setRename(true)}
				handleUpdate={(doc) => handleUpdateDocument(doc)}
				handleReset={() => resetName()}
				hideMoveTo={true}
			/>}
			<div className="absolute top-0 left-0 z-50 px-2 py-1 text-sm text-white uppercase bg-red-600 rounded-md ">
				{getFileExt(document?.url)}
			</div>
			<div className="h-32 border-2 rounded-lg cursor-pointer" >
				<div className='h-full text-black'>
					<div className='w-full mt-5' onClick={() => window.open(url, "_blank")}  >
						<div className="relative flex flex-col items-center justify-center w-full h-full gap-2 pt-10 pb-6 rounded-lg" >
							<div className="w-1/2 h-1 text-lg uppercase bg-gray-200 rounded-md " ></div>
							<div className="w-1/2 h-1 text-lg uppercase bg-gray-200 rounded-md " ></div>
							<div className="w-1/2 h-1 text-lg uppercase bg-gray-200 rounded-md " ></div>
						</div>
					</div>
				</div>
			</div>
			{!rename &&
				<div className="text-sm text-center text-gray-400 cursor-text" onClick={(e) => handleRename(e)}>
					{title}
				</div>
			}

			{rename &&
				<div className='text-white '>
					<input
						autoFocus
						ref={inputRef}
						onKeyDown={handleKeyDown}
						onBlur={() => resetName()}
						type="text"
						value={name}
						className='w-24 h-5 text-sm text-center rounded'
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
			}
		</div >
	)
}
// <div className="mx-8 cursor-pointer" >
// 	<div className="relative w-full " >

// 		{/* <div className="absolute flex items-center h-5 cursor-pointer left-2 top-3" onClick={(e) => { e.stopPropagation(); }} >
// 				<input
// 					id="comments"
// 					aria-describedby="comments-description"
// 					name="comments"
// 					type="checkbox"
// 					onChange={(e) => handleSelectChange(document, e)}
// 					className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
// 				/>
// 			</div> */}

// 		{/*  */}
// <a className='w-full' href={url} download target="_blank"  >

// 	<div className="relative flex flex-col items-center justify-center gap-2 pt-10 pb-6 border-2 rounded-lg" >
// 		<KDocumentDropdown
// 			element={document}
// 			elements={allDocuments}
// 		// handleDelete={(e) => handleDelete(e)}
// 		// handleEdit={() => setRename(true)}
// 		// handleUpdate={(folder) => handleUpdateFolder(folder)}
// 		/>

// 		<div className="w-1/2 h-1 text-lg uppercase bg-gray-200 rounded-md " ></div>
// 		<div className="w-1/2 h-1 text-lg uppercase bg-gray-200 rounded-md " ></div>
// 		<div className="w-1/2 h-1 text-lg uppercase bg-gray-200 rounded-md " ></div>
// 	</div>
// 	<div className="absolute top-0 left-0 px-2 py-1 text-sm text-white uppercase bg-red-600 rounded-md " >PDF</div>
// </a>
// 	</div>
// 	<div className="px-2 mt-1 text-sm text-gray-400 rounded-md " >{title}</div>
// </div>