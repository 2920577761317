import moment from "moment-timezone";
const XDate = require("xdate");

export function sameDate(a, b) {
	return (
		a instanceof XDate &&
		b instanceof XDate &&
		a.getFullYear() === b.getFullYear() &&
		a.getMonth() === b.getMonth() &&
		a.getDate() === b.getDate()
	);
}

const dayNames = [
	"Søndag",
	"Mandag",
	"Tirsdag",
	"Onsdag",
	"Torsdag",
	"Fredag",
	"Lørdag",
];

const monthNames = [
	"Januar",
	"Februar",
	"Marts",
	"April",
	"Maj",
	"Juni",
	"Juli",
	"August",
	"September",
	"Oktober",
	"November",
	"December",
];

export function dateString(date) {
	// let d = new XDate(date);

	return `${dayNames[date.day()]} d. ${date.date()}. ${monthNames[date.month()]
		}`;
}

export function timeString(date) {
	let d = new XDate(date);
	return `${d.hours()}${d.toGMTString(":mm")}`;
}

export function convertUnixToHumanTime(time) {
	if (time === null) {
		return "before jesus christ our lord and savior";
	}
	const today = moment().tz("Europe/Copenhagen");
	var d = moment(parseInt(time)).tz("Europe/Copenhagen");
	if (d.isSame(today, "hour")) {
		return `${today.minutes() - d.minutes()} minutter siden`;
	} else if (d.isSame(today, "day")) {
		return `${today.hours() - d.hours()} timer siden`;
	}
	return `${dayNames[d.day()]} d. ${d.date()}. ${monthNames[d.month()]}`;
}

export function convertUnixToHumanFullTime(time) {
	if (time === null) {
		return "before jesus christ our lord and savior";
	}
	const today = moment().tz("Europe/Copenhagen");
	var d = moment(parseInt(time)).tz("Europe/Copenhagen");
	if (d.isSame(today, "hour")) {
		return `${today.minutes() - d.minutes()} minutter siden`;
	} else if (d.isSame(today, "day")) {
		return `${today.hours() - d.hours()} timer siden`;
	}
	return `${d.date()}. ${monthNames[d.month()]} kl. ${d.hours()}:${d.minutes()}`;
}

export function convertUnixToHumanTimeNotification(time) {
	if (time === null) {
		return "before jesus christ our lord and savior";
	}
	const today = moment().tz("Europe/Copenhagen");
	var d = moment(parseInt(time)).tz("Europe/Copenhagen");
	if (d.isSame(today, "hour")) {
		return `${today.minutes() - d.minutes()} m`;
	} else if (d.isSame(today, "day")) {
		return `${today.hours() - d.hours()} t`;
	} else if (d.isSame(today, "month")) {
		return `${today.day() - d.day()} d`;
	} else if (d.isSame(today, "year")) {
		return `${today.month() - d.month()} m`;
	}
	return `${today.year() - d.year()} år`;
}

export const convertToShortHumanTime = (item) => {
	const date = moment(item, "YYYY-MM-DD");
	const time = moment(item, "YYYY-MM-DD HH:mm").format("HH:mm");
	console.log("got time", time);
	return `${date.date()} ${monthNames[date.month()]?.substring(0, 3)}. ${time}`;
};

export const convertToNewsDate = (item) => {
	var date = moment(parseInt(item)).tz("Europe/Copenhagen");
	return `${date.date()}. ${monthNames[
		date.month()
	]?.toUpperCase()} ${date.year()}`;
};

export const toDatabaseFormat = (dateString) => {
	const date = moment(dateString, "DD-MM-YYYY HH:mm");
	return date.format("YYYY-MM-DD HH:mm");
};

export const toDatabaseFormatDateOnly = (dateString) => {
	const date = moment(dateString, "DD-MM-YYYY");
	return date.format("YYYY-MM-DD");
};

export const unixToDateString = (unix) => {
	return moment(parseInt(unix)).format('DD/MM/YYYY')
}

export const unixToDateTimeString = (unix) => {
	return moment(parseInt(unix)).format('DD/MM/YYYY HH:mm')
}

export const getCurrentWeek = () => {
	const currentDate = moment()

	const weekStart = currentDate.clone().startOf('isoWeek');
	const weekEnd = currentDate.clone().endOf('isoWeek');

	return {
		start: weekStart.toDate(),
		end: weekEnd.toDate(),
		text: `${weekStart.format('DD-MM-YYYY')} til ${weekEnd.format('DD-MM-YYYY')}`
	}
}

export const getCurrentMonth = () => {
	const currentDate = moment()

	const startOfMonth = currentDate.clone().startOf('month');
	const endOfMonth = currentDate.clone().endOf('month');

	return {
		start: startOfMonth.toDate(),
		end: endOfMonth.toDate(),
		text: `${startOfMonth.format('DD-MM-YYYY')} til ${endOfMonth.format('DD-MM-YYYY')}`
	}
}

export const isValidDate = (d) => {
	return d instanceof Date && !isNaN(d);
}

export const handleMinDate = (minDate) => {
	return Number(minDate) ?
		new Date(Number(minDate))
		: moment(minDate, 'DD-MM-YYYY HH:mm').toDate()
}

export const defaultDatePlaceholder = "-";