/* disable-eslint */

import { generateS3File } from "../utils/fileUtils";
import {
    UPLOAD_IMAGE,
    UPLOAD_IMAGE_MULTIPLE,
    DELETE_S3_FILES,
    DELETE_USER_IMAGE_BY_IDS,
    DELETE_USER_FILES_BY_IDS
} from "./graphqlMutations";
import { useMutation } from "@apollo/client";


interface IKintellaFile {
    filename: string;
    mimetype: string;
    key: string;
    url: string;
    referenceId: string;
    type: string;
    id: string
}

interface ILocalFile {
    file: File;
    obj: string;
}

interface IKintellaImage {
    id: string;
    url: string;
    referenceId: string;
    type: string;
    key: string;

}

interface IImageCreateInput {
    url: string;
    key: string;
}


interface IS3File {
    filename: string;
    mimetype: string;
    key: string;
    url: string;
}




type IImage = IKintellaImage | ILocalFile | null;
type IFile = IKintellaFile | ILocalFile | null;

const handleError = (error: any) => {
    console.log(JSON.stringify(error, null, 2));
};


export function useFileUtils() {

    const [doUploadS3File] = useMutation(UPLOAD_IMAGE);
    const [doUploadS3Files] = useMutation(UPLOAD_IMAGE_MULTIPLE);
    const [doDeleteS3Files] = useMutation(DELETE_S3_FILES);
    const [doDeleteUserImages] = useMutation(DELETE_USER_IMAGE_BY_IDS);
    const [doDeleteUserFiles] = useMutation(DELETE_USER_FILES_BY_IDS);

    async function updateImages(images: IImage[], imagesCopy: IKintellaImage[]) {
        const imagesToDelete = imagesCopy.filter((oldImg) => !images.includes(oldImg));
        const uploadedFiles = (images.filter((i) => (i as ILocalFile)?.file) as ILocalFile[]).map(f => f.file);

        console.log(imagesToDelete)
        console.log(uploadedFiles)
        if (imagesToDelete.length > 0) {
            try {
                const s3keys = imagesToDelete.map((i) => i.key);
                await doDeleteUserImages({ variables: { ids: imagesToDelete.map((i) => i.id) } })
                await deleteS3Files(s3keys)
            } catch (error) {
                handleError(error);
            }
        }
        return await createImages(uploadedFiles);
    }

    async function updateFiles(files: IFile[], filesCopy: IKintellaFile[]) {
        const filesToDelete: IKintellaFile[] = filesCopy.filter((oldFile) => !files.includes(oldFile));
        const uploadedFiles = (files.filter((i) => (i as ILocalFile)?.file) as ILocalFile[]).map(f => f.file);


        if (filesToDelete.length > 0) {
            try {
                const s3keys = filesToDelete.map((file) => file.key);
                await doDeleteUserFiles({ variables: { ids: filesToDelete.map((file) => file.id) } });
                await deleteS3Files(s3keys);
            } catch (error) {
                handleError(error);
            }
        }
        return await createFiles(uploadedFiles);
    }

    async function createS3Files(uploadedFiles: File[]) {
        if (!uploadedFiles || uploadedFiles?.length === 0) return [];
        try {
            const res = await uploadFiles(uploadedFiles);
            const is3files: IS3File[] = res?.data?.imageUploadMultiple
            return is3files;
        } catch (error) {
            handleError(error);
            return [];
        }
    }


    async function createImages(images: File[]): Promise<IImageCreateInput[]> {
        const s3files = await createS3Files(images);
        console.log("Got s3 files: ", s3files);

        return s3files.map((file) => {
            return {
                url: file.url,
                key: file.key
            };
        });
    }

    async function createFiles(files: File[]) {
        const s3files = await createS3Files(files);
        return s3files.map((f: IS3File) => {
            return {
                filename: f.filename,
                mimetype: f.mimetype,
                key: f.key,
                url: f.url
            }
        })
    }

    async function uploadFiles(files: File[]) {
        try {
            return await doUploadS3Files({
                variables: {
                    file: files,
                },
            })

        } catch (error) {
            handleError(error);
        }
    }


    // async function uploadSingleFile(f: File | null) {
    //     if (!f) return null;
    //     if (typeof f === "string") return f;
    //     try {
    //         const rnfFile = generateS3File(f.uri, f.filename, f.type);
    //         const file = await doUploadS3File({ variables: { file: rnfFile } })
    //         return file.data.imageUploadSingle.url
    //     } catch (error) {
    //         handleError(error);
    //     }

    // }

    async function deleteS3Files(keys: string[]) {
        try {
            return await doDeleteS3Files({
                variables: {
                    keys,
                },
            })
        } catch (error) {
            handleError(error);
        }
    }

    return Object.freeze({
        createImages,
        updateImages,
        // uploadSingleFile,
        createFiles,
        updateFiles
    });
}