import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Colors from '../styles/Colors';


function KSnackbar({ title, open, setOpen, duration, verticalPosition, horizontalPosition, type, id }) {

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const pickColor = (t) => {
		switch (t) {
			case 'success':
				return Colors.success;
			case 'error':
				return Colors.red;
			case 'warning':
				return Colors.yellow;
			default:
				return Colors.lightPurple;
		}
	}



	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<Snackbar
			anchorOrigin={{
				vertical: verticalPosition,
				horizontal: horizontalPosition
			}}
			open={open}
			autoHideDuration={duration}
			onClose={handleClose}
			message={title}
			action={action}
			id={id}
			ContentProps={{
				sx: {
					background: pickColor(type)
				}
			}}
		/>
	);
}

export default KSnackbar;

