import React, { useState } from 'react';
import KButton from '../../components/KButton';
import { GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID, GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID, GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID } from '../../graphql/graphqlQueries';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import { getResidentFullName } from '../../utils/residentUtils';
import Checkbox from '@mui/material/Checkbox';
import { assignmentsRoute, createAssignmentRoute } from '../../routes/mainRoutes';
import { useHistory } from 'react-router-dom';
// import GlobalFilterTable from '../../graphql/GlobalFilterTable';
import KPageWrapper from '../../components/KPageWrapper';
import FolderIcon from '@mui/icons-material/Folder';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { assignmentConsentStatus, assignmentResponsibleStatus, assignmentSortStatus, assignmentStatus, getSimpleAssignmentStatus, responsible } from '../../utils/assignmentUtils';
import { Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { isWorker } from '../../utils/roles';
import KModal from '../../components/KModal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import KTextField from '../../components/KTextField';
import { FaSearch } from "react-icons/fa";
import { classNames } from '../../utils/tailwindUtils';
import KGlobalFilterTable from '../../components/graphql/KGlobalFilterTable';

export default function Assigments() {
	const history = useHistory();

	const user = useSelector(state => state.user)

	const [showFilters, setShowFilters] = useState(false);
	const [selectedStatusFilters, setSelectedStatusFilters] = useState(assignmentStatus);
	const [selectedResponsibleFilters, setSelectedResponsibleFilters] = useState(assignmentResponsibleStatus);
	const [selectedSortType, setSelectedSortType] = useState(assignmentSortStatus);
	const [search, setSearch] = useState();

	const [activeTodo, setActiveTodo] = useState();

	const Assignment = ({ item: data }) => (
		<StyledTableRow
			style={{ cursor: 'pointer' }}
			key={data.id}
			// onClick={() =>
			// 	history.push(`${assignmentsRoute}/${data?.id}`)
			// }
			onClick={() =>
				setActiveTodo(data)
			}
		>
			<StyledCell component="th" scope="row">
				{data?.imageUrl
					? <Avatar src={data.imageUrl} />
					: (
						<Avatar>
							<FolderIcon />
						</Avatar>
					)
				}
			</StyledCell>
			<StyledCell align="left">{data.title}</StyledCell>
			<StyledCell align="left">{getResidentFullName(data?.resident)}</StyledCell>
			<StyledCell align="left">{getResidentFullName(data?.creator)}</StyledCell>
			<StyledCell align="left">{responsible[data?.responsible]?.name}</StyledCell>
			<StyledCell align="left">{getSimpleAssignmentStatus(data)}</StyledCell>
			<StyledCell align="left">{data?.deadline}</StyledCell>
		</StyledTableRow>
	);

	// TODO - lav om til 1 funktion
	const handleSelectStatusFilter = (status) => {
		let newFilters = Object.assign({}, selectedStatusFilters);
		Object.keys(newFilters).forEach(v => newFilters[v].checked = false)
		newFilters[status[0]].checked = !newFilters[status[0]].checked;
		setSelectedStatusFilters(newFilters)
	}
	const handleSelectResponsibleFilter = (status) => {
		let newFilters = Object.assign({}, selectedResponsibleFilters);
		Object.keys(newFilters).forEach(v => newFilters[v].checked = false)
		newFilters[status[0]].checked = !newFilters[status[0]].checked;
		setSelectedResponsibleFilters(newFilters)
	}

	const handleSelectSortFilter = (status) => {
		let newFilters = Object.assign({}, selectedSortType);
		Object.keys(newFilters).forEach(v => newFilters[v].checked = false)
		newFilters[status[0]].checked = !newFilters[status[0]].checked;
		setSelectedSortType(newFilters)
	}


	const filterStatus = (item) => {
		if (selectedStatusFilters.ALL.checked) {
			return true;
		} else if (selectedStatusFilters.OPEN.checked) {
			return (
				!item.status.accepted &&
				!item.status.solved &&
				!item.status.consent !== assignmentConsentStatus.NOT_REQUIRED.value &&
				item.status.consent !== assignmentConsentStatus.REFUSED.value &&
				item.status.consent !== assignmentConsentStatus.REQUIRED.value
			)
		} else if (selectedStatusFilters.SOLVED.checked) {
			return item.status.solved
		} else if (selectedStatusFilters.ACCEPTED.checked) {
			return item.status.accepted && !item.status.solved && item.status?.acceptedBy?.id === user.data.id
		} else if (selectedStatusFilters.AWAITING.checked) {
			return item.status.consent === assignmentConsentStatus.REQUIRED.value
		} else if (selectedStatusFilters.REFUSED.checked) {
			return item.status.consent === assignmentConsentStatus.REFUSED.value
		} else if (selectedStatusFilters.SOLVED.checked) {
			return item.status.solved
		}
		return false;
	}

	const filterResponsible = (item) => {
		if (selectedResponsibleFilters.RELATIVE.checked) {
			return item.responsible === responsible.RELATIVE.value;
		} else if (selectedResponsibleFilters.WORKER.checked) {
			return item.responsible === responsible.WORKER.value
		} else {
			return true;
		}
	}

	const sortDeadline = (a, b) => {
		if (selectedSortType.CREATED_AT.checked) {
			if (moment(a.createdAt).isSameOrBefore(moment(b.createdAt))) return 1;
			if (moment(a.createdAt).isSameOrAfter(moment(b.createdAt))) return -1;
		} else if (selectedSortType.DEADLINE.checked) {
			if (moment(a.deadline, 'DD/MM/YYYY').isSameOrBefore(moment(b.deadline, 'DD/MM/YYYY'))) return -1;
			if (moment(a.deadline, 'DD/MM/YYYY').isSameOrAfter(moment(b.deadline, 'DD/MM/YYYY'))) return 1;
		}
	}

	const filterSearch = (item) => {
		return search?.length > 0
			? item?.title?.includes(search) || getResidentFullName(item?.creator)?.includes(search)
			: true;
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Opgaver'
			}]}
			buttons={[
				<KButton
					label="OPRET OPGAVE"
					onClick={() => history.push(createAssignmentRoute)}
				/>
			]}
		>
			<KModal open={showFilters} handleChange={setShowFilters}>
				<div>
					<FilterHeader>FILTRER EFTER STATUS</FilterHeader>
					<FilterContainer>
						{Object.entries(selectedStatusFilters).map((status, i) => {
							return (
								<FilterContent key={i} onClick={() => handleSelectStatusFilter(status)}>
									<p>{status[1].name}</p>
									<CheckBox checked={status[1].checked} />
								</FilterContent>
							)
						})}
					</FilterContainer>
				</div>
				<div>
					<FilterHeader>FILTRER EFTER ANSVARLIG</FilterHeader>
					<FilterContainer>
						{Object.entries(selectedResponsibleFilters).map((status, i) => {
							return (
								<FilterContent key={i} onClick={() => handleSelectResponsibleFilter(status)}>
									<p>{status[1].name}</p>
									<CheckBox checked={status[1].checked} />
								</FilterContent>
							)
						})}
					</FilterContainer>
				</div>
				<div>
					<FilterHeader>SORTER EFTER</FilterHeader>
					<FilterContainer>
						{Object.entries(selectedSortType).map((status, i) => {
							return (
								<FilterContent key={i} onClick={() => handleSelectSortFilter(status)}>
									<p>{status[1].name}</p>
									<CheckBox checked={status[1].checked} />
								</FilterContent>
							)
						})}
					</FilterContainer>
				</div>



			</KModal>
			{/* <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
					<Tooltip onClick={() => setShowFilters(!showFilters)} title="Opgave filtre">
						<IconButton>
							<FilterListIcon color="primary" />
						</IconButton>
					</Tooltip>

				</div>
			</Toolbar> */}
			<div className="grid grid-cols-3 gap-x-2">
				<div className={classNames(
					activeTodo ? "col-span-2" : 'col-span-3'
				)}>
					{/* <GlobalFilterTable
						columns={['Billede', 'Titel', 'Omhandler', 'Oprettet af', 'Ansvarlig', 'Status', 'Frist']}
						RenderItem={Assignment}
						residentQuery={GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID}
						departmentQuery={GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID}
						organizationQuery={
							GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID
						}
						pageSize={999}
						ordering={["createdAt", "desc"]}
						filterMethod={(item) => {
							return (
								isWorker({ data: item.creator }) &&
								filterStatus(item) &&
								filterResponsible(item) &&
								filterSearch(item)
							)
						}}
						sortMethod={(a, b) => {
							return (
								sortDeadline(a, b)
							)
						}}
					/> */}

					<KGlobalFilterTable
						headers={['Billede', 'Titel', 'Omhandler', 'Oprettet af', 'Ansvarlig', 'Status', 'Frist']}
						RenderItem={Assignment}
						residentQuery={GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID}
						departmentQuery={GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID}
						organizationQuery={
							GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID
						}
						pageSize={10}
						// hidePagination={true}
						type="opgaver"
						ordering={["createdAt", "desc"]}
						filterMethod={(item) => {
							return (
								isWorker({ data: item.creator }) &&
								filterStatus(item) &&
								filterResponsible(item) &&
								filterSearch(item)
							)
						}}
						sortMethod={(a, b) => {
							return (
								sortDeadline(a, b)
							)
						}}
					/>
				</div>
				{activeTodo &&
					<div>
						<p>Opgave</p>
						<p>{activeTodo?.title}</p>
					</div>
				}

			</div>
		</KPageWrapper>
	)
}

const StyledTableRow = styled(TableRow)(() => ({
	'&:nth-of-type(odd)': {
		backgroundColor: Colors.tableLight
	},
	'&:nth-of-type(even)': {
		backgroundColor: Colors.tableDark
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const StyledCell = styled(TableCell)(() => ({
	width: 50,
	padding: '8px 10px !important'
}));

const FilterHeader = styled.p({
	fontSize: 14,
	fontFamily: 'MetricBold',
	color: Colors.lightBlack,
	marginTop: 10,
	marginBottom: 10,
	textAlign: 'center'
})

const FilterContainer = styled.div({
	display: 'flex',
	gap: 10,
	flexDirection: 'column'
})

const FilterContent = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	cursor: 'pointer'
})

const CheckBox = styled(Checkbox)(() => ({
	padding: '0px !important'
}));