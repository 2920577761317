import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

export const VideoPlayer = ({
	url,
	autoPlay = false,
	loop = false,
	containerClassName = '',
	playOnVisible = false,
	...props
}) => {
	const [playing, setPlaying] = useState(autoPlay);
	// const playerRef = useRef<ReactPlayer | null>(null);
	// const playerContainerRef = useRef<HTMLDivElement | null>(null);

	const handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setPlaying(true);
			} else {
				setPlaying(false);
			}
		});
	};

	// useEffect(() => {
	// 	const observer = new IntersectionObserver(handleIntersection, {
	// 		threshold: 0.5,
	// 	});
	// 	if (playerContainerRef.current && playOnVisible) {
	// 		observer.observe(playerContainerRef.current);
	// 	}
	// 	return () => {
	// 		observer.disconnect();
	// 	};
	// }, [playOnVisible]);

	return (
		<span className={containerClassName}>
			<ReactPlayer
				{...props}
				url={url}
				playing={playing}
				loop={loop}
				controls
				style={props.style}
				muted={autoPlay || playOnVisible}
			/>
		</span>
	);
};
