export const homeRoute = "/hjem";
export const dashboardRoute = "/dashboard";
export const statisticRoute = "/statistik";
export const guidesRoute = "/hjælp";
export const administrationRoute = "/administration";
export const createInterestRoute = "/administration/grupper/opret";
export const interestRoute = (id) => `/administration/grupper/${id}`;
export const interestGroupsRoute = `/administration/grupper`;

export const roomGroupRoute = `/administration/rum`;
export const roomRoute = (id) => `/administration/værelse/${id}`;
export const createRoomRoute = `/administration/rum/opret`;

export const departmentGroupRoute = `/administration/afsnit`;
export const departmentRoute = (id) => `/administration/afsnit/${id}`;
export const createDepartmentRoute = `/administration/afsnit/opret`;

export const orgDocumentsRoute = `/administration/dokumenter`

export const calendarActivityTemplatesRoute = "/administration/skabeloner";
export const calendarActivityTemplateRoute = (id) => `/administration/skabeloner/${id}`;
export const createActivityTemplateRoute = "/administration/skabeloner/aktivitet/opret";
export const updateActivityTemplateRoute = "/administration/skabeloner/aktivitet/opdater";

export const residentsRoute = "/borgeroversigt";
export const residentOnboardingRoute = "/borgeroversigt/onboarding";

export const newsRoute = "/nyheder";
export const createNewsRoute = '/nyhed/opret'
export const newsStoryRoute = (id) => `/nyheder/${id}`
export const editNewsRoute = (id) => `/nyhed/rediger/${id}`

export const assignmentsRoute = "/opgaver";

export const calendarRoute = "/kalender";
export const calendarActivityRoute = (id) => `/kalender/${id}`;
export const createCalendarActivityRoute = '/aktivitet/opret';
export const editCalendarActivityRoute = '/aktivitet/rediger/:id';

export const remindersRoute = "/påmindelser";

export const kmdLoginRoute = "/recieve_token";


export const assignmentRoute = (id) => `${assignmentsRoute}/${id}`
export const createAssignmentRoute = `/opgave/opret`

export const editAssignmentRoute = (id) => `${assignmentsRoute}/rediger/${id}`
export const overdueAssignmentRoute = '/forfaldne/opgaver'

export const selectOrganizationRoute = '/vælg/organisation'