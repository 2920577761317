/* This example requires Tailwind CSS v2.0+ */

export default function KFeed({ elements, }) {
	return (
		<div className="flow-root mt-4">
			<ul role="list" className="-mb-6">
				{elements && elements?.map((event, eventIdx) => (
					<li key={event.id}>
						<div className="relative pb-6">
							{eventIdx !== elements?.length - 1 ? (
								<span className={`absolute top-4 left-1 -ml-px h-full w-0.5 bg-gray-200`} aria-hidden="true" />
							) : null}
							<div className="relative flex space-x-3">
								<div>
									<span
										className={`flex items-center justify-center w-2 h-2 ${event?.color ? event.color : 'bg-gray-200'} rounded-full ring-4 ring-white`}
									>
									</span>
								</div>
								<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
									<div>
										<p className="text-sm text-gray-500">
											<span className="font-medium text-gray-900">
												{event.title}
											</span>
											{' '}{event?.description}
										</p>
										<p className="text-xs text-gray-500">{event.footerText}</p>
										<span className="mt-2 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
											{event?.badgeText}
										</span>
									</div>
								</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	)
}