import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch, useParams, useLocation } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import TopBar from "./components/TopBar";
import * as routes from "./routes/mainRoutes";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import ResidentRouter from "./routes/ResidentRouter";

import theme from "./styles/Theme";
import PrivateRoute from "./components/PrivateRoute";
import Assigments from "./pages/assigments/Assigments";
import Assignment from "./pages/assigments/Assignment";
import Calendar from "./pages/calendar/Calendar";
import News from "./pages/news/News";
import CreateNews from "./pages/news/CreateNews";
import SelectOrganization from "./pages/auth/SelectOrganization";
import Colors from "./styles/Colors";
import Story from "./pages/news/Story";
import EditNews from "./pages/news/EditNews";
import CalendarActivity from "./pages/calendar/CalendarActivity";
import CreateCalendarActivity from "./pages/calendar/CreateCalendarActivity";
import { loginKmd } from "./utils/KmdUtils";
import { useKMD } from "./config";
import EditCalendarActivity from "./pages/calendar/EditCalendarActivity";
import Dashboard from "./pages/dashboard/Dashboard";
import Statistics from "./pages/statistics/Statistics";
import Guides from "./pages/guides/Guides";
import OverdueAssignments from "./pages/assigments/OverdueAssignments";
import moment from 'moment';
import localization from 'moment/locale/da';
import SettingsNew from "./pages/settings/SettingsNew";
import CreateInterestGroup from "./pages/settings/CreateInterestGroup";
import CreateActivityTemplate from "./pages/settings/CreateActivityTemplate"
import CalendarActivityTemplate from "./pages/settings/CalendarActivityTemplate"
import InterestGroup from "./pages/settings/InterestGroup";
import InterestGroups from "./pages/settings/InterestGroups";
import RoomGroup from "./pages/settings/rooms/RoomGroup";
import CalendarActivityTemplates from "./pages/settings/CalendarActivityTemplates";
import Room from "./pages/settings/rooms/Room";
import CreateRoom from "./pages/settings/rooms/CreateRoom";
import DepartmentGroup from "./pages/settings/departments/DepartmentGroup";
import Department from "./pages/settings/departments/Department";
import CreateDepartment from "./pages/settings/departments/CreateDepartment";
import KMainSidebar from "./components/navbars/KMainSidebar";
import CreateTodo from "./pages/todos/CreateTodo";
import EditTodo from "./pages/todos/EditTodo";
import Todos from "./pages/todos/Todos";
import KmdLoginPage from "./pages/auth/KmdLoginPage";
import LoginPage from "./pages/auth/LoginPage";
import OrgDocuments from "./pages/settings/documents/OrgDocuments";

// moment.locale('da')
moment.updateLocale('da', localization);

function App(history) {
	const user = useSelector((state) => state.user);

	const httpLink = createUploadLink({
		uri: process.env.REACT_APP_BACKEND_URL,
	});

	const authLink = setContext((_, { headers }) => {
		const token = user.token;
		return {
			headers: {
				...headers,
				authorization: token ? token : "",
			},
		};
	});

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
	});

	// Listens on redux user state, and pushes to dashboard if user changes to authenticated
	useEffect(() => {
		if (user.loggedIn && !user.KMDAuth && useKMD) {
			return loginKmd(user.data.organization.municipality);
		}
	}, [user.loggedIn]);

	return (
		<ApolloProvider client={client} >
			<ThemeProvider theme={theme}>
				<BrowserRouter history={history} >
					<Switch >
						<Route exact path={"/"} component={LoginPage} />
						<div style={{ backgroundColor: Colors.mainGrey, overflow: "hidden" }}>
							<TopBar />
							<Container>
								{user?.loggedIn &&
									// <Sidebar>
									// 	<NavigationBar />
									// </Sidebar>
									// <KInnerSidebar elements= />
									<KMainSidebar />

								}
								<Content>
									<Route
										exact
										path={routes.selectOrganizationRoute}
										component={SelectOrganization}
									/>
									<Route
										path={routes.kmdLoginRoute}
										component={KmdLoginPage}
									/>
									<PrivateRoute
										exact
										path={routes.dashboardRoute}
										component={Dashboard}
									/>
									<PrivateRoute
										exact
										path={routes.administrationRoute}
										component={SettingsNew}
									/>
									<PrivateRoute
										exact
										path={routes.createInterestRoute}
										component={CreateInterestGroup}
									/>
									<PrivateRoute
										exact
										path={routes.createActivityTemplateRoute}
										component={CreateActivityTemplate}
									/>
									<PrivateRoute
										exact
										path={routes.calendarActivityTemplatesRoute}
										component={CalendarActivityTemplates}
									/>
									<PrivateRoute
										exact
										path={routes.calendarActivityTemplateRoute(':id')}
										component={CalendarActivityTemplate}
									/>
									<PrivateRoute
										exact
										path={routes.interestRoute(':id')}
										component={InterestGroup}
									/>
									<PrivateRoute
										exact
										path={routes.interestGroupsRoute}
										component={InterestGroups}
									/>

									<PrivateRoute
										exact
										path={routes.createRoomRoute}
										component={CreateRoom}
									/>

									<PrivateRoute
										exact
										path={routes.roomRoute(':id')}
										component={Room}
									/>

									<PrivateRoute
										exact
										path={routes.roomGroupRoute}
										component={RoomGroup}
									/>
									<PrivateRoute
										exact
										path={routes.departmentGroupRoute}
										component={DepartmentGroup}
									/>
									<PrivateRoute
										exact
										path={routes.orgDocumentsRoute}
										component={OrgDocuments}
									/>
									<PrivateRoute
										exact
										path={routes.createDepartmentRoute}
										component={CreateDepartment}
									/>
									<PrivateRoute
										exact
										path={routes.departmentRoute(':id')}
										component={Department}
									/>
									<PrivateRoute
										exact
										path={routes.statisticRoute}
										component={Statistics}
									/>
									<PrivateRoute
										exact
										path={routes.guidesRoute}
										component={Guides}
									/>
									<PrivateRoute
										path={routes.residentsRoute}
										component={ResidentRouter}
									/>
									<PrivateRoute
										exact
										path={routes.newsRoute}
										component={News}
									/>
									<PrivateRoute
										exact
										path={routes.newsStoryRoute(':id')}
										component={Story}
									/>
									<PrivateRoute
										exact
										path={routes.createNewsRoute}
										component={CreateNews}
									/>
									<PrivateRoute
										exact
										path={routes.editNewsRoute(':id')}
										component={EditNews}
									/>
									<PrivateRoute
										exact
										path={routes.assignmentsRoute}
										component={Todos}
									/>
									<PrivateRoute
										exact
										path={routes.assignmentRoute(':id')}
										component={Assignment}
									/>
									<PrivateRoute
										exact
										path={routes.editAssignmentRoute(':id')}
										component={EditTodo}
									/>
									<PrivateRoute
										exact
										path={routes.createAssignmentRoute}
										component={CreateTodo}
									/>
									<PrivateRoute
										exact
										path={routes.calendarRoute}
										component={Calendar}
									/>
									<PrivateRoute
										exact
										path={routes.calendarActivityRoute(':id')}
										component={CalendarActivity}
									/>

									<PrivateRoute
										exact
										path={routes.createCalendarActivityRoute}
										component={CreateCalendarActivity}
									/>
									<PrivateRoute
										exact
										path={routes.editCalendarActivityRoute}
										component={EditCalendarActivity}
									/>
									<PrivateRoute
										exact
										path={routes.overdueAssignmentRoute}
										component={OverdueAssignments}
									/>

									{/*
									<PrivateRoute
										exact
										path={routes.remindersRoute}
										component={RemindersPage}
									/> */}
									{/* <PrivateRoute
										exact
										path={routes.notFoundRoute}
										component={() => Redirect />}
									/> */}
								</Content>
							</Container>

						</div>
					</Switch>
				</BrowserRouter>
			</ThemeProvider>
		</ApolloProvider>
	);
}

const Container = styled.div({
	display: "flex",
	height: "calc(100vh - 74px)",
	overflow: "hidden",
});

const Sidebar = styled.div({
	width: "15vw",
	minWidth: 220,
	paddingTop: 0,
});

const Content = styled.div({
	width: "95vw",
	overflow: "hidden",
	paddingTop: 0,
	paddingBottom: 30,
	paddingRight: 20,
});

export default App;
