import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import KButton from '../KButton'
import KTitle from '../KTitle'

export default function KConfirmModal({ open, setOpen, title, description, handleConfirm, confirmText, loading }) {
	return (
		<Transition.Root show={open ? open : false} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-8">
								<div>
									<div className="flex flex-col items-center text-center">
										<KTitle className="text-center" title={title} />
										<div className="mt-3">
											<p className="text-gray-600">
												{description}
											</p>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
									<KButton disabled={loading} className="w-full" label={confirmText ? confirmText : `OK`} onClick={() => handleConfirm()} />
									<KButton disabled={loading} className="w-full" label={`Annuller`} onClick={() => setOpen(false)} />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}