import { classNames } from '../../../utils/tailwindUtils';
import defaultVideoPlaceholder from '../../../assets/defaultVideoPlaceholder.jpg';
import { VideoPlayer } from './VideoPlayer';

export const MediaRender = ({
	media,
	className = '',
	height = '450px',
	onClick = () => { },
}) => {
	const isVideo = (media) => {
		const url = new URL(media);
		const lcPathname = url.pathname?.toLowerCase();
		return lcPathname?.endsWith('.mp4') || lcPathname?.endsWith('.mov') || media?.startsWith('data:video');
	};


	if (!isVideo(media?.url)) {
		return (
			<div className="flex items-center justify-center ">
				<img
					src={media?.url}
					alt=""
					className={classNames(className)}
					style={{
						height: height,
						objectFit: 'contain',
					}}
					onClick={(e) => onClick(e)}
				/>
			</div>
		);
	} else if (isVideo(media?.url)) {
		return (
			<div className="flex items-center justify-center bg-black">
				<VideoPlayer
					url={media?.url ?? defaultVideoPlaceholder}
					controls={true}
					onClick={onClick}
					playOnVisible
					height={height}
				/>
			</div>
		);
	}

	return null; // Handle any other case if necessary
};
