import React, { useState } from 'react'
import { alertWarningTypes } from '../../utils/globalTypes'
import KAlert from '../alerts/KAlert'
import KSection from '../cards/KSection'
import KButton from '../KButton'
import KModal from '../modals/KModal'
import KResidentSelect from '../selectors/KResidentSelect'
import DummyImage from '../../assets/dummyPerson.jpeg'
import { GET_ALL_RESIDENTS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { residentStatusOptions } from '../../utils/residentUtils'
import { useQuery } from '@apollo/client'

export default function KRecipientForm({ selectedResidents, setSelectedResidents, errors, setErrors, disabled }) {
	const user = useSelector(state => state.user);

	const [showAddResident, setShowAddResident] = useState();
	const [residents, setResidents] = useState();

	const { data: residentsData, loading: residentsLoading } = useQuery(GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, {
		variables: { id: user.data.organization.id, size: 999, filters: residentStatusOptions.ACTIVE.id },
		fetchPolicy: 'no-cache'
	});

	useEffect(() => {
		if (residentsData) {
			const residents = residentsData[Object.keys(residentsData)[0]]?.results;
			setResidents(residents)
		}
	}, [residentsData])

	const handleChange = (value) => {
		setErrors({
			...errors,
			selectedResidents: null
		});

		handleResidentChange(value)
	}

	const handleResidentChange = (sResidents) => {
		const newResidents = [...sResidents];
		newResidents.sort((a, b) => (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0))
		setSelectedResidents(newResidents)
	}

	return (
		<KSection
			title="Modtagere"
			description="Her kan du tilføje modtagere"
			rightText="Påkrævet"
			loading={residentsLoading}
		>
			<div className="grid grid-cols-1 gap-y-3">
				<KButton
					label={selectedResidents?.length > 0 ? "REDIGER MODTAGERE" : "TILFØJ MODTAGERE"}
					onClick={() => setShowAddResident(true)}
					disabled={disabled}
					disabledText="Modtagere kan ikke redigeres."
				/>
				{errors?.selectedResidents &&
					<KAlert type={alertWarningTypes.ERROR} RenderItem={() => <p>{errors?.selectedResidents}</p>} />
				}
				<KModal open={showAddResident} setOpen={setShowAddResident} disableCloseOutside={true}>
					<KResidentSelect
						residents={residents}
						selected={selectedResidents}
						onSelect={(residents) => handleChange(residents)}
						title={selectedResidents?.length > 0 ? 'Rediger modtagere' : 'Tilføj modtagere'}
						setOpen={(e) => setShowAddResident(e)}
					/>
				</KModal>
				<div className="grid grid-cols-5 gap-x-2 gap-y-2">
					{selectedResidents?.map(resident => {
						return (
							<div className="flex items-center p-2 border rounded gap-x-2">
								<img className="w-8 h-8 rounded-full" src={resident?.imageUrl ? resident?.imageUrl : DummyImage} />
								<div>
									<p>{resident?.fullName}</p>
									<div className="flex gap-x-2">
										<p className="text-xs text-gray-500">{resident?.room?.department?.name}</p>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</KSection>
	)
}
