import { Avatar, Button } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Colors from '../styles/Colors';
import KModal from './KModal'
import KTextField from './KTextField'
import KSnackbar from './KSnackbar';

export default function KPicker({ label, title, subTitle, onSelect, selected, selectedText, validation, error, list, open, setOpen }) {
	const user = useSelector(state => state.user);
	console.log(list)
	// const [open, setOpen] = useState(true);
	const [selectedResidents, setSelectedResidents] = useState([]);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [currentView, setCurrentView] = useState('residents');

	const handleSelectResident = (resident, checked) => {
		if (checked) {
			setSelectedResidents(selectedResidents?.filter(p => p.id !== resident.id))
		} else {
			console.log(selectedResidents)
			console.log(resident)
			setSelectedResidents([...selectedResidents, resident])
			// if (validation) {
			// 	if (validation(resident)) {
			// 		setSelectedResidents([...selectedResidents, resident])
			// 	} else {
			// 		setErrorMessage(`${getResidentFullName(resident)} har ikke givet samtykke til billede-deling. Billedet kan derfor ikke deles. Vælg et andet billede med andre borgere på, inden du går videre.`)
			// 		setShowError(true);
			// 	}
			// } else {
			// }
		}
	}

	const handleDone = () => {
		onSelect(selectedResidents)
		setOpen(false)
		setSelectedResidents([])
	}

	return (
		<div>
			<KSnackbar
				open={showError}
				setOpen={setShowError}
				title={errorMessage}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
			/>
			<KModal containerStyles={ModalContainerStyles} open={open} handleChange={() => setOpen(true)}>
				<TopContainer>
					<ModalTitle fontSize={18} fontFamily="MetricBold">{title?.toUpperCase()}</ModalTitle>
					<ModalTitle fontSize={14} fontFamily="MetricRegular">{subTitle}</ModalTitle>
				</TopContainer>

				<ContentContainer>
					<Content>
						{list?.map((item, index) => {
							const checked = selectedResidents?.map(r => r.id)?.includes(item?.id);
							return (
								<ItemContainer
									key={index}
									onClick={() => handleSelectResident(item, checked)}
									selected={checked}
									index={index}
								>
									<Avatar src={item?.imageUrl} />
									<div>
										<p>{item?.label}</p>
										<ContentUnderText>{selectedText}</ContentUnderText>
									</div>
								</ItemContainer>
							)
						})}
					</Content>
					<Content>
						{(!list || list?.length === 0) &&
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<p>Ingen fundet.</p>
							</div>
						}
					</Content>
				</ContentContainer>
				<ButtomContainer>
					<p style={{ cursor: 'pointer' }} onClick={() => setOpen(false)}>Annuller</p>
					<DoneButton onClick={() => handleDone()}>Færdig</DoneButton>
				</ButtomContainer>
			</KModal>

		</div>
	)
}

const InputContainer = styled.div({
	cursor: 'pointer'
})

const ModalContainerStyles = {
	paddingLeft: 0,
	paddingRight: 0,
	paddingBottom: 0,
	minWidth: '50%'
}

const ModalTitle = styled.p(({ fontFamily, fontSize, cursor }) => ({
	fontFamily: fontFamily,
	fontSize: fontSize,
	color: Colors.lightBlack,
	cursor: cursor ? cursor : 'auto'
}))

const TopContainer = styled.div({
	paddingLeft: 30,
	paddingRight: 30,
	textAlign: 'center'
})

const ContentContainer = styled.div({
	height: 379,
	overflow: 'scroll'
})

const Content = styled.div({
	marginTop: 30
})

const ContentUnderText = styled.p({
	fontSize: 10,
	fontFamily: 'MetricRegular',
	marginTop: -3
})

const ButtomContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	height: 50,
	borderTop: '1px solid #e2e2e2',
	alignItems: 'center',
	paddingLeft: 20,
	paddingRight: 20
})

const DoneButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: Colors.darkPurple,
		color: '#fff',
		fontSize: 12,
		paddingRight: 30,
		paddingLeft: 30,

		'&:hover': {
			backgroundColor: Colors.darkPurple
		}
	}
}))

const ItemContainer = styled.div(({ index, selected }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: 10,
	cursor: 'pointer',
	borderTop: index & 2 !== 0 || index === 0 ? '1px solid #e2e2e2' : 'none',
	borderBottom: index & 2 !== 0 ? '1px solid #e2e2e2' : 'none',
	paddingBottom: 10,
	paddingTop: 10,
	paddingRight: 30,
	paddingLeft: 30,
	backgroundColor: selected ? Colors.darkPurple : '#fff',
	color: selected ? '#fff' : '#000',
	height: 50,
	userSelect: 'none',
}))

const PickerInput = styled(KTextField)(() => ({
	'& .Mui-disabled': {
		border: '1px solid #000 !important',
		'& fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: "blue",
			background: "grey"
		}
	}
}))
