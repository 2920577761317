import { useMutation, useQuery } from '@apollo/client'
import { Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import KPageWrapper from '../../components/KPageWrapper'
import KTextField from '../../components/KTextField'
import KImageDrop from '../../components/KImageDrop'
import { GET_ALL_CALENDAR_ACTIVITIES_IN_DRAFT_BY_CREATOR_ID, GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries'
import { calendarActivityTemplatesRoute } from '../../routes/mainRoutes'

import moment from 'moment';
import KLoadingButton from '../../components/KLoadingButton'
import { CREATE_CALENDAR_ACTIVITY_TEMPLATE } from '../../graphql/graphqlMutations'
import { validateActivityTemplate } from '../../utils/validators'
import ImageUploadQuery from '../../graphql/ImageUploadQuery'
import { UPLOAD_IMAGE } from '../../graphql/graphqlMutations';

export default function CreateCalendarActivity({ history }) {
	const user = useSelector(state => state.user);

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [workerNote, setWorkerNote] = useState();
	const [errors, setErrors] = useState();
	const [actionRequired, setActionRequired] = useState(false);

	const [templateName, setTemplateName] = useState("");
	const [images, setImages] = useState();
	const [doUploadImage, { loading: uploadLoading }] = useMutation(UPLOAD_IMAGE);

	const [doCreateActivityTemplate, { loading: submitLoading }] = useMutation(CREATE_CALENDAR_ACTIVITY_TEMPLATE, {
		refetchQueries: [GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID]
	})


	const handleChange = (key, setter, value) => {
		setErrors({
			...errors,
			[key]: null
		});

		setter(value)
	}

	const handleImageChange = (values) => {
		setImages(values?.map(val => {
			return {
				file: val,
				obj: URL.createObjectURL(val)
			}
		}))
	}

	const makeCalendarActivityTemplate = (url) => {
		const validated = validateActivityTemplate({
			title,
			description,
			templateName
		});

		if (!validated.success) {
			return setErrors(validated.errors)
		}

		const variables = {
			input: {
				// id: 1,
				creatorId: user?.data?.id,
				departmentId: 1, // TODO
				organizationId: user?.data?.organization?.id,
				title: title,
				templateName: templateName,
				description: description,
				actionRequired: actionRequired,
				imageUrl: url,
				note: workerNote
			}
		}
		doCreateActivityTemplate({ variables })
			.then(res => {
				// console.log(res)
				history.goBack();
			}).catch(err => {
				console.log(err)
				console.log(JSON.stringify(err, 2, null))
			})
	}


	const postActivity = (isDraft) => {
		const validated = validateActivityTemplate({
			title,
			description,
			templateName
		});

		if (!validated.success) {
			return setErrors(validated.errors)
		}

		images ?
			ImageUploadQuery(makeCalendarActivityTemplate, images[0]?.file, doUploadImage)
			: makeCalendarActivityTemplate(null)
	};

	return (
		<KPageWrapper
			paths={[{
				title: 'Skabeloner',
				route: calendarActivityTemplatesRoute
			}, {
				title: 'Opret skabelon'
			}]}
			loading={[submitLoading, uploadLoading].some(val => val === true)}
		>
			<Container>
				<Content>
					<KTextField
						error={errors?.templateName}
						value={templateName}
						label="Navn på skabelon"
						required
						onChange={(value) => handleChange('templateName', setTemplateName, value)}
					/>
					<KTextField
						error={errors?.title}
						value={title}
						label="Titel"
						required
						onChange={(value) => handleChange('title', setTitle, value)}
					/>
					<KTextField
						error={errors?.description}
						value={description}
						label="Beskrivelse"
						required
						onChange={(value) => handleChange('description', setDescription, value)}
						multiline
						rows={6.2}
					/>
					<KTextField
						value={workerNote}
						label="Medarbejdernote (Vil ikke blive vist for pårørende)"
						onChange={(value) => handleChange('workerNote', setWorkerNote, value)}
						multiline
						rows={4}
					/>

					<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
						<Checkbox checked={actionRequired} onChange={() => setActionRequired(!actionRequired)} />
						<p>Kræver svar fra pårørende</p>
					</div>

				</Content>

				<Content>

					<Content>
						<KImageDrop
							height={370}
							handleDrop={(file) => handleImageChange(file)}
							onClear={() => setImages()}
							files={images}
						/>
					</Content>


				</Content>
			</Container>
			<div style={{ marginTop: 20, width: 382, display: 'flex', gap: 20, alignItems: 'center' }}>
				<KLoadingButton onClick={() => postActivity(false)} title="OPRET SKABELON" />
			</div>
		</KPageWrapper>
	)
}

const Container = styled.div({
	display: 'flex',
	gap: 20,
	paddingTop: 20
})

const Content = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	gap: 20
})