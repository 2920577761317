import { useMutation, useQuery, client } from '@apollo/client'
import { TableCell, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import KButton from '../../../components/KButton'
import KDataGrid from '../../../components/KDataGrid'
import KPageWrapper from '../../../components/KPageWrapper'
import { GET_ALL_ROOMS_BY_ORGANZATION_ID } from '../../../graphql/graphqlQueries'
import { administrationRoute, roomRoute, createRoomRoute } from '../../../routes/mainRoutes'
import { roomGroupHeaders } from '../../../utils/settingsUtils'
import moment from 'moment';
import { DELETE_MULTIPLE_ROOMS_BY_ID } from '../../../graphql/graphqlMutations'
import styled from 'styled-components';
import KModal from '../../../components/KModal'
import Colors from '../../../styles/Colors'
import { getResidentFullName } from '../../../utils/residentUtils'
import Alert from '../../../components/alerts/Alert'


export default function RoomGroup({ history }) {
	const user = useSelector(state => state.user);

	const [rooms, setRooms] = useState([]);
	const [showModal, setShowModal] = useState('')
	const [showErrorModal, setShowErrorModal] = useState('')

	const [deleteSelected, setDeleteSelected] = useState([]);

	const { data, loading, refetch } = useQuery(GET_ALL_ROOMS_BY_ORGANZATION_ID,
		{ variables: { id: parseInt(user?.data?.organization?.id) } })

	const [doDeleteRooms, { loading: deleteLoading }] = useMutation(DELETE_MULTIPLE_ROOMS_BY_ID, {
		refetchQueries: [GET_ALL_ROOMS_BY_ORGANZATION_ID]
	})

	const [errors, setErrors] = useState("")

	useEffect(async () => {
		// refetch()
		setRooms(data?.getAllRoomsByOrganizationId)
		refetch()
	}, [data])

	const handleDelete = async () => {
		const foundRoomsToDelete = rooms?.filter(room => deleteSelected?.includes(room.name));
		await doDeleteRooms({
			variables: {
				ids: foundRoomsToDelete?.map(e => e.id)
			}
		})
		setShowModal(false)
		setDeleteSelected([]);
	}

	const handleOnDelete = (selected) => {
		setDeleteSelected(selected)
		if (displayRoomsWithResidents(selected)) {
			setShowErrorModal(true)
		} else {
			setShowModal(true)
		}
	}

	const displayRoomsWithResidents = (deleteSelected) => {
		const foundRoomsToDelete = rooms?.filter(room => deleteSelected?.includes(room.name));
		const roomsWithResidents = foundRoomsToDelete?.filter(room => room.resident !== null)
		if (!roomsWithResidents?.length) return ""
		const roomNames = roomsWithResidents.map(room => room.name)
		return `Følgende boliger kan ikke slettes da de har en beboer: ${roomNames.join(', ')}`
	}

	const RenderItem = ({ row }) => {

		return (
			<React.Fragment>
				<TableCell
					component="th"
					// id={labelId}
					scope="row"
					padding="none"
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
						{row?.name}
					</div>
				</TableCell>
				<TableCell align="right">{row?.department?.name || "-"}</TableCell>
				<TableCell align="right">{row?.resident ? getResidentFullName(row.resident) : "-"}</TableCell>
				{/* <TableCell align="right">{row?.resident?.phone || "-"}</TableCell> */}
				<TableCell align="right">{moment(parseInt(row?.updatedAt)).format('DD-MM-YYYY HH:mm')}</TableCell>
			</React.Fragment>
		)
	}
	return (
		<KPageWrapper
			paths={[{
				title: 'Administration',
				route: administrationRoute
			}, {
				title: 'Boliger'
			}]}
			buttons={[
				<KButton
					label="TILFØJ BOLIG"
					onClick={() => { history.push(createRoomRoute) }}
				/>
			]}
			// errors={errors}
			loading={loading || deleteLoading}
		>
			<div style={{ marginTop: 20 }}>
				<KModal
					open={showModal}
					title={deleteSelected?.length > 1 ? "Slet boliger" : "Slet bolig"}
					subTitle={deleteSelected?.length > 1 ? "Er du sikker på at du vil slette de valgte boliger?" : `Er du sikker på at du vil slette boligen?`}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						{!errors && <ModalButton fullWidth onClick={() => handleDelete()}>Slet</ModalButton>}
						<ModalButton fullWidth onClick={() => setShowModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>

				<KModal
					open={showErrorModal}
					title={"OBS!"}
					subTitle={displayRoomsWithResidents(deleteSelected)}
					handleChange={() => setShowErrorModal(false)}
				>
					<ModalContainer>
						<ModalButton fullWidth onClick={() => setShowErrorModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>

				{rooms &&
					<KDataGrid
						headers={roomGroupHeaders}
						rows={rooms}
						withSearch={true}
						searchField="name"
						RenderItem={RenderItem}
						handleDelete={(s) => handleOnDelete(s)}
						onClick={(id) => history.push(roomRoute(id))}
					/>
				}
			</div>
		</KPageWrapper>
	)
}

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,

		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))