import React from 'react'
import { ExclamationCircleIcon, InformationCircleIcon, ShieldExclamationIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { classNames } from '../../utils/tailwindUtils'
import { alertWarningTypes } from '../../utils/globalTypes'

export default function KAlert({ RenderItem, type }) {
	const getIcon = () => {
		if (type?.name === alertWarningTypes.ERROR.name) {
			return (
				<ExclamationCircleIcon className={classNames(
					type?.icon,
					"w-5 h-5 text-yellow-400"
				)} aria-hidden="true" />
			)
		} else if (type?.name === alertWarningTypes.INFO.name) {
			return (
				<InformationCircleIcon className={classNames(
					type?.icon,
					"w-5 h-5 text-yellow-400"
				)} aria-hidden="true" />
			)
		} else {
			return (
				<ExclamationCircleIcon className={classNames(
					type?.icon,
					"w-5 h-5 text-yellow-400"
				)} aria-hidden="true" />
			)
		}
	}

	return (
		<div>
			<div
				className={classNames(
					type?.container ? type?.container : null,
					"p-4 rounded-md bg-yellow-50"
				)}
			>
				<div className="flex">
					<div className="flex-shrink-0">
						{getIcon()}
					</div>
					<div className="ml-3">
						<RenderItem />
					</div>
				</div>
			</div>
		</div>
	)
}
