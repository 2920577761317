import React, { useEffect, useRef } from "react";
import KTitle from "./KTitle";
import { FaChevronLeft } from "react-icons/fa";
import styled from 'styled-components';
import Colors from "../styles/Colors";
import KLoader from "./KLoader";
import { useHistory } from "react-router-dom";
import Alert from "./alerts/Alert";

export default function KPageWrapper({ contentContainerStyle = {}, buttons, children, loading, paths, errors, TopBar, disableScroll, style = {} }) {
	const history = useHistory();

	const scrollRef = useRef();

	useEffect(() => {
		if (errors) {
			window.scrollTo(0, 0)
		}
	}, [errors])

	return (
		<div style={style} >
			<Container>
				<div style={{ display: 'flex', gap: 10 }}>
					{paths?.map(path => {
						return (
							<TitleContainer hasRoute={path?.route ? true : false} onClick={() => path?.route ? history.push(path?.route) : () => { }}>
								<FaChevronLeft style={{ paddingTop: 1 }} color={Colors.lightBlack} size={18} />
								<KTitle title={path?.title} />
							</TitleContainer>
						)
					})}
				</div>
				<div style={{ display: 'flex', gap: 10 }}>
					{buttons && buttons.map((b) => b)}
				</div>
			</Container>
			{TopBar &&
				<TopBar />
			}
			<ContentContainer TopBar={TopBar} ref={scrollRef} style={{ ...contentContainerStyle, overflow: disableScroll ? null : 'scroll' }}>
				{loading &&
					<KLoader />
				}
				{errors && <Alert messages={errors} />}
				{!loading && children}

			</ContentContainer>

		</div>
	);
}

const Container = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	height: 60,
	alignItems: 'center',
	paddingTop: 20,
	paddingBottom: 10
})

const TitleContainer = styled.div(({ hasRoute }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: hasRoute ? 'pointer' : 'default'
}))

const ContentContainer = styled.div(({ TopBar }) => ({
	backgroundColor: '#fff',
	height: TopBar ? 'calc(100vh - 224px)' : `calc(100vh - 160px)`,
	// overflow: disableScroll ? null : 'scroll',
	paddingLeft: 20,
	paddingRight: 20,
	paddingTop: 10,
	paddingBottom: 10
}))