import { useMutation, useQuery } from '@apollo/client'
import { Avatar, TableCell, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import KButton from '../../components/KButton'
import KDataGrid from '../../components/KDataGrid'
import KPageWrapper from '../../components/KPageWrapper'
import KTextField from '../../components/KTextField'
import KTitle from '../../components/KTitle'
import { GET_ALL_INTERESTS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries'
import { createInterestRoute, interestGroupsRoute, interestRoute, administrationRoute } from '../../routes/mainRoutes'
import { interestGroupHeaders, interestGroupResidentHeaders } from '../../utils/settingsUtils'
import moment from 'moment';
import { DELETE_INTEREST_BY_ID, DELETE_MULTIPLE_INTEREST_BY_ID } from '../../graphql/graphqlMutations'
import styled from 'styled-components';
import KModal from '../../components/KModal'
import Colors from '../../styles/Colors'

export default function InterestGroups({ history }) {
	const user = useSelector(state => state.user);

	const [interestGroups, setInterestGroups] = useState([]);
	const [showModal, setShowModal] = useState('')
	const [deleteSelected, setDeleteSelected] = useState();


	const { data, loading } = useQuery(GET_ALL_INTERESTS_BY_ORGANIZATION_ID, { variables: { id: user?.data?.organization?.id } })
	const [doDeleteInterest] = useMutation(DELETE_MULTIPLE_INTEREST_BY_ID, {
		refetchQueries: [GET_ALL_INTERESTS_BY_ORGANIZATION_ID]
	})

	useEffect(() => {
		setInterestGroups(data?.getAllInterestsByOrganizationId)
	}, [data])

	const handleDelete = async () => {
		const foundInterestGroups = interestGroups?.filter(e => deleteSelected?.includes(e.title));
		await doDeleteInterest({
			variables: {
				ids: foundInterestGroups?.map(e => e.id)
			}
		})

		setShowModal(false)
	}

	const handleOnDelete = (selected) => {
		setShowModal(true)
		setDeleteSelected(selected)
	}

	const RenderItem = ({ row }) => {
		return (
			<React.Fragment>
				<TableCell
					component="th"
					// id={labelId}
					scope="row"
					padding="none"
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
						{/* <Avatar sx={{ width: 30, height: 30, mt: 1, mb: 1 }} src={row?.imageUrl} /> */}
						{row?.title}
					</div>
				</TableCell>
				<TableCell align="right">{row?.residents?.length}</TableCell>
				<TableCell align="right">{row?.relatives?.length}</TableCell>
				<TableCell align="right">{row?.workers?.length}</TableCell>
				<TableCell align="right">{moment(parseInt(row?.updatedAt)).format('DD-MM-YYYY HH:mm')}</TableCell>

			</React.Fragment>
		)
	}
	return (
		<KPageWrapper
			paths={[{
				title: 'Administration',
				route: administrationRoute
			}, {
				title: 'Interessegrupper'
			}]}
			buttons={[
				<KButton
					label="TILFØJ INTERESSEGRUPPE"
					onClick={() => history.push(createInterestRoute)}
				/>
			]}
		>
			<div style={{ marginTop: 20 }}>
				{/* <KTitle title="Interessegrupper" />
				<div>
					<KButton label="TILFØJ INTRESSEGRUPPE" />
				</div> */}
				<KModal
					open={showModal}
					title={deleteSelected?.length > 1 ? "Slet interessegrupper" : "Slet interessegruppe"}
					subTitle={deleteSelected?.length > 1 ? "Er du sikker på at du vil slette de valgte interessegrupper?" : "Er du sikker på at du vil slette interessegruppen?"}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						<ModalButton fullWidth onClick={() => handleDelete()}>Slet</ModalButton>
						<ModalButton fullWidth onClick={() => setShowModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>

				{interestGroups &&
					<KDataGrid
						headers={interestGroupHeaders}
						rows={interestGroups}
						withSearch={true}
						searchField="title"
						RenderItem={RenderItem}
						handleDelete={(s) => handleOnDelete(s)}
						onClick={(id) => history.push(interestRoute(id))}

					// handleDelete={(s) => handleRemoveUserInterest(s, interest?.workers)}
					/>
				}
			</div>
		</KPageWrapper>
	)
}

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,

		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))