import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Logo from '../../assets/kintella1024.png'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function KInnerSidebar({ title, elements, activeElement, onChange, warnings }) {
	const getWarnings = (name) => {
		return warnings?.filter(k => k?.profile?.name === name)
	};

	return (
		<div className="h-full pb-4 overflow-y-auto bg-white border-r border-gray-200">
			<div className="flex items-center flex-shrink-0 h-12 px-4 border-b gap-x-4">
				<p className="text-lg font-bold text-gray-800">{title}</p>
			</div>
			<div className="flex flex-col flex-grow">
				<nav className="flex-1 space-y-1 bg-white" aria-label="Sidebar">
					{elements?.map((item) => {
						return (
							<div
								key={item.name}
								onClick={() => onChange(item)}
								className={classNames(
									item?.id === activeElement?.id
										? 'bg-indigo-50 border-mainPurple text-mainPurple'
										: 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
									'relative group flex items-center px-3 py-2 text-sm font-bold border-l-4 cursor-pointer select-none'
								)}
							>
								{item?.icon &&
									<item.icon
										className={classNames(
											item?.id === activeElement?.id ? 'text-mainPurple' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
								}
								<p>{item.name}</p>
								{getWarnings(item?.name)?.length > 0 && <ExclamationCircleIcon className="absolute w-6 h-6 text-yellow-400 right-4" />}
							</div>
						)
					})}
				</nav>
			</div>
		</div>
	)
}