import { Dialog, } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import KTextField from '../../KTextField';
import KTitle from '../../KTitle';
import KButton from '../../KButton';
import moment from 'moment';
import Colors from '../../../styles/Colors';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { da } from 'date-fns/locale'
import { isValidDate, defaultDatePlaceholder } from '../../../utils/dateUtils';
// import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.css";
import KInput from '../../inputs/KInput';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import KDatePicker from '../KDatePicker';
registerLocale('da', da)


export default function KDateTimePicker({ label, onSubmit = () => { }, minDate, error, defaultValue, disableTime, required, withClear }) {
	const [open, setOpen] = useState(false);
	const [date, setDate] = useState(null);

	const handleOpen = () => {
		!minDate && setOpen(true)
		isValidDate(minDate) && setOpen(true)
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit(disableTime ? moment(date).format('DD-MM-YYYY') : moment(date).format('DD-MM-YYYY HH:mm'))
		setOpen(false)
	}

	// timeout that overrides the min date with the default value (fires only once)
	useEffect(() => {
		if (defaultValue) {
			if (moment.isMoment(defaultValue)) {
				setDate(defaultValue?.toDate())
			}
		}
	}, [defaultValue])

	console.log(date)
	useEffect(() => {
		if (minDate) {
			if (isValidDate(minDate)) {
				if (date && date < minDate) {
					setDate(new Date(minDate))
				}
			} else { //  when clearing startdate clear, clear end date
				setDate(null)
			}
		}
	}, [minDate])


	// return (
	// 	<div>
	// 		<KDatePicker />
	// 	</div>
	// )
	console.log(date)
	return (
		<div>
			<div className='cursor-pointer' onClick={(e) => handleOpen()}>
				<KInput
					error={error}
					disabled
					label={label}
					value={handleDisplayDate(date, !disableTime)}
					required={required}
					Icon={() => <CalendarDaysIcon className="w-5 h-5 text-gray-500" />}
					className="cursor-pointer"
				/>
				{date && withClear &&
					<p
						onClick={(e) => {
							e.stopPropagation()
							setDate(null)
							onSubmit(null)
						}}
						className="mt-1 ml-1 text-xs text-right text-gray-500 cursor-pointer"
					>
						Ryd felt
					</p>
				}
			</div>
			<Dialog open={open}>
				<div className='p-10' >
					<div className='flex gap-2 mx-auto mb-6' >
						<KTitle title={`Vælg ${label}:`} />
						<KTitle style={{ fontFamily: 'MetricSemiBold', color: Colors.darkGrey }} title={disableTime ? handleDisplayDate(date) : handleDisplayDateTime(date)} />
					</div>
					<DatePicker
						selected={date}
						onChange={(d) => setDate(d)}
						locale="da"
						showTimeSelect={disableTime ? false : true}
						timeFormat="p"
						timeIntervals={15}
						dateFormat="Pp"
						inline
						minDate={minDate}
						timeCaption="Tid"
					/>
					<div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
						<p style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>Annuller</p>
						<KButton type="submit" onClick={(e) => handleSubmit(e)} label="Færdig" />
					</div>
				</div>

			</Dialog >
		</div >
	)
}

const PickerInput = styled(KTextField)(() => ({
	'& .Mui-disabled': {
		border: '1px solid #000 !important',
		'& fieldset.MuiOutlinedInput-notchedOutline': {
			borderColor: "blue",
			background: "grey"
		}
	}
}))


const formatServerDate = (d) => {
	if (!d) return defaultDatePlaceholder
	return moment(new Date(Number(d))).format('MM-DD-YYYY HH:mm').toDate()
}

const handleDisplayDate = (date, showTime) => {
	if (!date || date === "Invalid date") return defaultDatePlaceholder;
	if (typeof date === "string" && Number(date)) return moment(new Date(Number(date))).format('DD-MM-YYYY HH:mm')
	if (isValidDate(date) && !showTime) return moment(date).format('DD-MM-YYYY');
	if (isValidDate(date) && showTime) return moment(date).format('DD-MM-YYYY HH:mm');
	return date
}

const handleDisplayDateTime = (date) => {
	if (!date || date === "Invalid date") return defaultDatePlaceholder;
	if (typeof date === "string" && Number(date)) return moment(new Date(Number(date))).format('DD-MM-YYYY HH:mm')
	if (isValidDate(date)) return moment(date).format('DD-MM-YYYY HH:mm');
	return date
}