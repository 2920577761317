import React, { useEffect, useState } from 'react'
import { relativeClassifications } from '../../../../utils/residentUtils';
import _ from 'lodash';
import { classNames } from '../../../../utils/tailwindUtils';
import { getMobileFormat } from '../../../../utils/KmdUtils';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import DummyImage from '../../../../assets/dummyPerson.jpeg';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export default function ResidentRelative({ relative, onClick }) {
	const [fRelative, setFRelative] = useState();

	useEffect(() => {
		const formattedRelative = {
			...relative,
			user: {
				...relative.user,
				mobile: relative?.user?.mobile ? getMobileFormat(relative?.user?.mobile) : null,
			}

		}

		setFRelative(formattedRelative)
	}, [relative])

	return (
		<div onClick={(() => onClick(fRelative))} className="grid grid-cols-1 bg-white border border-gray-200 rounded-lg shadow-sm cursor-pointer hover:border-gray-400">
			<li
				key={fRelative?.user?.email}
				className="relative flex flex-col col-span-1 text-center bg-white divide-y divide-gray-200 rounded-lg shadow"
			>
				<div className="flex flex-col flex-1 p-8 pb-4">
					<img className="flex-shrink-0 w-24 h-24 mx-auto border-2 rounded-full" src={fRelative?.user?.imageUrl ? fRelative?.user?.imageUrl : DummyImage} alt="" />
					<h3 className="mt-6 text-lg font-medium text-gray-900">{fRelative?.user?.fullName}</h3>
					<dl className="flex flex-col justify-between flex-grow mt-1">
						<dd className="text-base text-gray-500">{relativeClassifications?.find(e => e.id == fRelative?.user?.role?.id)?.name} pårørende</dd>
						<dd className="mt-3">
							<span className={classNames(
								fRelative?.user?.active ? 'bg-green-100 text-green-800' : fRelative?.user?.invitationSend ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-100 text-gray-800',
								"px-3 py-1 text-sm font-medium  rounded-full"
							)}>
								{fRelative?.user?.active ? 'Aktiv på Kintella' : fRelative?.user?.invitationSend ? 'Inviteret til Kintella' : 'Afventer invitation til Kintella'}
							</span>
						</dd>
						{!fRelative?.user?.invitationSend &&
							<ExclamationCircleIcon className="absolute text-yellow-400 w-7 h-7 top-3 right-3" />
						}
					</dl>
				</div>
				<div>
					<div className="flex -mt-px divide-x divide-gray-200">
						<div className="flex flex-1 w-0">
							<a
								href={`mailto:${fRelative?.user?.email}`}
								className="relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium text-gray-700 border border-transparent rounded-bl-lg hover:text-gray-500"
							>
								<EnvelopeIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
								<span className="ml-3">{fRelative?.user?.email ? fRelative?.user?.email : '-'}</span>
							</a>
						</div>
						<div className="flex flex-1 w-0 -ml-px">
							<a
								href={`tel:${fRelative?.user?.phone}`}
								className="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium text-gray-700 border border-transparent rounded-br-lg hover:text-gray-500"
							>
								<PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
								<span className="ml-3">{fRelative?.user?.mobile?.value ? fRelative?.user?.mobile?.value : '-'}</span>
							</a>
						</div>
					</div>
				</div>
			</li>
		</div>
	)
}