/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';
import { classNames } from '../../utils/tailwindUtils'
import KSelect from '../selectors/KSelect';

export default function KPagination({ page, pageSize, setPage, pageInfo, setPageSize, type }) {
	const [showNumber, setShowNumber] = useState();

	useEffect(() => {
		if (page === 0) {
			setShowNumber({
				from: pageInfo?.totalItems === 0 ? 0 : 1,
				to: pageSize > pageInfo?.totalItems ? pageInfo?.totalItems : pageSize
			})
		} else {
			setShowNumber({
				from: page * pageSize,
				to: (page * pageSize * 2) > pageInfo?.totalItems ? pageInfo?.totalItems : page * pageSize * 2
			})
		}

	}, [page, pageSize, pageInfo])

	const rowOptions = [5, 10, 25]


	const handleSizeChange = (e) => {
		setPage(0)
		setPageSize(e)
	}
	return (
		<div className="flex items-center justify-between py-3 bg-white border-gray-200 ">
			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
						Viser <span className="font-medium">{showNumber?.from}</span> til <span className="font-medium">{showNumber?.to}</span> af{' '}
						<span className="font-medium">{pageInfo?.totalItems}</span> {type}
					</p>
				</div>
				<div className="flex gap-x-5">
					<div className="flex items-center gap-x-3">
						<p className="text-sm text-gray-700">Rækker pr. side</p>
						<KSelect className="bg-white" handleSelect={(e) => handleSizeChange(e)} elements={rowOptions} height={'h-10'} selected={pageSize} />
					</div>
					<nav className="inline-flex -space-x-px rounded-md shadow-sm isolate" aria-label="Pagination">
						<div
							onClick={() => pageInfo?.hasPreviousPage && setPage(page - 1)}
							className={classNames(
								pageInfo?.hasPreviousPage ? 'cursor-pointer' : 'cursor-default bg-gray-50',
								"relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-20"
							)}
						>
							<span className="sr-only">Forrige</span>
							<ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
						</div>
						{Array.from(Array(pageInfo?.totalPages).keys())?.map((page, index) => {
							return (
								<div
									onClick={() => setPage(index)}
									className={classNames(
										"cursor-pointer",
										index === pageInfo?.currentPage
											? "relative z-10 inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-600 border border-indigo-500 bg-indigo-50 focus:z-20"
											: 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
									)}
								>
									{index + 1}
								</div>
							)
						})}
						<div
							onClick={() => pageInfo?.hasNextPage && setPage(page + 1)}
							className={classNames(
								pageInfo?.hasNextPage ? 'cursor-pointer' : 'cursor-default bg-gray-50',
								"relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-20"
							)}
						>
							<span className="sr-only">Næste</span>
							<ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
						</div>
					</nav>
				</div>
			</div>
		</div>
	)
}