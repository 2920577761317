import React from "react";
import { residentInfomationRoute } from "../../../routes/residentRoutes";
import { residentOnboardingRoute } from "../../../routes/residentRoutes";
import styled from 'styled-components';
import Avatar from "@mui/material/Avatar";
import moment from 'moment';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Colors from '../../../styles/Colors';
import { useHistory } from "react-router-dom";

import {
	getResidentFullName,
	getResidentGender,
} from "../../../utils/residentUtils";

export default function Resident({ data }) {

	const history = useHistory();
	console.log('Got resident row data:', data)
	// console.log('Got resident row name:', data.name)
	return (
		<StyledTableRow
			key={data.id}
			sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			// onClick={() =>
			// 	history.push((getResidentFullName(data) === "Børge Nielsen" ? `${residentOnboardingRoute}/${data.id}` : `${residentInfomationRoute}/${data.id}`), { from: history.location })
			// }
			onClick={() =>
				history.push(`${residentInfomationRoute}/${data.id}`, { from: history.location })
			}
			className="cursor-pointer"
		>
			<StyledCell component="th" scope="row">
				<Avatar alt="Remy Sharp" src={data?.imageUrl} />
			</StyledCell>
			<StyledCell align="left">{getResidentFullName(data)}</StyledCell>
			<StyledCell align="left">{data.room.department.name}</StyledCell>
			<StyledCell align="left">{data.room.name}</StyledCell>
			<StyledCell align="left">{moment(data.birthDate).format('DD-MM-YYYY')}</StyledCell>
			<StyledCell align="left">{getResidentFullName(data) === "Børge Nielsen" ? <StatusContainer> <YellowStatusLight />Ikke flyttet ind</StatusContainer> : <StatusContainer> <GreenStatusLight />Flyttet ind</StatusContainer>}</StyledCell>
		</StyledTableRow>
	)
}

const YellowStatusLight = () => <div className="w-3 h-3 bg-yellow-400 rounded-full" />
const GreenStatusLight = () => <div className="w-3 h-3 rounded-full bg-success" />
const StatusContainer = ({ children }) => <div className="flex items-center flex-1 gap-2" >{children}</div>


const StyledTableRow = styled(TableRow)(() => ({
	'&:nth-of-type(odd)': {
		backgroundColor: Colors.tableLight
	},
	'&:nth-of-type(even)': {
		backgroundColor: Colors.tableDark
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const StyledCell = styled(TableCell)(() => ({
	width: 50,
	padding: '8px 10px !important'
}));