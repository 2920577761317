import { BookOpenIcon, CalendarIcon, CheckIcon, DocumentIcon, HandThumbUpIcon, MinusCircleIcon, ShieldCheckIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import _ from 'lodash';
import { unixToDateString } from './dateUtils';
import { isPrimaryNoData } from './roles';
import NexusLogo from '../assets/nexus.png'

export const getResidentFullName = (resident) =>
	`${resident?.firstName}${resident?.middleName ? " " + resident?.middleName : ""
	} ${resident?.lastName}`;


//format dd-mm-yy
export const getResidentBirthDay = (resident) => {
	let { cpr, birthDate } = resident;
	let tmp = [cpr.slice(0, 2), "-", cpr.slice(2, 4), "-", "19", cpr.slice(4, 6)].join(
		""
	);
	return tmp;
};

export const getResidentGender = (resident) => {
	return resident?.gender?.toLowerCase() === "male" ? "Mand" : "Kvinde";
};

export const getResidentPhoneNumber = (phone) => {
	if (!phone) return "Ingen"
	return phone.slice(3).split("").reduce((a, n, i) => {
		return a + (i % 2 ? n + " " : n)
	}, "")
}

export const authenticationOptions = [{
	id: 'NEMID',
	name: 'NemID / MitID',
	description: 'Vælg dette, hvis den pårørende er  bosiddende i Danmark og har NemID/MitID'
}, {
	id: 'FA2',
	name: '2-faktor',
	description: 'Vælg dette, hvis den pårørende fx bor i udlandet og ikke har NemID/MitID'
}];

export const relativeClassifications = [{
	id: 3,
	name: 'Primær',
}, {
	id: 4,
	name: 'Sekundær'
}]

export const residentSortingOptions = {
	NAME_ASC: {
		id: [['firstName', 'ASC'], ['lastName', 'ASC']],
		name: 'Alfabetisk',
		title: 'Alfabetisk først',
		asc: true,

	},
	NAME_DESC: {
		id: [['firstName', 'DESC'], ['lastName', 'DESC']],
		name: 'Alfabetisk',
		title: 'Alfabetisk sidst',
		asc: false
	},
	CREATED_ASC: {
		id: [['createdAt', 'ASC']],
		name: 'Oprettet',
		title: 'Oprettet først',
		asc: true
	},
	CREATED_DESC: {
		id: [['createdAt', 'DESC']],
		title: 'Oprettet sidst',
		name: 'Oprettet',
		asc: false
	}
}

export const residentSortingOptionsList = [
	residentSortingOptions.NAME_ASC,
	residentSortingOptions.NAME_DESC,
	residentSortingOptions.CREATED_ASC,
	residentSortingOptions.CREATED_DESC
]


export const residentColumns = ['Billede', 'Navn', 'Afsnit', 'Bolig', 'Fødselsdag', 'Status'];

export const residentNavigation = [{
	id: 0,
	name: 'Oprettelse og Aktivering',
	icon: CalendarIcon
}, {
	id: 1,
	name: 'Profil',
	icon: UserCircleIcon,
}, {
	id: 2,
	name: 'Pårørende',
	icon: UserGroupIcon
}, {
	id: 3,
	name: 'Livshistorie',
	icon: BookOpenIcon
}, {
	id: 4,
	name: 'Samtykke',
	icon: ShieldCheckIcon
}, {
	id: 6,
	name: 'Dokumenter',
	icon: DocumentIcon,
}, {
	id: 7,
	name: 'Afvikling',
	icon: MinusCircleIcon,
	onClick: () => console.log('Hello')
}];

export const residentMovedInNavigation = [{
	id: 1,
	name: 'Profil',
	icon: UserCircleIcon,
}, {
	id: 2,
	name: 'Pårørende',
	icon: UserGroupIcon
}, {
	id: 3,
	name: 'Livshistorie',
	icon: BookOpenIcon
}, {
	id: 4,
	name: 'Samtykke',
	icon: ShieldCheckIcon
}, {
	id: 7,
	name: 'Afvikling',
	icon: MinusCircleIcon
}];

const warningProfiles = {
	PROFILE: {
		key: 'Profile',
		name: 'Profil',
		// warnings: [warningTypes.ROOM]
	},
	RELATIVES: {
		key: 'Relatives',
		name: 'Pårørende',
		// warnings: [warningTypes.ROOM]
	}
}

export const warningTypes = {
	ROOM: {
		key: 'Room',
		name: 'Bolig',
		message: 'Bolig er påkrævet inden borgeren kan flytte ind.',
		profile: warningProfiles.PROFILE,
		required: true
	},
	DEPARTMENT: {
		key: 'Department',
		name: 'Afsnit',
		message: 'Afsnit er påkrævet inden borgeren kan flytte ind.',
		profile: warningProfiles.PROFILE,
		required: true,
	},
	HOUSING: {
		key: 'Housing',
		name: 'Bolig',
		message: 'Det er påkrævet at tilknytte en bolig, til borgeren før borgen kan flytte ind.',
		profile: warningProfiles.PROFILE,
		required: true
	},
	RELATIVES: {
		key: 'Relatives',
		name: 'Pårørende',
		message: 'Det er som minimum påkrævet at tilknytte en primær pårørende.',
		profile: warningProfiles.RELATIVES,
		required: true
	},
	PRIMARY_RELATIVE: {
		key: 'Primary Relative',
		name: 'Primær Pårørende',
		message: 'Det er påkrævet at tilknytte en primær pårørende.',
		profile: warningProfiles.RELATIVES,
		required: true
	},
	PRIMARY_RELATIVE_INVITATION: {
		key: 'Primary relative invitation',
		name: 'Primær pårørende invitation',
		message: 'Det er påkrævet at invitere primær pårørende til at bruge kintella før borgeren kan flytte ind.',
		profile: warningProfiles.RELATIVES,
		required: true
	},
	NEXUS: {
		key: 'Nexus',
		name: 'Nexus',
		message: 'Denne borger er ikke integreret med Nexus KMD. Kontakt support for at få borgeren tilkoblet korrekt.',
		profile: warningProfiles.PROFILE
	}
}



export const getResidentWarnings = (resident) => {
	const warnings = [];

	if (_.isEmpty(resident?.room)) {
		warnings.push(warningTypes.ROOM)
		warnings.push(warningTypes.DEPARTMENT)
		warnings.push(warningTypes.HOUSING)
	}

	if (_.isEmpty(resident?.relatives)) {
		warnings.push(warningTypes.RELATIVES)
	}

	if (_.isEmpty(resident?.externalId)) {
		warnings.push(warningTypes.NEXUS)
	}

	const primaryUser = resident?.relatives?.find(e => isPrimaryNoData(e?.user));
	if (_.isEmpty(primaryUser)) {
		warnings.push(warningTypes.PRIMARY_RELATIVE)
	}

	if (!primaryUser?.user?.sendInvitation) {
		warnings.push(warningTypes.PRIMARY_RELATIVE_INVITATION)
	}

	return warnings;
}

const checkTypes = {
	applied: { icon: () => (<div className="text-white">2</div>), bgColorClass: 'bg-gray-400' },
	advanced: { icon: HandThumbUpIcon, bgColorClass: 'bg-blue-500' },
	completed: { icon: CheckIcon, bgColorClass: 'bg-darkPurple' },
	step: () => ({ text: 'Test', bgColorClass: 'bg-green-500' }),
}

const appliedComponent = (id) => {
	return {
		icon: () => (
			<div className="text-white">{id}</div>
		),
		bgColorClass: 'bg-gray-400'
	}
}

export const getResidentWorkerChecklist = (resident) => {
	return [
		{
			id: 1,
			type: checkTypes.completed,
			content: 'Borger oprettet i kintella af',
			target: 'Birthe Hansen',
			date: unixToDateString(resident?.createdAt),
			tooltip: 'Påkrævet'
		},
		{
			id: 2,
			type: !_.isEmpty(resident?.relatives?.find(e => isPrimaryNoData(e?.user)))
				? checkTypes.completed
				: appliedComponent(2)
			,
			content: 'Primær pårørende oprettet i',
			target: 'Kintella',
			date: !_.isEmpty(resident?.relatives?.find(e => isPrimaryNoData(e?.user))) ? unixToDateString(resident?.relatives?.find(e => isPrimaryNoData(e?.user))?.user?.createdAt) : '',
			tooltip: 'Påkrævet'
		},
		{
			id: 3,
			type: !_.isEmpty(resident?.relatives?.find(e => isPrimaryNoData(e?.user))?.user?.invitationSend)
				? checkTypes.completed
				: appliedComponent(3)
			,
			content: 'Primær pårørende inviteret til at bruge',
			target: 'Kintella',
			date: resident?.relatives?.find(e => isPrimaryNoData(e?.user))?.user?.invitationSend ? resident?.relatives?.find(e => isPrimaryNoData(e?.user))?.user?.invitationSend : '',
			tooltip: 'Påkrævet'
		},
		{
			id: 4,
			type: _.isEmpty(resident?.room) ? appliedComponent(4) : checkTypes.completed,
			content: 'Tilknyt bolig til ',
			target: 'borgeren',
			tooltip: 'Påkrævet'
			// date: '29/0',
		},
		{
			id: 5,
			type: appliedComponent(5),
			content: 'Opdater borgerens ',
			target: 'livshistorie',
			// date: '29/0',
		},
		{
			id: 6,
			type: appliedComponent(6),
			content: 'Opdater borgerens ',
			target: 'samtykke',
			// date: '29/0',
		},
	]
}

export const getResidentRelativesWarning = (warnings) => {
	const noRelatives = warnings?.find(e => e?.key === warningTypes.RELATIVES.key)
	if (noRelatives) {
		return noRelatives.message
	}

	const noPrimary = warnings?.find(e => e?.key === warningTypes.PRIMARY_RELATIVE.key)
	if (noPrimary) {
		return noPrimary.message
	}

	const noPrimaryInvitation = warnings?.find(e => e?.key === warningTypes.PRIMARY_RELATIVE_INVITATION.key)
	if (noPrimaryInvitation) {
		return noPrimaryInvitation.message
	}
}


export const relativeInvitationTypes = [
	{ id: 1, title: 'Send invitation med det samme', description: 'Du opretter den pårørende nu - og systemet sender invitationen afsted, hvis/når borgeren er gjort Aktiv. Du kan altid se borgerens status i Borgeroversigten eller ved at klikke dig ind på Borgeren og vælge Oprettelse og Aktivering.' },
	{ id: 2, title: 'Send invitation senere', description: 'Du opretter den pårørende nu - og du kan selv senere vælge at afsende invitationen.	Du sender invitationen senere ved at gå ind på borgeren og vælge Pårørende.' },
]

export const checkProfileChanges = ({ resident, residentParent, currentRoom, currentDeparment, image }) => {

	if (!_.isEqual(resident, residentParent)) {
		return true;
	}

	if (resident?.room?.id != currentRoom?.id) {
		return true;
	}

	if (resident?.room?.departmentId != currentDeparment?.id) {
		return true;
	}

	if (image) {
		return true
	}
}


export const residentConsentOptions = {
	USE_CONSENT: {
		title: "Opbevaring",
		description: "Billeder af mig må på Kintella vises for personale, andre borgere og deres pårørende på mit plejecenter. Billeder vil i denne forbindelse blive gemt i Kintella.",
		key: "useStatus",
	},
	WEBSITE_CONSENT: {
		title: "Hjemmeside",
		description: "Billeder af mig må vises på plejecenterets hjemmeside",
		key: "websiteStatus",
	},
	NEWS_CONSENT: {
		title: "Nyheder",
		description: "Billeder af mig må bruges i plejecenterets nyhedsavis",
		key: "newsStatus",
	},
	INFO_CONSENT: {
		title: "Info-tavler",
		description: "Billeder af mig må bruges i plejecenterets info-tavler",
		key: "infoStatus",
	},
	RELATIVE_INVITE: {
		title: "Invitationer",
		description: 'Mine pårørende og personale må invitere andre pårørende til at blive en del af min pårørende-gruppe"',
		key: "inviteStatus",
	}
}

export const residentConsentSections = {
	IMAGES: {
		title: 'Billeder',
		description: "Både personale, og pårørende kan uploade billeder til Kintella. Hvis du kan identificere en person ud fra et billede (fx et portrætbillede), vil billedet være en personoplysning og dermed omfattet af databeskyttelsesforordningen. Deling af sådanne billeder kræver, at der er givet samtykke til deling. Du skal derfor tage stilling til, om billeder, hvor du fremgår, må vises for andre brugere af Kintella, dvs. plejerpersonale, borgere og pårørende.",
		options: [residentConsentOptions.USE_CONSENT, residentConsentOptions.WEBSITE_CONSENT, residentConsentOptions.NEWS_CONSENT, residentConsentOptions.INFO_CONSENT]
	},
	RELATIVES: {
		title: "Pårørende",
		description: "I tillæg til ovenstående samtykker om billeder skal du forholde dig til, om din Primære Pårørende må invitere andre (sekundære) pårørende med i din Pårørendegruppe. De vil få adgang til nyheder, opgaver (ikke-sunhedsfaglige), og kalender-aktiviteter, der skal koordineres mellem dig, dine pårørende og personalet.",
		options: [residentConsentOptions.RELATIVE_INVITE]
	},
}

export const residentConsentSectionList = [residentConsentSections.IMAGES, residentConsentSections.RELATIVES];

export const defaultResidentConsent = {
	useStatus: false,
	websiteStatus: false,
	newsStatus: false,
	inviteStatus: false,
	infoStatus: false,
}

export const residentOverviewOptions = {
	ALL: {
		id: 1,
		name: 'Alle',
		search: 'Søg efter borger i Kintella'
	},
	ACTIVE: {
		id: 2,
		name: 'Aktive',
		search: 'Søg efter aktive borgere i Kintella'
	},
	ONBOARDING: {
		id: 3,
		name: 'Afventer handling',
		search: 'Søg efter borgere som er under oprettelse i Kintella'
	}
}

export const residentOverviewOptionsList = [
	residentOverviewOptions.ALL,
	residentOverviewOptions.ACTIVE,
	residentOverviewOptions.ONBOARDING,
]

export const residentStatusOptions = {
	ALL: {
		id: 'ALL',
		name: 'Alle'
	},
	ACTIVE: {
		id: 'ACTIVE',
		name: 'Aktive'
	},
	ONBOARDING: {
		id: 'ONBOARDING',
		name: 'Afventer handling'
	},
}


export const residentStatusOptionsList = [
	residentStatusOptions.ALL,
	residentStatusOptions.ACTIVE,
	residentStatusOptions.ONBOARDING,
]


export const residentStatusOptionsListIds = [
	residentStatusOptions.ACTIVE.id,
	residentStatusOptions.ONBOARDING.id,
]

export const sortResidents = (list, sorting) => {
	if (sorting.name === sortingOptions[0].name) {
		return list.sort((a, b) => {
			if (a?.firstName > b?.firstName) {
				return -1
			} else if (b?.firstName > a?.firstName) {
				return -1
			} else {
				return 0;
			}
		})
	} else {
		return list
	}
}

export const getResidentStatus = (resident) => {
	const checklist = getResidentWorkerChecklist(resident);
	if (resident?.status === residentStatusOptions.ACTIVE?.id) {
		return residentStatusOptions.ACTIVE.id
	}

	if (checklist?.some(e => !e.complete)) {
		return residentStatusOptions.ONBOARDING.id
	}

	if (checklist?.every(e => e.complete) && resident?.status !== residentStatusOptions.ACTIVE?.id) {
		return residentStatusOptions.READY_TO_ACTIVATE.id
	}
}

export const residentTimelineOptions = (resident) => ({
	CONSENT: {
		id: '01',
		name: 'Ja tak til Kintella',
		description: 'Indhent og marker at samtykke er givet af borger.',
		tasks: [{
			name: 'Samtykke indhentet til at få en profil på Kintella.',
			required: true,
			complete: true
		}],
		Content: ({ setActiveMenu }) => (
			<div>
				<p className="max-w-2xl mt-1 text-sm text-gray-500">
					For at læse mere om samtykke i Kintella, kan du klikke
					<span
						onClick={() => setActiveMenu(residentNavigation[4])}
						className="border-b border-gray-600 cursor-pointer ">
						{` her `}
					</span>
					eller gå ind under Samtykke i menuen til venstre.
				</p>
			</div>
		)
	},
	INFORMATION: {
		id: '02',
		name: 'Hent stamdata',
		description: 'Indhent og marker at samtykke er givet af borger.',
		tasks: [{
			name: 'Stamdata indhentet fra Nexus KMD.',
			required: true,
			complete: true
		}],
		Content: ({ setActiveMenu }) => (
			<div className="grid grid-cols-1 gap-y-3">
				<p className="text-sm text-gray-500">Borgeren er nu integeret med Nexus KMD. Det vil sige at der bliver vekslet data mellem Kintella og Nexus KMD. Der vil i Kintella være markeret med logoet vist nedenfor hvis data bliver vekslet med Nexus KMD</p>
				<img className="mt-2 ml-1 h-7" src={NexusLogo} />
				<div>

					<p className="mt-2 text-sm text-gray-500 ">For at redigere i borgerens stamdata, kan du klikke <span onClick={() => setActiveMenu(residentNavigation[1])} className="border-b border-gray-600 cursor-pointer ">her</span> eller gå ind under Profil i menuen til venstre.</p>
				</div>
			</div>
		)
	},
	ROOM: {
		id: '03',
		name: 'Tilknyt bolig',
		description: 'Indhent og marker at samtykke er givet af borger.',
		tasks: [{
			name: 'Borger tilknyttet en bolig',
			required: true,
			complete: resident?.room ? true : false
		}],
		Content: ({ setActiveMenu }) => (
			<div >
				<p className="mt-2 text-sm text-gray-500 ">Det er nødvendigt at tilknytte borgeren en bolig for at aktivere borgere.</p>
				<p className="mt-2 text-sm text-gray-500 "> For at gøre dette nu, kan du klikke  <span onClick={() => setActiveMenu(residentNavigation[1])} className="border-b border-gray-600 cursor-pointer ">her</span> eller gå ind under Profil i menuen til venstre.</p>
			</div>
		)
	},
	RELATIVES: {
		id: '04',
		name: 'Inviter pårørende',
		description: 'Du mangler at sende en invitation til en pårørende, før du kan aktivere borgeren på Kintella.',
		tasks: [{
			name: 'Primær pårørende oprettet i Kintella',
			required: true,
			complete: resident?.relatives?.find(e => e.user?.role?.id == 3) ? true : false
		}, {
			name: 'Primær pårørende inviteret til at bruge Kintella',
			required: true,
			complete: resident?.relatives?.find(e => e.user?.role?.id == 3 && e?.user.invitationSend) ? true : false
		}],
		Content: ({ setActiveMenu }) => (
			<div >
				<p className="max-w-2xl mt-1 text-sm text-gray-500">Du mangler at sende en invitation til en pårørende, før du kan aktivere borgeren på Kintella.</p>
				<p className="max-w-2xl text-sm text-gray-500">
					For at gøre dette nu, kan du klikke <span onClick={() => setActiveMenu(residentNavigation[2])} className="border-b border-gray-600 cursor-pointer ">her</span> eller gå ind under Pårørende i menuen til venstre
				</p>
			</div>
		)
	}
})

export const residentTimelineOptionsList = (resident) => ([
	residentTimelineOptions(resident).CONSENT,
	residentTimelineOptions(resident).INFORMATION,
	residentTimelineOptions(resident).ROOM,
	residentTimelineOptions(resident).RELATIVES,

])

export const getResidentTimeLine2 = (resident, relatives, consentGiven, dataImported, room) => {
	console.log('HERE RELATIVES', relatives, 'HERE RELATIVES')
	return [
		{
			id: '01',
			name: 'Ja tak til Kintella',
			description: 'Indhent og marker at samtykke er givet af borger.',
			tasks: [{
				name: 'Samtykke indhentet til at få en profil på Kintella.',
				required: true,
				complete: consentGiven
			}],
		},
		{
			id: '02',
			name: 'Hent stamdata',
			description: 'Indhent og marker at samtykke er givet af borger.',
			tasks: [{
				name: 'Stamdata indhentet fra Nexus KMD.',
				required: true,
				complete: dataImported
			}],
		},
		{
			id: '03',
			name: 'Tilknyt bolig',
			description: 'Indhent og marker at samtykke er givet af borger.',
			tasks: [{
				name: 'Borger tilknyttet en bolig',
				required: false,
				complete: room ? true : false
			}],
		},
		{
			id: '04',
			name: 'Inviter pårørende',
			description: 'Du mangler at sende en invitation til en pårørende, før du kan aktivere borgeren på Kintella.',
			tasks: [{
				name: 'Primær pårørende oprettet i Kintella',
				required: false,
				complete: relatives?.find(e => e?.role?.id == 3) ? true : false
			}, {
				name: 'Primær pårørende inviteret til at bruge Kintella',
				required: false,
				complete: relatives?.find(e => e?.role?.id == 3 && e?.sendInvitation) ? true : false
			}],
		}
	]
}

export const defaultResidentTimelineObjects = {
	CONSENT: {
		id: '01',
		name: 'Ja tak til Kintella',
		description: 'Indhent og marker at samtykke er givet af borger.',
		tasks: [{
			name: 'Samtykke indhentet til at få en profil på Kintella.',
			required: true,
			complete: false
		}],
	},
	INFORMATION: {
		id: '02',
		name: 'Hent stamdata',
		description: 'Indhent og marker at samtykke er givet af borger.',
		tasks: [{
			name: 'Stamdata indhentet fra Nexus KMD.',
			required: true,
			complete: false
		}],
	},
	ROOM: {
		id: '03',
		name: 'Tilknyt bolig',
		description: 'Indhent og marker at samtykke er givet af borger.',
		tasks: [{
			name: 'Borger tilknyttet en bolig',
			required: true,
			complete: false
		}],
	},
	RELATIVES: {
		id: '04',
		name: 'Inviter pårørende',
		description: 'Du mangler at sende en invitation til en pårørende, før du kan aktivere borgeren på Kintella.',
		tasks: [{
			name: 'Primær pårørende oprettet i Kintella',
			required: true,
			complete: false
		}, {
			name: 'Primær pårørende inviteret til at bruge Kintella',
			required: true,
			complete: false
		}],
	},
	FINISH: {
		id: '05',
		name: 'Sådan!',
		description: 'Du har klaret alle de nødvendige opgaver for at aktivere borgeren. Klik på aktiver borger oppe i højre hjørne for at aktivere borgeren.',
	}
}

export const getResidentTimeLine3 = (resident) => {
	console.log(resident)
	const defaultArr = [
		{
			id: '01',
			name: 'Ja tak til Kintella',
			description: 'Indhent og marker at samtykke er givet af borger.',
			tasks: [{
				name: 'Samtykke indhentet til at få en profil på Kintella.',
				required: true,
				complete: true
			}],
		},
		{
			id: '02',
			name: 'Hent stamdata',
			description: 'Indhent og marker at samtykke er givet af borger.',
			tasks: [{
				name: 'Stamdata indhentet fra Nexus KMD.',
				required: true,
				complete: true
			}],
		},
		{
			id: '03',
			name: 'Tilknyt bolig',
			description: 'Det er nødvendigt at tilknytte borgeren en bolig for at aktivere borgere.',
			tasks: [{
				name: 'Borger tilknyttet en bolig',
				required: true,
				complete: resident?.room ? true : false
			}],
		},
		{
			id: '04',
			name: 'Inviter pårørende',
			description: 'Du mangler at sende en invitation til en pårørende, før du kan aktivere borgeren på Kintella.',
			tasks: [{
				name: 'Primær pårørende oprettet i Kintella',
				required: true,
				complete: resident?.relatives?.find(e => e.user?.role?.id == 3) ? true : false
			}, {
				name: 'Primær pårørende inviteret til at bruge Kintella',
				required: true,
				complete: resident?.relatives?.find(e => e.user?.role?.id == 3 && e?.user.sendInvitation) ? true : false
			}],
		}
	]

	const isFinish = defaultArr?.every(e => e?.tasks?.every(t => t?.complete));

	if (!isFinish) {
		return {
			all: defaultArr,
			active: defaultArr?.find(e => e?.tasks?.some(e => !e?.complete)),
			isFinish
		}
	} else {
		defaultArr.push({
			id: '05',
			name: 'Sådan!',
			description: 'Du har klaret alle de nødvendige opgaver for at aktivere borgeren.',
			tasks: [{
				name: 'Borger aktiveret i Kintella',
				complete: resident?.status === residentStatusOptions.ACTIVE.id,
				required: true
			}]
			// tasks: [{
			// 	name: 'Primær pårørende oprettet i Kintella',
			// 	required: true,
			// 	complete: resident?.relatives?.find(e => e.user?.role?.id == 3) ? true : false
			// }, {
			// 	name: 'Primær pårørende inviteret til at bruge Kintella',
			// 	required: true,
			// 	complete: resident?.relatives?.find(e => e.user?.role?.id == 3 && e?.user.invitationSend) ? true : false
			// }],
		})

		return {
			all: defaultArr,
			active: defaultArr[4],
			isFinish
		}
	}
}


export const getResidentTimeLine = (resident) => {
	let options = residentTimelineOptions(resident);

	if (resident?.room) {
		options.ROOM = {
			...options.ROOM,
			complete: true
		}
	}

	const primaryRelative = resident?.relatives?.find(e => e?.user?.role?.id == 3);

	if (primaryRelative) {
		options.RELATIVES = {
			...options.RELATIVES,
			complete: true
		}
	}

	if (primaryRelative?.invitationSend) {
		options.RELATIVES = {
			...options.RELATIVES,
			complete: true
		}
	}

	const newArray = [
		options.CONSENT,
		options.INFORMATION,
		options.ROOM,
		options.RELATIVES,
	]

	return newArray


}

export const residentTimeline = [
	{
		id: '01',
		name: 'Ja tak til Kintella',
		description: 'Indhent og marker at samtykke er givet af borger.',
		complete: false,
		active: true
	},
	{
		id: '02',
		name: 'Hent stamdata',
		description: 'Indtast CPR-nummer, og tryk på hent data fra Nexus.',
		active: true
	},
	{ id: '03', name: 'Tilknyt bolig', description: 'Tilknyt borgeren et afsnit og en bolig', status: 'current' },
	{ id: '04', name: 'Inviter pårørende', description: 'Tilknyt som minimum en primær pårørende', status: 'missing' },
	// { id: '05', name: 'Inviter primær pårørende', description: 'Send invitations-mail til primær pårørende', status: 'missing' },
]
