import { useQuery } from '@apollo/client';
import { Avatar, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, GET_ALL_INTERESTS_BY_ORGANIZATION_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries';
import Colors from '../../styles/Colors';
import { getResidentFullName } from '../../utils/residentUtils';
import KSnackbar from '../KSnackbar';
import KTitle from '../KTitle';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../utils/tailwindUtils';

export default function KResidentSelect({ label, title, setOpen, onSelect, selected, selectedText, selectedValidation, validation, error, residents, hideOptions, }) {
	const user = useSelector(state => state.user);

	// const [open, setOpen] = useState();
	const [selectedResidents, setSelectedResidents] = useState(selected);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [currentView, setCurrentView] = useState('residents');
	const [departments, setDepartments] = useState();
	const [departmentObjects, setDepartmentObjects] = useState();
	const [currentResidents, setCurrentResidents] = useState([])
	const [interestGroups, setInterestGroups] = useState([]);

	const { data: departmentsData } = useQuery(
		GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, { variables: { organizationId: user.data.organization.id } }
	);

	const { data: interestData } = useQuery(
		GET_ALL_INTERESTS_BY_ORGANIZATION_ID, { variables: { id: user.data.organization.id } }
	);


	useEffect(() => {
		setDepartments(departmentsData?.getAllDepartmentsByOrganizationId);
	}, [departmentsData]);

	useEffect(() => {
		setInterestGroups(interestData?.getAllInterestsByOrganizationId);
	}, [interestData]);

	useEffect(() => {
		if (departments && residents) {
			const formatted = departments.map(department => {
				return {
					...department,
					residents: residents?.filter(r => r?.room?.department?.id === department?.id).sort((a, b) => a.firstName.localeCompare(b.firstName))
				}
			})

			formatted.push({
				name: 'Hele centret',
				residents: residents
			})

			setDepartmentObjects(formatted)
		}

	}, [residents, departments])

	useEffect(() => {
		if (residents) {
			const list = [...residents];
			setCurrentResidents(list?.sort((a, b) => a?.firstName.localeCompare(b?.firstName)))
		}
	}, [residents])

	useEffect(() => {
		if (open) {
			setSelectedResidents(selected)
		}
	}, [open])

	const handleSelectResident = (resident, checked) => {
		if (checked) {
			setSelectedResidents(selectedResidents?.filter(p => p.id !== resident.id))
		} else {
			if (validation) {
				if (validation(resident)) {
					setSelectedResidents([...selectedResidents, resident])
				} else {
					setErrorMessage(`${getResidentFullName(resident)} har ikke givet samtykke til billede-deling. Billedet kan derfor ikke deles. Vælg et andet billede med andre borgere på, inden du går videre.`)
					setShowError(true);
				}
			} else {
				setSelectedResidents([...selectedResidents, resident])
			}
		}
	}

	const handleDone = () => {
		onSelect(selectedResidents)
		setOpen(false)
	}

	const handleSelectMutipleResidents = (residents, checked) => {
		if (checked) {
			setSelectedResidents(selectedResidents?.filter(p => !residents?.map(r => r?.id).includes(p?.id)))
		} else {
			const merged = [...selectedResidents, ...residents]
			let unique = [
				...new Map(merged?.map((item) => [item["id"], item])).values(),
			];
			setSelectedResidents(unique)
		}
	}

	const handleSelectMultiple = (residents, checked) => {
		if (residents?.length === 0) {
			setErrorMessage(`Der blev ikke fundet nogle borgere på dette afsnit`)
			setShowError(true);
			return;
		}

		handleSelectMutipleResidents(residents, checked)
	}

	return (
		<div>
			<KSnackbar
				open={showError}
				setOpen={setShowError}
				title={errorMessage}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
			/>
			<div className="flex items-center justify-between px-4 py-4 border-b">
				<KTitle title={title} />
				<XMarkIcon className="w-6 h-6 cursor-pointer stroke-2" onClick={() => setOpen(false)} />
			</div>
			<div className="grid grid-cols-2 m-4 h-14 gap-x-2">
				<div onClick={() => setCurrentView('residents')} className={classNames(
					currentView === 'residents' ? 'hover:border-darkPurple border-darkPurple hover:text-darkPurple text-darkPurple' : 'border-gray-200 hover:border-gray-700',
					"flex items-center justify-center border-2 cursor-pointer h-14 "
				)}>
					<p>VÆLG BORGER</p>
				</div>
				{!hideOptions &&
					<div onClick={() => setCurrentView('departments')} className={classNames(
						currentView === 'departments' ? 'hover:border-darkPurple border-darkPurple hover:text-darkPurple text-darkPurple' : 'border-gray-200 hover:border-gray-700',
						"flex items-center justify-center border-2 cursor-pointer h-14 "
					)}>
						<p>VÆLG AFSNIT</p>
					</div>
				}
			</div>
			<ContentContainer>
				{currentView === 'residents' &&
					<Content>
						{currentResidents?.map((resident, index) => {
							const checked = selectedResidents?.map(r => r.id)?.includes(resident?.id);
							return (
								<ResidentContainer
									key={index}
									onClick={() => handleSelectResident(resident, checked)}
									selected={checked}
									index={index}
								>
									<Avatar src={resident?.imageUrl} />
									<div>
										<p>{resident?.fullName}</p>
										<p className="text-sm text-gray-600">{resident?.room?.department?.name} - {resident?.room?.name}</p>
										{selectedValidation && selectedValidation(resident.id) &&
											<ContentUnderText>{selectedText}</ContentUnderText>
										}

									</div>
								</ResidentContainer>
							)
						})}
					</Content>
				}
				{currentView === 'interests' &&
					<Content>
						{interestGroups?.map((interest, index) => {
							const checked = interest?.residents?.length === 0 ? false : interest?.residents?.every(resident => {
								return selectedResidents?.map(r => r.id).includes(resident?.id);
							});

							return (
								<ResidentContainer
									key={index}
									onClick={() => handleSelectMultiple(interest?.residents, checked)}
									selected={checked}
									index={index}
								>
									<div>
										<p>{interest?.title}</p>
									</div>
								</ResidentContainer>
							)
						})}
					</Content>
				}
				{currentView === 'departments' &&
					<Content>
						{departmentObjects?.map((department, index) => {
							const checked = department?.residents?.length === 0 ? false : department?.residents?.every(resident => {
								return selectedResidents?.map(r => r.id).includes(resident?.id);
							});

							const isDisabled = department?.residents?.length > 0 ? false : true;

							return (
								<div
									className={classNames(
										isDisabled ? 'text-gray-400' : 'cursor-pointer',
										checked ? 'bg-darkPurple text-white' : '',
										"flex items-center h-12 px-2 mx-4 border"
									)}
									key={index}
									onClick={() =>
										isDisabled
											? () => { }
											: handleSelectMultiple(department?.residents, checked)}
									selected={checked}
									index={index}
								>
									<div>
										<p>{department?.name}</p>
										{isDisabled
											? <p className="text-xs">Ingen borgere fundet på dette afsnit</p>
											: null
										}
									</div>
								</div>
							)
						})}
					</Content>
				}
			</ContentContainer>
			<ButtomContainer>
				<p style={{ cursor: 'pointer' }} onClick={() => setOpen(false)}>Annuller</p>
				<DoneButton onClick={() => handleDone()}>Færdig</DoneButton>
			</ButtomContainer>
			{/* <KModal containerStyles={ModalContainerStyles} open={open} handleChange={() => setOpen(true)}>

			</KModal> */}

		</div>
	)
}

const ContentContainer = styled.div({
	height: 379,
	overflow: 'scroll'
})

const Content = styled.div({
	// marginTop: 30
})

const ContentUnderText = styled.p({
	fontSize: 10,
	fontFamily: 'MetricRegular',
	marginTop: -3
})

const ButtomContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	height: 50,
	borderTop: '1px solid #e2e2e2',
	alignItems: 'center',
	paddingLeft: 20,
	paddingRight: 20
})

const DoneButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: Colors.darkPurple,
		color: '#fff',
		fontSize: 12,
		paddingRight: 30,
		paddingLeft: 30,

		'&:hover': {
			backgroundColor: Colors.darkPurple
		}
	}
}))

const ResidentContainer = styled.div(({ index, selected }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: 10,
	cursor: 'pointer',
	borderTop: index & 2 !== 0 || index === 0 ? '1px solid #e2e2e2' : 'none',
	borderBottom: index & 2 !== 0 ? '1px solid #e2e2e2' : 'none',
	paddingBottom: 10,
	paddingTop: 10,
	paddingRight: 30,
	paddingLeft: 30,
	backgroundColor: selected ? Colors.darkPurple : '#fff',
	color: selected ? '#fff' : '#000',
	height: 50,
	userSelect: 'none',
}))
