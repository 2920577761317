import React, { useState, useDispatch, useEffect, useCallback } from "react";
import GlobalFilterQueryInfinity from "../../graphql/GlobalFilterQueryInfinity";

import {
	GET_ALL_NEWS_BY_ORGANIZATION_ID,
	GET_ALL_NEWS_BY_DEPARTMENT_ID,
	GET_ALL_NEWS_BY_RESIDNT_ID,
} from "../../graphql/graphqlQueries";
import KButton from "../../components/KButton";
import Colors from "../../styles/Colors";
import KPageWrapper from "../../components/KPageWrapper";
import { createNewsRoute } from "../../routes/mainRoutes";
import Story from "./Story";
import KSubmitSearchInput from "../../components/inputs/KSubmitSearchInput";

import { newsKey } from "../../redux/reduxUtils";

const KDelayedSearchInputMemo = React.memo(KSubmitSearchInput);
const GlobalFilterQueryInfinityMemo = React.memo(GlobalFilterQueryInfinity);

export default function News({ history }) {
	const [search, setSearch] = useState();
	const [searchTimeStamp, setSearchTimeStamp] = useState(0);


	const MarginWrapper = ({ children, className, key }) => (
		<div key={key} className={className} style={{ marginRight: '20%', marginLeft: 'calc(20% - 96px)' }}>
			{children}
		</div>
	)

	const NewsOverview = ({ data, key }) => {
		return (
			<MarginWrapper key={key} className="p-6 mb-6 bg-white rounded shadow">
				<Story history={history} defaultNews={data} />
			</MarginWrapper>
		);
	};

	const NewsSkeleton = ({ key }) => {
		return (
			<MarginWrapper key={key} className="flex flex-col gap-3 p-6 mb-6 bg-white rounded shadow ">
				<div className={skeletonLineStyle("w-1/2 py-4")}  ></div>
				<div className={skeletonLineStyle("py-2")}></div>
				<div className={skeletonLineStyle("py-2")}></div>
				<div className={skeletonLineStyle("h-1 mt-5 mb-8")}></div>
			</MarginWrapper>
		);
	};


	const handleSearch = (e) => {
		setSearch(e);
		setSearchTimeStamp(Date.now());
	}

	return (
		<KPageWrapper
			disableScroll={true}
			paths={[{
				title: 'Nyheder',
			}]}
			buttons={[
				<KButton
					label="OPRET NYHED"
					onClick={() => history.push(createNewsRoute)}
				/>
			]}
			contentContainerStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, backgroundColor: Colors.mainGrey }}
		>
			<div style={{ marginRight: '20%', marginLeft: 'calc(20% - 96px)', marginBottom: 10 }}>
				<KDelayedSearchInputMemo
					placeholder={"Søg på nyheder"}
					height="h-8"
					// clearSearch={() => setSearch("")}
					onSubmit={(v) => handleSearch(v)}
					autoFocus
				/>
			</div>
			{/* </MarginWrapper> */}
			<GlobalFilterQueryInfinityMemo
				RenderItem={NewsOverview}
				residentQuery={GET_ALL_NEWS_BY_RESIDNT_ID}
				departmentQuery={GET_ALL_NEWS_BY_DEPARTMENT_ID}
				organizationQuery={GET_ALL_NEWS_BY_ORGANIZATION_ID}
				pageSize={2}
				showPagination={true}
				searchValue={search}
				searchTimeStamp={searchTimeStamp}
				reduxKey={newsKey}
				Skeleton={NewsSkeleton}
			/>
		</KPageWrapper>
	);
}

export const MarginWrapper = ({ children, className, key }) => (
	<div key={key} className={className} style={{ marginRight: '20%', marginLeft: 'calc(20% - 96px)' }}>
		{children}
	</div>
)

const skeletonLineStyle = (classes = "") => "bg-gray-200 rounded my-1 " + classes;