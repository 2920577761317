import { useEffect, useState, useRef } from 'react';

export const useIsVisible = ref => {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = new IntersectionObserver(([entry]) =>
		setIntersecting(entry.isIntersecting),
	);

	useEffect(() => {
		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [ref]);

	return isIntersecting;
};


export function useStateMonitor(state) {
	const ref = useRef();
	ref.current = state;
	return () => ref.current;
}