import _ from 'lodash'
import { residentStatusOptions } from '../residentUtils';
import { isValidEmail } from '../validators';

export const validateProfile = ({ resident, room }) => {
	let errors = {};

	if (_.isEmpty(resident?.firstName)) {
		errors.firstName = 'Feltet kan ikke være tomt.';
	} else {
		errors.firstName = null;
	}

	if (_.isEmpty(resident?.lastName)) {
		errors.lastName = 'Feltet kan ikke være tomt.';
	} else {
		errors.lastName = null;
	}

	if (resident?.email && !isValidEmail(resident?.email)) {
		errors.email = "Feltet skal indeholde korrekt formateret email."
	} else {
		errors.email = null;
	}

	if (!room && resident?.status === residentStatusOptions.ACTIVE.id) {
		errors.room = 'Feltet kan ikke være tomt.'
	} else {
		errors.room = null
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateNewResident = ({ resident, room, department }) => {
	let errors = {};
	if (!resident?.firstName) {
		errors.firstName = 'Feltet kan ikke være tomt.';
	} else {
		errors.firstName = null;
	}

	if (!resident?.lastName) {
		errors.lastName = 'Feltet kan ikke være tomt.';
	} else {
		errors.lastName = null;
	}

	if (!resident?.cpr) {
		console.log('yes herE?')
		errors.cpr = 'Feltet kan ikke være tomt.';
	} else {
		console.log('no here??')
		errors.cpr = null;
	}

	if (resident?.mobile && resident?.mobile?.value?.length !== resident?.mobile?.country?.format?.length && !resident?.mobile?.isEmpty) {
		errors.mobile = `Mobilnummer skal være korrekt formateret. Kan efterlades tomt.`
	}

	if (resident?.email && !isValidEmail(resident?.email)) {
		errors.email = 'Email skal være korrekt formatteret. Kan efterlades tomt.'
	}

	if (!room && department) {
		errors.room = 'Feltet kan ikke været tomt.'
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

