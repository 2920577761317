import React, { useState, useEffect, useRef } from 'react'
import KSelect from '../../../components/selectors/KSelect'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/client';
import KSnackbar from '../../../components/KSnackbar'
import { UPDATE_RESIDENT, UPLOAD_IMAGE } from '../../../graphql/graphqlMutations';
import { GET_RESIDENT_BY_ID, GET_ALL_ROOMS_BY_ORGANZATION_ID, GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID } from '../../../graphql/graphqlQueries';
import KSubWrapper from '../../../components/containers/KSubWrapper'
import KInput from '../../../components/inputs/KInput'
import KPhoneInput from '../../../components/inputs/KPhoneInput';
import { validate } from '../../../utils/validators/index';
import { validateProfile } from '../../../utils/validators/residentValidator';
import ImageUploadQuery from '../../../graphql/ImageUploadQuery';
import KSection from '../../../components/cards/KSection';
import { checkProfileChanges, warningTypes } from '../../../utils/residentUtils';
import KToggle from '../../../components/pickers/toggle/KToggle';
import KAvatar from '../../../components/KAvatar';
import { importResidentImage, uploadMobileFormat } from '../../../utils/KmdUtils';
import KButton from '../../../components/KButton';
import { alertWarningMessages } from '../../../utils/globalTypes';
import { convertBase64ToFile } from '../../../utils/fileUtils';
import NexusLogo from '../../../assets/nexus.png'

export default function Services({ residentParent, user, warnings }) {
	const [resident, setResident] = useState(residentParent);
	const [errors, setErrors] = useState();
	const [image, setImage] = useState();
	const [imageLoading, setImageLoading] = useState();

	const [message, setMessage] = useState({ msg: "", type: "" });

	const [rooms, setRooms] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [currentRoom, setCurrentRoom] = useState("");
	const [currentDeparment, setCurrentDepartment] = useState("");

	const [doUploadImage, { loading: uploadLoading }] = useMutation(UPLOAD_IMAGE);
	const [doUpdateResident, { loading: updateLoading }] = useMutation(UPDATE_RESIDENT, {
		refetchQueries: [GET_RESIDENT_BY_ID]
	})

	const { data: allRooms, loading: roomsLoading } = useQuery(GET_ALL_ROOMS_BY_ORGANZATION_ID, {
		variables: { id: Number(user?.data?.organization?.id) },
		fetchPolicy: 'no-cache'
	});

	const { data: allDepartments, loading: departmentLoading } = useQuery(GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, {
		variables: { organizationId: user?.data?.organization?.id },
		fetchPolicy: 'no-cache'
	});

	useEffect(() => {
		setResident(residentParent)
	}, [residentParent])

	useEffect(() => {
		if (allRooms || allDepartments) {
			setRooms(allRooms?.getAllRoomsByOrganizationId)
			setDepartments(allDepartments?.getAllDepartmentsByOrganizationId)
		}
	}, [allRooms, allDepartments])

	useEffect(() => {
		if (resident && rooms?.length > 0 && departments?.length > 0) {
			const foundRoomIndex = rooms.findIndex(r => r?.id == resident?.room?.id)
			setCurrentRoom(rooms[foundRoomIndex])

			const foundDepartmentIndex = departments.findIndex(r => r?.id == resident?.room?.departmentId)
			setCurrentDepartment(departments[foundDepartmentIndex])
		}
	}, [resident, rooms, departments])

	const updateResident = async (url) => {
		try {
			const validated = validate(
				validateProfile,
				{ resident, room: currentRoom },
				setErrors
			)

			if (validated) {
				image
					? ImageUploadQuery(handleUpdate, image, doUploadImage)
					: handleUpdate()
			}

		} catch (error) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const handleUpdate = async (url) => {
		try {
			await doUpdateResident({
				variables: {
					input: {
						externalId: resident?.externalId,
						executorId: user?.data?.id,
						id: resident?.id,
						imageUrl: url ? url : resident?.url,
						firstName: resident?.firstName,
						middleName: resident?.middleName,
						lastName: resident?.lastName,
						email: resident?.email ? resident?.email : null,
						phone: resident?.phone ? uploadMobileFormat(resident?.phone?.value) : null,
						mobile: resident?.mobile ? uploadMobileFormat(resident?.mobile?.value) : null,
						cpr: resident?.cpr,
						roomId: currentRoom?.id,
					}
				}
			})

			setMessage(alertWarningMessages.UPDATED('Borgerprofilen'))
		} catch (err) {
			if (err?.message === 'Email already in use') {
				setMessage(alertWarningMessages.ERROR('Noget gik alt, tjek venligst for fejl.'))
				setErrors({ email: 'Der findes allerede en borger med denne email' })
			} else {
				setMessage(alertWarningMessages.UNKNOWN)
			}
		}
	}

	const handleDepartmentChange = (value) => {
		if (!_.isEqual(value, currentDeparment)) {
			setCurrentRoom(null)
			setCurrentDepartment(value)
		}
	}

	const onImageChange = async (e) => {
		const [file] = e.target.files;
		setImage(file);
	};

	const onImageRemove = async () => {
		setImage(null)
		setResident({ ...resident, imageUrl: null })
	}

	const importImage = async () => {
		setImageLoading(true)
		try {
			const res = await importResidentImage(resident, user)
			const fImage = await convertBase64ToFile(res?.resident?.imageBase64)
			setImage(fImage)
		} catch (err) {
			setMessage(alertWarningMessages.ERROR('Kunne ikke hente borgerens billede. Prøv igen senere.'))
		} finally {
			setImageLoading(false)
		}
	}

	return (
		<KSubWrapper
			title="Profil"
			showSubmit={checkProfileChanges({
				resident,
				residentParent,
				currentRoom,
				currentDeparment,
				image
			})}
			loading={[updateLoading, departmentLoading, roomsLoading, uploadLoading]?.some(e => e === true)}
			onSubmit={updateResident}
		>
			<>
				<div className='grid grid-cols-2 gap-x-6 gap-y-6' >
					<div className="space-y-6 lg:col-start-1 lg:col-span-2">
						<KSection
							title="Borgerbillede"
							description="Tilføj, rediger eller slet borgerbillede."

						>
							<div className='relative'>
								<KAvatar
									sizeClass={"h-44 w-44"}
									loading={imageLoading}
									image={image || resident?.imageUrl}
									handleChange={(e) => onImageChange(e)}
									handleRemove={(e) => onImageRemove(e)}
								/>
								{resident?.externalId &&
									<div className="absolute right-1 top-1">
										<img className="ml-2 h-7" src={NexusLogo} />

										<div className="mt-4">
											<p className="max-w-2xl mt-1 mb-2 text-sm text-gray-500">Du kan hente billede fra Nexus.</p>
											<KButton onClick={(e) => importImage(e)} label="Hent borgerbillede fra Nexus" />
										</div>
									</div>
								}
							</div>
						</KSection>
						<KSection title="Stamdata">
							<div className="mt-5">
								<div className="grid w-full grid-cols-3 gap-x-2">
									<KInput
										label="Fornavn"
										value={resident?.firstName}
										onChange={(v) => setResident({ ...resident, firstName: v })}
										error={errors?.firstName}
									/>
									<KInput
										label="Mellemnavn"
										value={resident?.middleName}
										onChange={(v) => setResident({ ...resident, middleName: v })}
									/>
									<KInput
										label="Efternavn"
										value={resident?.lastName}
										onChange={(v) => setResident({ ...resident, lastName: v })}
										error={errors?.lastName}
									/>
								</div>
								<div className="grid grid-cols-3 mt-4 gap-x-2">
									<KPhoneInput
										mobile={resident?.phone}
										label="Telefon"
										onChange={(v) => setResident({ ...resident, phone: v })}
									/>
									<KPhoneInput
										mobile={resident?.mobile}
										label="Mobil"
										onChange={(v) => setResident({ ...resident, mobile: v })}
									/>
									<KInput
										label="Email"
										icon={true}
										type="email"
										value={resident?.email}
										onChange={(v) => setResident({ ...resident, email: v })}
										error={errors?.email}
									/>
								</div>
							</div>
						</KSection>
						<KSection warning={warnings?.find(e => e.key === warningTypes.HOUSING.key)?.message} title="Bolig" description="Tilføj eller rediger borgerens bolig tilknytning.">
							<div className="">
								<p className="mb-2 space-y-1 text-sm font-bold leading-6 text-gray-600 uppercase">Bolig</p>

								<div className="grid grid-cols-2 gap-x-2">
									<KSelect
										warning={warnings?.find(e => e?.key === warningTypes.ROOM.key)?.message}
										label="Afsnit"
										selected={currentDeparment}
										elements={departments}
										handleSelect={(e) => handleDepartmentChange(e)}
									/>
									<KSelect
										label="Bolig"
										warning={warnings?.find(e => e?.key === warningTypes.ROOM.key)?.message}
										selected={currentRoom}
										elements={currentDeparment ? rooms?.filter(room => room?.departmentId == currentDeparment?.id) : rooms}
										handleSelect={(e) => setCurrentRoom(e)}
										checkDisabled={(e) => e?.resident && e?.resident?.id !== currentRoom?.resident?.id}
										error={errors?.room}
									/>
								</div>
							</div>
						</KSection>
						<KSection warning={warnings?.find(e => e.key === warningTypes.NEXUS.key)?.message} title="Nexus integration">
							<div className="flex items-center justify-between">
								<p>Integreret med Nexus KMD</p>
								<KToggle disabled checked={resident?.externalId ? true : false} />
							</div>

						</KSection>
					</div>

					<KSnackbar
						open={message.msg}
						setOpen={(v) => { setMessage({ ...message, msg: v }) }}
						title={message.msg}
						duration={6000}
						verticalPosition="bottom"
						horizontalPosition="center"
						type={message.type}
					/>
				</div >
			</>
		</KSubWrapper >
	)
}
