
import React, { useState } from 'react'
import _ from 'lodash';

import KSubWrapper from '../../../../components/containers/KSubWrapper';
import KButton from '../../../../components/KButton';
import KSection from '../../../../components/cards/KSection';
import { getResidentFullName, getResidentRelativesWarning } from '../../../../utils/residentUtils';
import CreateRelative from './CreateRelative';
import ResidentRelative from './ResidentRelative';
import KSnackbar from '../../../../components/KSnackbar';
import EditRelative from './EditRelative';
import KModal from '../../../../components/modals/KModal'
import { alertWarningMessages } from '../../../../utils/globalTypes';
import AddExistingRelatives from './AddExistingRelatives';

export default function Relatives({ resident, warnings }) {
	const [showAddRelativeModal, setShowAddRelativeModal] = useState(false)
	const [showEditRelativeModal, setShowEditRelativeModal] = useState(false)
	const [activeRelative, setActiveRelative] = useState()
	const [snackbarMessage, setSnackbarMessage] = useState({ msg: "", type: "" });
	const [relType, setRelType] = useState(0)

	const TopBar = () => (
		<div className="flex items-center justify-between h-12 pr-4 border-b">
			<p className="ml-4 text-lg font-bold text-gray-600">Pårørende</p>
			<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
				<KButton disabled={!resident?.residentConsent?.inviteStatus} disabledText="Der er ikke givet samtykke til at invitere pårørende. Se mere under menuen Samtykke." className="min-w-full" label="TILFØJ PÅRØRENDE" onClick={() => setShowAddRelativeModal(true)} />
			</div>

		</div>
	)

	const handleRelativeClick = (relative) => {
		setActiveRelative(relative)
		setShowEditRelativeModal(true)
	}

	const handleRelativeCreated = () => {
		setShowAddRelativeModal(false)
		setSnackbarMessage(alertWarningMessages.CREATED('Pårørende'))
	}

	return (
		<KSubWrapper
			title="Pårørende"
			TopBar={TopBar}
		>
			<KModal
				open={showAddRelativeModal}
				setOpen={() => setShowAddRelativeModal(false)}
			>
				<SwitchBtn value={relType} setValue={setRelType} />
				{relType === 0 ?
					<div className="">
						<CreateRelative
							residentId={resident?.id}
							onSubmit={handleRelativeCreated}
							relatives={resident?.relatives}
							setOpen={setShowAddRelativeModal}
						/>
					</div> :
					<div className="p-6">
						<AddExistingRelatives resident={resident} onRelativeAdded={() => setShowAddRelativeModal(false)} />
					</div>
				}
			</KModal>
			<KModal
				open={showEditRelativeModal}
				setOpen={setShowEditRelativeModal}
			>
				<div>
					<EditRelative
						resident={resident}
						relative={activeRelative}
						setOpen={setShowEditRelativeModal}
						setSnackbarMessage={setSnackbarMessage}
					/>
				</div>
			</KModal>

			<div>
				<KSection
					title="Pårørende"
					description="Her kan du se oversigt over borgerens pårørende. Klik på en pårørende for at redigere og/eller se flere informationer."
					warning={getResidentRelativesWarning(warnings)}

				>
					<div className="bg-white">
						<div className="grid grid-cols-3 sm:grid-cols-2 gap-x-5 gap-y-5">
							{resident?.relatives?.map((e) => e).sort((a, b) => a?.user?.roleId - b?.user?.roleId).map((relative, index) => (
								<ResidentRelative
									relative={relative}
									resident={resident}
									setSnackMessage={setSnackbarMessage}
									onClick={handleRelativeClick}
								/>
								// <div>test</div>
							))}
						</div>
					</div >
				</KSection>
			</div>
			<KSnackbar
				open={snackbarMessage.msg}
				setOpen={(v) => { setSnackbarMessage({ ...snackbarMessage, msg: v }) }}
				title={snackbarMessage.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={snackbarMessage.type}
			/>
		</KSubWrapper>

	)
}


const SwitchBtn = ({ value = 0, setValue = () => { }, items = [{ value: 0, label: "Tilføj ny pårørende" }, { value: 1, label: "Tilføj eksisterende pårørende" }] }) => {
	return (
		<div className='flex px-6 pt-6 gap-x-2' >
			{items.map((item, index) => (
				<div key={index}
					className={`flex items-center justify-center w-1/2 h-10 rounded-md cursor-pointer ${value === item.value ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-500'}`}
					onClick={() => setValue(item.value)}>
					{item.label}
				</div>
			))}
		</div>
	)
}