import { useMutation } from '@apollo/client';
import { Checkbox } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import KButton from '../../components/KButton';
import KLoadingButton from '../../components/KLoadingButton';
import KPageWrapper from '../../components/KPageWrapper'
import KTextField from '../../components/KTextField';
import { CREATE_INTEREST } from '../../graphql/graphqlMutations';
import { GET_ALL_INTERESTS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries';
import { dashboardRoute, interestGroupsRoute } from '../../routes/mainRoutes'
import Colors from '../../styles/Colors';
import { validateNewInterest } from '../../utils/validators';

export default function CreateInterestGroup({ history }) {
	const user = useSelector(state => state.user);

	const [title, setTitle] = useState();
	const [description, setDescription] = useState();

	const [residentSelected, setResidentSelected] = useState(false);
	const [relativesSelected, setRelativesSelected] = useState(false);
	const [workersSelected, setWorkersSelected] = useState(false);

	const [errors, setErrors] = useState();

	const [createInterest, { loading: submitLoading }] = useMutation(CREATE_INTEREST, {
		refetchQueries: [GET_ALL_INTERESTS_BY_ORGANIZATION_ID]
	})

	const onCreateInterest = () => {
		const validated = validateNewInterest({
			title,
			options: [residentSelected, relativesSelected, workersSelected]
		})

		if (!validated.success) {
			return setErrors(validated.errors)
		}

		createInterest({
			variables: {
				input: {
					title,
					description,
					organizationId: user?.data?.organization?.id,
					allowResidents: residentSelected,
					allowRelatives: relativesSelected,
					allowWorkers: workersSelected,
					creatorId: user?.data?.id
				}
			}
		}).then(res => {
			history.goBack();
		}).catch(err => {
			console.log(JSON.stringify(err, 2, null))
			// console.log(err)
		})
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Interessegrupper',
				route: interestGroupsRoute
			}, {
				title: 'Opret interessegruppe'
			}]}
		>
			<Container>
				<KTextField
					error={errors?.title}
					value={title}
					label="Titel"
					required
					onChange={(value) => setTitle(value)}
				/>
				<KTextField
					error={errors?.description}
					value={description}
					label="Beskrivelse"
					required
					onChange={(value) => setDescription(value)}
					multiline
					rows={5}
				/>
				<div>
					<p style={{ fontFamily: 'MetricSemiBold' }}>Hvem kan tilføjes til den her interessegruppe?</p>
					<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
						<Checkbox checked={residentSelected} onChange={() => setResidentSelected(!residentSelected)} />
						<p>Borger</p>
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
						<Checkbox checked={relativesSelected} onChange={() => setRelativesSelected(!relativesSelected)} />
						<p>Pårørende</p>
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
						<Checkbox checked={workersSelected} onChange={() => setWorkersSelected(!workersSelected)} />
						<p>Personale</p>
					</div>
				</div>
				<div>
					<KLoadingButton onClick={() => onCreateInterest()} title="OPRET INTERESSEGRUPPE" />
				</div>

			</Container>
		</KPageWrapper>
	)
}

const Container = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 20,
	marginTop: 20,
	maxWidth: 500
}))
