import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, MinusCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { classNames } from '../../utils/tailwindUtils'

export default function KSelect({ label, elements, selected, handleSelect, checkDisabled, error, warning, elementKey, required, descriptionKey, disableText, withClear, disabled }) {
	return (
		<Listbox value={selected} onChange={!disabled && handleSelect}>
			{({ open }) => (
				<>
					<div className="relative ">
						<div className="flex justify-between">
							<label className="block text-sm font-medium text-gray-700">
								{label}
							</label>
							<span className="text-sm text-gray-500" id="email-optional">
								{required ? 'Påkrævet' : ''}
							</span>
						</div>

						<Listbox.Button
							className={classNames(
								error ? 'border-red-300' : 'border-gray-300',
								warning && !error ? 'border-yellow-400' : 'border-gray-300',
								"relative h-10 mt-1 w-full py-2 pl-3 pr-10 text-left bg-gray-100 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-darkPurple focus:border-darkPurple sm:text-sm border"
							)}
						>
							<span className="inline-flex w-full truncate">
								<span className="font-black text-gray-600 truncate">{selected && elementKey ? selected[elementKey] : selected?.name ? selected?.name : selected}</span>
								<span className="ml-2 text-gray-500 truncate">{selected?.username}</span>
							</span>
							{error &&
								<div className="absolute inset-y-0 flex items-center pr-3 pointer-events-none right-5">
									<ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
								</div>
							}
							{warning && !error &&
								<div className="absolute inset-y-0 flex items-center pr-3 pointer-events-none right-5">
									<ExclamationCircleIcon className="w-5 h-5 text-yellow-400" aria-hidden="true" />
								</div>
							}
							<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none gap-x-2">
								<ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>
						{withClear &&
							<p onClick={() => handleSelect(null)} className="mt-1 ml-1 text-xs text-right text-gray-500 cursor-pointer">Ryd felt</p>
						}
						{error &&
							<p className="mt-1 ml-1 text-sm text-red-600 " id="email-error">
								{error}
							</p>
						}
						{warning && !error &&
							<p className="mt-1 ml-1 text-sm text-yellow-500 " id="email-error">
								{warning}
							</p>
						}

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{elements?.map((item) => (
									<Listbox.Option
										key={item.username}
										className={({ active }) =>
											classNames(
												active ? checkDisabled && checkDisabled(item) ? 'text-gray-600 bg-gray-200' : 'text-white bg-darkPurple' : checkDisabled && checkDisabled(item) ? 'text-gray-600 bg-gray-200' : 'text-gray-900',
												'cursor-default select-none relative py-2 pl-3 pr-9',
												checkDisabled && checkDisabled(item) ? 'pointer-events-none' : 'pointer-events-auto cursor-pointer',
											)
										}
										value={item}
									>
										{({ selected, active }) => (
											<>
												<div className="">
													<span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
														{elementKey ? item[elementKey] : item?.name ? item?.name : item}
													</span>
													<span className={classNames('block text-xs')}>
														{descriptionKey ? item[descriptionKey] : item?.description}
													</span>
												</div>

												{selected ? (
													<span
														className={classNames(
															active ? 'text-white' : 'text-darkPurple',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}
													>
														<CheckIcon className="w-5 h-5" aria-hidden="true" />
													</span>
												) : null}

												{checkDisabled && checkDisabled(item) &&
													<span
														className='absolute inset-y-0 right-0 flex items-center pr-4 text-gray-400'
													>
														{disableText
															? disableText
															: <MinusCircleIcon className="w-5 h-5" aria-hidden="true" />
														}

													</span>
												}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)
			}
		</Listbox >
	)
}