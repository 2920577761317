import React from "react";

export default function KTitle({ title, className, style }) {
	return (
		<p
			style={{ fontFamily: 'MetricBold', ...style}} className={`w-max text-xl text-gray-600 uppercase ${className}`}
		>
			{title}
		</p>
	);
}
