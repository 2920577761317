import React, { useEffect, useState } from "react";
import {
	GET_ALL_NEWS_BY_ORGANIZATION_ID,
	GET_ALL_NEWS_BY_DEPARTMENT_ID,
	GET_ALL_NEWS_BY_RESIDNT_ID,
	GET_NEWS_BY_ID,
	GET_NEWS_RECEIVERS_BY_ID
} from "../../graphql/graphqlQueries";
import {
	CREATE_NEWS_COMMNT,
	CREATE_NEWS_LIKE,
	DELETE_NEWS_BY_ID,
	DELETE_NEWS_LIKE,
} from "../../graphql/graphqlMutations";
import { convertToNewsDate, unixToDateTimeString } from "../../utils/dateUtils";
import styled from "styled-components";
import KButton from "../../components/KButton";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import Colors from "../../styles/Colors";
import { editNewsRoute } from "../../routes/mainRoutes";
import { Avatar, IconButton } from "@mui/material";
import { canEditAndDelete, isRelativeNoData } from "../../utils/roles";
import KFileViewer from "../../components/KFileViewer";
import { checkIfUrlInText } from "../../utils/validators";
import KFileCard from "../../components/cards/KFileCard";
import KImageCarousel from "../../components/media/imageCarousel/KImageCarousel";
import KModal from "../../components/modals/KModal";
import CancelIcon from '@mui/icons-material/Cancel';
import KTextArea from "../../components/inputs/KTextArea";
import { useRef } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import KConfirmModal from "../../components/modals/KConfirmModal";
import KLoader from "../../components/KLoader";

import { clearState } from "../../redux/slices/globalFilterQuerySlice";
import { newsKey } from "../../redux/reduxUtils";

export default function Story({ history, match, defaultNews, isPreview }) {
	const user = useSelector(state => state.user);

	const [news, setNews] = useState(defaultNews);


	const [isLiked, setIsLiked] = useState(false);
	const [nrOflikes, setNrOfLikes] = useState(defaultNews?.likes?.length || 0);

	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const [showLikesModal, setShowLikesModal] = useState(false)
	const [showReceiversModal, setShowReceiversModal] = useState(false)


	const [showCommentsModal, setShowCommentsModal] = useState(false)
	const [commentFocus, setCommentFocus] = useState(false)

	const [comment, setComment] = useState('');

	const dispatch = useDispatch();


	const [makeLike, { loading: createLikeLoading }] = useMutation(CREATE_NEWS_LIKE);
	const [deleteLike, { loading: deleteLikeLoading }] = useMutation(DELETE_NEWS_LIKE);
	const [deleteNews] = useMutation(DELETE_NEWS_BY_ID, {
		refetchQueries: [
			GET_ALL_NEWS_BY_ORGANIZATION_ID,
			GET_ALL_NEWS_BY_DEPARTMENT_ID,
			GET_ALL_NEWS_BY_RESIDNT_ID,
		]
	});
	const [makeComment, { loading: createCommentLoading }] = useMutation(CREATE_NEWS_COMMNT);

	const [getNewsById] = useLazyQuery(GET_NEWS_BY_ID, {
		variables: { id: defaultNews?.id },
		onCompleted: (data) => {
			setNews(data?.getNewsById)
			setNrOfLikes(data?.getNewsById?.likes?.length)
		},
		onError: (err) => {
			console.log("error: ", err);
		}
	})

	const windowRef = useRef()

	const DisplayReceivers = () => {
		const [receivers, setReceivers] = useState([]);
		const { data, loading } = useQuery(GET_NEWS_RECEIVERS_BY_ID, { variables: { id: defaultNews?.id } })

		useEffect(() => {
			if (data) {
				const rs = data?.getNewsReceiversByNewsId?.map(r => r?.resident)
				const departments = rs?.reduce((acc, cur) => {
					const department = cur?.room?.department?.name
					if (!acc[department]) {
						acc[department] = []
					}
					acc[department].push(cur)
					return acc
				}, {})
				console.log("departments: ", departments);
				console.log("got receivers: ", data?.getNewsById?.newsReceivers);
				setReceivers(departments)
			}
		}, [data])

		if (loading) return <KLoader />

		return (
			<div className="grid grid-cols-1 divide-y">
				{Object.keys(receivers).map((d, i) => {
					return (
						<div key={i} className="grid grid-cols-1 divide-y">
							<div className="px-4 py-4">
								<p className="text-lg font-bold text-gray-600">{d}</p>
							</div>
							{receivers[d].map((r, i) => {
								return (
									<div key={i} className="flex items-center py-4 pl-8 gap-x-4">
										<Avatar src={r?.imageUrl}
											className="w-8 h-8" />
										<p>{r?.fullName}</p>
									</div>
								)
							})}
						</div>
					)
				})}
			</div>
		)
	}


	useEffect(() => {
		if (defaultNews) {
			setNews(defaultNews)
			const likes = defaultNews?.likes?.map(l => l.userId)
			setNrOfLikes(likes?.length)
			const liked = likes?.includes(user.data.id)
			setIsLiked(liked)
		}
	}, [defaultNews])


	const postLike = async () => {
		try {
			const variables = {
				input: {
					newsId: news.id,
					userId: user.data.id
				}
			};

			if (!isLiked) {
				await makeLike({ variables: variables })
				setIsLiked(true);
				setNrOfLikes(nrOflikes + 1)
			} else {
				await deleteLike({ variables: variables.input })
				setIsLiked(false);
				setNrOfLikes(nrOflikes - 1)
			}
		} catch (err) {
			console.log(JSON.stringify(err, null, 2));
		}
	};

	const deleteStory = () => {
		dispatch(clearState({ key: newsKey }));
		deleteNews({
			variables: {
				id: news?.id,
			},

		})
			.then(() => {

				setShowDeleteModal(false)

			})
			.catch((err) => console.log(JSON.stringify(err)));
	};


	const postComment = () => {
		makeComment({
			variables: {
				createNewsCommentInput: {
					newsId: news?.id,
					userId: user?.data?.id,
					message: comment,
				},
			},
		})
			.then(() => {
				// refetch()
				getNewsById()
				setComment("");
				setCommentFocus(false)
			})
			.catch((err) => console.log(err));
	};

	const handleCloseCommentModal = (e) => {
		setShowCommentsModal(false)
		// handle content size flash on close
		setTimeout(() => {
			setComment("")
			setCommentFocus(false)
		}, 250)
	}

	const handleShowCommentModal = (e) => {
		setShowCommentsModal(true)
		if (e) {
			setCommentFocus(true)
		}
	}

	return (
		<div className={isPreview ? 'pointer-events-none' : 'relative'} ref={windowRef}>
			<KConfirmModal
				open={showDeleteModal}
				setOpen={setShowDeleteModal}
				confirmText="Slet nyhed"
				handleConfirm={() => deleteStory()}
				title="Slet nyhed"
				description="Er du sikker på at du vil slette nyheden?"
			/>


			<KModal
				open={showLikesModal}
				setOpen={() => setShowLikesModal(!showLikesModal)}
			>
				<div className="grid grid-cols-1 divide-y">
					<div className="flex justify-end py-2 pr-2">
						<IconButton onClick={() => setShowLikesModal(false)}>
							<CancelIcon className="w-6 h-6" />
						</IconButton>
					</div>
					<div className="grid grid-cols-1 divide-y">
						{news?.likes?.length === 0 &&
							<p className="px-4 py-4">Ingen synes godt om fundet.</p>
						}
						{news?.likes?.map(like => {
							return (
								<div className="flex items-center px-4 py-4 gap-x-4">
									<Avatar src={like?.user?.imageUrl} />
									<div>
										<p>{like?.user?.fullName}</p>
										{isRelativeNoData(like?.user)
											? <p className="text-sm text-gray-500">{`Pårørende (${like?.user?.relatives?.find(rel => rel?.resident?.organizationId === news?.organizationId)?.resident?.fullName})`}</p>
											: <p className="text-sm text-gray-500">Personale</p>
										}
									</div>
								</div>
							)
						})}
					</div>
				</div >
			</KModal>


			{/* receivers */}
			<KModal
				open={showReceiversModal}
				setOpen={() => setShowReceiversModal(!showReceiversModal)}
			>
				<div className="grid grid-cols-1 divide-y">
					<div className="flex justify-between py-2 pr-2">
						{/* display title left */}
						<div className="">
							<h3 className="pt-2 pl-4 text-2xl font-bold">Alle modtagere</h3>
							{/* Modtagere */}
						</div>
						<IconButton onClick={() => setShowReceiversModal(false)}>
							<CancelIcon className="w-6 h-6" />
						</IconButton>
					</div>
					<DisplayReceivers />
				</div >
			</KModal>


			<KModal
				open={showCommentsModal}
				setOpen={(e) => e === true ? setShowCommentsModal(true) : handleCloseCommentModal()}
			>
				<div className="grid grid-cols-1 divide-y">
					<div className="flex justify-end py-2 pr-2">
						<IconButton onClick={() => handleCloseCommentModal(false)}>
							<CancelIcon className="w-6 h-6" />
						</IconButton>
					</div>
					<div style={{ height: '50vh', overflow: 'scroll' }} className="p-4">
						{news?.comments?.length === 0 &&
							<p>Ingen kommentarer fundet.</p>
						}
						{news?.comments?.map(comment => {
							return (
								<div style={{ display: 'flex', gap: 15, alignItems: 'center', marginTop: 15 }}>
									<div>
										<Avatar src={comment?.user?.imageUrl} />
									</div>
									<div style={{ backgroundColor: Colors.lightGrey, padding: '10px 15px 10px 15px', borderRadius: 5 }}>
										<p style={{ fontSize: 14, fontFamily: 'MetricSemiBold' }}>
											{comment?.user?.fullName}
											{isRelativeNoData(comment?.user) &&
												<span style={{ marginLeft: 10, fontSize: 12, fontFamily: 'MetricRegular' }}>
													{`(${comment?.user?.relatives?.find(rel => rel?.resident?.organizationId === news?.organizationId)?.resident?.fullName})`}
												</span>
											}
										</p>
										<p>{comment?.message}</p>
										<div>
											<p className="mt-1 text-xs text-gray-400">{unixToDateTimeString(parseInt(comment?.createdAt))}</p>
										</div>
									</div>
								</div>
							)
						})}
					</div>
					<div className="flex p-2">
						<div className="w-full">
							<KTextArea focus={true} onFocus={(e) => setCommentFocus(true)} rows={commentFocus ? 6 : 1} value={comment} onChange={(c) => setComment(c)} placeholder="Skriv kommentar" />
						</div>
					</div>
					<div className="p-2">
						<KButton disabledText="" disabled={comment === "" || createCommentLoading} onClick={() => postComment()} label="GEM KOMMENTAR" />
					</div>
				</div>
			</KModal>

			{!isPreview && canEditAndDelete(user, news?.creator) &&
				<div className="absolute top-0 right-0 flex text-gray-900 gap-x-4">
					<IconButton onClick={() => history.push(editNewsRoute(news?.id))}>
						<PencilIcon className="w-5 h-5" />
					</IconButton>
					<IconButton onClick={() => setShowDeleteModal(true)}>
						<TrashIcon className="w-5 h-5" />
					</IconButton>
				</div>
			}

			<div className="grid h-full grid-cols-1 divide-y gap-y-4">
				<div className="h-full">
					<div style={{}}>
						<p className="text-xs font-bold text-gray-600">{convertToNewsDate(news?.createdAt)}</p>
						<Title>
							{news?.title}
						</Title>
						<p><span className="mr-1">Af:</span>{news?.creator?.fullName}</p>
						{news?.description &&
							<p className="mt-4 whitespace-pre-wrap">
								{checkIfUrlInText(news?.description)?.map(e => {
									if (e?.type === 'link') {
										return <a target="_blank" style={{ textDecoration: 'auto', color: Colors.darkPurple }} href={e?.text}>{e?.text}</a>
									}
									return (
										<span>{e?.text}</span>
									)
								})}
							</p>
						}
					</div>
					<div className="mt-4">
						<div onClick={() => setShowReceiversModal(true)} className="cursor-pointer hover:underline">
							<p> Vis modtagere</p>
						</div>
					</div>
				</div>
				{(news?.imageUrl || news?.images?.length > 0) &&
					<div className="pt-4">

						{news?.images &&
							<KImageCarousel
								images={news?.images}
							/>
						}
					</div>
				}
				{news?.files && news?.files?.length > 0 &&
					<div className="mt-2">
						<div className="grid grid-cols-2 mt-4 gap-x-2 gap-y-2">
							{news?.files?.map(file => {
								return <a href={file?.url} without rel="noopener noreferrer" target="_blank"><KFileCard file={file} /></a>
							})}
						</div>
					</div>
				}
				<div className="grid grid-cols-2 pt-2 text-lg gap-x-2">
					<div>
						<div onClick={() => setShowLikesModal(true)} className="cursor-pointer hover:underline">
							<p>{nrOflikes} synes godt om</p>
						</div>
						<KButton loading={createLikeLoading || deleteLikeLoading} onClick={() => postLike()} label={isLiked ? "FJERN SYNES GODT OM" : "SYNES GODT OM"} />
					</div>
					<div>
						<div onClick={() => setShowCommentsModal(true)} className="cursor-pointer hover:underline">
							<p>{news?.comments?.length || 0} {news?.comments?.length === 1 ? 'kommentar' : 'kommentarer'}</p>
						</div>
						<KButton onClick={() => handleShowCommentModal(true)} label="KOMMENTER" />

					</div>
				</div>
			</div >
		</div >

	);
}

const ReceiversContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	width: '100%',
	padding: 20,
	backgroundColor: Colors.white,
	borderRadius: 10,
	overflow: 'auto'
})

const ResidentContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	alignItems: 'center',
	width: '100%',
	padding: 10,
	borderRadius: 10,
	overflow: 'auto',
	'&:hover': {
		backgroundColor: Colors.lightGray
	}
})

const Title = styled.p({
	fontFamily: 'MetricBold',
	color: Colors.mainGreen,
	fontSize: 25,
	textTransform: 'uppercase',
	marginBottom: 5
})
