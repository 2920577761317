import { useMutation, useQuery } from '@apollo/client'
import { Button, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import KButton from '../../components/KButton'
import KTitle from '../../components/KTitle'
import { LOGIN_MUTATION, UPDATE_USER_BY_ID, UPDATE_WORKER_ORGANIZATION } from '../../graphql/graphqlMutations'
import { GET_KMD_ORGANIZATIONS_BY_MUNICIPALITY, GET_ALL_ORGANIZATION_BY_MUNICIPALITY } from '../../graphql/graphqlQueries'
import { setLoggedIn, setUser } from '../../redux/slices/userSlice'
import { residentsRoute } from '../../routes/mainRoutes'
import Colors from '../../styles/Colors'
import { useKMD } from '../../config'

export default function SelectOrganization({ location, history }) {
	const user = useSelector(state => state.user);

	const dispatch = useDispatch();

	const [organizations, setOrganizations] = useState();
	const [selectedOrganization, setSelectedOrganization] = useState();

	const { data, error } = useKMD ? useQuery(GET_KMD_ORGANIZATIONS_BY_MUNICIPALITY, {
		variables: {
			municipalityId: location?.state?.user?.data?.municipalityId,
			token: user?.KMDAuth?.token,
			endpoint: user?.data?.municipality?.kmdRootEndpoint
		},
		fetchPolicy: "no-cache",
	}) : useQuery(GET_ALL_ORGANIZATION_BY_MUNICIPALITY, { variables: { id: location?.state?.user?.data?.municipalityId }});

	const [updateOrganization] = useMutation(UPDATE_WORKER_ORGANIZATION);

	useEffect(() => {
		const orgs = useKMD ? data?.getKMDOrganizationsByMunicipality : data?.getAllOrganizationsByMunicipalityId
		setOrganizations(orgs)
	}, [data])

	const handleSubmit = async () => {
		updateOrganization({ 
			variables: {
				input: {
					id: location?.state?.user?.data?.id,
					organizationId: selectedOrganization?.id
				}
			},
		}).then(res => {
			dispatch(setUser(res?.data?.updateWorkerOrganization));
			dispatch(setLoggedIn(true));
			history.push(residentsRoute)
		}).catch(err => {
			console.log(err)
		})
	}

	useEffect(() => {
		if (user.loggedIn && user.data.organization !== null) {
			history.push(residentsRoute);
		}
	}, [user.loggedIn])

	return (
		<Wrapper>
			<Container>
				<KTitle title="Vælg center" />
				{organizations?.map(org => {
					return (
						<OrganizationContainer onClick={() => org?.id === selectedOrganization?.id ? setSelectedOrganization(null) : setSelectedOrganization(org)}>
							<p>{org?.name}</p>
							<Checkbox checked={org?.id === selectedOrganization?.id} />
						</OrganizationContainer>
					)
				})}
			</Container>
			<ButtonContainer>
				<SubmitButton onClick={handleSubmit} color="primary">VIDERE</SubmitButton>
			</ButtonContainer>
		</Wrapper>
	)
}

const Wrapper = styled.div({
	width: '100vw', 
	paddingTop: 50
})

const Container = styled.div({
	display: 'flex', 
	alignItems: 'center', 
	flexDirection: 'column',
	height: `calc(70vh - 112px)`
})

const OrganizationContainer = styled.div({
	userSelect: 'none',
	display: 'flex', 
	alignItems: 'center',
	cursor: 'pointer' ,
	width: 250,
	justifyContent: 'space-between'
})

const ButtonContainer = styled.div({
	display: 'flex',
	justifyContent: 'center'
})

const SubmitButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: Colors.darkPurple,
		color: '#fff',
		paddingLeft: 20,
		paddingRight: 20,

		'&:hover': {
			backgroundColor: Colors.darkPurple
		}
	}
}))