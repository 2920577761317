import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import KLoadingButton from '../../../components/KLoadingButton';
import KPageWrapper from '../../../components/KPageWrapper'
import KTextField from '../../../components/KTextField';
import { CREATE_ROOM } from '../../../graphql/graphqlMutations';
import { GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, GET_ALL_ROOMS_BY_ORGANZATION_ID } from '../../../graphql/graphqlQueries';
import { roomGroupRoute } from '../../../routes/mainRoutes'
import { validateNewRoom } from '../../../utils/validators';
import KSelect from '../../../components/KSelect';

export default function CreateRoom({ history }) {
	const user = useSelector(state => state.user);

	const [title, setTitle] = useState();
	const [department, setDepartment] = useState({});
	const [errors, setErrors] = useState({});

	const [createRoom, { loading: submitLoading }] = useMutation(CREATE_ROOM, {
		refetchQueries: [GET_ALL_ROOMS_BY_ORGANZATION_ID]
	})


	const { data: allDepartments, loading: departmentLoading } = useQuery(
		GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, { variables: { organizationId: user?.data?.organization?.id }, fetchPolicy: 'no-cache' },
	);


	const handleChange = (key, setter, value) => {
		setErrors({ ...errors, [key]: false });
		setter(value)
	}

	const onCreateRoom = () => {
		const validated = validateNewRoom({
			title,
			department
		})

		if (!validated.success) {
			return setErrors(validated.errors)
		}

		createRoom({
			variables: {
				input: {
					name: title,
					departmentId: parseInt(department.id),
				}
			}
		}).then(res => {
			history.push(roomGroupRoute);
		}).catch(err => {
			console.log(JSON.stringify(err, 2, null))
			setErrors({ ...errors, serverError: err.message });
		})
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Boliger',
				route: roomGroupRoute
			}, {
				title: 'Opret bolig',
			}]}
			loading={departmentLoading || submitLoading}
			errors={errors?.serverError}
		>
			<div className='flex pt-5 ' >
				<div className='flex flex-col flex-1 max-w-md gap-8 mb-6' >

					<KTextField
						error={errors?.title}
						value={title}
						label="Navn"
						required
						onChange={(value) => handleChange('title', setTitle, value)}
					/>

					<KSelect
						error={errors?.department}
						label="Afdeling"
						options={allDepartments?.getAllDepartmentsByOrganizationId}
						value={department}
						optionKey="name"
						handleChange={(value) => handleChange('department', setDepartment, value)}
					/>

					<div>
						<KLoadingButton onClick={() => onCreateRoom()} title="OPRET BOLIG" />
					</div>
				</div>
			</div>
		</KPageWrapper>
	)
}
