import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import StarIcon from '@mui/icons-material/Star';

export default function KTextField({
	label,
	onChange,
	fullWidth,
	multiline,
	rows,
	value,
	error,
	defaultValue,
	maxLength,
	disabled,
	required,
	startAdornment,
	endAdornment,
	autoFocus,
	underlineText,
	placeholder,
	containerStyle,
	style
}) {
	return (
		<div style={containerStyle ? containerStyle : { width: '100%', position: 'relative' }}>
			{required && !value && <StarIcon color="primary" style={{ position: 'absolute', fontSize: 8, right: 7, top: 7 }} />}
			<TextField
				style={style}
				placeholder={placeholder}
				size="small"
				fullWidth={fullWidth ? fullWidth : true}
				InputLabelProps={{ shrink: true }}
				label={label}
				multiline={multiline}
				rows={rows}
				value={value}
				disabled={disabled}
				variant="outlined"
				error={error}
				autoFocus={autoFocus}
				onChange={(e) => onChange(e.target.value)}
				defaultValue={defaultValue}
				InputProps={{
					startAdornment: startAdornment ? (
						<InputAdornment position="start">
							<p>{startAdornment}</p>
						</InputAdornment>
					) : null,
					endAdornment: endAdornment ? (
						<InputAdornment position="end">
							<p>{endAdornment}</p>
						</InputAdornment>
					) : null
				}}
				inputProps={{
					maxLength: maxLength,
				}}
			/>
			{underlineText && <p style={{ fontSize: 12, paddingLeft: 2 }}>{underlineText}</p>}
		</div>
	);
}
