import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import StarIcon from '@mui/icons-material/Star';
import styled from 'styled-components';

export default function KSelect({ disabled, options, label, optionKey, disabledKey, handleChange, error, value, required, multiple }) {
	return (
		<FormControl size="small" disabled={disabled} fullWidth error={error}>
			<InputLabel>{label}</InputLabel>
			{required && !value && <StarIcon color="primary" style={{ position: 'absolute', fontSize: 8, left: 7, top: 7 }} />}
			<Select
				style={{ fontFamily: 'MetricSemiBold', color: '#545454' }}
				value={value}
				label={label}
				onChange={(e) => handleChange(e.target.value)}
				multiple={multiple}
			>
				{options?.map((option, index) => {
					return (
						<MenuItem key={index} disabled={option[disabledKey]} value={option}>{optionKey ? option[optionKey] : option}</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
}
