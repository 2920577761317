import { CalendarDaysIcon, Cog6ToothIcon, NewspaperIcon, QuestionMarkCircleIcon, SquaresPlusIcon, UserGroupIcon } from "@heroicons/react/24/solid"
import { FaTasks } from "react-icons/fa"
import { administrationRoute, assignmentsRoute, calendarRoute, dashboardRoute, guidesRoute, newsRoute, residentsRoute } from "../routes/mainRoutes"

export const alertWarningTypes = {
	ERROR: {
		id: 1,
		name: 'Error',
		icon: "text-red-400",
		container: "bg-red-50",
	},
	WARNING: {
		id: 2,
		name: 'Warning',
		icon: "text-yellow-400",
		container: "bg-yellow-500"
	},
	SUCCESS: {
		id: 3,
		name: 'Success',
		icon: "text-green-400",
		container: "bg-green-500"
	},
	INFO: {
		id: 4,
		name: 'Info',
		icon: "text-blue-400",
		container: "bg-blue-50"
	}
}

export const alertWarningMessages = {
	UNKNOWN: {
		id: 1,
		type: 'error',
		msg: "Noget gik galt, prøv igen senere",
		timeStamp: Date.now()
	},
	SUCCESS: (msg) => ({
		id: 3,
		type: 'success',
		msg,
		timeStamp: Date.now()
	}),
	CREATED: (type) => ({
		id: 4,
		type: 'success',
		msg: `${type} blev oprettet`,
		timeStamp: Date.now()
	}),
	DELETED: (type) => ({
		id: 5,
		type: 'success',
		msg: `${type} blev slettet`,
		timeStamp: Date.now()
	}),
	UPDATED: (type) => ({
		id: 6,
		type: 'success',
		msg: `${type} blev opdateret`,
		timeStamp: Date.now()
	}),
	UPLOADED: (type) => ({
		id: 7,
		type: 'success',
		msg: `${type} blev uploadet`,
		timeStamp: Date.now()
	}),
	ERROR: (msg) => ({
		id: 8,
		type: 'error',
		msg: msg,
		timeStamp: Date.now()
	})
}

export const mainNavigation = [{
	name: 'Overblik',
	icon: SquaresPlusIcon,
	route: dashboardRoute
}, {
	name: 'Borgeroversigt',
	icon: UserGroupIcon,
	route: residentsRoute
}, {
	name: 'Nyheder',
	icon: NewspaperIcon,
	route: newsRoute
}, {
	name: 'Opgaver',
	icon: FaTasks,
	route: assignmentsRoute,
	className: "mr-3.5 flex-shrink-0 h-6 w-6 ml-0.5"

}, {
	name: 'Kalender',
	icon: CalendarDaysIcon,
	route: calendarRoute
}, {
	name: 'Administration',
	icon: Cog6ToothIcon,
	route: administrationRoute
}, {
	name: 'Hjælp',
	icon: QuestionMarkCircleIcon,
	route: guidesRoute
}]