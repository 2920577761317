import { useMutation } from '@apollo/client'
import { ArrowTopRightOnSquareIcon, ClockIcon, KeyIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/outline'
import { Avatar } from '@mui/material'
import { groupBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import KFileCard from '../../components/cards/KFileCard'
import KButton from '../../components/KButton'
import KLoader from '../../components/KLoader'
import KSnackbar from '../../components/KSnackbar'
import KImageCarousel from '../../components/media/imageCarousel/KImageCarousel'
import { CHANGE_TODO_STATUS, UPDATE_TODO_PARTICIPANT_STATUS, UPDATE_TODO_PARTICIPANT_STATUS_BY_TODO } from '../../graphql/graphqlMutations'
import { GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries'
import { assigmentConsentTypes, assignmentConsentStatus, getAssigmentParticipantStatus, getAssigmentStatus, responsible, todoFilterOptions, todoFilterOptionsList } from '../../utils/assignmentUtils'
import { unixToDateTimeString } from '../../utils/dateUtils'
import { alertWarningMessages } from '../../utils/globalTypes'
import { isLeader } from '../../utils/roles'
import KAccordion from '../../components/lists/KAccordion'
import KSelect from '../../components/selectors/KSelect'
import KConfirmModal from '../../components/modals/KConfirmModal'

export default function Todo({ todo, loading, setLoading }) {
	const user = useSelector((state) => state.user);

	const [message, setMessage] = useState({ msg: "", type: "" });

	const [groupedParticipants, setGroupedParticipants] = useState();
	const [selectedFilter, setSelectedFilter] = useState(todoFilterOptions.ALL);

	const [showConfirmAcceptModal, setShowConfirmAcceptModal] = useState(false);
	const [showConfirRegretModal, setShowConfirmRegretModal] = useState(false);
	const [showConfirmSolvedModal, setShowConfirmSolvedModal] = useState(false);
	const [showConfirmReopenModal, setShowConfirmReopenModal] = useState(false);

	const [updateIndex, setUpdateIndex] = useState();

	const [changeTodo, { loading: updateLoading }] = useMutation(UPDATE_TODO_PARTICIPANT_STATUS, {
		refetchQueries: [GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID]
	});

	const [changeAllTodo, { loading: allLoading }] = useMutation(UPDATE_TODO_PARTICIPANT_STATUS_BY_TODO, {
		refetchQueries: [GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID]
	});

	const updateTodo = async (variables) => {
		try {
			setLoading(true)
			await changeTodo({ variables })

			setTimeout(() => {
				setUpdateIndex(null)
			}, 1000)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}


	const updateAccepted = (todoParticipantId, status) => {
		setUpdateIndex(todoParticipantId)
		updateTodo({
			input: {
				todoParticipantId,
				accepted: status,
				acceptedBy: status ? parseInt(user.data.id) : null,
			},
		})
	}

	const updateSolved = (todoParticipantId, solved) => {
		setUpdateIndex(todoParticipantId)
		updateTodo({
			input: {
				todoParticipantId,
				solved: solved,
				solvedBy: solved ? parseInt(user.data.id) : null,
			},
		})
	}

	useEffect(() => {
		console.log('YES calleed')
		const grouped = groupBy(todo?.participants, (e) => {
			return e?.resident?.room?.department?.name
		})

		if (grouped) {
			const newGroups = {};

			Object.keys(grouped)?.map((name) => {
				const participants = grouped[name];

				newGroups[name] = participants.filter(p => {
					if (selectedFilter?.name === todoFilterOptions.ALL.name) {
						return true;
					} else if (selectedFilter?.name === todoFilterOptions.SOLVED.name) {
						return p?.todoParticipantStatus?.solved
					} else if (selectedFilter?.name === todoFilterOptions.ACCEPTED.name) {
						return p?.todoParticipantStatus?.accepted
					} else if (selectedFilter?.name === todoFilterOptions.OPEN.name) {
						return !p?.todoParticipantStatus?.accepted
					} else if (selectedFilter?.name === todoFilterOptions.AWAITING_CONSENT.name) {
						return p?.todoParticipantStatus?.consent === assignmentConsentStatus.REQUIRED.value && p?.todoParticipantStatus?.consentBy === null
					} else if (selectedFilter?.name === todoFilterOptions.CONSENT_REJECTED.name) {
						return p?.todoParticipantStatus?.consent === assignmentConsentStatus.REFUSED.value
					}
				})
			})

			return setGroupedParticipants(newGroups);
		}

	}, [todo, selectedFilter])

	const updateAcceptTodoForAll = async () => {
		try {
			await changeAllTodo({
				variables: {
					input: {
						todoParticipantId: todo?.participants?.map(e => e.id),
						accepted: true,
						acceptedBy: parseInt(user.data.id),
						solvedBy: null,
						solved: false
					},
				}
			})

			setShowConfirmAcceptModal(false)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const updateRegretTodoForAll = async () => {
		try {
			await changeAllTodo({
				variables: {
					input: {
						todoParticipantId: todo?.participants?.map(e => e.id),
						accepted: false,
						acceptedBy: null,
						solvedBy: null,
						solved: false
					},
				}
			})
			setShowConfirmRegretModal(false)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const updateSolvedTodoForAll = async () => {
		try {
			await changeAllTodo({
				variables: {
					input: {
						todoParticipantId: todo?.participants?.map(e => e.id),
						accepted: true,
						acceptedBy: parseInt(user.data.id),
						solved: true,
						solvedBy: parseInt(user.data?.id),
					},
				}
			})
			setShowConfirmSolvedModal(false)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const updateReopenTodoForAll = async () => {
		try {
			await changeAllTodo({
				variables: {
					input: {
						todoParticipantId: todo?.participants?.map(e => e.id),
						solved: false,
						solvedBy: null,
					},
				}
			})
			setShowConfirmReopenModal(false)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	return (
		<div>
			<KConfirmModal
				confirmText={"Gem"}
				open={showConfirmAcceptModal}
				setOpen={setShowConfirmAcceptModal}
				title="Du er ved at opdatere alle opgaver"
				description="Denne handling vil overskrive alle opgaver, er du sikker på du vil fortsætte?"
				handleConfirm={() => updateAcceptTodoForAll()}
			/>
			<KConfirmModal
				confirmText={"Gem"}
				open={showConfirRegretModal}
				setOpen={setShowConfirmRegretModal}
				title="Du er ved at opdatere alle opgaver"
				description="Denne handling vil overskrive alle opgaver, er du sikker på du vil fortsætte?"
				handleConfirm={() => updateRegretTodoForAll()}
			/>
			<KConfirmModal
				confirmText={"Gem"}
				open={showConfirmSolvedModal}
				setOpen={setShowConfirmSolvedModal}
				title="Du er ved at opdatere alle opgaver"
				description="Denne handling vil overskrive alle opgaver, er du sikker på du vil fortsætte?"
				handleConfirm={updateSolvedTodoForAll}
			/>
			<KConfirmModal
				confirmText={"Gem"}
				open={showConfirmReopenModal}
				setOpen={setShowConfirmReopenModal}
				title="Du er ved at opdatere alle opgaver"
				description="Denne handling vil overskrive alle opgaver, er du sikker på du vil fortsætte?"
				handleConfirm={updateReopenTodoForAll}
			/>
			<div className="p-6" style={{ height: '80vh', overflow: 'scroll' }}>

				<p className="text-xl font-bold text-gray-700">{todo?.title}</p>
				<p className="text-gray-600">{todo?.description}</p>
				{todo?.images && todo?.images?.length > 0 &&
					<KImageCarousel
						thumbnailSize={40}
						images={todo?.images}
					/>
				}
				<div className="mt-6 border border-gray-300 rounded">
					<div className="flex items-center justify-start h-10 p-2">
						<p>Detajler</p>
					</div>
					<div className="grid grid-cols-1 border-t divide-y gap-y-4">
						<div className="grid grid-cols-1 p-4 gap-y-4">
							<div className="grid items-center grid-cols-2">
								<p className="text-xs font-semibold text-gray-700">Oprettet den</p>
								<div className="flex items-center gap-x-2">
									<ClockIcon className="w-5 h-5 text-gray-500" />
									<p>{unixToDateTimeString(parseInt(todo?.createdAt))}</p>
								</div>
							</div>
							<div className="grid items-center grid-cols-2">
								<p className="text-xs font-semibold text-gray-700">Oprettet af</p>
								<div className="flex items-center gap-x-2">
									<Avatar className="w-5 h-5" src={todo?.creator?.imageUrl} />
									<p>{todo?.creator?.fullName}</p>
								</div>
							</div>
							<div className="grid items-center grid-cols-2">
								<p className="text-xs font-semibold text-gray-700">Samtykke</p>
								<div className="flex items-center gap-x-2">
									<KeyIcon className="w-5 h-5 text-grey-500" />
									<p>{assigmentConsentTypes[todo?.consentRequired]?.name}</p>
								</div>
							</div>
							<div className="grid items-center grid-cols-2">
								<p className="text-xs font-semibold text-gray-700">Ansvarlig for udførelse</p>
								<div className="flex items-center gap-x-2">
									{todo?.responsible === responsible.WORKER.value
										? <UserGroupIcon className="w-5 h-5 text-gray-500" />
										: <UserIcon className="w-5 h-5 text-gray-500" />
									}
									<p>{responsible[todo?.responsible]?.name}</p>
								</div>
							</div>
							<div className="grid items-center grid-cols-2">
								<p className="text-xs font-semibold text-gray-700">Frist</p>
								<div className="flex items-center gap-x-2">
									<ClockIcon className="w-5 h-5 text-gray-500" />
									<p>{todo?.deadline ? todo?.deadline : 'Ingen frist'}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-6 border border-gray-300 rounded">
					<div className="flex items-center justify-start h-10 p-2">
						<p>Omhandler:</p>
					</div>
					<div className="border-t">
						<div className="mx-6 my-2">
							<p>Filtrer på status:</p>
							<KSelect
								selected={selectedFilter}
								elements={todoFilterOptionsList}
								handleSelect={(e) => setSelectedFilter(e)}
							/>
						</div>
						{groupedParticipants && Object.keys(groupedParticipants)?.map((name) => {
							return (
								<KAccordion Title={() => <p>{name} <span className="ml-3 text-sm text-gray-600">({groupedParticipants[name]?.length} {groupedParticipants[name]?.length === 1 ? 'borger' : 'borgere'})</span></p>}>
									{groupedParticipants[name]?.map(p => {
										return (
											<div className="px-2 pt-4 mt-2 border-t">
												<p>{p?.resident?.fullName}</p>
												<div className="my-5">
													{p?.todoParticipantStatus?.consent !== assigmentConsentTypes.NOT_REQUIRED.value &&
														<div className="grid items-center grid-cols-2">
															<p className="text-xs font-semibold text-gray-700">Samtykke status</p>
															<div className="flex items-center gap-x-2">
																{p?.todoParticipantStatus?.consent === assignmentConsentStatus.GIVEN.value
																	? <KeyIcon className="w-5 h-5 text-green-600 stroke-2" />
																	: <KeyIcon className="w-5 h-5 text-grey-500" />

																}
																<p>{assignmentConsentStatus[p?.todoParticipantStatus?.consent]?.name}</p>
															</div>
														</div>
													}
													<div className="grid items-center grid-cols-2 my-2">
														<p className="text-xs font-semibold text-gray-700">Opgave status</p>
														<div className="flex items-center gap-x-2">
															<ArrowTopRightOnSquareIcon className="w-5 h-5 text-gray-500" />
															<p>{getAssigmentParticipantStatus(p.todoParticipantStatus)}</p>
														</div>
													</div>
												</div>
												{(loading && updateIndex === p?.id) || allLoading &&
													<div>
														<div className="flex items-center justify-center h-8 m-2">
															<KLoader maxHeight={25} />
														</div>
													</div>
												}
												{todo?.responsible === responsible.WORKER.value && updateIndex !== p.id && !allLoading &&
													<div className="mt-4 mb-2">

														{p?.todoParticipantStatus?.consent === assignmentConsentStatus.NOT_REQUIRED.value && !p?.todoParticipantStatus?.accepted &&
															<div className="grid grid-cols-2 h-7 gap-x-4">
																<KButton labelClassName="text-gray-50" className="bg-green-700 border-0 hover:bg-green-800" onClick={() => updateAccepted(p.id, true)} label="Accepter opgave" />
															</div>
														}

														{p?.todoParticipantStatus?.solved &&
															<div className="grid grid-cols-2 h-7 gap-x-4">
																<KButton labelClassName="text-gray-50" className="w-full bg-green-700 border-0 hover:bg-green-800" label="Genåben opgave" onClick={() => updateSolved(p.id, false)} />
															</div>
														}

														{(todo?.todoParticipantStatus?.consent === assignmentConsentStatus.GIVEN.value || todo?.todoParticipantStatus?.consent === assignmentConsentStatus.NOT_REQUIRED.value)
															&& todo?.todoParticipantStatus?.accepted && !todo?.status.solved
															&& (todo?.todoParticipantStatus?.acceptedBy?.id === user?.data?.id || isLeader(user)) &&
															<div className="grid grid-cols-2 h-7 gap-x-4">
																<KButton labelClassName="text-gray-50" className="w-full bg-green-700 border-0 hover:bg-green-800" label="Genåben opgave" onClick={() => updateSolved(p.id, false)} />
															</div>
														}

														{p?.todoParticipantStatus?.consent === assignmentConsentStatus.NOT_REQUIRED.value
															&& p?.todoParticipantStatus?.accepted
															&& !p?.todoParticipantStatus.solved
															&& (p?.todoParticipantStatus?.acceptedBy?.id === user?.data?.id || isLeader(user)) &&
															<div className="grid grid-cols-2 h-7 gap-x-4">
																<KButton labelClassName="text-gray-50" className="w-full bg-green-700 border-0 hover:border-0 hover:bg-green-800" label="Løs opgave" onClick={() => updateSolved(p.id, true)} />
																<KButton labelClassName="text-gray-50" className="w-full bg-red-700 border-0 hover:bg-red-800" label="Fortryd opgave" onClick={() => updateAccepted(p.id, false)} />
															</div>
														}

													</div>
												}
											</div>
										)
									})}
								</KAccordion>
							)
						})}
						<div className="pt-2 border-t">
							<div className="mx-6 my-2">
								<div className="flex justify-between gap-x-4">
									<KButton onClick={() => setShowConfirmAcceptModal(true)} labelClassName="text-gray-50" className="bg-green-700 border-0 hover:border-0 hover:bg-green-800" label="Accepter opgave (for alle)" />
									<KButton onClick={() => setShowConfirmRegretModal(true)} labelClassName="text-gray-50" className="bg-red-700 border-0 hover:border-0 hover:bg-red-800" label="Fortryd opgave (for alle)" />
									<KButton onClick={() => setShowConfirmSolvedModal(true)} labelClassName="text-gray-50" className="bg-green-700 border-0 hover:border-0 hover:bg-green-800" label="Løs opgave (for alle)" />
									<KButton onClick={() => setShowConfirmReopenModal(true)} labelClassName="text-gray-50" className="bg-green-700 border-0 hover:border-0 hover:bg-green-800" label="Genåben opgave (for alle)" />
								</div>
								<p className="mt-2">Når du har oprettet den samme opgave til mere end en borger, kan du Acceptere, Fortryde, Løse eller Genåbne opgaven for alle borgerne på én gang. Hvis du vælger at bruge knapperne “for alle”, ændres status for alle borgere. Tidligere valg for en enkelt borger bliver også ændret til den nye status.</p>
							</div>

						</div>

					</div>

				</div>
				{todo?.files && todo?.files?.length > 0 &&
					<div className="mt-6 border border-gray-300 rounded">
						<div className="flex items-center justify-start h-10 p-2 ">
							<p>Vedhæftninger</p>
						</div>
						<div className="pb-4 pl-4 pr-4 border-t">
							{todo?.files && todo?.files?.length > 0 &&
								<div className="mt-2">
									<div className="grid grid-cols-1 mt-4 gap-x-2 gap-y-2">
										{todo?.files?.map(file => {
											return <a href={file?.url} without rel="noopener noreferrer" target="_blank"><KFileCard file={file} /></a>
										})}
									</div>
								</div>
							}

						</div>
					</div>
				}
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</div >
	)
}
