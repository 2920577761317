import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { classNames } from '../../utils/tailwindUtils'

import { useSelector } from 'react-redux';



export default function KSubmitSearchInput({ label, value, onSubmit, type, placeholder, autoFocus, clearOnFilterChange = true }) {

	const [search, setSearch] = useState();
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const globalFilter = useSelector((state) => state.globalFilter);

	const handleSearch = (e) => {
		setSearch(e.target.value)
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			setHasSubmitted(true)
			onSubmit(search)
		}
	}

	useEffect(() => {
		if (search == "") {
			if (hasSubmitted) {
				onSubmit(search)
				setHasSubmitted(false)
			}
		}
	}, [search])

	useEffect(() => {
		if (clearOnFilterChange) {
			setSearch("")
		}
	}, [globalFilter])


	return (
		<div>
			<div className="flex justify-between">
				<label className="block text-sm font-medium text-gray-700">
					{label}

				</label>
			</div>
			<div className="relative h-10 rounded-md shadow-sm">
				<input
					autoFocus={autoFocus}
					type='search'
					onChange={(e) => handleSearch(e)}
					value={search}
					onKeyDown={handleKeyDown}
					className={classNames(
						'block h-full w-full pt-2 pb-2 pl-10 bg-gray-100 rounded-md focus:ring-darkPurple focus:border-darkPurple sm:text-sm border',
					)}
					placeholder={placeholder}
					aria-invalid="true"
					aria-describedby={`${type}-error"`}
				/>
				<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
					<MagnifyingGlassCircleIcon className="w-4 h-4 text-gray-400" />
				</div>
			</div>
		</div>
	)

}
