import { useMutation, useQuery, client } from '@apollo/client'
import { TableCell, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import KButton from '../../../components/KButton'
import KDataGrid from '../../../components/KDataGrid'
import KPageWrapper from '../../../components/KPageWrapper'
import { GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID } from '../../../graphql/graphqlQueries'
import { administrationRoute, departmentRoute, createDepartmentRoute } from '../../../routes/mainRoutes'
import { departmentGroupHeaders } from '../../../utils/settingsUtils'
import moment from 'moment';
import { DELETE_MULTIPLE_DEPARTMENTS_BY_ID } from '../../../graphql/graphqlMutations'
import styled from 'styled-components';
import KModal from '../../../components/KModal'
import Colors from '../../../styles/Colors'

export default function DepartmentGroup({ history }) {
	const user = useSelector(state => state.user);

	const [departments, setDepartments] = useState([]);
	// const [ depsWithResidentsmz ] 
	const [showModal, setShowModal] = useState('')
	const [showErrorModal, setShowErrorModal] = useState('')

	const [deleteSelected, setDeleteSelected] = useState([]);

	const { data, loading, refetch } = useQuery(GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID,
		{ variables: { organizationId: parseInt(user?.data?.organization?.id) } })

	const [doDeleteDepartment, { loading: deleteLoading }] = useMutation(DELETE_MULTIPLE_DEPARTMENTS_BY_ID, {
		refetchQueries: [GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID]
	})

	useEffect(async () => {
		// refetch()
		setDepartments(data?.getAllDepartmentsByOrganizationId)
		refetch()
	}, [data])

	const handleDelete = async () => {
		const foundDepartmentsToDelete = departments?.filter(d => deleteSelected?.includes(d.name));

		await doDeleteDepartment({
			variables: {
				ids: foundDepartmentsToDelete?.map(e => e.id)
			}
		})
		setShowModal(false)
	}

	const handleOnDelete = (selected) => {
		setDeleteSelected(selected)
		if (displayDepsWithResidents(selected)) {
			setShowErrorModal(true)
		} else {
			setShowModal(true)
		}
	}

	const displayDepsWithResidents = (deleteSelected) => {
		const foundDepsToDelete = departments?.filter(department => deleteSelected?.includes(department.name));
		const depsWithResidents = foundDepsToDelete?.filter(d => d.residents.length)
		if (!depsWithResidents?.length) return ""
		const depNames = depsWithResidents.map(d => d.name)
		return `Følgende afsnit kan ikke slettes da de har en beboer: ${depNames.join(', ')}`
	}

	const RenderItem = ({ row }) => {

		return (
			<React.Fragment>
				<TableCell
					component="th"
					// id={labelId}
					scope="row"
					padding="none"
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
						{row?.name}
					</div>
				</TableCell>
				<TableCell align="right">{row?.residents?.length}</TableCell>

				<TableCell align="right">{row?.rooms?.length}</TableCell>
				<TableCell align="right">{moment(parseInt(row?.updatedAt)).format('DD-MM-YYYY HH:mm')}</TableCell>
			</React.Fragment>
		)
	}
	return (
		<KPageWrapper
			paths={[{
				title: 'Administration',
				route: administrationRoute
			}, {
				title: 'Afsnit'
			}]}
			buttons={[
				<KButton
					label="TILFØJ AFSNIT"
					onClick={() => { history.push(createDepartmentRoute) }}
				/>
			]}
			loading={[loading, deleteLoading].some(e => e === true)}
		>
			<div style={{ marginTop: 20 }}>
				<KModal
					open={showModal}
					title={"Slet afsnit"}
					subTitle={deleteSelected?.length > 1 ? "Er du sikker på at du vil slette de valgte afsnit?" : `Er du sikker på at du vil slette afsnittet?`}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						{<ModalButton fullWidth onClick={() => handleDelete()}>Slet</ModalButton>}
						<ModalButton fullWidth onClick={() => setShowModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>

				<KModal
					open={showErrorModal}
					title={"OBS!"}
					subTitle={deleteSelected.length && displayDepsWithResidents(deleteSelected)}
					handleChange={() => setShowErrorModal(false)}
				>
					<ModalContainer>
						<ModalButton fullWidth onClick={() => setShowErrorModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>

				{departments &&
					<KDataGrid
						headers={departmentGroupHeaders}
						rows={departments}
						withSearch={true}
						searchField="name"
						RenderItem={RenderItem}
						handleDelete={(s) => handleOnDelete(s)}
						onClick={(id) => history.push(departmentRoute(id))}
					/>
				}
			</div>
		</KPageWrapper >
	)
}

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,

		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))