import React from 'react'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import da from 'date-fns/locale/da';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import styled from 'styled-components';
import Colors from '../styles/Colors';
import { getCalendarColor, getRenderTitle } from '../utils/calendarUtils';
import { calendarActivityRoute } from '../routes/mainRoutes';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const locales = {
	'da': da,
}

const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales,
})

const getDifferenceInHours = (start, end) => {
	const startDate = moment(start)
	const endDate = moment(end);

	if (startDate.diff(endDate, 'hours', true) === 0) {
		return 'center'
	} else {
		return 'flex-start'
	}
}

export default function KCalendar({ activities, history, onDateRangeChange, currentView, onViewChange, loading }) {
	return (
		<StyledCalendar
			loading={loading}
			onView={(view) => onViewChange(view)}
			onRangeChange={(dates) => onDateRangeChange(dates)}
			selectable={false}
			localizer={localizer}
			events={activities}
			culture='da'
			startAccessor="start"
			defaultView={currentView}
			endAccessor="end"
			views={{
				month: true,
				day: true,
				week: true
			}}
			messages={{
				month: 'Måned',
				day: 'Dag',
				week: 'Uge',
				previous: 'Tilbage',
				next: 'Næste',
				today: '',
				showMore: total => `+${total} flere`
			}}
			rtl={false}
			popupOffset={100}
			components={{
				// toolbar: ToolBar,
				month: {
					event: ({ event }) => {
						return (
							<div onClick={() => !event?.type && history.push(calendarActivityRoute(event.id))} style={{ backgroundColor: getCalendarColor(event), paddingLeft: 5 }}>
								<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
									<p style={{ fontSize: 12 }}>{moment(event.start).format('HH:mm')}</p>
									<p style={{ fontSize: 15 }}>{getRenderTitle(event)}</p>
								</div>
							</div>
						)
					}
				},
				day: {
					event: ({ event }) => {
						const align = getDifferenceInHours(event.start, event.end)
						return (
							<div onClick={() => !event?.type && history.push(calendarActivityRoute(event.id))} style={{ backgroundColor: getCalendarColor(event), paddingLeft: 5, height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 2 }}>
								<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
									<p style={{ fontSize: 14 }}>{getRenderTitle(event)}</p>
								</div>
							</div>
						)
					}
				},
				week: {
					event: ({ event }) => {
						const align = getDifferenceInHours(event.start, event.end)
						return (
							<div onClick={() => !event?.type && history.push(calendarActivityRoute(event.id))} style={{ backgroundColor: getCalendarColor(event), paddingLeft: 5, height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 2 }}>
								<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
									<p style={{ fontSize: 10 }}>{getRenderTitle(event)}</p>
								</div>
							</div>
						)
					}
				}
				// event: Event,
				// agenda: {
				//   event: EventAgenda,
				// },
			}}
		/>
	)
}

const StyledCalendar = styled(Calendar)(({ loading }) => {
	const isLoading = loading.some(e => e === true);

	return {
		'& .rbc-header': {
			fontSize: 16,
			textTransform: 'uppercase',
			fontFamily: 'MetricBold',
			color: Colors.lightBlack,
			borderLeft: 'none',
			borderRight: 'none',
			borderBottom: 'none'
		},
		'& .rbc-toolbar': {
			height: 40,
			alignItems: 'flex-start'
		},
		'& .rbc-toolbar-label': {
			// visibility: 'hidden'
			fontFamily: 'MetricBold',
			textTransform: 'uppercase',
			fontSize: 12,
			color: Colors.lightBlack
		},
		'& .rbc-toolbar button.rbc-active': {
			borderBottom: `2px solid ${Colors.mainGreen} !important`,
			backgroundColor: '#fff',
			boxShadow: 'none',
		},
		'& .rbc-event': {
			backgroundColor: 'transparent',
		},
		'& .rbc-btn-group button': {
			padding: 0,
			borderBottom: 'none',
			borderLeft: 'none',
			borderTop: 'none',
			borderRight: 'none',
			marginRight: 10,
			marginLeft: 10,
			borderRadius: 0,
			fontSize: 12,
			textTransform: 'uppercase',
			'&:hover': {
				backgroundColor: 'transparent'
			},
			'&:active': {
				backgroundColor: 'transparent'
			}
			// border: 'none'
		},
		'& .rbc-date-cell > a': {
			// fontSize: '18px !important',
			fontSize: 18,
			fontFamily: 'MetricBold',
			color: Colors.lightBlack
		},
		'& .rbc-month-view': {
			borderTop: 'none',
			borderLeft: 'none',
			borderRight: 'none',
			opacity: isLoading ? 0.3 : 1
		},
		'& .rbc-day-slot': {
			padding: 0,
			'& .rbc-day-slot': {
				display: 'none'
			}
		},
		'& .rbc-time-view': {
			opacity: isLoading ? 0.3 : 1
		},
		'& .rbc-day-slot .rbc-event': {
			padding: 0,
			// border: ''
			border: `1px solid ${Colors.white}`
		},
		'& .rbc-day-slot .rbc-event-label': {
			display: 'none'
		}
	}
})
