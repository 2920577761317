import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { da } from 'date-fns/locale'

export default function KDatePicker({ label, onChange, value, error }) {
	console.log(error)
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={da}>
			<DatePicker
				error={error}
				label={label}
				value={value}
				onChange={(newValue) => {
					onChange(newValue);
				}}
				minDate={new Date()}
				inputFormat="dd-MM-yyyy"
				renderInput={(params) => <TextField fullWidth size="small" {...params} error={error} />}
			/>
		</LocalizationProvider>
	);
}