/* eslint-disable */
import React from 'react'
import Colors from '../../styles/Colors'

export default function KCalendarColorGuide() {

    const colorItem = (colorCode, text, key) => (
        <div className="flex flex-row items-center py-1 mr-4 text-sm" key={key}>
            <span style={{ backgroundColor: colorCode, }} className={`h-2 px-3 mr-1 rounded`}></span>
            {text}
        </div>
    )

    return (
        <div className='flex text-gray-400 px-7' >
            {items.map((item, i) => colorItem(item.color, item.text, i))}
        </div>
    )
}

const items = [
    {
        text: 'Deltager/Kræver ikke svar',
        color: Colors.green
    },
    {
        text: 'Afventer svar',
        color: Colors.yellow
    },
    {
        text: 'Pårørende-aktivitet',
        color: Colors.darkPurple
    },
    {
        text: 'Deltager ikke/Besøg ikke muligt',
        color: Colors.grey
    },
    {
        text: 'Frist overskredet',
        color: Colors.red
    }
]