import React, { useRef } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import Colors from "../styles/Colors";
import { isImageObj, isImageType } from "../utils/fileUtils";
import PDFIcon from '../assets/pdf-icon.png';
import { isValidURL } from "../utils/validators";
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import { maxHeight, maxWidth } from "@mui/system";

export default function KImageDrop({ handleDrop, files, height, onClear }) {
	const dropzoneRef = useRef();

	const openDialog = () => {
		dropzoneRef.current.open()
	};

	return (
		<div>
			<Dropzone
				noClick
				noKeyboard
				ref={dropzoneRef}
				onDrop={handleDrop}
			// accept="image/*"
			// minSize={1024}
			// maxSize={3072000}
			// maxFiles={1}
			>
				{({ getRootProps, getInputProps }) => {
					if (!files) {
						return (
							<DropZone onClick={() => openDialog()} style={{ height: height, cursor: 'pointer' }}>

								<div className="flex justify-center">

									<input {...getInputProps()} />
									<p >Placer billede, eller klik for at vælge</p>
								</div>

							</DropZone>
						)
					}

					return (
						<ImageZone className="rounded" style={{ height: height }} onClick={() => openDialog()} >
							{onClear &&
								<IconButton style={{ top: 0, right: 0, position: "absolute" }} onClick={(e) => {
									e?.stopPropagation();
									onClear()
								}} ><CancelIcon />
								</IconButton>}

							<div className="overflow-hidden">
								{files?.map(image => {
									console.log(image)
									/** Is url and image type */
									if (isValidURL(image?.obj) && isImageType(image?.obj)) {
										return <img style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} src={image?.obj} />
									}
									/** Is not url and is image type */
									if (!isValidURL(image?.obj) && isImageObj(image?.file)) {
										return <img style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} src={image?.obj} />
									}
									/** PDF */
									return <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={PDFIcon} />
								})}
							</div>
						</ImageZone>
					)
				}}
			</Dropzone>
		</div>
	);
}

const FileHeader = styled.p({
	fontFamily: 'MetricBold',
	color: Colors.lightBlack,
	fontSize: 12,
	marginTop: 10
})

const FileText = styled.li({
	fontFamily: 'MetricLight',
	fontSize: 10
})

const DropZone = styled.div({
	padding: '20px',
	border: '3px dashed #eeeeee',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
})

const ImageZone = styled.div({
	border: '3px dashed #eeeeee',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	overflow: 'hidden',
	cursor: 'pointer',
	// maxWidth: 100
})