import CancelIcon from '@mui/icons-material/Cancel';

export default function Alert({ messages = [] }) {
	const msgCount = typeof messages === 'string' ? 1 : messages.length;
	return (
		<div className="w-full p-4 mt-5 mb-4 rounded-md bg-red-50">
			<div className="flex">
				<div className="flex-shrink-0">
					<CancelIcon fontSize='small' className="text-red-400 " aria-hidden="true" />
				</div>
				<div className="ml-3">
					<h3 className="text-red-800 text-md">Fandt {msgCount} fejl:</h3>
					<div className="mt-2 text-red-700 text-md">
						<ul role="list" className="pl-5 space-y-1 list-disc">
							{typeof messages == "array" ?
								messages.map((message, index) => (
									<li key={index}>{message}</li>
								)) : <li>{messages}</li>}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}