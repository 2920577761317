import React, { useState } from 'react'
import KInput from '../../../components/inputs/KInput';
import KPhoneInput from '../../../components/inputs/KPhoneInput';
import KButton from '../../../components/KButton';
import KSelect from '../../../components/selectors/KSelect';
import { relationArray } from '../../../utils/relationUtil';
import { authenticationOptions, relativeClassifications, relativeInvitationTypes } from '../../../utils/residentUtils';
import _ from 'lodash';
import KSelectBox from '../../../components/selectors/KSelectBox';
import { softValidateNewRelative } from '../../../utils/validators/relativeValidators';
import { validate } from '../../../utils/validators/index';
import { useMutation } from '@apollo/client';
import { VALIDATE_EMAIL } from '../../../graphql/graphqlMutations';

export default function AddRelative({ relatives, setRelatives, setShowCreateRelativeModal }) {
	const [errors, setErrors] = useState();
	const [relative, setRelative] = useState({
		loginMethod: authenticationOptions[0],
		role: relatives?.some(e => e.role.id === relativeClassifications[0].id) ? relativeClassifications[1] : relativeClassifications[0],
		sendInvitation: true

	});

	const [validateEmail] = useMutation(VALIDATE_EMAIL)

	const updateRelative = async () => {
		if (!validate(
			softValidateNewRelative,
			{ relative, relatives },
			setErrors
		)) {
			return;
		}

		/** Validate if relative with email exists */
		if (relative?.email) {
			const { data } = await validateEmail({ variables: { email: relative?.email } })
			if (!data?.validateEmail?.valid) {
				return setErrors({
					email: 'Denne email er allerede i brug af en anden pårørende.'
				})
			}
		}

		const newRelatives = relatives?.concat(relative);
		setRelatives(newRelatives)
		setShowCreateRelativeModal(false)
	}

	const handleRelativeChange = (key, value) => {
		setRelative({
			...relative,
			[key]: value
		})
	}

	return (
		<div className="w-full">
			<div>
				<KSelect
					error={errors?.role}
					label="Klassifikation"
					required
					elements={relativeClassifications}
					handleSelect={(value) => handleRelativeChange('role', value)}
					selected={relativeClassifications?.find(e => e.id == relative?.role?.id)}
				/>
				<div className="grid grid-cols-2 mt-3 gap-y-3 gap-x-3">
					<KSelect
						label="Login metode"
						required
						elements={authenticationOptions}
						handleSelect={(value) => handleRelativeChange('loginMethod', value)}
						selected={authenticationOptions?.find(e => e.id === relative?.loginMethod?.id)}
					/>
					<KSelect
						label="Relation"
						selected={relationArray.find(e => e === relative?.relation)}
						elements={relationArray}
						handleSelect={(value) => handleRelativeChange('relation', value)}
					/>
					<KInput
						value={relative?.firstName}
						required
						fullWidth={true}
						label="Fornavn"
						onChange={(value) => handleRelativeChange('firstName', value)}
						error={errors?.firstName}
					/>

					<KInput
						value={relative?.lastName}
						required
						fullWidth={true}
						label="Efternavn"
						onChange={(value) => handleRelativeChange('lastName', value)}
						error={errors?.lastName}
					/>
					<KInput
						value={relative?.email}
						fullWidth={true}
						label="Email"
						onChange={(value) => handleRelativeChange('email', value)}
						error={errors?.email}
					/>
					<KPhoneInput
						showInfoText={relative?.loginMethod?.id === authenticationOptions[1].id}
						mobile={relative?.mobile}
						error={errors?.mobile}
						label="Mobilnummer"
						onChange={(value) => handleRelativeChange('mobile', value)}

					/>
				</div>

				<KSelectBox
					error={errors?.invitation}
					handleSelect={(e) => handleRelativeChange('sendInvitation', e.id === relativeInvitationTypes[0].id ? true : false)}
					className="sm:grid-cols-2"
					list={relativeInvitationTypes}
					selected={relative?.sendInvitation ? relativeInvitationTypes[0] : relativeInvitationTypes[1]}
				/>

				<div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
					<KButton onClick={() => updateRelative()} label="Tilføj pårørende" />
				</div>
			</div>

		</div>
	)
}
