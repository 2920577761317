import { createSlice } from "@reduxjs/toolkit";


export const makeGFQueryCache = (
	data = [],
	page = null,
	scrollPosition = null,
	filter = null,
	key = null
) => {
	return {
		data,
		page,
		scrollPosition,
		filter,
		key
	}
}

const initialState = makeGFQueryCache();

export const globalFilterSlice = createSlice({
	name: "globalFilterQuery",
	initialState: {
		news: initialState,
	},
	reducers: {
		setState: (state, action) => {
			const { data, key, page, scrollPosition, filter } = action.payload;
			state[key] = {
				...state[key],
				data: data,
				page: page,
				scrollPosition: scrollPosition,
				filter: filter
			};

		},
		clearState: (state, action) => {
			state[action.payload.key] = initialState;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setState, clearState } = globalFilterSlice.actions;

export default globalFilterSlice.reducer;