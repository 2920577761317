import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { FormLabel } from '@mui/material'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled from 'styled-components'
import da from '../../utils/countryTranslations.json'

export default function KPhoneInput({ label, mobile, onChange, error, disabled, showInfoText }) {

	const handleChange = (value, country, e, formattedValue) => {
		const isEmpty = formattedValue === `+${country?.dialCode}`;

		const newValue = {
			country,
			value: isEmpty ? null : formattedValue,
			isEmpty
		}

		console.log(newValue)

		onChange(newValue)

	}

	return (
		<div style={{ position: 'relative' }}>
			<label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-700">
				{label}
			</label>
			<StyledPhoneInput
				placeholder=''
				disabled={disabled}
				error={error}
				countryCodeEditable={false}
				enableSearch
				country={mobile?.country?.countryCode || 'dk'}
				searchPlaceholder="Søg"
				value={mobile?.value}
				disableSearchIcon
				searchNotFound="Ingen fundet"
				localization={da}
				onChange={(value, country, e, formattedValue) => handleChange(value, country, e, formattedValue)}
				inputStyle={{
					height: 38,
					border: error ? '1px solid #d32f2f' : '',
					width: '100%',
					backgroundColor: '#f3f4f6d9'
				}}
			/>
			{showInfoText && <p className="mt-1 ml-1 text-xs text-gray-600">Obs! Der sendes en bekræftelses-kode til nummeret, så kontroller gerne en ekstra gang, at det er korrekt indtastet.</p>}
			{error &&
				<div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none -top-5">
					<ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
				</div>
			}
			{error &&
				<p className="mt-1 ml-1 text-sm text-red-600" id="email-error">
					{error}
				</p>
			}
		</div>
	)
}

const StyledPhoneInput = styled(PhoneInput)(({ error }) => ({
	'& .flag-dropdown': {
		height: 38,
		border: error ? '1px solid #d32f2f' : '',
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5
	},
	'& .react-tel-input .form-control': {
		width: '100%',
	}
}))
