import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import KSection from '../../components/cards/KSection'
import KInput from '../../components/inputs/KInput'
import KTextArea from '../../components/inputs/KTextArea'
import KButton from '../../components/KButton'
import KPageWrapper from '../../components/KPageWrapper'
import { CREATE_NEWS } from '../../graphql/graphqlMutations'
import { newsRoute } from '../../routes/mainRoutes'
import { validateNewNews } from '../../utils/validators/newsValidator'
import Story from './Story'
import KModal from '../../components/modals/KModal'
import KConfirmModal from '../../components/modals/KConfirmModal'
import { useFileUtils } from '../../graphql/useFileUtils';
import KImageForm from '../../components/forms/KImageForm'
import KFileForm from '../../components/forms/KFileForm'
import KRecipientForm from '../../components/forms/KRecipientForm'
import { alertWarningMessages } from '../../utils/globalTypes'
import KSnackbar from '../../components/KSnackbar'

import { clearState } from '../../redux/slices/globalFilterQuerySlice'
import { newsKey } from '../../redux/reduxUtils'

export default function CreateNews({ history }) {
	const user = useSelector(state => state.user);

	const { createFiles, createImages } = useFileUtils();

	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [images, setImages] = useState();
	const [files, setFiles] = useState();
	const [selectedResidents, setSelectedResidents] = useState([]);
	const [taggedResidents, setTaggedResidents] = useState([]);

	const [showAddModal, setShowAddModal] = useState(false)
	const [showPreview, setShowPreview] = useState(false)

	const [errors, setErrors] = useState();
	const [createLoading, setCreateLoading] = useState(false);

	const [message, setMessage] = useState({ msg: "", type: "" });

	const [createNews] = useMutation(CREATE_NEWS);

	const dispatch = useDispatch();


	const makeNews = async () => {
		setCreateLoading(true)

		try {
			const imgs = await createImages(images?.map(e => e.file))
			const fls = await createFiles(files?.map(e => e.file))

			await createNews({
				variables: {
					input: {
						title: title,
						description: description,
						imageUrl: null,
						organizationId: parseInt(user.data.organization.id),
						creatorId: parseInt(user.data.id),
						departmentId: 1, // TODO
						newsReceivers: selectedResidents?.map(r => r.id),
						images: imgs,
						files: fls
					},
				},
			});
			dispatch(clearState({ key: newsKey }));

			history.push(newsRoute)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		} finally {
			setCreateLoading(false)
		}

	};


	const postNews = async () => {
		const validated = validateNewNews({ title, description, selectedResidents });

		if (!validated.success) {
			setShowAddModal(false)
			return setErrors(validated.errors)
		}
		makeNews()
	};

	const handleChange = (key, setter, value) => {
		setErrors({
			...errors,
			[key]: null
		});

		setter(value)
	}

	const TopBar = () => (
		<div className="flex items-center justify-end h-16 px-6 bg-white border-b">
			<div className="flex gap-x-4">
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton disabledText="Nyheden kan ikke vises, før alle påkrævet felter er udfyldt." disabled={!title || !description || selectedResidents?.length === 0} className="min-w-full" label="FORHÅNDVISNING" onClick={() => setShowPreview(true)} />
				</div>
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton className="min-w-full" label="OPRET NYHED" onClick={() => setShowAddModal(true)} />
				</div>
			</div>

		</div>
	)

	return (
		<KPageWrapper
			title="Opret nyhed"
			paths={[{
				title: 'NYHEDER',
				route: newsRoute
			}, {
				title: 'Opret nyhed',
			}]}
			loading={[createLoading]?.some(e => e === true)}
			contentContainerStyle={{ padding: 0 }}
			TopBar={TopBar}
		>
			<div className="p-6">
				<KModal
					open={showPreview}
					setOpen={() => setShowPreview(!showPreview)}
				>
					<div className="p-10">
						<Story
							defaultNews={{
								title,
								description,
								creator: user.data,
								createdAt: new Date().valueOf(),
								images: images ? images?.map(e => ({ url: e?.obj })) : null,
								newsReceivers: selectedResidents?.map(r => ({ resident: r })),
								files: files ? files : null,
							}}
							isPreview={true}
						/>
					</div>
				</KModal>
				<KConfirmModal
					title="Opret nyhed"
					confirmText={"OPRET NYHED"}
					description={"Er du sikker på du vil oprette nyheden?"}
					open={showAddModal}
					setOpen={setShowAddModal}
					handleConfirm={() => postNews()}
					loading={createLoading}
				/>
				<div className="grid grid-cols-1 gap-x-4">
					<div className="grid grid-cols-1 pb-6 cols-span-2 gap-y-6">
						<KSection
							title="Oplysninger"
						>
							<div className="grid grid-cols-1 gap-y-3">
								<KInput
									error={errors?.title}
									value={title}
									label="Titel"
									required
									onChange={(value) => handleChange('title', setTitle, value)}
								/>
								<KTextArea
									error={errors?.description}
									value={description}
									label="Beskrivelse"
									required
									onChange={(value) => handleChange('description', setDescription, value)}
									multiline
									rows={5}
								/>
							</div>
						</KSection>
						<KRecipientForm
							selectedResidents={selectedResidents}
							setSelectedResidents={setSelectedResidents}
							errors={errors}
							setErrors={setErrors}
						/>
						<KImageForm
							images={images}
							setImages={setImages}
							taggedResidents={taggedResidents}
							setTaggedResidents={setTaggedResidents}
							selectedResidents={selectedResidents}
						/>
						<KFileForm
							files={files}
							setFiles={setFiles}
						/>
					</div>
				</div>
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KPageWrapper>
	)
}
