export default {
	darkPurple: "#7D0FBE",
	purple: "#AF52DE",
	lightPurple: "#a761d3",
	buttonsGradient: ["#964FC0", "#523166"],
	grey: "#b3b1b1",
	lightGrey: "#f1f1f1",
	darkGrey: "#9e9d9d",
	white: "white",
	white2: ["white", "white"],
	red: "#D2222D",
	redB: ["#8A0707", "#8b0000"],
	yellow: "#FFBF00",
	green: "#238823",
	greenB: ["#006400", "#355e3b"],
	customTitleBlack: "#3F414E",
	customDescriptionGrey: "#A1A4B2",
	black: ["black", "black"],
	main: "#7D0FBE",
	gradient1: ["#7D0FBE", "#471465"],
	lightGreen: "#00FF47",
	buttonsGradientWhite: ["#fff", "#F6F4F4"],
	mainGreen: '#238823',
	lightBlack: '#606060',
	mediumBlack: '#4a4a4a',
	tableLight: '#f8f8f8',
	tableDark: '#ececec',
	mainGrey: '#f7f7f7',
	success: '#3f8f39',

};
