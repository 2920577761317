export const worker = "worker";
export const relative = "relative";

export const isWorker = (user) =>
	user?.data?.role?.id === "3" || user?.data?.role?.id === "4" ? false : true;

export const isRelative = (user) =>
	user?.data?.role?.id === "3" || user?.data?.role?.id === "4" ? true : false;

export const isPrimary = (user) =>
	user.data.role.id === "3" ? true : false;

export const isPrimaryNoData = (user) =>
	user?.role?.id === "3" ? true : false;

export const isSecondary = (user) =>
	user.data.role.id === "4" ? true : false;

export const getRoleTypeName = (user) =>
	user?.role?.id == "3" ? 'Primær pårørende' : 'Sekundær pårørende';

export const getRoleTypeNameByRoleId = (user) =>
	user.roleId == "3" ? 'Primær pårørende' : 'Sekundær pårørende';

export const isRelativeNoData = (user) =>
	user?.role?.id === "3" || user?.role?.id === "4" ? true : false;

export const isLeader = (user) => {
	return user?.data?.role?.id === "1" ? true : false;
}

export const isWorkerNoData = (user) => {
	return user?.role?.id === "3" || user?.role?.id === "4" ? false : true;
}

export const canEditAndDelete = (user, creator) => {
	if (isLeader(user) || user?.data?.role?.id == '5' && isWorkerNoData(creator)) {
		return true;
	} else if (user?.data?.id === creator?.id) {
		return true;
	} else {
		return false;
	}
}