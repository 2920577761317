import { gql } from "@apollo/client";

export const GET_USER_BY_CPR = gql`
	query Query($cpr: String!) {
		getUserByCpr(cpr: $cpr) {
			id
			pin
		}
	}
`;

export const GET_NEWS_BY_ID = gql`
	query Query($id: ID!) {
		getNewsById(id: $id) {
			id
			title
			imageUrl
			updatedAt
			createdAt
			description
			comments {
				message
				user {
					imageUrl
					firstName
					lastName
					relatives {
						resident {
							firstName
							lastName
						}
					}
					role {
						id
					}
				}
				createdAt
			}
			likes {
				userId
			}
			creator {
				id
				imageUrl
				firstName
				lastName
				fullName
				role {
					id
				}
				relatives {
					resident {
					firstName
					lastName

					}
				}
			}
			newsReceivers {
				residentId
				resident {
					fullName
				}
			}
			images {
				id
				url
				filename
				key
			}
			files {
				id
				url
				filename
				key
				type
			}
		}
	}
`;


export const GET_NEWS_RECEIVERS_BY_ID = gql`
query GetNewsReceiversByNewsId($id: ID!) {
	getNewsReceiversByNewsId(id: $id) {
	  newsId
	  resident {
			fullName
			imageUrl
			room {
				id
				department {
					id
					name
				}
			}
		}
	}
  }
`;


export const GET_ASSIGNMENT_BY_ID = gql`
	query Query($id: ID!) {
		getTodoById(id: $id) {
			id
			deadline
			description
			title
			residentId
			creatorId
			imageUrl
			createdAt
			images {
				id
				url
				filename
				key
			}
			files {
				id
				url
				filename
				key
				type
			}
			creator {
				id
				imageUrl
				firstName
				lastName
				role {
					id
				}
			}
			participants {
				id
				todoId
				residentId
				resident {
					id
					fullName
					imageUrl
					room {
						department {
							name
						}
					}
				}
				todoParticipantStatus {
					accepted
					solved
					consent
					acceptedBy {
						id
						firstName
						lastName
					}
					solvedBy {
						id
						firstName
						lastName
					}
					consentBy {
						id
						firstName
						lastName
					}
				}
			}
			# resident {
			# 	id
			# 	fullName
			# 	imageUrl
			# 	room {
			# 		id
			# 		name
			# 		department {
			# 			id
			# 			name
			# 		}
			# 	}
			# }
			responsible
			consentRequired
		}
	}
`;
export const GET_ALL_NEWS_BY_DEPARTMENT_ID = gql`
	query Query($page: Int, $size: Int, $order: [String], $id: ID!, $search: String) {
		getAllNewsByDepartmentId(
			page: $page
			size: $size
			order: $order
			id: $id,
			search: $search
		) {
			results {
				id
				description
				title
				organizationId
				comments {
					id
					message
					createdAt
					user {
						role {
							id
						}
						imageUrl
						fullName
						relatives {
							resident {
								fullName
								organizationId
							}
						}
					}
				}
				likes {
					id
					userId
					user {
						fullName
						imageUrl
						role {
							id
						}
						relatives {
							resident {
								fullName
								organizationId
							}
						}
					}
				}
				imageUrl
				updatedAt
				createdAt
				creator {
					id
					fullName
				}
				# newsReceivers {
				# 	residentId
				# 	resident {
				# 		fullName
				# 	}

				# }
				images {
					url
					filename
					key
				}
				files {
					url
					filename
					key
				}
			}
			pageInfo {
				hasNextPage
				totalItems
				totalPages
				currentPage
				hasPreviousPage
			}
		}
	}
`;

export const GET_ALL_NEWS_BY_ORGANIZATION_ID = gql`
	query Query($page: Int, $size: Int, $order: [String], $id: ID!, $search: String) {
		getAllNewsByOrganizationId(
			page: $page
			size: $size
			order: $order
			id: $id
			search: $search
		) {
			results {
				id
				description
				title
				organizationId
				comments {
					id
					message
					createdAt
					user {
						role {
							id
						}
						imageUrl
						fullName
						relatives {
							resident {
								fullName
								organizationId
							}
						}
					}
				}
				likes {
					id
					userId
					user {
						fullName
						imageUrl
						role {
							id
						}
						relatives {
							resident {
								fullName
								organizationId
							}
						}
					}
				}
				imageUrl
				updatedAt
				createdAt
				creator {
					id
					fullName
					role {
						id
					}
				}
				# newsReceivers {
				# 	residentId
				# 	resident {
				# 		fullName
				# 	}

				# }
				images {
					url
					filename
					key
				}
				files {
					url
					filename
					key
				}
			}
			pageInfo {
				hasNextPage
				totalItems
				totalPages
				currentPage
				hasPreviousPage
			}
		}
	}
`;

export const GET_ALL_NEWS_BY_RESIDNT_ID = gql`
	query Query($id: ID!, $page: Int, $size: Int, $order: [String]) {
		getAllNewsByResidentId(
			id: $id
			page: $page
			size: $size
			order: $order
		) {
			results {
				id
				description
				title
				comments {
					id
					message
					createdAt
					user {
						role {
							id
						}
						imageUrl
						fullName
						relatives {
							resident {
								fullName
								organizationId
							}
						}
					}
				}
				likes {
					id
					userId
					user {
						fullName
						imageUrl
						role {
							id
						}
						relatives {
							resident {
								fullName
								organizationId
							}
						}
					}
				}
				imageUrl
				updatedAt
				createdAt
				creator {
					id
					fullName
				}
				newsReceivers {
					residentId
					resident {
						fullName
					}

				}
				images {
					url
					filename
					key
				}
				files {
					url
					filename
					key
				}
			}
			pageInfo {
				hasNextPage
				totalItems
				totalPages
				currentPage
				hasPreviousPage
			}
		}
	}
`;

export const GET_USER_BY_ID = gql`
	query Query($getUserByIdId: ID!) {
		getUserById(id: $getUserByIdId) {
			firstName
			lastName
			mobile
			email
		}
	}
`;

export const GET_USER_BY_EMAIL = gql`
	query Query($email: String!) {
		getUserByEmail(email: $email) {
			id
			firstName
			lastName
			fullName
			mobile
			email
			imageUrl
			active
			invitationSend
			relatives {
				relation
				user {
					firstName
					lastName
					mobile
					imageUrl
				}
				resident {
					firstName
					lastName
					mobile
				}

			}
		}
	}
`;

export const CHECK_IF_USERS_EXISTS_BY_EMAIL = gql`
	query Query($emails: [String!]!) {
		checkIfUsersExistByEmail(emails: $emails) {
			id
			firstName
			lastName
			fullName
			mobile
			email
			imageUrl
			active
			invitationSend
			organizationId
		}
	}
`;


export const GET_RELATIVE_BY_EMAIL_AND_ORG_ID = gql`
	query Query($email: String!, $orgId: String!) {
		getRelativeByEmailAndOrgId(email: $email, orgId: $orgId) {
			id
			firstName
			lastName
			fullName
			mobile
			email
			imageUrl
			active
			invitationSend
			loginMethod
		}
	}
`;

export const GET_RELATIVE_BY_EMAIL = gql`
	query Query($email: String!) {
		getRelativeByEmail(email: $email) {
			id
			firstName
			lastName
			fullName
			mobile
			email
			imageUrl
			active
			invitationSend
			loginMethod
		}
	}
`;

export const GET_ALL_RESIDENTS_BY_ORGANIZATION_ID = gql`
	query Query($id: ID!, $page: Int, $size: Int, $order: [[String]], $exclude: [String], $search: String, $filters: [String]) {
		getAllResidentsByOrganizationId(
			id: $id
			page: $page
			size: $size
			order: $order
			exclude: $exclude
			search: $search
			filters: $filters
		) {
			results {
				id
				firstName
				middleName
				lastName
				imageUrl
				gender
				cpr
				birthDate
				fullName
				status
				createdAt
				residentConsent {
					useStatus
				}
				relatives {
					user {
						firstName
						lastName
						invitationSend
						role {
							id
						}
						active
						invitationSend
					}
				}
				room {
					name
					id
					department {
						id
						name
					}
				}
				deletedAt
			}
			pageInfo {
				hasNextPage
				totalItems
				totalPages
				currentPage
				hasPreviousPage
			}
		}
	}
`;

export const GET_ALL_RESIDENTS_BY_ORGANIZATION_ID_WITH_DELETED = gql`
	query Query($id: ID!, $page: Int, $size: Int, $order: [String]) {
		getAllResidentsByOrganizationIdWithDeleted(
			id: $id
			page: $page
			size: $size
			order: $order
		) {
			results {
				id
				cpr
				deletedAt
			}
		}
	}
`;

export const GET_ALL_AWAITNG_RESIDENTS_BY_ORGANIZATION_ID = gql`
	query Query($id: ID!) {
		getAllAwaitingResidentsByOrganizationId(
			id: $id
		) {
			id
			firstName
			middleName
			lastName
			fullName
			imageUrl
			gender
			cpr
			birthDate
			room {
				name
				department {
					name
				}
			}
		}
	}
`;

export const GET_ALL_RESIDENTS_BY_DEPARTMENT_ID = gql`
	query Query($id: ID!, $page: Int, $size: Int, $order: [[String]], $search: String, $filters: [String]) {
		getAllResidentsByDepartmentId(
			id: $id
			page: $page
			size: $size
			order: $order,
			search: $search
			filters: $filters
		) {
			results {
				id
				firstName
				middleName
				lastName
				imageUrl
				gender
				cpr
				birthDate
				fullName
				createdAt
				status
				relatives {
					user {
						firstName
						lastName
						invitationSend
						role {
							id
						}
						active
						invitationSend
					}
				}
				room {
					name
					department {
						name
					}
				}
			}
			pageInfo {
				hasNextPage
				totalItems
				totalPages
				currentPage
				hasPreviousPage
			}
		}
	}
`;

export const GET_ALL_RESIDENTS_INCLUDING_ROOM_BY_ORGANIZATION_ID = gql`
	query Query(
		$organizationId: ID!
		$page: Int
		$size: Int
		$order: [[String]]
		$filters: [String]
	) {
		getAllResidentsByOrganizationId(
			id: $organizationId
			page: $page
			size: $size
			order: $order
			filters: $filters
		) {
			results {
				id
				firstName
				lastName
				imageUrl
				middleName
				room {
					departmentId
				}
			}
		}
	}
`;

export const GET_ALL_RESIDENTS_BY_ORGANIZATION_ID_INCLUDING_CONSENT = gql`
	query Query($id: ID!, $page: Int, $size: Int, $order: [String]) {
		getAllResidentsByOrganizationId(
			id: $id
			page: $page
			size: $size
			order: $order
		) {
			results {
				id
				firstName
				lastName
				imageUrl
				middleName
				residentConsent {
					useStatus
				}
			}
		}
	}
`;

export const GET_RESIDENT_BY_ID = gql`
	query Query($id: ID!) {
		getResidentById(id: $id) {
			id
				externalId
				birthDate
				cpr
				email
				firstName
				middleName
				lastName
				gender
				mobile
				phone
				imageUrl
				questions
				createdAt
				movedIn
				fullName
				status
				residentConsent {
					inviteStatus
				}
				room {
					id
					name
					departmentId
					resident {
						id
					}
					department {
						name
						id
					}
				}
				interests {
					title
					id
				}
				relatives {
					relation
					user {
						id
						firstName
						lastName
						mobile
						email
						roleId
						imageUrl
						organizationId
						active
						lastActive
						createdAt
						invitationSend
						loginMethod
						roleId
						fullName
						sendInvitation
						role {
							id
						}
					}
				}
		}
	}
`;

export const GET_RELATIVES_FOR_RESIDENT = gql`
	query Query($residentId: ID!) {
		getResidentById(id: $residentId) {
			relatives {
				user {
					firstName
					lastName
					imageUrl
				}
			}
		}
	}
`;

export const GET_ALL_INTEREST_FOR_RESIDENT = gql`
	query Query($residentId: Int!) {
		getAllInterestsForResident(residentId: $residentId) {
			interest {
				title
				id
			}
		}
	}
`;

export const GET_ALL_INTERESTS = gql`
	query Query {
		getAllInterests {
			id
			title
			residents {
				firstName
				lastName
			}
			users {
				firstName
				lastName
			}		
		}
	}
`;

export const GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID = gql`
	query Query($organizationId: ID!) {
		getAllDepartmentsByOrganizationId(id: $organizationId) {
			id
			name
			updatedAt
			rooms {
				id
				name
				resident {
					firstName
				}
			}
			residents {
				id
			}
		}
	}
`;

export const GET_DEPARTMENT_BY_ID = gql`
	query Query($id: ID!) {
		getDepartmentById(id: $id) {
			id
			name
			updatedAt
			residents {
				firstName
			}
		}
	}
`;

export const GET_ALL_CALENDAR_ACTIVITIES_BY_ORGANIZATION_ID = gql`
	query Query($id: ID!) {
		getAllCalendarActivitiesByOrganizationId(id: $id) {
			results {
				title
				description
				startDate
				endDate
			}
		}
	}
`;

export const GET_RESIDENT_CONSENT_STATUS_BY_RESIDENT_ID = gql`
	query Query($id: ID!) {
		getResidentConsentById(id: $id) {
			useStatus
			websiteStatus
			newsStatus
			inviteStatus
			infoStatus
		}
	}
`;

export const GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_ORGANIZATION_ID = gql`
	query Query($id: ID!, $dateRange: [String], $order: [String], $size: Int) {
		getAllCalendarActivitiesByOrganizationId(
			id: $id
			dateRange: $dateRange
			order: $order
			size: $size
		) {
			results {
				creatorId
				id
				title
				description
				startDate
				endDate
				actionRequired
				calendarStatus {
					status
				}
			}
		}
	}
`;

export const GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_DEPARTMENT_ID = gql`
	query Query($id: ID!, $dateRange: [String], $order: [String], $size: Int) {
		getAllCalendarActivitiesByDepartmentId(
			id: $id
			dateRange: $dateRange
			order: $order
			size: $size
		) {
			results {
				creatorId
				id
				title
				description
				startDate
				endDate
				actionRequired
				calendarStatus {
					status
				}
			}
		}
	}
`;

export const GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_RESIDENT_ID = gql`
	query Query($id: ID!, $dateRange: [String], $order: [String], $size: Int) {
		getAllCalendarActivitiesByResidentId(
			id: $id
			dateRange: $dateRange
			order: $order
			size: $size
		) {
			results {
				creatorId
				id
				title
				description
				startDate
				endDate
				actionRequired
				kmdId
				calendarStatus {
					status
				}
			}
		}
	}
`;


export const GET_ALL_NOTIFICATIONS_BY_RESIDENT_ID = gql`
	query Query($page: Int, $size: Int, $order: [String], $id: ID!) {
		getAllNotificationsByResidentId(
			page: $page
			size: $size
			order: $order
			id: $id
		) {
			results {
				id
				title
				body
				residentId
				userId
				type
				referenceId
				updatedAt
			}
			pageInfo {
				hasNextPage
				totalItems
				totalPages
				currentPage
				hasPreviousPage
			}
		}
	}
`;

export const GET_CALENDAR_ACTIVITY_BY_ID = gql`
	query Query($id: ID!) {
		getCalendarActivityById(id: $id) {
			id
			actionRequired
			creator {
				id
				firstName
				lastName
				role {
					id
				}
			}
			description
			organizationId
			secondReminder
			firstReminder
			title
			startDate
			endDate
			kmdId
			imageUrl
			repetitionId
			repetitionDate
			repetitionType
			withRepetition
			note
			isDraft
			files {
				id
				filename
				key
				url
			}
			images {
				id
				filename
				key
				url
			}
			participants {
				calendarActivityId
				relativeStatus {
					attendingStatus
					participantId
					extraParticipants
					userId
					user {
						fullName
						imageUrl
					}
				}
				resident {
					id
					fullName
				}
			}
			calendarStatus {
				status
			}
			createdAt
		}
	}
`;

export const GET_ALL_ROOMS_BY_ORGANZATION_ID = gql`
	query GetAllRoomsByOrganizationId($id: Int!) {
		getAllRoomsByOrganizationId(id: $id) {
			id
			name
			departmentId
			updatedAt
			department {
				id
				name 
			}
			resident {
				id
				firstName
				lastName
				phone
			}

		}
	}
`;


export const GET_ALL_RESIDENT_DOCUMENTS_BY_RESIDENT_ID = gql`
	query GetAllResidentDocumentsByResidentId($residentId: ID!, $folderId: ID, $order: [String!]) {
	getAllResidentDocumentsByResidentId(residentId: $residentId, folderId: $folderId, order: $order) {
			name
			url
			id
			parentId
			residentId
			parent {
				name
				id
			}
			createdAt
			updatedAt
		}
	}
`;


export const GET_RESIDENT_DOCUMENT_BY_ID = gql`
query GetResidentDocumentById($id: ID!) {
	getResidentDocumentById(id: $id) {
			name
			url
			id
			parentId
			residentId

			parent {
				name
				id
			}
			createdAt
			updatedAt
		}
	}
`;


export const GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID = gql`
	query GetAllResidentDocumentFoldersByResidentId($residentId: ID!, $folderId: ID, $order: [String!]) {
	getAllResidentDocumentFoldersByResidentId(residentId: $residentId, folderId: $folderId, order: $order) {
		name
		id
		documents {
			name
			url 
			id
			createdAt
			updatedAt
		}
		parentId
		parent {
			name
			id
		}
		createdAt
		updatedAt
		residentId
		children {
			id
			name
			parentId
			createdAt
			updatedAt
			children {
				id
				name
				parentId
			}
			parent {
				name
				id
			}

		}
	}
	}
`;

export const GET_RESIDENT_DOCUMENT_FOLDER_BY_ID = gql`
query GetResidentDocumentFolderById($id: ID!) {
	getResidentDocumentFolderById(id: $id) {
		id
		name
		residentId
		parentId
		createdAt
		updatedAt
		documents {
			name
			url 
			id
			createdAt
			updatedAt
			}
		parent {
			id
			name
		}
		children {
			id
			name
			parentId
			createdAt
			updatedAt
			children {
				createdAt
				updatedAt
				id
				name
				parentId
			}
		}
	}
	}
`;

export const GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID = gql`
	query GetAllOrganizationDocumentsByOrganizationId($organizationId: ID!, $folderId: ID, $order: [String!]) {
	getAllOrganizationDocumentsByOrganizationId(organizationId: $organizationId, folderId: $folderId, order: $order) {
			name
			url
			id
			parentId
			organizationId
			parent {
				name
				id
			}
			createdAt
			updatedAt
		}
	}
`;


export const GET_ORGANIZATION_DOCUMENT_BY_ID = gql`
query GetOrganizationDocumentById($id: ID!) {
	getOrganizationDocumentById(id: $id) {
			name
			url
			id
			parentId
			organizationId

			parent {
				name
				id
			}
			createdAt
			updatedAt
		}
	}
`;


export const GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID = gql`
	query GetAllOrganizationDocumentFoldersByOrganizationId($organizationId: ID!, $folderId: ID, $order: [String!]) {
	getAllOrganizationDocumentFoldersByOrganizationId(organizationId: $organizationId, folderId: $folderId, order: $order) {
		name
		id
		documents {
			name
			url 
			id
			createdAt
			updatedAt
		}
		parentId
		parent {
			name
			id
		}
		createdAt
		updatedAt
		organizationId
		children {
			id
			name
			parentId
			createdAt
			updatedAt
			children {
				id
				name
				parentId
			}
			parent {
				name
				id
			}

		}
	}
	}
`;

export const GET_ORGANIZATION_DOCUMENT_FOLDER_BY_ID = gql`
query GetOrganizationDocumentFolderById($id: ID!) {
	getOrganizationDocumentFolderById(id: $id) {
		id
		name
		organizationId
		parentId
		createdAt
		updatedAt
		documents {
			name
			url 
			id
			createdAt
			updatedAt
			}
		parent {
			id
			name
		}
		children {
			id
			name
			parentId
			createdAt
			updatedAt
			children {
				createdAt
				updatedAt
				id
				name
				parentId
			}
		}
	}
	}
`;

export const GET_ALL_ROOMS_BY_DEPARTMENT_ID = gql`
	query Query($id: ID!, $page: Int, $size: Int, $order: [String]) {
		getAllRoomsByDepartmentId(
			id: $id
			page: $page
			size: $size
			order: $order
		) { 
		results {
			id
			name
			departmentId
			updatedAt
			department {
				id
				name 
			}
			resident {
				id
				firstName
				lastName
				phone
			}
		}
		pageInfo {
				hasNextPage
				totalItems
				totalPages
				currentPage
				hasPreviousPage
			}
		}
	}
	
`;


export const GET_ROOM_BY_ID = gql`
	query GetRoomById($id: ID!) {
		getRoomById(id: $id) {
			id
			name
			departmentId
			updatedAt
			department {
				id
				name 
			}
			resident {
				id
				firstName
				lastName
				phone
				imageUrl
				birthDate
				cpr
				gender
			}
		}
	}`

// export const GET_ALL_DEPARTMENTS_BY_ORGANZATION_ID = gql`
// 	query GetAllDepartmentsByOrganizationId($organizationId: ID!) {
// 	getAllDepartmentsByOrganizationId(organizationId: $id) {
// 		id
// 		name
// 		residents {
// 			fullName
// 		}
// 	}
// }
// `;




export const GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID = gql`
	query Query($page: Int, $size: Int, $order: [String], $id: ID!) {
	getAllTodosByOrganizationId(
		page: $page
			size: $size
			order: $order
			id: $id
	) {
			results {
				title
				description
				deadline
				id
				createdAt
				creator {
					id
					fullName
					imageUrl
						role {
						id
					}
				}
				resident {
					fullName
					imageUrl
				}
				participants {
					id
					todoId
					residentId
					resident {
						id
						fullName
						room {
							department {
								id
								name
							}
						}
					}
					todoParticipantStatus {
						accepted
						solved
						consent
						acceptedBy {
							id
							firstName
							lastName
						}
						solvedBy {
							id
							firstName
							lastName
						}
						consentBy {
							id
							firstName
							lastName
						}
					}
				}
				imageUrl
				status {
					todoId
					accepted
					solved
					consent
					consentBy {
						id
						firstName
						lastName
					}
					acceptedBy {
						id
						firstName
						lastName
					}
					solvedBy {
						id
						firstName
						lastName
					}
				}
				creatorId
				responsible
				consentRequired
				files {
					id
					filename
					key
					url
				}
				images {
					id
					filename
					key
					url
				}
			}
			pageInfo {
				hasNextPage
			}
		}
}
`;

export const GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID = gql`
	query Query($page: Int, $size: Int, $order: [String], $id: ID!) {
	getAllTodosByDepartmentId(
		page: $page
			size: $size
			order: $order
			id: $id
	) {
			results {
				title
				description
				deadline
				id
				createdAt
				creator {
					id
					fullName
					imageUrl
						role {
						id
					}
				}
				resident {
					fullName
					imageUrl
				}
				imageUrl
				status {
					todoId
					accepted
					solved
					consent
					consentBy {
						id
						firstName
						lastName
					}
					acceptedBy {
						id
						firstName
						lastName
					}
					solvedBy {
						id
						firstName
						lastName
					}
				}
				creatorId
				responsible
				consentRequired
				files {
					id
					filename
					key
					url
				}
				images {
					id
					filename
					key
					url
				}
		}
			pageInfo {
			hasNextPage
		}
	}
}
`;

export const GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID = gql`
	query Query($page: Int, $size: Int, $order: [String], $id: ID!) {
	getAllTodosByResidentId(
		page: $page
			size: $size
			order: $order
			id: $id
	) {
			results {
				title
				description
				deadline
				id
				createdAt
				creator {
					id
					fullName
					imageUrl
						role {
						id
					}
				}
				resident {
					fullName
					imageUrl
				}
				imageUrl
				status {
					todoId
					accepted
					solved
					consent
					consentBy {
						id
						firstName
						lastName
					}
					acceptedBy {
						id
						firstName
						lastName
					}
					solvedBy {
						id
						firstName
						lastName
					}
				}
				creatorId
				responsible
				consentRequired
				files {
					id
					filename
					key
					url
				}
				images {
					id
					filename
					key
					url
				}
		}
			pageInfo {
			hasNextPage
		}
	}
}
`;

export const GET_ALL_ORGANIZATION_BY_MUNICIPALITY = gql`
	query GetAllOrganizationsByMunicipalityId($id: ID!) {
	getAllOrganizationsByMunicipalityId(id: $id) {
		id
		name
	}
}
`

export const GET_KMD_ORGANIZATIONS_BY_MUNICIPALITY = gql`
	query GetKMDOrganizationsByMunicipality(
		$municipalityId: Int
		$token: String
		$endpoint: String
	) {
		getKMDOrganizationsByMunicipality(
			municipalityId: $municipalityId
			token: $token
			endpoint: $endpoint
		) {
			id
			name
		}
	}
`;

export const GET_KMD_RESIDENT_INFORMATION = gql`
	query GetKMDResidentInformation($cpr: String, $token: String, $endpoint: String) {
	getKMDResidentInformation(cpr: $cpr, token: $token, endpoint: $endpoint) {
		updateVersion
		calendarName
			fields {
				id
				displayName
				expandable
				value
				title
				tooltip
				questions {
					title
					displayName
					id
					value
					exportTitle
					isMaster
				}
			}
		}
	}
`

export const GET_KMD_EVENTS_BY_RESIDENT_ID = gql`
	query getKMDEventsByResidentId($token: String, $endpoint: String, $id: String) {
	getKMDEventsByResidentId(token: $token, endpoint: $endpoint, id: $id) {
		id
		startDate
		endDate
		title
		description
			participants {
			calendarActivityId
				resident {
				id
				firstName
				lastName
			}
		}
		type
	}
}
`

export const GET_KMD_EVENTS_BY_DEPARTMENT_ID = gql`
	query getKMDEventsByDepartmentId($token: String, $endpoint: String, $id: String) {
	getKMDEventsByDepartmentId(token: $token, endpoint: $endpoint, id: $id) {
		id
		startDate
		endDate
		title
		description
			participants {
			calendarActivityId
				resident {
				id
				firstName
				lastName
			}
		}
		type
	}
}
`


export const GET_KMD_EVENTS_BY_ORGANIZATION_ID = gql`
	query GetKMDEventsByOrganizationId($id: String, $token: String, $endpoint: String) {
	getKMDEventsByOrganizationId(id: $id, token: $token, endpoint: $endpoint) {
		id
		startDate
		endDate
		title
		description
			participants {
			calendarActivityId
				resident {
				id
				firstName
				lastName
			}
		}
		type
	}
}
`

export const GET_CORE_STATISTICS_BY_ORGANIZATION_ID = gql`
	query GetCoreStatisticsByOrganizationId($input: CoreStatisticInput) {
	getCoreStatisticsByOrganizationId(input: $input) {
			item {
				items {
				count
				date
				totalCount
			}
			totalCount
			monthlyCount
			weeklyCount
		}
			items {
			type
				items {
				count
				date
				totalCount
			}
			totalCount
			monthlyCount
			weeklyCount
		}
		totalCount
		activeCount
	}
}
`

export const GET_OVERDUE_TODOS_BY_ORGANIZATION_ID = gql`
	query GetOverdueTodosByOrganizationId($id: ID!, $date: String!) {
	getOverdueTodosByOrganizationId(id: $id, date: $date) {
		id
		title
		deadline
			resident {
			firstName
			lastName
		}
	}
}
`

export const GET_DAILY_OVERVIEW_BY_ORGANIZATION_ID = gql`
	query GetDailyOverviewByOrganizationId($input: DailyOverviewInput) {
	getDailyOverviewByOrganizationId(input: $input) {
		name
			events {
			departmentId
			name
			title
			type
			id
		}
	}
}
`

export const GET_ALL_CALENDAR_ACTIVITY_TEMPLATES = gql`
	query GetAllCalendarActivityTemplates {
		getAllCalendarActivityTemplates {
		id
		title
		templateName
		description
		actionRequired
		startDate
		endDate
		note
		imageUrl
	}
}
`
export const GET_ALL_CALENDAR_ACTIVITIES_IN_DRAFT_BY_CREATOR_ID = gql`
	query Query($id: ID!, $dateRange: [String], $order: [String], $size: Int) {
	getAllDraftCalendarActivitiesByCreatorId(
		id: $id
			dateRange: $dateRange
			order: $order
			size: $size
	) {
			results {
			creatorId
				creator {
				id
				firstName
				lastName
					role {
					id
				}
			}
			id
			title
			description
			startDate
			endDate
			actionRequired
			firstReminder
			secondReminder
			kmdId
			isDraft
			note
				participants {
				calendarActivityId
					relativeStatus {
					attendingStatus
					participantId
					extraParticipants
					userId
						user {
						firstName
						lastName
						imageUrl
					}
				}
					resident {
					id
					firstName
					lastName
				}
			}
		}
	}
}
`;

export const GET_ALL_INTERESTS_BY_ORGANIZATION_ID = gql`
	query Query($id: ID!) {
	getAllInterestsByOrganizationId(id: $id) {
		id
		title
		description
		allowResidents
		allowRelatives
		allowWorkers
		updatedAt
			residents {
			id
			firstName
			middleName
			lastName
			imageUrl
			gender
			cpr
			birthDate
				residentConsent {
				useStatus
			}
				room {
				name
				id
					department {
					id
					name
				}
			}
			deletedAt
		}
			users {
			firstName
			lastName
				role {
				id
			}
		}		
			relatives {
			firstName
			lastName
				role {
				id
			}
		}		
			workers {
			firstName
			lastName
				role {
				id
			}
		}
	}
}
`;

export const GET_INTEREST_BY_ID = gql`
	query Query($id: ID!) {
	getInterestById(id: $id) {
		id
		title
		description
		allowResidents
		allowRelatives
		allowWorkers
			residents {
			id
			firstName
			middleName
			lastName
			imageUrl
			gender
			cpr
			birthDate
			fullName
			imageUrl
				residentConsent {
				useStatus
			}
				room {
				name
				id
					department {
					id
					name
				}
			}
			deletedAt
		}
			users {
			id
			firstName
			lastName
			fullName
			imageUrl
				role {
				id
			}
		}		
			relatives {
			id
			firstName
			lastName
			fullName
			email
			mobile
			imageUrl
				relatives {
					resident {
					fullName
				}
			}
				role {
				id
			}
		}		
			workers {
			id
			firstName
			lastName
			fullName
			imageUrl
				role {
				id
			}
		}
	}
}
`;

export const GET_ALL_RELATIVES_BY_ORGANIZATION_ID = gql`
	query GetAllRelativesByOrganizationId($id: ID!) {
	getAllRelativesByOrganizationId(id: $id) {
		id
		lastName
		fullName
			relatives {
			resident {
				id
				fullName
			}
		}
	}
}
`;
export const GET_ALL_WORKERS_BY_ORGANIZATION_ID = gql`
	query GetAllWorkersByOrganizationId($id: ID!) {
	getAllWorkersByOrganizationId(id: $id) {
		id
		lastName
		fullName
			relatives {
			resident {
				id
				fullName
			}
		}
	}
}
`;

export const GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID = gql`
	query GetAllCalendarActivityTemplatesByOrganizationId($id: ID!) {
	getAllCalendarActivityTemplatesByOrganizationId(id: $id) {
		id
		actionRequired
		description
		imageUrl
		title
		templateName
		updatedAt
		note

	}
}
`;

export const GET_CALENDAR_ACTIVITY_TEMPLATE_BY_ID = gql`
	query GetCalendarActivityTemplateById($id: ID!) {
	getCalendarActivityTemplateById(id: $id) {
		id
		actionRequired
		description
		imageUrl
		title
		templateName
		note

	}
}
`;

export const GET_CHANGELOG_BY_TYPE_AND_RESIDENT_ID = gql`
	query GetChangeLogByTypeAndResidentId($type: String!, $residentId: ID!) {
	getExportChangeLogByTypeAndResidentId(type: $type, residentId: $residentId) {
		id
		type
		object
		direction
		userId
		roleId
		externalId
		referenceId
		createdAt
		user {
			id
			firstName
			lastName
			role {
				id
			}
		}
	}
}
`;

export const GET_CHANGELOG_RESIDENT_CONSENT_BY_RESIDENT_ID = gql`
	query GetResidentConsentChangeLogByResidentId($residentId: ID!) {
	getResidentConsentChangeLogByResidentId(residentId: $residentId) {
		id
		websiteStatus
		useStatus
		inviteStatus
		infoStatus
		newsStatus
		createdAt
		user {
			id
			firstName
			lastName
			fullName
			role {
				id
			}
		}
	}
}
`;