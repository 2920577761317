
import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';

import { UPDATE_RESIDENT_KMD } from '../../../graphql/graphqlMutations';
import { GET_CHANGELOG_BY_TYPE_AND_RESIDENT_ID, GET_KMD_RESIDENT_INFORMATION } from '../../../graphql/graphqlQueries';

import KFeed from '../../../components/feeds/KFeed';
import { useSelector } from 'react-redux';
import { getResidentFullName } from '../../../utils/residentUtils';
import moment from 'moment';
import KSubWrapper from '../../../components/containers/KSubWrapper';
import { isRelativeNoData } from '../../../utils/roles';
import KSection from '../../../components/cards/KSection';
import KAlert from '../../../components/alerts/KAlert';
import { alertWarningTypes } from '../../../utils/globalTypes';
import { useKMD } from '../../../config.js'
import { loginKmd } from '../../../utils/KmdUtils';
import NexusLogo from '../../../assets/nexus.png'

export default function Lifestory({ resident }) {
	const user = useSelector(state => state.user);

	const [schema, setSchema] = useState();
	const [changeLog, setChangeLog] = useState();
	const [schemaCopy, setSchemaCopy] = useState();
	const [errors, setErrors] = useState();

	const [doUpdateResidentKMD, { loading: submitLoading }] = useMutation(UPDATE_RESIDENT_KMD, {
		refetchQueries: [GET_KMD_RESIDENT_INFORMATION, GET_CHANGELOG_BY_TYPE_AND_RESIDENT_ID]
	});

	const { data: kmdSchemaData, error: kmdError, loading: kmdSchemaLoading } = useQuery(GET_KMD_RESIDENT_INFORMATION, {
		variables: {
			cpr: resident?.cpr,
			token: user?.KMDAuth?.token,
			endpoint: user?.data?.organization?.municipality?.kmdRootEndpoint
		},
		fetchPolicy: "no-cache"
	})

	const { data: changeLogData, error: changeLogError, loading: changeLogLoading } = useQuery(GET_CHANGELOG_BY_TYPE_AND_RESIDENT_ID, {
		variables: {
			type: 'UPDATE',
			residentId: resident?.id
		},
		fetchPolicy: "no-cache"
	})

	useEffect(() => {
		if (kmdSchemaData?.getKMDResidentInformation) {
			setSchema(kmdSchemaData?.getKMDResidentInformation)
			setSchemaCopy(kmdSchemaData?.getKMDResidentInformation)
		}
	}, [kmdSchemaData])

	useEffect(() => {
		if (changeLogData?.getExportChangeLogByTypeAndResidentId) {
			setChangeLog(changeLogData?.getExportChangeLogByTypeAndResidentId?.map(e => ({
				...e,
				title: `${getResidentFullName(e?.user)}`,
				description: 'opdaterede livshistorie',
				footerText: moment(parseInt(e?.createdAt)).format('DD-MM-YYYY HH:mm'),
				badgeText: `${isRelativeNoData(e?.user) ? 'Pårørende' : 'Personale'}`,
				color: `${isRelativeNoData(e?.user) ? 'bg-darkPurple' : 'bg-gray-200'}`
			})))
		}
	}, [changeLogData])

	useEffect(() => {
		if (kmdError) {
			setErrors({ kmd: 'Kunne ikke hente livshistorie på nuværende tidspunkt. Prøv igen senere.' })
		}
	}, [kmdError])

	const handleSchemaChange = (e, fieldId) => {
		const newSchema = {
			...schema,
			fields: schema?.fields?.map(field => {
				if (field.id === fieldId) {
					return {
						...field,
						value: e
					}
				}

				return field;
			})
		}

		setSchema(newSchema)
	}

	const handleUpdate = async () => {
		try {
			await doUpdateResidentKMD({
				variables: {
					input: {
						externalId: resident?.externalId,
						residentId: resident?.id,
						executorId: user?.data?.id,
						values: JSON.stringify(schema),
						token: user.KMDAuth?.token,
						endpoint: user?.data?.organization?.municipality?.kmdRootEndpoint,
						cpr: resident?.cpr
					}
				}
			});
		} catch (err) {
			if (err?.message === 'Handling ikke mulig') {
				setErrors({
					kmdUpdate: "Borgeren er i mellemtiden blevet opdateret af en anden bruger."
				})
			}
		}
	}

	const hasChanges = () => {
		if (!_.isEqual(schema, schemaCopy)) {
			return true;
		}
	}

	const AlertItem = (title, value) => (
		<div>
			<h3 className="text-sm font-bold text-red-800 ">{title ? title : 'Noget gik galt'}</h3>
			<div className="mt-2 text-sm text-red-700">
				<p>
					{value ? value : errors?.kmd}
				</p>
			</div>
		</div>
	)

	useEffect(() => {
		if (!_.isEmpty(user.KMDAuth)) {
			const expireAt = moment(user.KMDAuth.createdAt).add(user.KMDAuth.expires, 'seconds')
			if (expireAt.isBefore(moment())) {
				handleKMDLogin();
			}
		} else {
			handleKMDLogin();
		}
	}, []);

	const handleKMDLogin = () => {
		useKMD && loginKmd(user.data.organization.municipality)
	}

	return (
		<KSubWrapper
			title="Livshistorie"
			loading={[submitLoading, kmdSchemaLoading, changeLogLoading]?.some(e => e === true)}
			onSubmit={handleUpdate}
			showSubmit={hasChanges()}
		>
			<div>
				<div class="grid grid-cols-10 h-full">
					<div class="col-span-7 mr-4 divide-y divide-gray-200">
						<div className="grid grid-cols-1 gap-y-5">
							{errors?.kmd &&
								<div>
									{resident?.externalId &&
										<KAlert type={alertWarningTypes.ERROR} RenderItem={() => AlertItem('Noget gik galt')} />

									}
									{!resident?.externalId &&
										<KSection title="Borgerens livhistorie">

											<KAlert type={alertWarningTypes.ERROR} RenderItem={() => AlertItem("Mangler integration til Nexus", "Denne borger er ikke integreret til Nexus KMD, kontakt venligst support for at få borgeren integreret korrekt.")} />
										</KSection>
									}

								</div>
							}

							{!errors?.kmd &&
								<KSection hideTopbar={true}>
									<div className="flex items-center gap-x-7">
										<img className="ml-2 h-7" src={NexusLogo} />
										<p>Denne sektion er integeret med Nexus. Det vil sige at det information i udfylder her vil blive send retur til nexus</p>
									</div>
								</KSection>
							}
							{errors?.kmdUpdate &&
								<KAlert type={alertWarningTypes.ERROR} RenderItem={() => AlertItem("Handling ikke mulig", errors?.kmdUpdate)} />
							}

							{schema?.fields?.map((field, index) => {
								return (
									<KSection title={field?.title} description={field?.tooltip}>
										<textarea
											rows={8}
											className="block w-full p-2 mt-2 bg-gray-100 border-transparent border-gray-300 rounded-md shadow-sm focus-none sm:text-sm focus:border-transparent focus:ring-0"
											defaultValue={''}
											value={field?.value}
											onChange={(e) => handleSchemaChange(e.target.value, field?.id)}
										/>
									</KSection>
								)
								// }
							})}
						</div>

					</div>
					<div class="col-span-3 pl-2 pr-2">
						<p className="text-lg font-bold">Historik</p>
						{!changeLog || changeLog?.length === 0 &&
							<p>Der blev ikke fundet nogen historik.</p>
						}
						<KFeed elements={changeLog} />
					</div>
				</div>

			</div>


		</KSubWrapper>
	)
}
