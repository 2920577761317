import React, { useState } from "react";
import { LOGIN_MUTATION } from "../../graphql/graphqlMutations";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setUser, setLoggedIn } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import KModal from "../../components/modals/KModal";
import KTitle from "../../components/KTitle";
import KInput from "../../components/inputs/KInput";
import KButton from "../../components/KButton";

export default function AdminLogin({ history, location, open, setOpen }) {
	const user = useSelector((state) => state.user);

	const [email, setEmail] = useState();
	const [password, setPassword] = useState();

	const [doLogin, { loading, error }] = useMutation(LOGIN_MUTATION);

	const dispatch = useDispatch();

	const login = async (e) => {
		e.preventDefault();

		try {
			const res = await doLogin({
				variables: {
					loginInput: {
						email: email,
						password: password,
					},
				},
			})

			dispatch(setUser(res?.data?.login));
			dispatch(setLoggedIn(true));
		} catch (err) {

		}
	};

	if (error) {
		<p>{error}</p>;
	}

	return (
		<KModal open={open} setOpen={setOpen}>
			<div className="p-6 ">
				<KTitle title="Admin login" />
				<form onSubmit={(e) => login(e)}>
					<div className="grid grid-cols-1 pt-4 gap-y-2">
						<KInput
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e)}
							type="email"
						/>
						<KInput
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e)}
							type="password"
						/>
					</div>
					<KButton
						className="mt-4"
						label="LOGIN"
						// onClick={login}
						type="submit"
					/>
				</form>
			</div>
		</KModal>
	);
}