export default function imageUploadMutation(file, mutation) {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await mutation({
				variables: {
					file: file,
				},
			})

			resolve(data?.imageUploadSingle.url)
		} catch (err) {
			reject(err)
		}
	})
}


