import { classNames } from '../../utils/tailwindUtils';

export default function KSidebar({ elements, Header, RenderItem, className }) {
	return (
		<div className={classNames(className, "h-full pb-4 overflow-y-auto")}>
			{Header && <Header />}
			<div className="flex flex-col flex-grow">
				<nav className="flex-1 space-y-1" aria-label="Sidebar">
					{elements?.map((item) => {
						return <RenderItem item={item} />
					})}
				</nav>
			</div>
		</div>
	)
}