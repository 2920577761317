import { useState, useEffect } from 'react'


import KSnackbar from '../../../../components/KSnackbar';
import { useQuery, useMutation } from '@apollo/client';
import {
	CREATE_RESIDENT_DOCUMENT_FOLDER_BY_ID,
	DELETE_RESIDENT_DOCUMENT_FOLDER_BY_ID,
	UPLOAD_IMAGE,
	CREATE_RESIDENT_DOCUMENT
} from '../../../../graphql/graphqlMutations';
import {
	GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID,
	GET_ALL_RESIDENT_DOCUMENTS_BY_RESIDENT_ID,
	GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID,
	GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID
} from '../../../../graphql/graphqlQueries';
import _ from 'lodash';
import KSubWrapper from '../../../../components/containers/KSubWrapper';
import KSearchInput from '../../../../components/inputs/KSearchInput';
import KBreadcrumb from '../../../../components/breadcrumbs/KBreadcrumb';
import KButton from '../../../../components/KButton';
import KDocumentCard from '../../../../components/cards/Documents/KDocumentCard';

import KSelect from '../../../../components/selectors/KSelect';
import KSection from '../../../../components/cards/KSection';
import KModalConfirm from '../../../../components/modals/KConfirmModal';
import Folder from './Folder';
import { getFolderName } from '../../../../utils/documentsUtils';
import { alertWarningMessages } from '../../../../utils/globalTypes';
import imageUploadMutation from '../../../../graphql/imageUploadMutation';

export default function Documents({ resident, user }) {
	const organizationId = parseInt(user?.data?.organization?.id);

	const [message, setMessage] = useState({ msg: "", type: "", timeStamp: null });
	const [searchPhrase, setSearchPhrase] = useState();

	const [documentUploaded, setDocumentUploaded] = useState({});
	const [allFolders, setAllFolders] = useState([]);
	const [allFoldersCopy, setAllFoldersCopy] = useState([]);
	const [allDocuments, setAllDocuments] = useState([]);
	const [allDocumentsCopy, setAllDocumentsCopy] = useState([]);

	const [sorting, setSorting] = useState(sortingOptions[0]);
	const [docType, setDocType] = useState(documentTypes[1]);
	const [currentFolder, setCurrentFolder] = useState({});
	const [folderPath, setFolderPath] = useState([{
		name: 'Dokumenter',
	}]);

	const [showDelete, setShowDelete] = useState();
	const [folderDelete, setFolderDelete] = useState();

	const folderQuery = docType.isResident 
		? { 
			name: GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID,
			opts: {
				variables: {
					residentId: resident?.id,
					folderId: currentFolder?.id,
					order: sorting?.id,
				},
				fetchPolicy: "no-cache"
			}
		}
		: { 
			name: GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID,
			opts: {
				variables: {
					organizationId: organizationId,
					folderId: currentFolder?.id,
					order: sorting?.id,
				},
				fetchPolicy: "no-cache"
			}
		}

	const { loading: allFoldersLoading, data: allFolderData } = useQuery(folderQuery.name,folderQuery.opts,
	);

	const docQuery = docType.isResident
		? { 
			name: GET_ALL_RESIDENT_DOCUMENTS_BY_RESIDENT_ID,
			opts: {
				variables: {
					residentId: resident?.id,
					folderId: currentFolder?.id,
					order: sorting?.id,
				},
				fetchPolicy: "no-cache"
			}
		}
		: { 
			name: GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID,
			opts: {
				variables: {
					organizationId: organizationId,
					folderId: currentFolder?.id,
					order: sorting?.id,
				},
				fetchPolicy: "no-cache"
			}
		}

	const { loading: allDocsLoading, data: allDocsData } = useQuery(docQuery.name, docQuery.opts);

	const enrichWithResidentMarker = (array, isResident) => {
		if (!Array.isArray(array)) return [];
		const modArray = array.map(o => ({
			...o,
			isResident
		}));
		return modArray;
	}


	// create new folder
	const [createFolder, { loading: createFolderLoading }] = useMutation(CREATE_RESIDENT_DOCUMENT_FOLDER_BY_ID, {
		refetchQueries: [GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID]
	});

	// delete folder by id
	const [deleteFolder, { loading: deleteFolderLoading }] = useMutation(DELETE_RESIDENT_DOCUMENT_FOLDER_BY_ID, {
		refetchQueries: [GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID]
	});

	const [doUploadFile] = useMutation(UPLOAD_IMAGE);

	// create new document
	const [createDocument, { loading: createDocumentLoading }] = useMutation(CREATE_RESIDENT_DOCUMENT, {
		refetchQueries: [GET_ALL_RESIDENT_DOCUMENTS_BY_RESIDENT_ID]
	});

	// set all folders
	useEffect(() => {

		const all = docType.isResident 
			? enrichWithResidentMarker(allFolderData?.getAllResidentDocumentFoldersByResidentId, true)
			: enrichWithResidentMarker(allFolderData?.getAllOrganizationDocumentFoldersByOrganizationId, false);
		setAllFolders(all)
		setAllFoldersCopy(all)
	}, [allFolderData]);

	// set all documents
	useEffect(() => {
		const all = docType.isResident
			? enrichWithResidentMarker(allDocsData?.getAllResidentDocumentsByResidentId, true)
			: enrichWithResidentMarker(allDocsData?.getAllOrganizationDocumentsByOrganizationId, false);
		setAllDocuments(all)
		setAllDocumentsCopy(all)
	}, [allDocsData]);

	useEffect(() => {
		setAllFolders(allFoldersCopy?.filter(e => {
			return e.name?.toLowerCase()?.includes(searchPhrase?.toLowerCase())
		}))
		setAllDocuments(allDocumentsCopy?.filter(e => {
			return e.name?.toLowerCase()?.includes(searchPhrase?.toLowerCase())
		}))
	}, [searchPhrase])


	const onFolderClick = (folder) => {
		setFolderPath([...folderPath, folder]);
		setCurrentFolder(folder);
	}

	const handlePathChange = (folder, i) => {
		const newPath = folderPath.slice(0, (i + 1));
		setFolderPath(newPath);
		setCurrentFolder(folder);
	}

	const handleCreateNewFolder = async () => {
		try {
			await createFolder({
				variables: {
					input: {
						name: getFolderName(allFolders),
						parentId: currentFolder?.id || null,
						residentId: resident?.id,
					}
				}
			})

			setMessage(alertWarningMessages.CREATED('Mappe'))
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const onDeleteFolder = async () => {
		try {
			await deleteFolder({
				variables: {
					id: folderDelete?.id
				}
			})

			setShowDelete(false)
			setMessage(alertWarningMessages.DELETED('Mappen'));
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const createNewFile = async () => {
		try {
			const url = await imageUploadMutation(documentUploaded, doUploadFile)

			await createDocument({
				variables: {
					input: {
						name: documentUploaded.name,
						parentId: currentFolder?.id || null,
						residentId: resident?.id,
						url: url,
					}
				}
			})
			setMessage(alertWarningMessages.UPLOADED('Dokument'));
		} catch (err) {
			if (err?.message === 'Error: Filetype not supported') {
				return setMessage(alertWarningMessages.ERROR('Den valgte fil type er ikke supporteret.'))
			}
			setMessage({ msg: "Noget gik galt, prøv igen senere.", type: "error", timeStamp: Date.now() });

		}
	}

	// listne to changes in the file input
	useEffect(() => {
		if (documentUploaded?.name) {
			createNewFile();
		}
	}, [documentUploaded])

	const handleDeleteFolder = (folder) => {
		setFolderDelete(folder)
		setShowDelete(true)
	}

	const setDocumentType = (options) => {
		if (options.id !== docType.id) {
			setCurrentFolder({});
			setDocType(options);
		}
	}

	const TopBar = () => (
		<div className="flex items-center justify-between h-12 pr-4 border-b">
			<p className="ml-4 text-lg font-bold text-gray-600">Dokumenter</p>
			{docType.isResident && <div className="grid grid-cols-2 gap-x-2">
				<KButton className="min-w-full" label="NY MAPPE" onClick={() => handleCreateNewFolder()} />
				<KButton>
					<label className='cursor-pointer' for="file">
						Upload dokument
					</label>
				</KButton>
				<input type="file" id="file" className='hidden' onChange={(e) => setDocumentUploaded(e.target.files[0])} />
			</div>}

		</div>
	)

	return (
		<KSubWrapper
			title="Dokumenter"
			// loading={allFoldersLoading}
			TopBar={TopBar}
		>
			<KModalConfirm
				open={showDelete}
				setOpen={() => setShowDelete(!showDelete)}
				title="Slet mappe"
				description="Er du sikker på, at du vil slette mappen?"
				handleConfirm={() => onDeleteFolder()}
				confirmText="SLET"
			/>
			<div className="grid grid-cols-2">
				<div className='grid grid-cols-2 gap-x-2'>
					<KSearchInput placeholder="Find dokument" onChange={setSearchPhrase} />
					<KSelect elements={sortingOptions} selected={sorting} handleSelect={(opt) => { setSorting(opt) }} />
				</div>
				{/* <div className='grid grid-cols-2 gap-x-2'>
					<div></div>
					<KSelect elements={documentTypes} selected={docType} handleSelect={setDocumentType} />
				</div> */}

			</div>
			<div className="mt-4 mb-4">
				<KBreadcrumb
					pages={folderPath}
					folder={currentFolder}
					onClick={(folder, i) => handlePathChange(folder, i)}
				/>
			</div>
			<div className="grid grid-cols-1 gap-y-5">
				<KSection
					loading={allFoldersLoading}
					title="Mapper"
					description="Her finder du de mapper, personalet har delt"
				>
					{allFolders && allFolders?.length > 0 &&
						<div className="grid grid-cols-6 gap-y-10">
							{allFolders?.map(folder => (
								<Folder
									key={folder.id}
									folder={folder}
									onClick={() => onFolderClick(folder)}
									allFolders={allFolders}
									setSnackMessage={setMessage}
									handleDelete={(folder) => handleDeleteFolder(folder)}
									handleError={(msg) => setMessage({ msg, type: "error", timeStamp: Date.now() })}
								/>
							))}

						</div>
					}
					{!allFolders || allFolders?.length === 0 &&
						<p className="text-sm">Der blev ikke fundet nogle mapper.</p>
					}
				</KSection>

				<KSection
					title="Dokumenter"
					description="Her finder du de dokumenter, personalet har delt."
					loading={allDocsLoading}
				>
					{allDocuments && allDocuments?.length > 0 &&
						<div className="grid grid-cols-7 gap-y-10">
							{allDocuments?.map((doc) => (
								<KDocumentCard
									key={doc.id}
									title={doc.name}
									url={doc.url}
									document={doc}
									onChange={(msg) => setMessage({ msg, type: "success", timeStamp: Date.now() })}
									onError={(msg) => setMessage({ msg, type: "error", timeStamp: Date.now() })}
									allFolders={allFolders?.filter(f => f.id != currentFolder?.id)}
									handleDelete={handleDeleteFolder}
									residentDocument={doc.isResident}
									canEdit={doc.isResident}
									setSnackMessage={setMessage}
								/>
							))}
						</div>
					}
					{!allDocuments || allDocuments?.length === 0 &&
						<p className="text-sm">Der blev ikke fundet nogle dokumenter.</p>
					}
				</KSection>
			</div>

			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
				key={message.timeStamp}
			/>
		</KSubWrapper >
	)
}

const sortingOptions = [
	{ name: "Alfabetisk", id: ['name', 'ASC'] },
	{ name: "Nyeste først", id: ["createdAt", "DESC"] },
	{ name: "Ældste først", id: ["createdAt", "ASC"] },
	{ name: "Senest opdateret", id: ["updatedAt", "DESC"] },
]

const documentTypes = [
	{ id: 1, name: 'Personlige', isResident: true},
	{ id: 2, name: 'Organisation', isResident: false}
]

