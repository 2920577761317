import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { classNames } from '../../utils/tailwindUtils'

export default function KSearchInput({ label, value, onChange, type, placeholder, autoFocus }) {
	return (
		<div>
			<div className="flex justify-between">
				<label className="block text-sm font-medium text-gray-700">
					{label}

				</label>
			</div>
			<div className="relative h-10 rounded-md shadow-sm">
				<input
					autoFocus={autoFocus}
					type='search'
					onChange={(e) => onChange(e.target.value)}
					value={value}
					className={classNames(
						'block h-full w-full pt-2 pb-2 pl-10 bg-gray-100 rounded-md focus:ring-darkPurple focus:border-darkPurple sm:text-sm border',
					)}
					placeholder={placeholder}
					aria-invalid="true"
					aria-describedby={`${type}-error"`}
				/>
				<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
					<MagnifyingGlassCircleIcon className="w-4 h-4 text-gray-400" />
				</div>
			</div>
		</div>
	)

}
