import React, { useRef } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { isImageObj, isImageType } from "../../utils/fileUtils";
import PDFIcon from '../../assets/pdf-icon.png';
import { isValidURL } from "../../utils/validators";
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import { maxHeight, maxWidth } from "@mui/system";

export default function KImageDrop({ handleDrop, Icon, height, width, title, acceptedFiles, acceptedSize, accept }) {
	const dropzoneRef = useRef();

	const openDialog = () => {
		dropzoneRef.current.open()
	};

	return (
		<div>
			<Dropzone
				noClick
				noKeyboard
				ref={dropzoneRef}
				onDrop={handleDrop}
				accept={accept}
			// minSize={1024}
			// maxSize={3072000}
			// maxFiles={1}
			>
				{({ getRootProps, getInputProps }) => {
					return (
						<DropZone onClick={() => openDialog()} style={{ height: height, width: width, cursor: 'pointer' }}>
							<div className="space-y-1 text-center">
								<input {...getInputProps()} />

								{Icon && <Icon />}
								{!Icon && (
									<svg
										className="w-12 h-12 mx-auto text-gray-400"
										stroke="currentColor"
										fill="none"
										viewBox="0 0 48 48"
										aria-hidden="true"
									>
										<path
											d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
											strokeWidth={2}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								)}
								<div className="flex justify-center text-sm text-gray-600">
									<label
										className="font-medium text-indigo-600 cursor-pointer hover:text-indigo-500"
									>
										<span>{title}</span>
									</label>

								</div>
								<p className="text-xs text-gray-500">
									{`${acceptedFiles?.join(', ')} op til ${acceptedSize}`}
								</p>
							</div>
						</DropZone>
					)
				}}
			</Dropzone>
		</div >
	);
}

const DropZone = styled.div({
	padding: '20px',
	border: '3px dashed #eeeeee',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
})