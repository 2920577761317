export const interestGroupResidentHeaders = [
	{
		id: 'fullName',
		numeric: false,
		disablePadding: true,
		label: 'Navn',
	},
	{
		id: 'room',
		numeric: true,
		disablePadding: false,
		label: 'Bolig',
		sortable: false
	},
	{
		id: 'department',
		numeric: true,
		disablePadding: false,
		label: 'Afsnit',
		sortable: false
	},
];

export const interestRelativeGroupHeaders = [
	{
		id: 'fullName',
		numeric: false,
		disablePadding: true,
		label: 'Navn',
	},
	{
		id: 'resident',
		numeric: true,
		disablePadding: false,
		label: 'Borger',
		sortable: false
	},
	{
		id: 'email',
		numeric: true,
		disablePadding: false,
		label: 'email',
		sortable: false
	},
	{
		id: 'phone',
		numeric: true,
		disablePadding: false,
		label: 'Telefon',
		sortable: false
	},
];

export const interestGroupHeaders = [
	{
		id: 'title',
		numeric: false,
		disablePadding: true,
		label: 'Navn',
	},
	{
		id: 'residents',
		numeric: true,
		disablePadding: false,
		label: 'Antal borgere',
		sortable: false
	},
	{
		id: 'relatives',
		numeric: true,
		disablePadding: false,
		label: 'Antal pårørende',
		sortable: false
	},
	{
		id: 'workers',
		numeric: true,
		disablePadding: false,
		label: 'Antal personale',
		sortable: false
	},
	{
		id: 'updatedAt',
		numeric: true,
		disablePadding: false,
		label: 'Senest opdateret',
		sortable: false
	},
];

export const roomGroupHeaders = [
	{
		id: 'name',
		numeric: false,
		disablePadding: true,
		sortable: false,
		label: 'Navn',
	},
	{
		id: 'department',
		numeric: true,
		disablePadding: false,
		label: 'Afsnit',
		sortable: false
	},
	{
		id: 'resident',
		numeric: true,
		disablePadding: false,
		label: 'Borger',
		sortable: false
	},
	{
		id: 'updatedAt',
		numeric: true,
		disablePadding: false,
		label: 'Senest opdateret',
		sortable: false
	},
];

export const departmentGroupHeaders = [
	{
		id: 'name',
		numeric: false,
		disablePadding: true,
		sortable: false,
		label: 'Navn',
	},
	{
		id: 'residents',
		numeric: true,
		disablePadding: false,
		label: 'Borgere',
		sortable: false
	},
	{
		id: 'rooms',
		numeric: true,
		disablePadding: false,
		label: 'Boliger',
		sortable: false
	},

	{
		id: 'updatedAt',
		numeric: true,
		disablePadding: false,
		label: 'Senest opdateret',
		sortable: false
	},
];



export const activityTemplateHeaders = [
	{
		id: 'templateName',
		numeric: false,
		disablePadding: true,
		label: 'Navn',
	},
	// {
	// 	id: 'title',
	// 	numeric: true,
	// 	disablePadding: false,
	// 	label: 'Titel',
	// 	sortable: false,
	// },
	{
		id: 'actionRequired',
		numeric: true,
		disablePadding: false,
		label: 'Svar påkrævet',
		sortable: false
	},
	{
		id: 'updatedAt',
		numeric: true,
		disablePadding: false,
		label: 'Senest opdateret',
		sortable: false
	},
];