import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
	name: 'ui',
	initialState: {
		flashMessage: {
			show: true,
			title: "Noget gik galt",
			description: ''
		},
	},
	reducers: {
		triggerFlashMessage: (state, action) => {
			state.flashMessage.show = !state.flashMessage.show;
			state.flashMessage.title = action?.payload?.title || '';
			state.flashMessage.type = action?.payload?.type || state.flashMessage.type;
			state.flashMessage.description = action?.payload?.description || ''
		},
	}
});


export const {
	triggerFlashMessage,
} = uiSlice.actions

export default uiSlice.reducer;