import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';

export default function KLoadingButton({ title, loading, onClick, style }) {
	return (
		<LoadingButton
			style={style}
			fullWidth
			loading={loading}
			variant="outlined"
			onClick={onClick}
		>
			{title}
		</LoadingButton>
	)
}
