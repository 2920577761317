import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Checkbox, TableCell, TableRow, Button, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import KButton from '../../components/KButton';
import KModal from '../../components/KModal';
import KPageWrapper from '../../components/KPageWrapper';
import KTextField from '../../components/KTextField';
import { GET_ALL_INTERESTS_BY_ORGANIZATION_ID, GET_ALL_RELATIVES_BY_ORGANIZATION_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, GET_ALL_WORKERS_BY_ORGANIZATION_ID, GET_INTEREST_BY_ID } from '../../graphql/graphqlQueries';
import { getResidentFullName } from '../../utils/residentUtils';
import { isWorker } from '../../utils/roles';
import _ from 'lodash';
import { createInterestRoute, interestGroupsRoute } from '../../routes/mainRoutes';
import KSelect from '../../components/KSelect';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { CREATE_MULTIPLE_RESIDENT_INTEREST, CREATE_MULTIPLE_USER_INTEREST, CREATE_RESIDENT_INTEREST, CREATE_USER_INTEREST, DELETE_MULTIPLE_INTEREST_BY_ID, DELETE_RESIDENT_INTEREST, DELETE_USER_INTEREST } from '../../graphql/graphqlMutations';
import KDataGrid from '../../components/KDataGrid';
import { interestGroupHeaders, interestGroupResidentHeaders, interestRelativeGroupHeaders } from '../../utils/settingsUtils';
import KTitle from '../../components/KTitle';
import KPicker from '../../components/KPicker';

export default function InterestGroup({ match, history }) {
	const user = useSelector(state => state.user);

	const [interest, setInterest] = useState();
	const [originalInterest, setOriginalInterest] = useState();
	const [residents, setResidents] = useState();
	const [relatives, setRelatives] = useState();
	const [workers, setWorkers] = useState();

	const [modalTitle, setModalTitle] = useState('')
	const [modalSubTitle, setModalSubTitle] = useState('')
	const [showModal, setShowModal] = useState('')
	const [modalType, setModalType] = useState()
	const [modalSelected, setModalSelected] = useState()

	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showAdd, setShowAdd] = useState(false);
	const [showAddRelative, setShowAddRelative] = useState(false);
	const [showAddWorker, setShowAddWorker] = useState(false);
	const [selectedResident, setSelectedResident] = useState('')
	const [selectedRelative, setSelectedRelative] = useState('')
	const [selectedWorker, setSelectedWorker] = useState('')

	const { data, loading, refetch } = useQuery(GET_INTEREST_BY_ID, { variables: { id: match?.params?.id } })
	const { data: relativesData } = useQuery(GET_ALL_RELATIVES_BY_ORGANIZATION_ID, { variables: { id: user?.data?.organization?.id } })
	const { data: workersData } = useQuery(GET_ALL_WORKERS_BY_ORGANIZATION_ID, { variables: { id: user?.data?.organization?.id } })
	const {
		data: residentsData,
		loading: residentsLoading,
		refetch: residentsRefetch,
		error: residentsError
	} = useQuery(GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, {
		variables: {
			id: user?.data?.organization?.id,
			size: 999,
			// exclude: interest?.residents?.map(e => e.id)
		}
	})

	const [doCreateResidentInterest] = useMutation(CREATE_RESIDENT_INTEREST, { refetchQueries: [GET_INTEREST_BY_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID] })
	const [doCreateMultipleResidentInterest, { loading: createResidentInterestsLoading }] = useMutation(CREATE_MULTIPLE_RESIDENT_INTEREST, { refetchQueries: [GET_INTEREST_BY_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID] })
	const [doDeleteResidentInterest, { loading: deleteResidentInterestsLoading }] = useMutation(DELETE_RESIDENT_INTEREST)
	const [doCreateUserInterest] = useMutation(CREATE_USER_INTEREST, { refetchQueries: [GET_INTEREST_BY_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID] })
	const [doCreateMultipleUserInterest, { loading: createUserInterestLoading }] = useMutation(CREATE_MULTIPLE_USER_INTEREST, { refetchQueries: [GET_INTEREST_BY_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID] })
	const [doDeleteUserInterest] = useMutation(DELETE_USER_INTEREST, {
		refetchQueries: [GET_INTEREST_BY_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID]
	})

	const [doDeleteInterest] = useMutation(DELETE_MULTIPLE_INTEREST_BY_ID, {
		refetchQueries: [GET_ALL_INTERESTS_BY_ORGANIZATION_ID]
	})

	useEffect(() => {
		setInterest(data?.getInterestById)
		setOriginalInterest(data?.getInterestById)
	}, [data])

	// useEffect(() => {
	// 	console.log(relativesData)
	// 	setRelatives(relativesData?.getAllRelativesByOrganizationId)
	// }, [relativesData])

	useEffect(() => {
		if (residentsData) {
			const residents = residentsData[Object.keys(residentsData)[0]]?.results;
			const formatted = residents?.map(r => ({
				...r,
				label: `${getResidentFullName(r)}`
			}));

			setResidents(formatted)
		}
	}, [residentsData])

	useEffect(() => {
		if (relativesData) {
			const formatted = relativesData?.getAllRelativesByOrganizationId?.map(r => ({
				...r,
				label: `${r.fullName} (${r?.relatives[0]?.resident?.fullName})`
			}));

			formatted.sort((a, b) => {
				if (a?.fullName > b?.fullName) {
					return 1
				} else if (a?.fullName < b?.fullName) {
					return -1
				} else {
					return 0
				}
			})
			setRelatives(formatted)
		}
	}, [relativesData])

	useEffect(() => {
		if (workersData) {
			const formatted = workersData?.getAllWorkersByOrganizationId?.map(r => ({
				...r,
				label: `${r.fullName}`
			}));

			// formatted.sort((a, b) => {
			// 	if (a?.relatives[0]?.resident?.fullName > b?.relatives[0]?.resident?.fullName) {
			// 		return 1
			// 	} else if (a?.relatives[0]?.resident?.fullName < b?.relatives[0]?.resident?.fullName) {
			// 		return -1
			// 	} else {
			// 		return 0
			// 	}
			// })
			setWorkers(formatted)
		}
	}, [workersData])

	const handleChange = (key, value) => {
		setInterest({
			...interest,
			[key]: value
		})
	}

	const handleAddResidentInterest = async (selected) => {
		try {
			await doCreateMultipleResidentInterest({
				variables: {
					input: {
						residentIds: selected?.map(e => e.id),
						interestId: interest.id
					}
				}
			})

			// refetch()
			// residentsRefetch()
			// setShowAdd(false)
			setShowAdd(false)
		} catch (err) {
			console.log(err)
		}
	}

	const handleDeleteInterest = async () => {
		await doDeleteInterest({
			variables: {
				ids: [interest?.id]
			}
		})

		history.push(interestGroupsRoute)
	}


	const handleAddUserInterest = async (selected) => {
		try {
			await doCreateMultipleUserInterest({
				variables: {
					input: {
						userIds: selected?.map(e => e.id),
						interestId: interest.id
					}
				}
			})

			setShowAddRelative(false)
			setShowAddWorker(false)
		} catch (err) {
			console.log(err)
		}
	}

	const handleRemoveResidentInterest = async (selected) => {
		try {
			const foundResidents = interest?.residents?.filter(e => selected?.includes(e.fullName))
			await doDeleteResidentInterest({
				variables: {
					input: {
						residentIds: foundResidents?.map(e => e.id),
						interestId: interest.id
					}
				}
			})

			refetch()
			setShowAdd(false)
		} catch (err) {
			console.log(err)
		}
	}

	const handleRemoveUserInterest = async (selected, list) => {
		console.log(selected)
		try {
			console.log(interest?.relatives, 'HEREEEEEE')
			const foundRelative = list?.filter(e => selected?.includes(e.fullName))
			await doDeleteUserInterest({
				variables: {
					input: {
						userIds: foundRelative?.map(e => e.id),
						interestId: interest.id
					}
				}
			})

			refetch()
			setShowAdd(false)
		} catch (err) {
			console.log(err)
		}
	}
	const handleRemoveRelativeInterest = async (selected) => {
		try {
			console.log(selected)
			const foundRelatives = interest?.relatives?.filter(e => selected?.includes(e.fullName))
			await doDeleteUserInterest({
				variables: {
					input: {
						userIds: foundRelatives?.map(e => e.id),
						interestId: interest.id
					}
				}
			})

			setShowModal(false)
		} catch (err) {
			console.log(err)
		}
	}

	const AddResidentButton = () => {
		return <KButton label="Tilføj" onClick={() => setShowAdd(true)} />
	}

	const AddRelativeButton = () => {
		return <KButton label="Tilføj" onClick={() => setShowAddRelative(true)} />
	}

	const AddWorkerButton = () => {
		return <KButton label="Tilføj" onClick={() => setShowAddWorker(true)} />
	}


	const ResidentRenderItem = ({ row }) => {
		return (
			<React.Fragment>
				<TableCell
					component="th"
					// id={labelId}
					scope="row"
					padding="none"
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
						<Avatar sx={{ width: 30, height: 30, mt: 1, mb: 1 }} src={row?.imageUrl} />
						{row?.fullName}
					</div>
				</TableCell>
				<TableCell align="right">{row?.room?.name}</TableCell>
				<TableCell align="right">{row?.room?.department?.name}</TableCell>
			</React.Fragment>
		)
	}
	const RelativeRenderItem = ({ row }) => {
		return (
			<React.Fragment>
				<TableCell
					component="th"
					// id={labelId}
					scope="row"
					padding="none"
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
						<Avatar sx={{ width: 30, height: 30, mt: 1, mb: 1 }} src={row?.imageUrl} />
						{row?.fullName}
					</div>
				</TableCell>
				<TableCell align="right">{row?.relatives[0]?.resident?.fullName}</TableCell>
				<TableCell align="right">{row?.email}</TableCell>
				<TableCell align="right">{row?.mobile}</TableCell>
			</React.Fragment>
		)
	}
	const WorkerRenderItem = ({ row }) => {
		console.log(row)
		return (
			<React.Fragment>
				<TableCell
					component="th"
					// id={labelId}
					scope="row"
					padding="none"
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
						<Avatar sx={{ width: 30, height: 30, mt: 1, mb: 1 }} src={row?.imageUrl} />
						{row?.fullName}
					</div>
				</TableCell>

			</React.Fragment>
		)
	}

	const getRelativesForPicker = () => {
		const ids = interest?.relatives?.map(e => e.id);
		const filtered = relatives?.filter(relative => !ids?.includes(relative?.id))
		return filtered
	}

	const getWorkersForPicker = () => {
		const ids = interest?.workers?.map(e => e.id);
		const filtered = workers?.filter(worker => !ids?.includes(worker?.id))
		return filtered
	}

	const handleDeleteGroup = () => {
		setModalTitle("Slet interessegruppe")
		setModalSubTitle("Er du sikker på at du vil slette interessegruppen?")
		setModalType('interest')
		setShowModal(true)
	}

	const handleDeleteResident = (selected) => {
		setModalTitle("Fjern borger")
		setModalSubTitle("Er du sikker på at du vil fjerne borgeren fra interessegruppen?")
		setModalSelected(selected)
		setModalType('resident')
		setShowModal(true)
	}

	const handleDeleteRelative = (selected) => {
		setModalTitle("Fjern pårørende")
		setModalSubTitle("Er du sikker på at du vil fjerne pårørende fra interessegruppen?")
		setModalSelected(selected)
		setModalType('relative')
		setShowModal(true)
	}

	const clearModal = () => {
		setShowModal(false)
		setModalTitle(null)
		setModalSubTitle(null)
		setModalSelected(null)
		setModalType(null)
	}

	const handleDelete = async () => {
		if (modalType === 'resident') {
			await handleRemoveResidentInterest(modalSelected)
			clearModal()
		}

		if (modalType === 'relative') {
			await handleRemoveRelativeInterest(modalSelected)
			clearModal()
		}

		if (modalType === 'interest') {
			await handleDeleteInterest()
			clearModal()
		}
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Interessegrupper',
				route: interestGroupsRoute
			}, {
				title: 'Interessegruppe'
			}]}
			loading={[loading].some(e => e === true)}
			buttons={[
				!_.isEqual(interest, originalInterest) && <KButton
					label="GEM"
					onClick={() => console.log('gem')}
				/>,
				<KButton
					label="SLET INTERESSEGRUPPE"
					onClick={() => handleDeleteGroup()}
				/>
			]}
		>
			<div>
				{/* <KModal open={showAdd} title="TILFØJ BORGER" handleChange={() => setShowAdd(false)}>
					<div style={{ marginTop: 20 }}>
						<KSelect
							options={residents?.filter(e => !interest?.residents?.map(e => e.id)?.includes(e.id))?.map(r => r?.label)}
							value={selectedResident}
							handleChange={(e) => setSelectedResident(e)}
						/>
					</div>
					{selectedResident &&
						<ButtomContainer>
							<p style={{ cursor: 'pointer' }} onClick={() => setShowAdd(false)}>Annuller</p>
							<DoneButton onClick={() => handleAddResidentInterest()}>Tilføj</DoneButton>
						</ButtomContainer>
					}
				</KModal> */}
				<KModal
					open={showModal}
					title={modalTitle}
					subTitle={modalSubTitle}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						<ModalButton fullWidth onClick={() => handleDelete()}>Slet</ModalButton>
						<ModalButton fullWidth onClick={() => setShowModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>
				<KPicker
					list={residents?.filter(e => !interest?.residents?.map(e => e.id)?.includes(e.id))}
					title="TILFØJ BORGER"
					open={showAdd}
					setOpen={setShowAdd}
					onSelect={(e) => handleAddResidentInterest(e)}
				/>
				<KPicker
					list={getRelativesForPicker()}
					title="TILFØJ PÅRØRENDE"
					open={showAddRelative}
					setOpen={setShowAddRelative}
					onSelect={(e) => handleAddUserInterest(e)}
				/>
				<KPicker
					list={getWorkersForPicker()}
					title="TILFØJ PERSONALE"
					open={showAddWorker}
					setOpen={setShowAddWorker}
					onSelect={(e) => handleAddUserInterest(e)}
				/>

				{/* <KModal open={showAddRelative} title="TILFØJ PÅRØRENDE" handleChange={() => setShowAddRelative(false)}>
					<div style={{ marginTop: 20 }}>
						<KSelect
							options={relatives?.filter(e => !interest?.relatives?.map(e => e.id)?.includes(e.id))?.map(r => r.label)}
							// value={selectedResident}
							handleChange={(e) => setSelectedRelative(e)}
						/>
					</div>
					{selectedRelative &&
						<ButtomContainer>
							<p style={{ cursor: 'pointer' }} onClick={() => setShowAddRelative(false)}>Annuller</p>
							<DoneButton onClick={() => handleAddUserInterest(selectedRelative, relatives)}>Tilføj</DoneButton>
						</ButtomContainer>
					}
				</KModal> */}
				{/* <KModal open={showAddWorker} title="TILFØJ PERSONALE" handleChange={() => setShowAddWorker(false)}>
					<div style={{ marginTop: 20 }}>
						<KSelect
							options={workers?.filter(e => !interest?.workers?.map(e => e.id)?.includes(e.id))?.map(r => r.label)}
							// value={selectedResident}
							handleChange={(e) => setSelectedWorker(e)}
						/>
					</div>
					{selectedWorker &&
						<ButtomContainer>
							<p style={{ cursor: 'pointer' }} onClick={() => setShowAddWorker(false)}>Annuller</p>
							<DoneButton onClick={() => handleAddUserInterest(selectedWorker, workers)}>Tilføj</DoneButton>
						</ButtomContainer>
					}
				</KModal> */}
				<div>
					<div style={{ marginTop: 20 }}>
						<KTextField
							value={interest?.title}
							required
							fullWidth={true}
							label="Titel"
							onChange={(value) => handleChange('title', value)}
						/>
					</div>
					<div style={{ marginTop: 20 }}>
						<KTextField
							// error={errors?.description}
							value={interest?.description}
							label="Beskrivelse"
							required
							onChange={(value) => handleChange('description', value)}
							multiline
							rows={5}
						/>
					</div>
					<Paper sx={{ width: '100%', mb: 2, mt: 2, p: 2 }}>
						<KTitle title="Hvem kan tilføjes:" />
						<p>Her vises hvilke bruger typer som kan tilføjes til en interessegruppe. Det vil sige at en interessegruppe, kan bestå af både pårørende, personale, borgere eller en blanding. Husk at pårørende altid er modtagere til deres borger.</p>
						<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
							<Checkbox checked={interest?.allowResidents ? interest?.allowResidents : false} onChange={() => handleChange('allowResidents', !interest?.allowResidents)} />
							<p>Borger</p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
							<Checkbox checked={interest?.allowRelatives ? interest?.allowRelatives : false} onChange={() => handleChange('allowRelatives', !interest?.allowRelatives)} />
							<p>Pårørende</p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
							<Checkbox checked={interest?.allowWorkers ? interest?.allowWorkers : false} onChange={() => handleChange('allowWorkers', !interest?.allowWorkers)} />
							<p>Personale</p>
						</div>
					</Paper>

					<div style={{ marginTop: 20 }}>
						{interest?.allowResidents &&
							<KDataGrid
								headers={interestGroupResidentHeaders}
								title="Tilføjet borgere"
								rows={interest?.residents}
								RightAction={AddResidentButton}
								handleDelete={handleDeleteResident}
								RenderItem={ResidentRenderItem}
								loading={[createResidentInterestsLoading, deleteResidentInterestsLoading]?.some(e => e === true)}
							/>
						}
					</div>

					<div style={{ marginTop: 20 }}>
						{interest?.allowRelatives &&
							<KDataGrid
								headers={interestRelativeGroupHeaders}
								title="Tilføjet pårørende"
								rows={interest?.relatives}
								handleDelete={handleDeleteRelative}
								RightAction={AddRelativeButton}
								RenderItem={RelativeRenderItem}
							/>
						}
					</div>

					<div style={{ marginTop: 20 }}>
						{/* {interest?.allowWorkers &&
							<KDataGrid
								headers={interestRelativeGroupHeaders}
								title="Tilføjet personale"
								rows={interest?.relatives}
								handleDelete={handleRemoveUserInterest}
								RightAction={AddRelativeButton}
								RenderItem={RelativeRenderItem}
							/>
						} */}
						{interest?.allowWorkers &&
							<KDataGrid
								headers={[interestGroupHeaders[0]]}
								title="Tilføjet personale"
								rows={interest?.workers}
								RightAction={AddWorkerButton}
								RenderItem={WorkerRenderItem}
								handleDelete={(s) => handleRemoveUserInterest(s, interest?.workers)}
							/>
						}
					</div>
				</div>
			</div>
		</KPageWrapper>
	)
}

const ButtomContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	// height: 50,
	paddingTop: 20,
	borderTop: '1px solid #e2e2e2',
	alignItems: 'center',
	// paddingLeft: 20,
	// paddingRight: 20
})

const DoneButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: Colors.darkPurple,
		color: '#fff',
		fontSize: 12,
		paddingRight: 30,
		paddingLeft: 30,

		'&:hover': {
			backgroundColor: Colors.darkPurple
		}
	}
}))


const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,

		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))