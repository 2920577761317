import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import qs from "qs";
import { useSelector } from "react-redux";
import _ from 'lodash';
import { formatResident, loginKmd, formatRelatives, uploadMobileFormat } from "../../../utils/KmdUtils";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_AWAITNG_RESIDENTS_BY_ORGANIZATION_ID, GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, GET_ALL_ROOMS_BY_ORGANZATION_ID, CHECK_IF_USERS_EXISTS_BY_EMAIL } from "../../../graphql/graphqlQueries";
import { CREATE_RESIDENT_WITH_RELATIVES, UPLOAD_IMAGE, CREATE_MULTIPLE_RESIDENT_RELATIVE } from "../../../graphql/graphqlMutations";
import moment from 'moment';
import { useKMD } from '../../../config.js'
import { residentsRoute } from "../../../routes/mainRoutes";
import KPageWrapper from "../../../components/KPageWrapper";
import KSnackbar from "../../../components/KSnackbar";
import KInput from "../../../components/inputs/KInput";
import KButton from "../../../components/KButton";
import KSelect from "../../../components/selectors/KSelect";
import KConfirmModal from "../../../components/modals/KConfirmModal";
import KSubWrapper from "../../../components/containers/KSubWrapper";
import { validate } from "../../../utils/validators/index.js";
import { validateNewResident } from "../../../utils/validators/residentValidator";
import { residentInfomationRoute } from "../../../routes/residentRoutes";
import Relatives from "./Relatives";
import KSection from "../../../components/cards/KSection";
import { alertWarningMessages } from "../../../utils/globalTypes";
import KPhoneInput from "../../../components/inputs/KPhoneInput";
import KToggle from "../../../components/pickers/toggle/KToggle";
import ImageUploadQuery from "../../../graphql/ImageUploadQuery";
import { convertBase64ToFile } from "../../../utils/fileUtils";
import KAvatar from "../../../components/KAvatar";
import KTimeline from "../../../components/stepper/KTimeline";
import { getResidentTimeLine2 } from "../../../utils/residentUtils";
export default function CreateResident({ history }) {
	const user = useSelector(state => state.user);

	const [loading, setLoading] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState({ msg: "", type: "", timeStamp: null });

	const [image, setImage] = useState();
	const [resident, setResident] = useState();
	const [department, setDepartment] = useState("");
	const [room, setRoom] = useState("");
	const [consentGiven, setConsentGiven] = useState(false);
	const [dataImported, setDataImported] = useState(false);
	const [errors, setErrors] = useState();

	const [showCreateModal, setShowCreateModal] = useState(false)

	const [relatives, setRelatives] = useState([]);
	const [existingRelatives, setExistingRelatives] = useState([]);

	const [rooms, setRooms] = useState([]);
	const [departments, setDepartments] = useState();

	const { data: allRooms } = useQuery(GET_ALL_ROOMS_BY_ORGANZATION_ID, { variables: { id: Number(user?.data?.organization?.id) } });
	const { data: allDepartments } = useQuery(GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, { variables: { organizationId: Number(user?.data?.organization?.id) } });

	const [checkIfUserExistsByEmail] = useLazyQuery(CHECK_IF_USERS_EXISTS_BY_EMAIL, {
		fetchPolicy: "network-only"
	})

	const [createResidentWithRelatives, { loading: createLoading }] = useMutation(CREATE_RESIDENT_WITH_RELATIVES, {
		refetchQueries: [GET_ALL_AWAITNG_RESIDENTS_BY_ORGANIZATION_ID]
	});

	const [createMultipleResidentRelative] = useMutation(CREATE_MULTIPLE_RESIDENT_RELATIVE, {
		refetchQueries: [GET_ALL_AWAITNG_RESIDENTS_BY_ORGANIZATION_ID]
	})

	const [doUploadImage, { loading: uploadLoading }] = useMutation(UPLOAD_IMAGE);


	useEffect(() => {
		setRooms(allRooms?.getAllRoomsByOrganizationId)
	}, [allRooms])

	useEffect(() => {
		setDepartments(allDepartments?.getAllDepartmentsByOrganizationId)
	}, [allDepartments])


	const kmdImportPerson = async (e) => {
		e.preventDefault();

		try {
			setLoading(true)
			const body = qs.stringify({
				endpoint: user?.data?.organization?.municipality?.kmdRootEndpoint,
				token: user.KMDAuth?.token,
				cpr: resident?.cpr,
			});

			const { data: residentData } = await axios.post(`${process.env.REACT_APP_KMD_URL}/resident/import`, body);

			setResident(formatResident(residentData))

			const kmdRelatives = formatRelatives(residentData?.relatives)
			const kmdRelativesEmails = kmdRelatives?.map(d => d?.email).filter(Boolean)
			const { data: relatives } = await checkIfUserExistsByEmail({ variables: { emails: kmdRelativesEmails } })

			const existingUsers = relatives?.checkIfUsersExistByEmail;
			// .filter(d => {
			// 	// only return relatives that are from same organization
			// 	return parseInt(d?.organizationId) === parseInt(user?.data?.organization?.id)
			// })

			const existingRelativeData = existingUsers?.map(d => {
				return {
					relative: d,
					input: {
						executorId: user?.data?.id,
						userId: d?.id,
						residentId: null, // residentId is set later as the resident doesnt exist in the database yet
						relation: kmdRelatives?.find(e => e?.email === d?.email)?.relation
					}
				}
			})
			setExistingRelatives(existingRelativeData)

			const newRelatives = kmdRelatives?.filter(d => !existingUsers?.some(e => e?.email === d?.email))
			setRelatives(newRelatives)

			const image = await convertBase64ToFile(residentData?.resident?.imageBase64);
			setImage(image)
			setErrors({})
			setDataImported(true)
		} catch (err) {
			setErrors({ ...errors, cpr: 'Der kunne ikke findes en borger med dette cpr-nummer. (kan indtastes med eller uden bindestreg)' })
		} finally {
			setLoading(false)
		}
	};

	const createResident = async (url) => {
		try {
			const validated = validate(
				validateNewResident,
				{ resident, room, department },
				setErrors
			)

			if (!validated) {
				setSnackbarMessage(alertWarningMessages.ERROR('Noget gik galt, tjek venligst at alle felter er udfyldt korrekt.'))
				return setShowCreateModal(false)
			}

			image
				? ImageUploadQuery(handleSubmit, image, doUploadImage)
				: handleSubmit()
		} catch (error) {
			console.error(error);
		}
	}

	const handleSubmit = async (url) => {
		try {
			const resident = await createResidentWithRelatives({
				variables: {
					input: {
						residentInput: formatResidentForSubmit(url),
						relativesInput: formatRelativesForSubmit()
					},
				}
			});

			if (existingRelatives?.length && resident?.data?.createResidentWithRelatives?.id) {
				try {
					await createMultipleResidentRelative(
						{
							variables: {
								input: existingRelatives?.map(d => {
									return {
										...d.input,
										residentId: parseInt(resident?.data?.createResidentWithRelatives?.id)
									}
								})
							}
						}
					)
				} catch (error) {
					console.error(error)
				}
			}

			history.push(`${residentInfomationRoute}/${resident?.data?.createResidentWithRelatives?.id}`)
		} catch (error) {
			if (error?.message === 'Cpr already exists') {
				setErrors({ cpr: 'Der findes allerede en borger med dette cpr.' })
			}

			if (error?.message === 'Invalid cpr') {
				setErrors({ cpr: 'Indtast venligst korrekt CPR-nummer (kan indtastes med eller uden bindestreg)' })
			}

			if (error?.message === 'Email already in use') {
				setErrors({ email: 'Der findes allerede en borger med denne email' })
			}

			if (error?.message.endsWith(': Email already in use')) {
				const email = error.message.split(':')[0];
				setErrors({ relatives: { email, text: 'Der findes allerede en pårørende med denne email' }})
			}
		} finally {
			setShowCreateModal(false)
		}
	}

	useEffect(() => {
		if (!_.isEmpty(user.KMDAuth)) {
			const expireAt = moment(user.KMDAuth.createdAt).add(user.KMDAuth.expires, 'seconds')
			if (expireAt.isBefore(moment())) {
				handleKMDLogin();
			}
		} else {
			handleKMDLogin();
		}
	}, []);

	const formatResidentForSubmit = (url) => {
		return {
			...resident,
			mobile: resident?.mobile ? uploadMobileFormat(resident?.mobile?.value) : null,
			phone: resident?.phone ? uploadMobileFormat(resident?.mobile?.value) : null,
			email: resident?.email ? resident?.email : null,
			executorId: user.data.id,
			cpr: resident?.cpr.replace('-', ''),
			roomId: room ? Number(room?.id) : null,
			organizationId: user?.data?.organization?.id,
			imageUrl: url
		}
	}

	const formatRelativesForSubmit = () => {
		const formatted = relatives?.map((relative, index) => {
			return {
				firstName: relative?.firstName,
				lastName: relative?.lastName,
				email: relative?.email ? relative?.email : null,
				relation: relative?.relation,
				sendInvitation: relative?.sendInvitation,
				mobile: relative?.mobile?.value ? uploadMobileFormat(relative?.mobile?.value) : null,
				roleId: relative?.role?.id,
				organizationId: parseInt(user.data.organization.id),
				loginMethod: relative?.loginMethod?.id,
				password: 'NMjQu74mChR0oSoZG7iZ',
				executorId: user.data.id
			}
		})

		return formatted
	}

	const handleDepartmentChange = (value) => {
		setRoom(null)
		setDepartment(value)
	}

	const handleKMDLogin = () => {
		useKMD && loginKmd(user.data.organization.municipality)
	}

	const TopBar = () => (
		<div className="flex items-start justify-between h-20 px-4 pt-4 border-b shadow gap-x-4 ">
			<div className="w-full">
				<KTimeline
					steps={getResidentTimeLine2(resident, relatives, consentGiven, dataImported, room)}
				/>

			</div>
			<div className="flex items-center justify-end ">
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton
						label="OPRET BORGER"
						disabledText="Borgeren kan ikke oprettes før der er givet samtykke."
						disabled={!consentGiven}
						className="min-w-full"
						onClick={() => setShowCreateModal(true)}
					/>
				</div>
			</div>

		</div>
	)

	const onImageChange = async (e) => {
		if (e) {
			const [file] = e.target.files;
			setImage(file);
		} else {
			setImage(null)
		}
	};

	return (
		<KPageWrapper
			paths={[{
				title: 'Borgeroversigt',
				route: residentsRoute
			}, {
				title: 'Opret borger'
			}]}
			contentContainerStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
			loading={[createLoading, uploadLoading, loading]?.some(e => e === true)}
		>
			<KSubWrapper TopBar={TopBar} height="calc(100% - 80px)">

				<KConfirmModal
					open={showCreateModal}
					setOpen={() => setShowCreateModal(!showCreateModal)}
					title="Opret borger"
					description="Er du sikker på, at du vil oprette borger?"
					handleConfirm={createResident}
					confirmText="Opret"
				/>
				<KSnackbar
					open={snackbarMessage.msg}
					setOpen={(v) => { setSnackbarMessage({ ...snackbarMessage, msg: v }) }}
					title={snackbarMessage?.msg}
					duration={6000}
					verticalPosition="bottom"
					horizontalPosition="center"
					type={snackbarMessage.type}
					key={snackbarMessage.timeStamp}
				/>

				<div className="grid grid-cols-1 gap-y-6">
					<KSection
					>
						<div>
							<div className="flex items-center justify-between gap-4 py-4 mt-2">
								<div className="">
									<p className="text-xl font-semibold text-gray-900">Ja tak til Kintella</p>
									<p className="text-base text-gray-500">Borgeren har accepteret at få en profil på Kintella, som kan deles med borgerens pårørende og personale.</p>
								</div >
								<KToggle
									checked={consentGiven}
									onChange={(value) => setConsentGiven(value)}
								/>
							</div>
						</div>
					</KSection>
					<KSection
						title="Stamdata"
						description="Hent og opdatér borgerens stamdata."
					>
						<div>
							<div style={{ maxWidth: "33%" }} className="items-end gap-x-2">

								<div className="w-full">
									<KInput
										label="CPR-Nummer"
										value={resident?.cpr}
										onChange={(v) => setResident({ ...resident, cpr: v })}
										error={errors?.cpr}
										required
									/>
								</div>
								<div className="w-full mt-2">
									<KButton onClick={(e) => kmdImportPerson(e)} label="Hent data fra Nexus" />
								</div>
							</div>
							<div className="mt-8">
								<KAvatar
									image={image}
									handleChange={(e) => onImageChange(e)}
									handleRemove={(e) => onImageChange(null)}
								/>
							</div>
							<div className="grid grid-cols-3 mt-5 gap-x-4 gap-y-2">
								<KInput
									label="Fornavn"
									value={resident?.firstName}
									onChange={(v) => setResident({ ...resident, firstName: v })}
									error={errors?.firstName}
									required
								/>
								<KInput
									label="Mellemnavn"
									value={resident?.middleName}
									onChange={(v) => setResident({ ...resident, middleName: v })}
									error={errors?.middleName}
								/>
								<KInput
									label="Efternavn"
									value={resident?.lastName}
									onChange={(v) => setResident({ ...resident, lastName: v })}
									error={errors?.lastName}
									required
								/>
								<KPhoneInput
									label="Telefon"
									value={resident?.phone?.value}
									onChange={(v) => setResident({ ...resident, phone: v })}
									error={errors?.phone}
								/>
								<KPhoneInput
									label="Mobil"
									value={resident?.mobile?.value}
									onChange={(v) => setResident({ ...resident, mobile: v })}
									error={errors?.mobile}
								/>
								<KInput
									type="email"
									label="Email"
									value={resident?.email}
									onChange={(v) => setResident({ ...resident, email: v })}
									error={errors?.email}
								/>
							</div>
						</div>
					</KSection>
					<KSection
						title="Bolig"
						description="Vælg det afsnit/afdeling og bolig, som borgeren skal bo på. Du kan også vælge at gøre dette senere"
					>
						<div className="grid grid-cols-2 gap-x-4">
							<KSelect
								label="Afsnit"
								selected={department}
								elements={departments}
								handleSelect={(e) => handleDepartmentChange(e)}
							/>
							<KSelect
								label="Bolig"
								selected={room}
								elements={department ? rooms?.filter(room => room?.departmentId == department?.id) : rooms}
								handleSelect={(e) => setRoom(e)}
								checkDisabled={(e) => e?.resident && e?.resident?.id !== room?.resident?.id}
								error={errors?.room}
								disableText="Optaget"
							/>
						</div>
					</KSection>
					<KSection
						title="Pårørende"
						description="Tilføj de pårørende, som du ønsker at tilknytte borgeren i Kintella."
						warning="Vær opmærksom på, at Kintella sender en invitations-mail til den pårørende. Hvis markeringen er grøn under Send invitation, sker det med det samme, når du aktiverer borgeren. Tryk på Rediger hvis du vil ændre valget og sende invitationen senere."
						hideWarningHeader={true}
					>
						<Relatives
							setRelatives={setRelatives}
							relatives={relatives}
							existingRelatives={existingRelatives}
							error={errors?.relatives}
							setExistingRelatives={setExistingRelatives}
						/>
					</KSection>
				</div>

			</KSubWrapper>
		</KPageWrapper>
	);
}
