import FolderIcon from '@mui/icons-material/Folder';
import Colors from '../../../../styles/Colors';
// import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'

import React, { useState, useRef, useEffect } from 'react';
import KDocumentDropdown from '../../../../components/selectors/dropdowns/KDocumentDropdown';
import { DELETE_RESIDENT_DOCUMENT_FOLDER_BY_ID, UPDATE_RESIDENT_DOCUMENT_FOLDER } from '../../../../graphql/graphqlMutations';
import { GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID } from '../../../../graphql/graphqlQueries';
import { useMutation } from '@apollo/client';
import { alertWarningMessages } from '../../../../utils/globalTypes';

export default function Folder({
	folder,
	onClick = () => { },
	onSelectChange = () => { },
	onUpdate = () => { },
	onError = () => { },
	allFolders = [],
	setSnackMessage,
	handleDelete
}) {
	const [rename, setRename] = useState(false);
	const [name, setName] = useState(folder.name);
	const [folderParentId, setFolderParentId] = useState(false);

	// update folder by
	const [updateFolder, { loading: updateFolderLoading }] = useMutation(UPDATE_RESIDENT_DOCUMENT_FOLDER, {
		refetchQueries: [GET_ALL_RESIDENT_DOCUMENT_FOLDERS_BY_RESIDENT_ID]
	});

	const handleUpdateFolder = async (folder) => {
		try {
			await updateFolder({
				variables: {
					input: {
						id: folder.id,
						name: folder.name,
						parentId: folder.parentId,
					}
				}
			});


			setSnackMessage(alertWarningMessages.UPDATED('Mappen'))
		} catch (err) {
			setSnackMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const inputRef = useRef(null);

	useEffect(() => {
		if (rename) {
			inputRef.current.focus();
		}
	}, [rename]);

	const resetName = () => {
		// setName(folder.name);
		setRename(false);
	}

	const handleRename = (e) => {
		e.stopPropagation();
		setRename(true);
	}

	const handleUpdate = (e) => {
		if (!name) return;

		if (allFolders.some(f => f.name === name && f.id !== folder.id)) {
			return setSnackMessage(alertWarningMessages.ERROR("En mappe med dette navn eksisterer allerede"));
		}

		setRename(false);
		if (name !== folder.name) {
			handleUpdateFolder({ ...folder, name: name })
		}
	}

	// listen in parent id update
	useEffect(() => {
		if (folderParentId !== false && folderParentId !== folder.parentId) {
			onUpdate({ ...folder, parentId: folderParentId });
			// handleUpdate();
		}
	}, [folderParentId]);

	const getNumberOfFilesText = (folder) => {
		const totalChildren = (folder?.documents?.length ?? 0) + (folder?.children?.length ?? 0);
		return `${totalChildren} ${totalChildren === 1 ? 'fil': 'filer'}`;
	}

	return (
		<div
			className="relative pt-2 rounded-lg w-36 bg-indigo-50"
		>
			{folder.isResident && <KDocumentDropdown
				element={folder}
				elements={allFolders}
				handleDelete={(e) => handleDelete(e)}
				handleEdit={() => setRename(true)}
				handleUpdate={(folder) => handleUpdateFolder(folder)}
				handleReset={() => resetName()}
			/>}

			<div className="flex flex-col items-center pb-2 cursor-pointer" onClick={() => onClick()} >
				<div className='mt-5' >
					<svg xmlns="http://www.w3.org/2000/svg" fill={Colors.lightBlack} viewBox="0 0 24 24" class="w-16 h-16"  >
						<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
					</svg>
				</div>
				<div className='text-gray-400'>
					{getNumberOfFilesText(folder)}
				</div>
				{!rename &&
					<div className="text-sm text-center max-w-20 cursor-text" onClick={(e) => handleRename(e)}>
						{name || folder?.name}
					</div>
				}

				{rename &&
					<div className='text-white' onClick={(e) => e.stopPropagation()}>
						<input
							ref={inputRef}
							onKeyDown={(event) => {
								if (event.key === 'Enter') {
									handleUpdate()
								}
								if (event.key === 'Escape') {
									resetName();
								}
							}}
							onBlur={() => resetName()}
							type="text"
							value={name}
							className='w-24 h-5 text-sm text-center rounded'
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
				}
			</div>
		</div >
	)
}



