import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import styled from "styled-components";

export default function KTable({ style, headers, data, RenderItem }) {
	console.log(data)
	return (
		<div>
			<TableContainer
				component={Paper}
				sx={{
					width: 'max-content',
					...style
				}}
			>
				<Table fixedHeader={false} sx={{ tableLayout: 'auto' }}>
					<TableHead>
						<TableRow>
							{headers?.map(e => (
								<StyledCell>{e}</StyledCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((row) => (
							<RenderItem row={row} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

const StyledCell = styled(TableCell)(() => ({
	fontFamily: 'MetricBold !important',
	fontSize: '14px !important',
	width: '180px !important',
	padding: '10px 15px !important'
}));

const StyledP = styled.p({
	marginTop: 5,
	marginLeft: 5
})
