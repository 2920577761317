import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import Residents from "../pages/resident/Residents";
import CreateResident from "../pages/resident/createResident/CreateResident";
import ResidentInformation from "../pages/resident/ResidentInformation";

import { createResidentRoute, residentInfomationRoute, residentOnboardingRoute } from "./residentRoutes";

export default function ResidentRouter() {
	let { path } = useRouteMatch();
	return (
		<Switch>
			<Route
				exact
				path={path}
				component={Residents}
			/>
			<Route
				exact
				path={`${createResidentRoute}`}
				component={CreateResident}
			/>
			<Route
				exact
				path={`${residentInfomationRoute}/:residentId`}
				component={ResidentInformation}
			/>
		</Switch>
	);
}
