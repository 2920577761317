import React, { useEffect, useState } from 'react';
import KButton from '../../components/KButton';
import { GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID, GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID, GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID } from '../../graphql/graphqlQueries';
import Avatar from "@mui/material/Avatar";
import Checkbox from '@mui/material/Checkbox';
import { assignmentsRoute, createAssignmentRoute, editAssignmentRoute, editNewsRoute } from '../../routes/mainRoutes';
import { useHistory } from 'react-router-dom';
import KPageWrapper from '../../components/KPageWrapper';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { assignmentConsentStatus, assignmentResponsibleStatus, assignmentSortStatus, assignmentStatus, getAssigmentParticipantStatus, getAssigmentStatus, getSimpleAssignmentStatus, responsible } from '../../utils/assignmentUtils';
import { Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { canEditAndDelete, isLeader, isWorker } from '../../utils/roles';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { classNames } from '../../utils/tailwindUtils';
import KGlobalFilterTable from '../../components/graphql/KGlobalFilterTable';
import { PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';;
import KModal from '../../components/modals/KModal';
import Todo from './Todo';
import KConfirmModal from '../../components/modals/KConfirmModal';
import { useMutation } from '@apollo/client';
import { DELETE_MULTIPLE_TODO, DELETE_TODO } from '../../graphql/graphqlMutations';
import KSnackbar from '../../components/KSnackbar';
import { alertWarningMessages } from '../../utils/globalTypes';
import KSearchInput from '../../components/inputs/KSearchInput';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import ReactTooltip from 'react-tooltip';
import KCheckbox from '../../components/inputs/KCheckbox';

export default function Todos() {
	const history = useHistory();

	const user = useSelector(state => state.user)

	const [showFilters, setShowFilters] = useState(false);
	const [selectedStatusFilters, setSelectedStatusFilters] = useState(assignmentStatus);
	const [selectedResponsibleFilters, setSelectedResponsibleFilters] = useState(assignmentResponsibleStatus);
	const [selectedSortType, setSelectedSortType] = useState(assignmentSortStatus);
	const [search, setSearch] = useState();
	const [currentTodos, setCurrentTodos] = useState();
	const [todosLoading, setTodosLoading] = useState();

	const [activeTodo, setActiveTodo] = useState();
	const [showActiveTodo, setShowActiveTodo] = useState();
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showDeleteMultipleModal, setShowDeleteMultipleModal] = useState(false)
	const [deleteTodos, setDeleteTodos] = useState(false)
	const [deleteFunc, setDeleteFunc] = useState(false);

	const [message, setMessage] = useState({ msg: "", type: "" });

	const [deleteTodo, { deleteLoading }] = useMutation(DELETE_TODO, {
		refetchQueries: [GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID]
	});

	const [deleteMutipleTodo, { loading: multipleDeleteLoading }] = useMutation(DELETE_MULTIPLE_TODO, {
		refetchQueries: [GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID]
	});

	const deleteAssignment = () => {
		deleteTodo({
			variables: {
				deleteTodoByIdId: activeTodo?.id,
			},
		})
			.then(() => {
				setShowActiveTodo(false)
				setShowDeleteModal(false)
				setMessage(alertWarningMessages.DELETED('Opgaven'))
			})
			.catch((err) => console.log(JSON.stringify(err)));
	};

	const deleteMultipleAssignments = async () => {
		try {
			await deleteMutipleTodo({
				variables: {
					ids: deleteTodos?.map(e => e?.id)
				}
			})
			setShowActiveTodo(false)
			setShowDeleteMultipleModal(false)
			setMessage(alertWarningMessages.DELETED('Opgaverne'))
		} catch (err) {
			console.log(JSON.stringify(err));
		}
	}

	const handleDeleteTodos = (selected, deleteFunc) => {
		setShowDeleteMultipleModal(true)
		setDeleteTodos(selected)
		setDeleteFunc()
	}

	const Assignment = ({ item, index, selected, handleToggleClick, setChecked }) => (
		<tr
			className={classNames(index % 2 === 0 ? 'cursor-pointer hover:bg-gray-50' : "cursor-pointer hover:bg-gray-50")}
			key={index}
			onClick={(e) => {
				setActiveTodo(item)
				setShowActiveTodo(true)
			}}
		>
			<td onClick={(e) => {
				e.stopPropagation()
				handleToggleClick(e.target.checked, item)
			}} className="relative px-7 sm:w-12 sm:px-6">
				<div className="-mt-3 left-4 top-1/2">
					<input
						type="checkbox"
						className="w-4 h-4 border-gray-300 rounded text-darkPurple focus:ring-darkPurple"
						checked={selected?.includes(item)}
					/>
				</div>
			</td>
			<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
				<div className="text-gray-900">{item?.title}</div>
			</td>
			<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
				{item?.participants && item?.participants?.length > 0 &&
					<div
						data-for={item?.id}
						data-tip
					>
						<div>
							{item?.participants?.length > 1 &&
								<div className="flex items-center justify-between px-2 py-1 text-gray-900 bg-gray-200">
									<p>Flere borgere</p>
									<ChevronDownIcon className="w-3 h-3" />
								</div>
							}

							{item?.participants?.length === 1 &&
								<p>{item?.participants[0]?.resident?.fullName}</p>
							}
							<ReactTooltip place="bottom" className="shadow-lg opacity-100" effect="solid" backgroundColor="#fff" id={item?.id} aria-haspopup="true">
								<div className="text-gray-900 divide-y w-96">
									{item?.participants?.map(participant => {
										return (
											<div className="flex justify-between py-2 gap-x-6">
												{/* <p>Samtykke</p> */}
												<p className="text-left">{participant?.resident?.fullName}</p>
												<p>{getAssigmentParticipantStatus(participant?.todoParticipantStatus)}</p>

											</div>
										)
									})}
								</div>
							</ReactTooltip>
						</div>
					</div>

				}

				{item?.participants?.length === 0 &&
					<div className="text-gray-900">{item?.resident?.fullName}</div>
				}

			</td>
			<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
				<div className="text-gray-900">{item?.creator?.fullName}</div>
			</td>
			<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
				<div className="text-gray-900">{responsible[item?.responsible]?.name}</div>
			</td>
			<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
				<div className="text-gray-900">
					{item?.participants?.length > 1
						? <div>Se status under opgaven</div>
						: <div>{getAssigmentParticipantStatus(item?.participants[0]?.todoParticipantStatus)}</div>
					}
				</div>
				<ReactTooltip place="bottom" className="shadow-lg opacity-100" effect="solid" backgroundColor="#fff" id={item?.id} aria-haspopup="true">
					<div className="text-gray-900 divide-y w-96">
						{item?.participants?.map(participant => {
							return (
								<div className="flex justify-between py-2 gap-x-6">
									{/* <p>Samtykke</p> */}
									<p className="text-left">{participant?.resident?.fullName}</p>
									<p>{getAssigmentParticipantStatus(participant?.todoParticipantStatus)}</p>

								</div>
							)
						})}
					</div>
				</ReactTooltip>
			</td>
			<td className="py-2 text-sm text-gray-500 px-7 whitespace-nowrap">
				<div className="text-gray-900">{item?.deadline ? item?.deadline : '-'}</div>
			</td>
		</tr >
	);

	// TODO - lav om til 1 funktion
	const handleSelectStatusFilter = (status) => {
		let newFilters = Object.assign({}, selectedStatusFilters);
		Object.keys(newFilters).forEach(v => newFilters[v].checked = false)
		newFilters[status[0]].checked = !newFilters[status[0]].checked;
		setSelectedStatusFilters(newFilters)
	}
	const handleSelectResponsibleFilter = (status) => {
		let newFilters = Object.assign({}, selectedResponsibleFilters);
		Object.keys(newFilters).forEach(v => newFilters[v].checked = false)
		newFilters[status[0]].checked = !newFilters[status[0]].checked;
		setSelectedResponsibleFilters(newFilters)
	}

	const handleSelectSortFilter = (status) => {
		let newFilters = Object.assign({}, selectedSortType);
		Object.keys(newFilters).forEach(v => newFilters[v].checked = false)
		newFilters[status[0]].checked = !newFilters[status[0]].checked;
		setSelectedSortType(newFilters)
	}


	const filterStatus = (item) => {
		if (selectedStatusFilters.ALL.checked) {
			return true;
		} else if (selectedStatusFilters.OPEN.checked) {
			return (
				!item.status.accepted &&
				!item.status.solved &&
				!item.status.consent !== assignmentConsentStatus.NOT_REQUIRED.value &&
				item.status.consent !== assignmentConsentStatus.REFUSED.value &&
				item.status.consent !== assignmentConsentStatus.REQUIRED.value
			)
		} else if (selectedStatusFilters.SOLVED.checked) {
			return item.status.solved
		} else if (selectedStatusFilters.ACCEPTED.checked) {
			return item.status.accepted && !item.status.solved && item.status?.acceptedBy?.id === user.data.id
		} else if (selectedStatusFilters.AWAITING.checked) {
			return item.status.consent === assignmentConsentStatus.REQUIRED.value
		} else if (selectedStatusFilters.REFUSED.checked) {
			return item.status.consent === assignmentConsentStatus.REFUSED.value
		} else if (selectedStatusFilters.SOLVED.checked) {
			return item.status.solved
		}
		return false;
	}

	const filterResponsible = (item) => {
		if (selectedResponsibleFilters.RELATIVE.checked) {
			return item.responsible === responsible.RELATIVE.value;
		} else if (selectedResponsibleFilters.WORKER.checked) {
			return item.responsible === responsible.WORKER.value
		} else {
			return true;
		}
	}

	const sortDeadline = (a, b) => {
		if (selectedSortType.CREATED_AT.checked) {
			if (moment(parseInt(a.createdAt)).isSameOrBefore(moment(parseInt(b.createdAt)))) return 1;
			if (moment(parseInt(a.createdAt)).isSameOrAfter(moment(parseInt(b.createdAt)))) return -1;
		} else if (selectedSortType.DEADLINE.checked) {
			if (moment(a.deadline, 'DD/MM/YYYY').isSameOrBefore(moment(b.deadline, 'DD/MM/YYYY'))) return -1;
			if (moment(a.deadline, 'DD/MM/YYYY').isSameOrAfter(moment(b.deadline, 'DD/MM/YYYY'))) return 1;
		}
	}

	const filterSearch = (item) => {
		return search?.length > 0
			? item?.title?.toLowerCase()?.includes(search?.toLowerCase())
			|| item?.creator?.fullName?.toLowerCase()?.includes(search?.toLowerCase())
			|| item?.resident?.fullName?.toLowerCase()?.includes(search?.toLowerCase())
			: true;
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Opgaver'
			}]}
			buttons={[
				<KButton
					label="OPRET OPGAVE"
					onClick={() => history.push(createAssignmentRoute)}
				/>
			]}
			loading={[deleteLoading]?.some(e => e === true)}
			contentContainerStyle={{ padding: 0 }}
		>
			<KConfirmModal
				open={showDeleteModal}
				setOpen={setShowDeleteModal}
				confirmText="Slet opgave"
				handleConfirm={() => deleteAssignment()}
				title="Slet opgave"
				description="Er du sikker på at du vil slette opgaven?"
			/>
			<KConfirmModal
				open={showDeleteMultipleModal}
				setOpen={setShowDeleteMultipleModal}
				confirmText="Slet opgaver"
				handleConfirm={() => deleteMultipleAssignments()}
				title="Slet opgaver"
				description="Er du sikker på at du vil slette opgaverne?"
			/>
			<KModal open={showFilters} setOpen={setShowFilters}>
				<div className="p-6">
					<div>
						<FilterHeader>FILTRER EFTER STATUS</FilterHeader>
						<FilterContainer>
							{Object.entries(selectedStatusFilters).map((status, i) => {
								return (
									<FilterContent key={i} onClick={() => handleSelectStatusFilter(status)}>
										<p>{status[1].name}</p>
										<CheckBox checked={status[1].checked} />
									</FilterContent>
								)
							})}
						</FilterContainer>
					</div>
					<div>
						<FilterHeader>FILTRER EFTER ANSVARLIG</FilterHeader>
						<FilterContainer>
							{Object.entries(selectedResponsibleFilters).map((status, i) => {
								return (
									<FilterContent key={i} onClick={() => handleSelectResponsibleFilter(status)}>
										<p>{status[1].name}</p>
										<CheckBox checked={status[1].checked} />
									</FilterContent>
								)
							})}
						</FilterContainer>
					</div>
					<div>
						<FilterHeader>SORTER EFTER</FilterHeader>
						<FilterContainer>
							{Object.entries(selectedSortType).map((status, i) => {
								return (
									<FilterContent key={i} onClick={() => handleSelectSortFilter(status)}>
										<p>{status[1].name}</p>
										<CheckBox checked={status[1].checked} />
									</FilterContent>
								)
							})}
						</FilterContainer>
					</div>

				</div>
			</KModal>
			<KModal width={window.innerWidth / 1.5} open={showActiveTodo} setOpen={setShowActiveTodo}>
				<div className={classNames(
					activeTodo ? 'block' : 'hidden',
					"relative "
				)}>
					<div className="flex items-center justify-end h-12 p-3 shadow">
						<div className="flex text-gray-900 gap-x-4">
							{canEditAndDelete(user, activeTodo?.creator) &&
								<>
									<IconButton onClick={() => history.push(editAssignmentRoute(activeTodo?.id))}>
										<PencilIcon className="w-5 h-5" />
									</IconButton>
									<IconButton onClick={() => setShowDeleteModal(true)}>
										<TrashIcon className="w-5 h-5" />
									</IconButton>
								</>
							}
							<IconButton onClick={() => setShowActiveTodo(false)}>
								<XMarkIcon className="w-5 h-5" />
							</IconButton>
						</div>

					</div>
					<Todo loading={todosLoading} setLoading={setTodosLoading} todo={currentTodos?.find(e => e?.id == activeTodo?.id)} />
				</div >
			</KModal >
			<Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ flex: 1, justifyContent: 'space-between', display: 'flex' }}>
					<div>
						<KSearchInput
							placeholder={"Søg på opgaver"}
							height="h-8"
							value={search}
							onChange={(v) => setSearch(v)}
							autoFocus
						/>

					</div>
					<Tooltip onClick={() => setShowFilters(!showFilters)} title="Opgave filtre">
						<IconButton>
							<FilterListIcon color="primary" />
						</IconButton>
					</Tooltip>

				</div>
			</Toolbar>
			< div className="grid grid-cols-1 p-4 gap-x-2" >
				<div>

					<KGlobalFilterTable
						headers={['Titel', 'Omhandler', 'Oprettet af', 'Ansvarlig for udførelse', 'Status', 'Frist']}
						RenderItem={Assignment}
						residentQuery={GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID}
						departmentQuery={GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID}
						organizationQuery={
							GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID
						}
						pageSize={999}
						type="opgaver"
						hidePagination={true}
						ordering={["createdAt", "asc"]}
						filterMethod={(item) => {
							return (
								isWorker({ data: item.creator }) &&
								filterStatus(item) &&
								filterResponsible(item) &&
								filterSearch(item)
							)
						}}
						sortMethod={(a, b) => {
							return (
								sortDeadline(a, b)
							)
						}}
						onNewData={(e) => {
							setCurrentTodos(e?.results)
							setTodosLoading(false)
						}}
						handleDelete={(selected) => handleDeleteTodos(selected)}
					/>
				</div>
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KPageWrapper >
	)
}

const FilterHeader = styled.p({
	fontSize: 14,
	fontFamily: 'MetricBold',
	color: Colors.lightBlack,
	marginTop: 10,
	marginBottom: 10,
	textAlign: 'center'
})

const FilterContainer = styled.div({
	display: 'flex',
	gap: 10,
	flexDirection: 'column'
})

const FilterContent = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	cursor: 'pointer'
})

const CheckBox = styled(Checkbox)(() => ({
	padding: '0px !important'
}));