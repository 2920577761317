import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import React, { useEffect, useState } from 'react'
import { isImageType } from '../utils/fileUtils';
import DummyImage from '../assets/dummyImg.png'

export default function KFileViewer({ file, imgStyles, imgAlt }) {
	const [isImage, setIsImage] = useState()

	useEffect(() => {
		setIsImage(isImageType(file))
	}, [file])

	if (file) {
		return (
			<img
				style={imgStyles ? imgStyles : { objectFit: 'cover', minWidth: '100%', maxHeight: 400 }}
				alt={imgAlt ? imgAlt : 'Billede'}
				src={file ? file : null}
			/>
		)
	} else if (!isImage && file) {
		return (
			<iframe
				style={{ overflow: 'hidden' }}
				src={`${file}#toolbar=0&navpanes=0&scrollbar=0`}
				type="application/pdf"
				frameBorder="0"
				scrolling="none"
				height="100%"
				width="100%"
			/>
		)
	} else {
		return (
			<div />
		)
	}
}
