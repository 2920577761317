import React, { useState } from 'react'
import KInput from '../../../../components/inputs/KInput'
import { useLazyQuery, useMutation } from '@apollo/client'
import KButton from '../../../../components/KButton'
import { GET_RELATIVE_BY_EMAIL, GET_RESIDENT_BY_ID } from '../../../../graphql/graphqlQueries'
import { CREATE_RESIDENT_RELATIVE } from '../../../../graphql/graphqlMutations'
import AddExistingRelativeCard from './AddExistingRelativeCard'
import KTitle from '../../../../components/KTitle'
import KConfirmModal from '../../../../components/modals/KConfirmModal'
import { useSelector } from 'react-redux'
import { relationArray } from '../../../../utils/relationUtil'
import KSelect from '../../../../components/selectors/KSelect'
import KSnackbar from '../../../../components/KSnackbar'

export default function AddExistingRelatives({ resident = {}, onRelativeAdded = () => { }, disableTitle = false, disableConfirm = false }) {
	const [existingRelativeEmail, setExistingRelativeEmail] = useState("")
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [relative, setRelative] = useState()
	const [relation, setRelation] = useState()
	const [message, setMessage] = useState("");
	const [errors, setErros] = useState({ msg: "", type: "", timeStamp: null });

	const user = useSelector(state => state.user);
	const [getUserByEmail, { data, loading }] = useLazyQuery(GET_RELATIVE_BY_EMAIL, {
		fetchPolicy: "network-only"
	})
	const [createResidentRelative] = useMutation(CREATE_RESIDENT_RELATIVE, {
		refetchQueries: [GET_RESIDENT_BY_ID]
	})

	const addResidentRelative = async () => {
		const input = {
			executorId: user?.data?.id,
			userId: relative?.id,
			residentId: parseInt(resident?.id) || null,
			relation: relation
		}

		if (!resident?.id) {
			setShowConfirmModal(false)
			onRelativeAdded({ relative, input })
			return;
		}

		try {
			await createResidentRelative({ variables: { input } })
			setShowConfirmModal(false)
			onRelativeAdded(input)

		} catch (error) {
			setErros(alertWarningMessages.UNKNOWN)
		}
	}

	const handleEmailChange = (value) => {
		setExistingRelativeEmail(value)
		setMessage("")
	}

	const relativeAlreadyExists = () => {
		return resident?.relatives?.some(e => e?.user?.email === existingRelativeEmail)
	}
	const alreadyExists = relativeAlreadyExists()

	const findRelative = async () => {
		setMessage("")
		const relativeData = await getUserByEmail({ variables: { email: existingRelativeEmail, orgId: user?.data?.organization?.id } })
		if (relativeData.data?.getRelativeByEmail) {
			setRelative(relativeData.data?.getRelativeByEmail)
			setMessage("")
		} else {
			setMessage("Ingen pårørende fundet med denne email")
		}
	}


	return (
		<div className="">
			{!disableTitle && <KTitle title="Tilføj eksisterende pårørende" />}
			<div className='flex flex-col ' >
				<KInput
					value={existingRelativeEmail}
					fullWidth={true}
					label="Email"
					onChange={(value) => handleEmailChange(value)}
				/>

				<div className={""} >
					<KButton className={"mt-2 mb-4"} label="Find pårørende" onClick={findRelative} loading={loading} disabledText={"Allerede pårørende"} disabled={relativeAlreadyExists()} />
					{alreadyExists && <p className="pt-4 text-red-500">Denne pårørende er allerede tilknyttet denne beboer</p>}
					{message && !alreadyExists && <p className="pt-4">{message}</p>}
				</div>
			</div>

			{relative && !alreadyExists &&
				<div className='flex flex-col mt-8 gap-y-4' >
					<KTitle title="Opret som pårørende" />

					<KSelect
						label="Vælg relation"
						selected={relationArray.find(e => e === relation)}
						elements={relationArray}
						handleSelect={(value) => setRelation(value)}
					/>
					<AddExistingRelativeCard
						disableAdd={alreadyExists}
						relative={relative}
						onAddClick={() => disableConfirm ? addResidentRelative() : setShowConfirmModal(true)}
					/>
				</div>
			}
			<KConfirmModal
				open={showConfirmModal}
				setOpen={setShowConfirmModal}
				title="Tilføj pårørende"
				description={`Er du sikker på du vil tilføje ${relative?.fullName} som ny pårørende til ${resident?.fullName || "borgeren"}?`}
				handleConfirm={addResidentRelative} />
			<KSnackbar
				open={errors.msg}
				setOpen={(v) => { setMessage({ ...errors, msg: v }) }}
				title={errors.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={errors.type}
				key={errors.timeStamp || ""}
			/>
		</div>
	)
}
