import React from 'react'
import styled from 'styled-components'
import Spinner from '../assets/spinner_no_bg.gif';

export default function KLoader({ maxHeight }) {
	return (
		<div className="flex items-center justify-center w-full h-full">
			<img style={{ maxHeight: maxHeight ? maxHeight : 100 }} src={Spinner} />
		</div>
	)
}

const LoaderContainer = styled.div({
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
})

const Loader = styled.img({
	maxHeight: 100
})