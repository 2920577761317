import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: "user",
	initialState: {
		token: "",
		data: null,
		loggedIn: false,
		KMDAuth: {},
		kombitAuth: {},
	},
	reducers: {
		setUser: (state, action) => {
			state.token = action.payload.token;
			state.data = action.payload.user;
		},
		setLoggedIn: (state, action) => {
			state.loggedIn = action.payload;
		},
		clearUser: (state) => {
			state.token = "";
			state.data = null;
			state.loggedIn = false;
		},
		setKMDAuth: (state, action) => {
			state.KMDAuth = action.payload
		},
		setKombitAuth: (state, action) => {
			state.kombitAuth = action.payload
		}
	},
});

// Action creators are generated for each case reducer function
export const { setUser, clearUser, setLoggedIn, setKMDAuth, setKombitAuth } = userSlice.actions;

export default userSlice.reducer;
