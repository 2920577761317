import React from 'react'
import SettingsCard from '../../components/cards/KSettingsCard'
import KPageWrapper from '../../components/KPageWrapper'
import ListAltIcon from '@mui/icons-material/ListAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { calendarActivityTemplatesRoute, interestGroupsRoute, roomGroupRoute, departmentGroupRoute, orgDocumentsRoute } from '../../routes/mainRoutes'

export default function SettingsNew({ history }) {
	return (
		<KPageWrapper
			paths={[{
				title: 'Administration',
			}]}
		>
			<section>
				<div className="grid gap-4 p-2 text-sm leading-6 bg-slate-50 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
					<SettingsCard
						onClick={() => history.push(calendarActivityTemplatesRoute)}
						icon={<ListAltIcon fontSize='large' />}
						title={'Skabeloner'}
						description={"Her kan du oprette og redigere dine skabeloner for kalenderaktiviteter"}
					/>
					{/* <SettingsCard
						onClick={() => history.push(interestGroupsRoute)}
						icon={<GroupsIcon fontSize='large' />}
						title={'Interessegrupper'}
						description={"Her kan du oprette og redigere dine interessegrupper"}
					/> */}
					<SettingsCard
						onClick={() => history.push(roomGroupRoute)}
						icon={<BedOutlinedIcon fontSize='large' />}
						title={'Boliger'}
						description={"Her kan du oprette og redigere boliger"}
					/>
					<SettingsCard
						onClick={() => history.push(departmentGroupRoute)}
						icon={<SafetyDividerIcon fontSize='large' />}
						title={'Afsnit'}
						description={"Her kan du oprette og redigere afsnit"}
					/>
					<SettingsCard
						onClick={() => history.push(orgDocumentsRoute)}
						icon={<InsertDriveFileOutlinedIcon fontSize='large' />}
						title={'Dokumenter til pårørende'}
						description={"Når du tilføjer dokumenter, bliver de tilgængelige for alle pårørende"}
					/>
				</div>
			</section >
		</KPageWrapper>
	)
}
