import React, { useState, useRef, useEffect } from 'react';

export default function KDropdown({
	Action,
	containerClass,
	contentClass,
	children,
	reset
}) {

	const [open, setOpen] = useState(false);

	const menuRef = useRef(null);

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setOpen(false);
			reset && reset()
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className={containerClass} >
			<Action onClick={() => setOpen(true)} />

			{/* <!-- Dropdown menu --> */}
			{open &&
				<div
					className={contentClass}
					onClick={(e) => setOpen(!open)}
					ref={menuRef}
				>
					{children}
				</div>
			}
		</div>

	)
}



