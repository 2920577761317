import React from "react";
import { Link } from "react-router-dom";
import * as routes from "../routes/mainRoutes";
import {
	FaHome,
	FaUserFriends,
	FaNewspaper,
	FaTasks,
	FaCalendarCheck,
	FaClock,
	Fa,
	FaVideo
} from "react-icons/fa";
import {
	MdDashboard, MdHelpCenter
} from "react-icons/md";
import {
	GiGears, GiGearStickPattern
} from "react-icons/gi";
import {
	IoMdSettings
} from "react-icons/io";
import KIcon from "./KIcon";
import { withRouter } from "react-router-dom";
import Colors from "../styles/Colors";
import styled from "styled-components";

function NavigationBar({ history }) {
	const NavigationItem = ({ to, title, Icon, iconSize }) => (
		<Link
			to={to}
			className={`flex flex-row py-2 px-6 space-x-4 font-semibold ${history?.location?.pathname.includes(to)
				? "text-mainGreen"
				: "text-gray-400"
				}`}
		>
			<div style={{ minWidth: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<KIcon size={iconSize ? iconSize : null} Icon={Icon} />
			</div>
			<NavigationTitle>{title}</NavigationTitle>
			{/* <div className="flex-10">
			</div> */}
		</Link>
	);
	return (
		<div className="flex flex-col h-full">
			{/* <NavigationItem to={routes.homeRoute} title="Hjem" Icon={FaHome} /> */}
			<NavigationItem
				to={routes.dashboardRoute}
				title="Overblik"
				Icon={MdDashboard}
				iconSize={27}
			/>
			<NavigationItem
				to={routes.residentsRoute}
				title="Borgeroversigt"
				Icon={FaUserFriends}
			/>
			<NavigationItem
				to={routes.newsRoute}
				title="Nyheder"
				Icon={FaNewspaper}
			/>
			<NavigationItem
				to={routes.assignmentsRoute}
				title="Opgaver"
				Icon={FaTasks}
			/>
			<NavigationItem
				to={routes.calendarRoute}
				title="Kalender"
				Icon={FaCalendarCheck}
			/>
			<NavigationItem
				to={routes.administrationRoute}
				title="Administration"
				Icon={IoMdSettings}
				iconSize={29}
			/>
			<NavigationItem
				to={routes.guidesRoute}
				title="Hjælp"
				Icon={MdHelpCenter}
				iconSize={28}
			/>

			{/* <NavigationItem
				to={routes.remindersRoute}
				title="Påmindelser"
				Icon={FaClock}
			/> */}
		</div>
	);
}

const NavigationTitle = styled.p({
	fontFamily: 'MetricSemiBold',
	fontSize: 18
})
export default withRouter(NavigationBar);
