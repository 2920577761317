import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import KSnackbar from '../../../components/KSnackbar';
import { useQuery, useMutation } from '@apollo/client';
import {
	CREATE_ORGANIZATION_DOCUMENT_FOLDER_BY_ID,
	DELETE_ORGANIZATION_DOCUMENT_FOLDER_BY_ID,
	UPLOAD_IMAGE,
	CREATE_ORGANIZATION_DOCUMENT
} from '../../../graphql/graphqlMutations';
import {
	GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID,
	GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID,
} from '../../../graphql/graphqlQueries';
import _ from 'lodash';
import KPageWrapper from '../../../components/KPageWrapper'
import KSearchInput from '../../../components/inputs/KSearchInput';
import KBreadcrumb from '../../../components/breadcrumbs/KBreadcrumb';
import KButton from '../../../components/KButton';
import KDocumentCard from '../../../components/cards/Documents/KDocumentCard';

import KSelect from '../../../components/selectors/KSelect';
import KSection from '../../../components/cards/KSection';
import KModalConfirm from '../../../components/modals/KConfirmModal';
import Folder from './Folder';
import { getFolderName } from '../../../utils/documentsUtils';
import { alertWarningMessages } from '../../../utils/globalTypes';
import imageUploadMutation from '../../../graphql/imageUploadMutation';
import { administrationRoute } from '../../../routes/mainRoutes'


export default function OrgDocuments() {
	const user = useSelector(state => state.user);
	const organizationId = parseInt(user?.data?.organization?.id);
	const [message, setMessage] = useState({ msg: "", type: "", timeStamp: null });
	const [searchPhrase, setSearchPhrase] = useState();

	const [documentUploaded, setDocumentUploaded] = useState({});
	const [allFolders, setAllFolders] = useState([]);
	const [allFoldersCopy, setAllFoldersCopy] = useState([]);
	const [allDocuments, setAllDocuments] = useState([]);
	const [allDocumentsCopy, setAllDocumentsCopy] = useState([]);

	const [sorting, setSorting] = useState(sortingOptions[0]);
	const [currentFolder, setCurrentFolder] = useState({});
	const [folderPath, setFolderPath] = useState([{
		name: 'Dokumenter',
	}]);

	const [showDelete, setShowDelete] = useState();
	const [folderDelete, setFolderDelete] = useState();

	const { loading: allFoldersLoading, data: allFolderData } = useQuery(
		GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID,
		{
			variables: {
				organizationId: organizationId,
				folderId: currentFolder?.id,
				order: sorting?.id,
			},
			fetchPolicy: "no-cache"
		}
	);

	const { loading: allDocsLoading, data: allDocsData } = useQuery(
		GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID,
		{
			variables: {
				organizationId: organizationId,
				folderId: currentFolder?.id,
				order: sorting?.id
			},
			fetchPolicy: "no-cache"
		}
	);

	// create new folder
	const [createFolder, { loading: createFolderLoading }] = useMutation(CREATE_ORGANIZATION_DOCUMENT_FOLDER_BY_ID, {
		refetchQueries: [GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID]
	});

	// delete folder by id
	const [deleteFolder, { loading: deleteFolderLoading }] = useMutation(DELETE_ORGANIZATION_DOCUMENT_FOLDER_BY_ID, {
		refetchQueries: [GET_ALL_ORGANIZATION_DOCUMENT_FOLDERS_BY_ORGANIZATION_ID]
	});

	const [doUploadFile] = useMutation(UPLOAD_IMAGE);

	// create new document
	const [createDocument, { loading: createDocumentLoading }] = useMutation(CREATE_ORGANIZATION_DOCUMENT, {
		refetchQueries: [GET_ALL_ORGANIZATION_DOCUMENTS_BY_ORGANIZATION_ID]
	});

	// set all folders
	useEffect(() => {
		setAllFolders(allFolderData?.getAllOrganizationDocumentFoldersByOrganizationId)
		setAllFoldersCopy(allFolderData?.getAllOrganizationDocumentFoldersByOrganizationId)
	}, [allFolderData]);

	// set all documents
	useEffect(() => {
		setAllDocuments(allDocsData?.getAllOrganizationDocumentsByOrganizationId)
		setAllDocumentsCopy(allDocsData?.getAllOrganizationDocumentsByOrganizationId)
	}, [allDocsData]);

	useEffect(() => {
		setAllFolders(allFoldersCopy?.filter(e => {
			return e.name?.toLowerCase()?.includes(searchPhrase?.toLowerCase())
		}))
		setAllDocuments(allDocumentsCopy?.filter(e => {
			return e.name?.toLowerCase()?.includes(searchPhrase?.toLowerCase())
		}))
	}, [searchPhrase])


	const onFolderClick = (folder) => {
		setFolderPath([...folderPath, folder]);
		setCurrentFolder(folder);
	}

	const handlePathChange = (folder, i) => {
		const newPath = folderPath.slice(0, (i + 1));
		setFolderPath(newPath);
		setCurrentFolder(folder);
	}

	const handleCreateNewFolder = async () => {
		try {
			await createFolder({
				variables: {
					input: {
						name: getFolderName(allFolders),
						parentId: currentFolder?.id || null,
						organizationId: organizationId,
					}
				}
			})

			setMessage(alertWarningMessages.CREATED('Mappe'))
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const onDeleteFolder = async () => {
		try {
			await deleteFolder({
				variables: {
					id: folderDelete?.id
				}
			})

			setShowDelete(false)
			setMessage(alertWarningMessages.DELETED('Mappen'));
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	}

	const createNewFile = async () => {
		try {
			const url = await imageUploadMutation(documentUploaded, doUploadFile)

			await createDocument({
				variables: {
					input: {
						name: documentUploaded.name,
						parentId: currentFolder?.id || null,
						organizationId: organizationId,
						url: url,
						creatorId: user?.data?.id
					}
				}
			})
			setMessage(alertWarningMessages.UPLOADED('Dokument'));
		} catch (err) {
			if (err?.message === 'Error: Filetype not supported') {
				return setMessage(alertWarningMessages.ERROR('Den valgte fil type er ikke supporteret.'))
			}
			setMessage({ msg: "Noget gik galt, prøv igen senere.", type: "error", timeStamp: Date.now() });

		}
	}

	// listne to changes in the file input
	useEffect(() => {
		if (documentUploaded?.name) {
			createNewFile();
		}
	}, [documentUploaded])

	const handleDeleteFolder = (folder) => {
		setFolderDelete(folder)
		setShowDelete(true)
	}

	const TopBar = () => (
		<div className="flex items-center justify-between h-12 pr-4 border-b">
			<p className="ml-4 text-lg font-bold text-gray-600">Dokumenter</p>
			<div className="grid grid-cols-2 gap-x-2">
				<KButton className="min-w-full" label="NY MAPPE" onClick={() => handleCreateNewFolder()} />
				<KButton>
					<label className='cursor-pointer' for="file">
						Upload dokument
					</label>
				</KButton>
				<input type="file" id="file" className='hidden' onChange={(e) => setDocumentUploaded(e.target.files[0])} />
			</div>

		</div>
	)

	return (
		<KPageWrapper
			paths={[{
				title: 'Administration',
				route: administrationRoute
			}, {
				title: 'Dokumenter'
			}]}
			buttons={[
				<KButton label="NY MAPPE" onClick={() => handleCreateNewFolder()} />,
				<KButton>
					<label className='cursor-pointer' for="file">
						Upload dokument
					</label>
				</KButton>,
				<input type="file" id="file" className='hidden' onChange={(e) => setDocumentUploaded(e.target.files[0])} />
			]}
			loading={[allDocsLoading, allFoldersLoading].some(e => e === true)}
		>
			<KModalConfirm
				open={showDelete}
				setOpen={() => setShowDelete(!showDelete)}
				title="Slet mappe"
				description="Er du sikker på, at du vil slette mappen?"
				handleConfirm={() => onDeleteFolder()}
				confirmText="SLET"
			/>
			<div className="grid grid-cols-2">
				<div className='grid grid-cols-2 gap-x-2'>
					<KSearchInput placeholder="Find dokument" onChange={setSearchPhrase} />
					<KSelect elements={sortingOptions} selected={sorting} handleSelect={(opt) => { setSorting(opt) }} />
				</div>

			</div>
			<div className="mt-4 mb-4">
				<KBreadcrumb
					pages={folderPath}
					folder={currentFolder}
					onClick={(folder, i) => handlePathChange(folder, i)}
				/>
			</div>
			<div className="grid grid-cols-1 gap-y-5">
				<KSection
					loading={allFoldersLoading}
					title="Mapper"
					description={!currentFolder?.name || currentFolder?.name === 'Dokumenter' ? "Her kan personale se og oprette mapper. Mapper og indhold i mapperne er synligt for personale og pårørende. Klik på en mappe for at se og/eller tilføje indhold. Klik på de 3 prikker på mappen, hvis du ønsker at omdøbe, flytte eller slette en mappe." : ""}
				>
					{allFolders && allFolders?.length > 0 &&
						<div className="grid grid-cols-6 gap-y-10">
							{allFolders?.map(folder => (
								<Folder
									key={folder.id}
									folder={folder}
									onClick={() => onFolderClick(folder)}
									allFolders={allFolders}
									setSnackMessage={setMessage}
									handleDelete={(folder) => handleDeleteFolder(folder)}
									handleError={(msg) => setMessage({ msg, type: "error", timeStamp: Date.now() })}
								/>
							))}

						</div>
					}
					{!allFolders || allFolders?.length === 0 &&
						<p className="text-sm">Der blev ikke fundet nogle mapper.</p>
					}
				</KSection>

				<KSection
					title="Dokumenter"
					description={!currentFolder?.name || currentFolder?.name === 'Dokumenter' ? "Her kan du som personale se og uploade dokumenter, som du ikke ønsker at have i en mappe, men blot liggende for sig selv. Dokumenterne er synlige for personale og pårørende. Klik på de 3 prikker på dokumentet, hvis du ønsker at omdøbe eller slette dokumentet." : ""}
					loading={allDocsLoading}
				>
					{allDocuments && allDocuments?.length > 0 &&
						<div className="grid grid-cols-7 gap-y-10">
							{allDocuments?.map((doc) => (
								<KDocumentCard
									key={doc.id}
									title={doc.name}
									url={doc.url}
									document={doc}
									onChange={(msg) => setMessage({ msg, type: "success", timeStamp: Date.now() })}
									onError={(msg) => setMessage({ msg, type: "error", timeStamp: Date.now() })}
									allFolders={allFolders?.filter(f => f.id != currentFolder?.id)}
									residentDocument={false}
									setSnackMessage={setMessage}
								/>
							))}
						</div>
					}
					{!allDocuments || allDocuments?.length === 0 &&
						<p className="text-sm">Der blev ikke fundet nogle dokumenter.</p>
					}
				</KSection>
			</div>

			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
				key={message.timeStamp}
			/>
		</KPageWrapper >
	)
}

const sortingOptions = [
	{ name: "Alfabetisk", id: ['name', 'ASC'] },
	{ name: "Nyeste først", id: ["createdAt", "DESC"] },
	{ name: "Ældste først", id: ["createdAt", "ASC"] },
	{ name: "Senest opdateret", id: ["updatedAt", "DESC"] },
]

