import { useMutation, useQuery } from '@apollo/client'
import { Avatar, TableCell, Button, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import KButton from '../../components/KButton'
import KDataGrid from '../../components/KDataGrid'
import KPageWrapper from '../../components/KPageWrapper'
import { GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries'
import { calendarActivityTemplateRoute, createActivityTemplateRoute, administrationRoute } from '../../routes/mainRoutes'
import { activityTemplateHeaders } from '../../utils/settingsUtils'
import moment from 'moment';
import { DELETE_MULTIPLE_CALENDAR_ACTIVITY_TEMPLATES_BY_ID } from '../../graphql/graphqlMutations'
import styled from 'styled-components';
import KModal from '../../components/KModal'
import Colors from '../../styles/Colors'

export default function ActivityTemplate({ history }) {
	const user = useSelector(state => state.user);

	const [templates, setTemplates] = useState([]);
	const [showModal, setShowModal] = useState('')
	const [deleteSelected, setDeleteSelected] = useState();

	const { data, loading, refetch } = useQuery(GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID, { variables: { id: user?.data?.organization?.id } })
	const [doDeleteTemplates] = useMutation(DELETE_MULTIPLE_CALENDAR_ACTIVITY_TEMPLATES_BY_ID, {
		refetchQueries: [GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID]
	})

	useEffect(() => {
		setTemplates(data?.getAllCalendarActivityTemplatesByOrganizationId)
	}, [data])

	const handleDelete = async () => {
		const foundTemplatesToDelete = templates?.filter(t => deleteSelected?.includes(t.templateName));
		await doDeleteTemplates({
			variables: {
				ids: foundTemplatesToDelete?.map(t => t.id)
			}
		})
		refetch()
		setShowModal(false)
	}

	const handleOnDelete = (selected) => {
		setShowModal(true)
		setDeleteSelected(selected)
	}

	const RenderItem = ({ row }) => {
		return (
			<React.Fragment>
				<TableCell
					component="th"
					// id={labelId}
					scope="row"
					padding="none"
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
						<Avatar sx={{ width: 30, height: 30, mt: 1, mb: 1 }} src={row?.imageUrl} />
						{row?.templateName}
					</div>
				</TableCell>
				<TableCell align="right">
					<Checkbox disabled checked={row?.actionRequired} />
				</TableCell>
				<TableCell align="right">{moment(parseInt(row?.updatedAt)).format('DD-MM-YYYY HH:mm')}</TableCell>

			</React.Fragment>
		)
	}
	return (
		<KPageWrapper
			paths={[{
				title: 'Administration',
				route: administrationRoute
			}, {
				title: 'Skabeloner'
			}]}
			buttons={[
				<KButton
					label="TILFØJ SKABELON"
					onClick={() => history.push(createActivityTemplateRoute)}
				/>
			]}
		>
			<div style={{ marginTop: 20 }}>
				{/* <KTitle title="Interessegrupper" />
				<div>
					<KButton label="TILFØJ INTRESSEGRUPPE" />
				</div> */}
				<KModal
					open={showModal}
					title={deleteSelected?.length > 1 ? "Slet skabeloner" : "Slet skabelon"}
					subTitle={deleteSelected?.length > 1 ? "Er du sikker på at du vil slette de valgte skabeloner?" : "Er du sikker på at du vil slette skabelonen?"}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						<ModalButton fullWidth onClick={() => handleDelete()}>Slet</ModalButton>
						<ModalButton fullWidth onClick={() => setShowModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>

				{templates &&
					<KDataGrid
						headers={activityTemplateHeaders}
						rows={templates}
						withSearch={true}
						searchField="templateName"
						RenderItem={RenderItem}
						handleDelete={(s) => handleOnDelete(s)}
						onClick={(id) => history.push(calendarActivityTemplateRoute(id))}
					/>
				}
			</div>
		</KPageWrapper>
	)
}

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,

		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))