/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { classNames } from '../../utils/tailwindUtils'


export default function KTimeline({ direction, steps, onClick, activeElement }) {

	return (
		<nav aria-label="Progress">
			<ol role="list" className={classNames(
				direction ? '' : 'md:flex md:divide-y-0',
				"grid mb-6 border border-gray-300 divide-y divide-gray-300 rounded-md divide-x overflow-hidden"
			)}>
				{steps?.map((step, stepIdx) => (
					<li onClick={() => onClick && onClick(step)} key={step.name} className="relative cursor-pointer md:flex md:flex-1">
						{step?.tasks?.every(e => e.complete) &&
							<div className={classNames(
								step?.id === activeElement?.id ? 'border-darkPurple border-b-2' : '',
								"grid items-start w-full h-full grid-cols-1 px-6 py-2"
							)}>
								<div className="flex items-center gap-x-2">
									<span className="flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-full bg-darkPurple group-hover:bg-indigo-800">
										<CheckIcon className="w-4 h-4 text-white" aria-hidden="true" />
									</span>
									<div className="grid grid-cols-1">
										<span className="ml-4 text-xs font-bold text-darkPurple">{step.name}</span>
										<span className="ml-4 text-xs text-gray-700">{step?.tasks?.some(e => e?.required) ? 'Påkrævet' : 'Ikke påkrævet'}</span>
									</div>
								</div>
								<div className="grid grid-cols-1">
								</div>
							</div>
						}
						{step?.id === activeElement?.id && !step?.tasks?.every(e => e.complete) &&
							<div className="grid items-start w-full h-full grid-cols-1 px-6 py-2 border-b-2 border-darkPurple">
								<div className="flex items-center gap-x-2">
									<span className="flex items-center justify-center flex-shrink-0 w-8 h-8 border rounded-full border-darkPurple">
										<span className="text-sm font-bold text-darkPurple">{step.id}</span>
									</span>
									<div className="grid grid-cols-1">
										<span className="ml-4 text-xs font-bold text-darkPurple">{step.name}</span>
										<span className="ml-4 text-xs text-gray-700">{step?.tasks?.some(e => e?.required) ? 'Påkrævet' : 'Ikke påkrævet'}</span>
									</div>
								</div>
							</div>
						}
						{step?.id !== activeElement?.id && !step?.tasks?.every(e => e.complete) &&
							<div className="grid items-start w-full h-full grid-cols-1 px-6 py-2">
								<div className="flex items-center gap-x-2">
									<span className="flex items-center justify-center flex-shrink-0 w-8 h-8 border border-gray-400 rounded-full">
										<span className="text-sm font-bold text-gray-400">{step.id}</span>
									</span>
									<div className="grid grid-cols-1">
										<span className="ml-4 text-xs font-bold text-gray-400">{step.name}</span>
										<span className="ml-4 text-xs text-gray-400">{step?.tasks?.some(e => e?.required) ? 'Påkrævet' : 'Ikke påkrævet'}</span>
									</div>
								</div>
								<div className="grid grid-cols-1">
								</div>
							</div>
						}
					</li>
				))}
			</ol>
		</nav>
	)
}