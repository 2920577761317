/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ChevronRightIcon, MailIcon, PhoneIcon, UserIcon } from '@heroicons/react/24/solid'
import { CakeIcon } from '@heroicons/react/24/outline'

import { getResidentFullName, getResidentBirthDay, getResidentGender, getResidentPhoneNumber } from '../../utils/residentUtils'
import Avatar from "@mui/material/Avatar";
import { residentInfomationRoute } from '../../routes/residentRoutes';
import { useHistory } from "react-router-dom";



export default function StackedList({ residents }) {

	const history = useHistory();

	return (
		<div className="overflow-hidden bg-white shadow sm:rounded-md"
		>
			<ul role="list" className="divide-y divide-gray-200">
				{residents.map((resident, index) => (
					<li className='cursor-pointer hover:bg-gray-50' key={resident.id} onClick={() => { history.push(`${residentInfomationRoute}/${resident.id}`, { from: history.location }) }}
					>
						<div className="px-4 py-4 sm:px-6">
							<div className="flex items-center justify-between ">
								<div className='flex' >
									<div className="mr-4">
										<Avatar alt="Remy Sharp" src={resident?.imageUrl} />
									</div>
									<div>
										<p className=" text-md">{getResidentFullName(resident)}</p>
										<p className="flex mt-2 text-sm text-gray-500">
											<span className="truncate">{(new Date().getFullYear()) - (new Date(resident.birthDate).getFullYear())} år</span>
										</p>
									</div>
								</div>

								<div className="flex items-center gap-20 2xl:gap-40">
									<div className='text-center flex-nowrap' >
										<p className="text-sm text-gray-900 flex-nowrap">
											Fødselsdag<time dateTime={resident.date}>{resident.dateFull}</time>
										</p>
										<p className="flex items-center mt-2 text-sm text-gray-500">
											<CakeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-mainPurple" aria-hidden="true" />
											{getResidentBirthDay(resident)}
										</p>
									</div>
									<div className='text-center' >
										<p className="text-sm text-gray-900">
											Køn<time dateTime={resident.date}>{resident.dateFull}</time>
										</p>
										<p className="flex items-center mt-2 text-sm text-gray-500">
											<UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-mainPurple" aria-hidden="true" />
											{getResidentGender(resident)}
										</p>
									</div>
									< div className='text-center' >
										<p className="text-sm text-gray-900">
											Telefon<time dateTime={resident.date}>{resident.dateFull}</time>
										</p>
										<p className="flex items-center mt-2 text-sm text-gray-500">
											<PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-mainPurple" aria-hidden="true" />
											{getResidentPhoneNumber(resident.phone || resident.mobile)}
										</p>
									</div>
									<ChevronRightIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />

								</div>
							</div>
							<div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div >
	)
}
