import { useEffect, useRef, useState } from "react";
import KCheckbox from "../inputs/KCheckbox";
import { set } from "lodash";

export default function KTable({ headers, list, RenderItem, handleDelete }) {
	const checkbox = useRef(null);
	const [checked, setChecked] = useState(false)
	const [indeterminate, setIndeterminate] = useState(false)
	const [selected, setSelected] = useState([])

	function toggleAll() {
		setSelected(checked || indeterminate ? [] : list)
		setChecked(!checked && !indeterminate)
		setIndeterminate(false)
	}

	const handleToggleClick = (e, item) => {
		setSelected(e
			? [...selected, item]
			: selected?.filter((p) => p !== item)
		)
	}

	const handleOnDelete = () => {
		handleDelete(selected)
		setSelected([])
		setChecked(false)
		setIndeterminate(false)
	}

	return (
		<div className="">
			<div className="flex flex-col w-full ">
				<div className="-mx-8 -my-2 overflow-x-auto">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							{selected?.length > 0 && (
								<div className="absolute top-0 flex items-center h-12 space-x-3 left-16">
									<button
										onClick={() => handleOnDelete()}
										type="button"
										className="inline-flex items-center px-2 py-1 text-sm font-semibold text-gray-900 bg-white rounded shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
									>
										Slet valgte
									</button>
								</div>
							)}
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										{handleDelete && (<th scope="col" className="relative px-7 sm:w-12 sm:px-6">
											<KCheckbox
												checked={checked}
												setChecked={toggleAll}
												ref={checkbox}
												className="absolute w-4 h-4 -mt-2 rounded accent-main-100 focus:ring-main-100 left-4 top-1/2"
											/>
										</th>)}
										{headers?.map(h => (
											<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
												{h}
											</th>
										))}
									</tr>
								</thead>
								<tbody className="bg-white divide-y divide-gray-200">
									{list?.map((item, index) => (
										<>
											<RenderItem item={item} index={index} selected={selected} handleToggleClick={handleToggleClick} setChecked={setChecked} />
										</>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}