/* eslint-disable default-case */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
	globalFilterTypes,
	getGlobalFilterType,
} from "../utils/globalFilterUtils";
import { useSelector, useDispatch } from "react-redux";
import KLoader from "../components/KLoader";
import { setState, clearState, makeGFQueryCache } from "../redux/slices/globalFilterQuerySlice";
import { MarginWrapper } from "../pages/news/News";
import { useStateMonitor } from "../utils/eventUtils";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import KButton from "../components/KButton";


export default function GlobalFilterQueryInfinity({
	RenderItem,
	residentQuery,
	departmentQuery,
	organizationQuery,
	pageSize,
	ordering,
	containerStyle = {},
	filterMethod,
	searchTimeStamp,
	reduxKey,
	Skeleton,
	searchValue
}) {
	const user = useSelector((state) => state.user);
	const globalFilter = useSelector((state) => state.globalFilter);
	const firstPage = 0;

	const [hasMoreData, setHasMoreData] = useState(false);
	const dispatch = useDispatch();

	const cachedData = reduxKey ? useSelector((state) => state.globalFilterQuery[reduxKey]) : {};

	const [page, setPage] = useState(cachedData?.page || firstPage);
	const [rows, setRows] = useState(cachedData?.data || []);
	const [rowsCopy, setRowsCopy] = useState([]);

	const size = pageSize ? pageSize : 20;
	const order = ordering ? ordering : ["createdAt", "desc"];

	const { width, height } = useWindowDimensions();
	const [scrollY, setScrollY] = useState(0);
	const [isIntersecting, setIntersecting] = useState(false);
	const [skeletonNode, setSkeletonNode] = useState(null);
	const containerRef = useRef(null);

	const rowsMonitor = useStateMonitor(rows);
	const pageMonitor = useStateMonitor(page);
	const scrollYMonitor = useStateMonitor(scrollY);
	const rowsCopyMonitor = useStateMonitor(rowsCopy);
	const searchMonitor = useStateMonitor(searchValue);
	const filterMonitor = useStateMonitor(getGlobalFilterType(globalFilter));


	useEffect(() => {
		if (skeletonNode) {
			const observer = new IntersectionObserver(([entry]) =>
				setIntersecting(entry.isIntersecting)
			);
			// must be after data list is rendered to properly scroll to the last position
			containerRef?.current?.scrollTo(0, cachedData?.scrollPosition || 0);
			console.log("Scrolling to");
			observer?.observe(skeletonNode);
			return () => {
				observer.disconnect();
			};
		}
	}, [skeletonNode]);

	useEffect(() => {
		if (
			isIntersecting
			// && hasMoreData
			&& !loading
			&& !searchValue
			// && page !== firstPage
		) {
			fetchNextPage();
		}
	}, [isIntersecting]);


	const handleScroll = (e) => {
		setScrollY(e.target.scrollTop);
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom && !searchValue) {
			if (!loading && hasMoreData) {
				console.log("Fetching next page bottom");
				fetchNextPage();
			}
		}
	}

	const setSkeletonRef = useCallback(node => {
		setSkeletonNode(node);
	}, []);


	const getId = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return globalFilter.residentFilter.id;
			case globalFilterTypes.DEPARTMENT:
				return globalFilter.departmentFilter.id;
			case globalFilterTypes.ORGANIZATION:
				return user.data.organization.id;
		}
	};

	const pickQurey = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				if (residentQuery !== null) {
					return residentQuery;
				}
				return organizationQuery;
			case globalFilterTypes.DEPARTMENT:
				return departmentQuery;
			case globalFilterTypes.ORGANIZATION:
				return organizationQuery;
		}
	};

	const { loading, data, error, refetch } = useQuery(pickQurey(getId()), {
		variables: { size: size, page: page, order: order, id: getId() },
		fetchPolicy: "network-only",
	});

	const [getSearchNews, { loading: searchLoading, data: searchData, error: searchError }] = useLazyQuery(pickQurey(getId()), {
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		if (searchValue) {
			console.log("Search value changed");
			getSearchNews({ variables: { size: 999, page: 0, order: order, id: getId(), search: searchValue } });
		}
		else {
			if (rowsCopy?.length) {
				setRows(rowsCopy);
				setTimeout(() => {
					containerRef?.current?.scrollTo({
						top: scrollY,
						behavior: 'smooth',
					});
				}, 10);
				setRowsCopy([]);
			}
		}
	}, [searchTimeStamp]);

	useEffect(() => {
		if (searchData && searchValue) {
			if (!rowsCopy.length) {
				setRowsCopy(rows);
			}
			console.log("Search data changed");
			setRows(searchData[Object.keys(searchData)[0]].results);
		}
	}, [searchData, searchTimeStamp]);

	useEffect(() => {
		const gType = getGlobalFilterType(globalFilter);
		if (gType !== cachedData?.filter) {
			setRows([]);
			setRowsCopy([]);
			setPage(firstPage);

			if (reduxKey) {
				dispatch(clearState({ key: reduxKey }));
			}
		}
	}, [globalFilter]);


	/* new  */
	useEffect(() => {
		if (page === cachedData?.page) {
			console.log("no change");
			return;
		}
		if (data && data[Object.keys(data)[0]].pageInfo) {
			const dataObj = data[Object.keys(data)[0]];
			setHasMoreData(dataObj.pageInfo.hasNextPage);

			if (page === firstPage) {
				setRows(dataObj.results);
				if (size < 3 && !searchValue) {
					fetchNextPage();
				}
			} else {
				const concatenated = rows.concat(dataObj.results)
				setRows(concatenated);
			}
		}
	}, [data]);

	// useEffect(() =>
	// 	// on unmount cache data in redux
	// 	() => {
	// 		const data = reduxKey && !searchMonitor() ? rowsMonitor() : rowsCopyMonitor();
	// 		dispatch(setState(
	// 			makeGFQueryCache(
	// 				data,
	// 				pageMonitor(),
	// 				scrollYMonitor(),
	// 				filterMonitor(),
	// 				reduxKey
	// 			)
	// 		));
	// 	}, []);


	const getList = () => {
		const filtered = filterMethod
			? rows?.filter((item) => filterMethod(item))
			: rows;

		return filtered;
	};

	const onRefresh = () => {
		refetch({
			page: 0,
			size,
			order,
		});
		setPage(0);
	};

	const fetchNextPage = async (s) => {
		console.log("setting data");
		const p = page + 1;
		setPage(p);
		return await refetch({
			page: p,
			size,
			order,
		});
	};



	if (loading && page === firstPage && !rows.length || searchLoading) {
		return <KLoader />
	}

	if (error) {
		// return <p>{JSON.stringify(error, null, 2)}</p>;
	}

	return (
		<div
			ref={containerRef}
			style={{ ...containerStyle, overflowY: "scroll", height: "100%" }}
			onScroll={(e) => { handleScroll(e); }}
		>
			{ <div  style={{
				margin: "0 calc(50% - 120px) 15px",
			}} >
				<KButton
					onClick={onRefresh}
					label={"Hent seneste"}
					loading={loading}
				/>
			</div>}

			{getList()?.map((item, i) => {
				return <RenderItem key={i} data={item} />
			})}

			<div
				ref={setSkeletonRef}
				style={{
					marginTop: -(height * 0.8),
					paddingTop: (height * 0.8),
				}}
			>
				{Skeleton && !searchValue && hasMoreData &&
					[...Array(1)].map((item, i) => (
						<Skeleton key={i} />
					))}
				{!Skeleton && loading && hasMoreData && <KLoader />}
			</div>

			{!loading && !hasMoreData && !searchValue &&
				<MarginWrapper className={"flex justify-center"} >
					<div className="mb-20 text-xl" >Du er helt ajour</div>
				</MarginWrapper>
			}

			{!loading && !hasMoreData && searchValue && rows?.length === 0 &&
				<MarginWrapper className={"flex justify-center"} >
					<div className="mb-20 text-xl" >Ingen nyheder matchede din søgning</div>
				</MarginWrapper>
			}
		</div>
	);
}