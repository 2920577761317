import React, { useState } from "react";
import { useRef } from "react";
import Colors from "../styles/Colors";
import { classNames } from "../utils/tailwindUtils";
import KLoader from "./KLoader";

export default function KButton({ onClick, label, type, className, color, disabled, children, disabledText, labelClassName, loading, disableText }) {
	const [showText, setShowText] = useState(false);
	// console.log(disabled, 'here')
	return (
		<React.Fragment>
			<button
				onMouseEnter={() => disabled && setShowText(true)}
				onMouseLeave={() => setShowText(false)}
				onClick={!disabled && !loading ? onClick : () => { }}
				style={{ fontSize: 14, fontFamily: 'MetricBold' }}
				className={classNames(
					disabled || loading ? 'text-gray-400 border-gray-100' : `hover:border-mainGreen text-mainGreen`,
					// loading ? '' : "hover:border-mainGreen ",
					`relative border-2 rounded px-8 py-1 text-xs font-extrabold  uppercase  ${color} flex justify-center items-center `,
					className ? className : "w-max",
				)}
				type={type}
			>
				<p className={classNames(
					labelClassName ? labelClassName : "",
					loading ? 'invisible' : 'visible'
				)}>
					{label}
				</p>
				{loading &&
					<div className="absolute top-1">
						<KLoader className="" maxHeight={15} />
					</div>
				}
				{showText && !disableText &&
					< div className="absolute top-0 left-0 z-50 w-full pt-1 pb-1 pl-2 pr-2 normal-case bg-gray-200 mt-7">
						<p className="text-xs font-normal text-gray-700">{disabledText ? disabledText : 'Der kan først gemmes når der er fortaget ændringer.'}</p>
					</div>
				}
				{children}
			</button>

		</React.Fragment >
	)
}
