
export default function ImageUploadQuery(create, file, mutation, callback, option, onError) {
	console.log(onError)
	mutation({
		variables: {
			file: file,
		},
	})
		.then((e) => {
			create(e.data.imageUploadSingle.url, option);
			callback && callback()
		})
		.catch((err) => {
			console.log('here')
			onError && onError(err)
			console.log(err)
		});
}
