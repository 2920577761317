import React, { useState, useEffect } from "react";
import KintellaSvg from "../assets/KintellaSvg";
import { logoutKombit } from "../utils/KombitUtils";
import { useSelector } from "react-redux";
import { clearUser } from "../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { getResidentFullName, residentStatusOptionsListIds } from "../utils/residentUtils";
import backGroundImage from "../assets/headerBackground.jpeg";
import Colors from "../styles/Colors";
import { useQuery, useMutation } from "@apollo/client";
import {
	GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID,
	GET_ALL_RESIDENTS_BY_ORGANIZATION_ID,
	GET_ALL_RESIDENTS_INCLUDING_ROOM_BY_ORGANIZATION_ID,
} from "../graphql/graphqlQueries";
import { RESET_WORKER_ORGANIZATION } from "../graphql/graphqlMutations";
import {
	globalFilterInitialState,
	getGlobalFilterDepartmentId,
	getGlobalFilterResidentId,
} from "../utils/globalFilterUtils";
import { setGlobalFilter } from "../redux/slices/globalFilterSlice";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

const allDepartment = {
	id: "0",
	name: "Hele centret",
};
const allResident = {
	id: "0",
	firstName: "Alle",
	lastName: "",
};

export default function TopBar() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const globalFilter = useSelector((state) => state.globalFilter);
	const [selected, setSelected] = useState({
		department: getGlobalFilterDepartmentId(globalFilter),
		resident: getGlobalFilterResidentId(globalFilter),
	});


	const [departments, setDepartments] = useState([]);
	const [residents, setResidents] = useState([]);
	const {
		data: departmentsData,
		loading: departmentsLoading,
		error: departmentsError,
	} = useQuery(GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID, {
		variables: { organizationId: user?.data?.organization?.id },
	});

	const {
		data: residentsData,
		loading: residentsLoading,
		error: residenstError,
	} = useQuery(GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, {
		variables: {
			id: user?.data?.organization?.id,
			page: 0,
			size: 999,
			order: [["firstName", "asc"]],
			filters: residentStatusOptionsListIds
		},
	});

	const logout = () => {
		const url = logoutKombit(user?.kombitAuth?.nameId, user?.kombitAuth?.sessionIndex);
		return url;
	};

	const [resetOrganization] = useMutation(RESET_WORKER_ORGANIZATION);

	const resetOrgAndLogout = () => {
		resetOrganization({ 
			variables: {
				id: user?.data?.id,
			},
		}).then(() => {
			const url = logoutKombit(user?.kombitAuth?.nameId, user?.kombitAuth?.sessionIndex);
			window.location.href = url;
		})
	};

	const updateFilter = (one, two) => {
		let tmpFilter = { ...globalFilterInitialState };
		let tmpDepartment = departments.filter((d) => d.id === one);
		let tmpResident = residents.filter((d) => d.id === two);

		tmpFilter.departmentFilter =
			tmpDepartment.length > 0
				? tmpDepartment[0]
				: tmpFilter.departmentFilter;
		tmpFilter.residentFilter =
			tmpResident.length > 0 ? tmpResident[0] : tmpFilter.residentFilter;
		dispatch(setGlobalFilter(tmpFilter));
	};

	useEffect(() => {
		if (departmentsData) {
			let tmp = [allDepartment];
			setDepartments(
				tmp.concat(departmentsData.getAllDepartmentsByOrganizationId)
			);
		}
	}, [departmentsData]);

	useEffect(() => {
		if (residentsData) {
			let tmp = [allResident];
			setResidents(
				tmp.concat(
					residentsData.getAllResidentsByOrganizationId.results
				)
			);
		}
	}, [residentsData]);

	const onSelectDepartment = (v) => {
		setSelected({ department: v, resident: "0" });
		updateFilter(v, "0");
	};

	const onSelectResident = (v) => {
		setSelected({ department: selected.department, resident: v });
		updateFilter(selected.department, v);
	};

	const filterResidentCheck = (r) => {
		if (r?.firstName === 'Alle') {
			return true;
		}

		if (selected?.department > 0) {
			if (r?.room?.department?.id == selected?.department) {
				return true;
			} else {
				return false
			}
		} else {
			return true;
		}
	}

	return (
		<div
			className={`flex justify-between h-20 px-8 py-3 items-center bg-darkPurple text-white bg-cover`}
			style={{ backgroundImage: `url(${backGroundImage})`, backgroundPosition: '0px center' }}
		>
			<div>
				<div className="flex flex-row space-x-20">
					<div className="w-48 cursor-pointer">
						<KintellaSvg />
					</div>
					{user?.loggedIn &&
						<div className="flex flex-col-reverse">
							{!departmentsLoading && !residentsLoading &&
								<div className="flex flex-row space-x-10 text-xs">
									<div className="flex flex-row space-x-1">
										<GSelect
											value={selected.department}
											label="Afsnit"
											options={departments}
											onChange={onSelectDepartment}
											keys={["name"]}
										/>
									</div>
									<div className="flex flex-row space-x-1">
										<GSelect
											value={selected.resident}
											label="Borger"
											options={residents?.filter((r) =>
												filterResidentCheck(r)
											)}
											onChange={onSelectResident}
											keys={["firstName", "lastName"]}
										/>
									</div>

								</div>
							}
						</div>
					}
				</div>
			</div>
			<div className="flex gap-x-24">
				{user?.loggedIn &&
					<div>
						<div className="flex gap-x-2">
							<p>{user?.data?.role?.title?.toUpperCase()}</p>
							<p className="text-mainGreen">|</p>
							<p className="font-bold uppercase">{getResidentFullName(user?.data)}</p>
						</div>
						<div>
							<p className="text-xs">{user?.data?.organization?.name?.toUpperCase()}</p>
						</div>
					</div>
				}
				<div className="flex gap-x-2">
					<button>
						<a href={`${logout()}`}>LOG UD</a>
					</button>
					<OptionsMenu callback={resetOrgAndLogout} />
				</div>
			</div>
		</div >
	);
	// if (![departmentsLoading, residentsLoading].some(e => e === true)) {
	// } else {
	// 	return (
	// 		<div />
	// 	)
	// }
}

const GSelect = ({ label, onChange, options, value, defaultValue, keys }) => {
	const getTitle = (item) => {
		let tilte = "";
		keys.map((key) => (tilte = tilte + ` ${item[key]}`));
		return tilte;
	};
	return (
		<div className="text-xs">
			<p style={{ fontFamily: 'MetricBold', fontSize: 14 }} className="pl-1 text-xs uppercase text-mainGreen">{label}</p>
			<select
				value={value ? value : null}
				className="text-xs bg-transparent focus:outline-none"
				onChange={(e) => onChange && onChange(e.target.value)}
				defaultValue={defaultValue ? defaultValue : null}
			>
				{options.map((option, index) => (
					<option
						key={option.id}
						value={option.id}
						className="text-black"
					>
						{getTitle(option)}
					</option>
				))}
			</select>
		</div>
	);
};

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const OptionsMenu = ({callback}) => {
	return (
		<Menu as="div" className="relative flex items-center inline-block text-left">
			<div>
				<Menu.Button className="flex items-center rounded-full text-xs text-white-400">
					<span className="sr-only">Open options</span>
					<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 top-8 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={callback}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block w-full px-4 py-2 text-left text-sm'
									)}
								>
						Log ud for at vælge ny tilknytning til center eller område
								</button>
							)}
						</Menu.Item>
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
}