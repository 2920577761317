import { getResidentFullName } from "./residentUtils";

export const globalFilterInitialState = {
	departmentFilter: { name: "Hele centret", id: 0 },
	residentFilter: {
		id: 0,
		firstName: "",
		lastName: "",
	},
};

export const globalFilterTypes = {
	RESIDENT: "RESIDENT",
	DEPARTMENT: "DEPARTMENT",
	ORGANIZATION: "ORGANIZATION",
};

export const IsGlobalFilterOnDepartment = (globalFilter) => {
	if (globalFilter.residentFilter && globalFilter.departmentFilter) {
		return (
			globalFilter.residentFilter.id <= 0 &&
			globalFilter.departmentFilter.id > 0
		);
	}
	return false;
};

export const IsGlobalFilterOnResident = (globalFilter) => {
	if (globalFilter.residentFilter && globalFilter.departmentFilter) {
		return globalFilter.residentFilter.id > 0;
	}
	return false;
};

export const getGlobalFilterName = (globalFilter) => {
	if (IsGlobalFilterOnDepartment(globalFilter)) {
		return globalFilter.departmentFilter.name;
	} else if (IsGlobalFilterOnResident(globalFilter)) {
		return getResidentFullName(globalFilter.residentFilter);
	}
	return "Hele centeret";
};

export const getGlobalFilterSelectedId = (globalFilter) => {
	if (globalFilter.residentFilter && globalFilter.departmentFilter) {
		return IsGlobalFilterOnDepartment(globalFilter)
			? globalFilter.departmentFilter.id
			: globalFilter.residentFilter.id;
	}
	return 0;
};

export const getGlobalFilterObject = (globalFilter) => {
	if (globalFilter.residentFilter && globalFilter.departmentFilter) {
		return IsGlobalFilterOnDepartment(globalFilter)
			? globalFilter.departmentFilter
			: globalFilter.residentFilter;
	}
	return { name: "Hele Centret", id: 0 };
};

export const getGlobalFilterDepartmentId = (globalFilter) => {
	return globalFilter?.departmentFilter?.id;
};

export const getGlobalFilterResidentId = (globalFilter) => {
	return globalFilter?.residentFilter?.id;
};

export const getGlobalFilterType = (globalFilter) => {
	if (IsGlobalFilterOnResident(globalFilter)) {
		return globalFilterTypes.RESIDENT;
	} else if (IsGlobalFilterOnDepartment(globalFilter)) {
		return globalFilterTypes.DEPARTMENT;
	}
	return globalFilterTypes.ORGANIZATION;
};

export const customQuery = (query, id) => {
	return {
		query: query,
		id: id,
	};
}