import { useMutation } from '@apollo/client';
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KDateTimePicker from '../../components/pickers/dateTimePicker/KDateTimePicker2';
import KPageWrapper from '../../components/KPageWrapper'
import { assignmentsRoute } from '../../routes/mainRoutes';
import { consentOptions, consentOptionsList, responsible, responsibleList } from '../../utils/assignmentUtils';
import { GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID, GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID, GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID } from "../../graphql/graphqlQueries";
import { CREATE_MULTIPLE_TODO } from "../../graphql/graphqlMutations";
import KButton from '../../components/KButton';
import KSection from '../../components/cards/KSection';
import KInput from '../../components/inputs/KInput';
import KTextArea from '../../components/inputs/KTextArea';
import KRecipientForm from '../../components/forms/KRecipientForm';
import KSelect from '../../components/selectors/KSelect';
import KImageForm from '../../components/forms/KImageForm';
import KFileForm from '../../components/forms/KFileForm';
import KConfirmModal from '../../components/modals/KConfirmModal';
import { validateNewTodo } from '../../utils/validators/todoValidator';
import { useFileUtils } from '../../graphql/useFileUtils';
import { alertWarningMessages } from '../../utils/globalTypes';
import KSnackbar from '../../components/KSnackbar';

export default function CreateTodo() {
	const history = useHistory();
	const user = useSelector(state => state.user);

	const { createFiles, createImages } = useFileUtils();

	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [deadline, setDeadline] = useState();
	const [images, setImages] = useState();
	const [files, setFiles] = useState();
	const [selectedResponsible, setSelectedResponsible] = useState(responsible.WORKER);
	const [consentRequired, setConsentRequired] = useState(consentOptions.NO);
	const [showAddModal, setShowAddModal] = useState(false);

	const [selectedResidents, setSelectedResidents] = useState([])
	const [taggedResidents, setTaggedResidents] = useState([])

	const [errors, setErrors] = useState();
	const [createLoading, setCreateLoading] = useState(false);

	const [message, setMessage] = useState({ msg: "", type: "" });

	const [createTodo] = useMutation(CREATE_MULTIPLE_TODO, {
		refetchQueries: [GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID, GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID, GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID]
	});

	console.log(deadline)
	const makeTodo = async () => {
		setCreateLoading(true)

		try {
			const imgs = await createImages(images?.map(e => e.file))
			const fls = await createFiles(files?.map(e => e.file))

			await createTodo({
				variables: {
					input: {
						deadline: deadline,
						description: description,
						title: title,
						residents: selectedResidents?.map(r => Number(r?.id)),
						creatorId: user.data.id,
						organizationId: user.data.organization.id,
						departmentId: 1,
						responsible: selectedResponsible.value,
						consentRequired: consentRequired?.value,
						images: imgs,
						files: fls
					},
				},
			});

			history.push(assignmentsRoute)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		} finally {
			setCreateLoading(false)
		}
	};

	const postTodo = async () => {
		const validated = validateNewTodo({
			title,
			description,
			selectedResidents,
			deadline: deadline
		});

		if (!validated.success) {
			setShowAddModal(false)
			return setErrors(validated.errors)
		}

		makeTodo()
	};

	const handleChange = (key, setter, value) => {
		setErrors({
			...errors,
			[key]: null
		});
		setter(value)
	}

	const TopBar = () => (
		<div className="flex items-center justify-end h-16 px-6 bg-white border-b">
			<div className="flex gap-x-4">

				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton className="min-w-full" label="OPRET OPGAVE" onClick={() => setShowAddModal(true)} />
				</div>
			</div>

		</div>
	)

	return (
		<KPageWrapper
			paths={[{
				title: 'Opgaver',
				route: assignmentsRoute
			}, {
				title: 'Opret opgave'
			}]}
			loading={[createLoading].some(e => e === true)}
			contentContainerStyle={{ padding: 0 }}
			TopBar={TopBar}
		>
			<KConfirmModal
				title="Opret opgave"
				confirmText={"OPRET OPGAVE"}
				description={"Er du sikker på du vil oprette opgaven?"}
				open={showAddModal}
				setOpen={setShowAddModal}
				handleConfirm={() => postTodo()}
				loading={createLoading}
			/>
			<div className="grid grid-cols-1 p-6 gap-x-4">
				<div className="grid grid-cols-1 pb-6 cols-span-2 gap-y-6">
					<KSection
						title="Oplysninger"
					>
						<div className="grid grid-cols-1 divide-y gap-y-3">
							<div className="grid grid-cols-1 gap-y-2">
								<KInput
									error={errors?.title}
									value={title}
									label="Titel"
									required
									onChange={(value) => handleChange('title', setTitle, value)}
								/>
								<KTextArea
									error={errors?.description}
									value={description}
									label="Beskrivelse"
									required
									onChange={(value) => handleChange('description', setDescription, value)}
									multiline
									rows={5}
								/>
							</div>
							<div className="grid grid-cols-3 pt-4 mt-4 gap-x-4">
								<KSelect
									label="Vælg ansvarlig for udførelse"
									selected={selectedResponsible}
									elements={responsibleList}
									handleSelect={(value) => setSelectedResponsible(value)}
								/>
								{selectedResponsible?.name === responsible.WORKER.name &&
									<KSelect
										label="Kræver samtykke"
										selected={consentRequired}
										elements={consentOptionsList}
										handleSelect={(value) => setConsentRequired(value)}
									/>
								}
								<KDateTimePicker
									label="Frist"
									onSubmit={setDeadline}
									disableTime={true}
									error={errors?.deadline}
								/>
							</div>
						</div>
					</KSection>
					<KRecipientForm
						selectedResidents={selectedResidents}
						setSelectedResidents={setSelectedResidents}
						errors={errors}
						setErrors={setErrors}
					/>
					<KImageForm
						images={images}
						setImages={setImages}
						taggedResidents={taggedResidents}
						setTaggedResidents={setTaggedResidents}
						selectedResidents={selectedResidents}
					/>
					<KFileForm
						files={files}
						setFiles={setFiles}
					/>

				</div>
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KPageWrapper>
	)
}
