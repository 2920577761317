import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { classNames } from "../../../utils/tailwindUtils";


const CarouselItem = ({ children, style = {}, className = "", onClick }) => {
	return (
		<div
			onClick={onClick}
			className={"inline-flex justify-center" + " " + className}
			style={style}
		>
			{children}
		</div>
	);
};

Carousel.Item = CarouselItem;

export default function Carousel({
	children,
	activeIndex = 0,
	setActiveIndex = () => { },
	className,
	style = {},
	auto = false,
}) {


	const [paused, setPaused] = useState(false);

	const updateIndex = (newIndex) => {
		if (newIndex < 0) {
			newIndex = React.Children.count(children) - 1;
		} else if (newIndex >= React.Children.count(children)) {
			newIndex = 0;
		}

		setActiveIndex(newIndex);
	};

	useEffect(() => {
		if (auto) {
			const interval = setInterval(() => {
				if (!paused) {
					updateIndex(activeIndex + 1);
				}
			}, 3000);

			return () => {
				if (interval) {
					clearInterval(interval);
				}
			};
		}
	});

	const handlers = useSwipeable({
		onSwipedLeft: () => updateIndex(activeIndex + 1),
		onSwipedRight: () => updateIndex(activeIndex - 1)
	});

	return (
		// carousel
		<div
			{...handlers}
			className={"overflow-hidden" + " " + className}
			onMouseEnter={() => setPaused(true)}
			onMouseLeave={() => setPaused(false)}
			style={style}
		>
			{/* inner */}
			<div
				className="duration-500 whitespace-nowrap"
				style={{ transform: `translateX(-${activeIndex * 100}%)` }}
			>
				{React.Children.map(children, (child, index) => {
					return React.cloneElement(child, { width: "100%" });
				})}
			</div>
			{/* Navigation */}
			{React.Children.length > 0 &&
				<div className="flex justify-center text-xl">
					{/* <button
					className="mx-2"
					onClick={() => {
						updateIndex(activeIndex - 1);
					}}
				>
					Forrige
				</button> */}
					<div
						onClick={() => updateIndex(activeIndex - 1)}
						className={classNames(
							activeIndex !== 0 ? 'cursor-pointer' : 'cursor-default bg-gray-50',
							"relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-20"
						)}
					>
						<span className="sr-only">Forrige</span>
						<ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
					</div>
					{/* {Array.from(Array(pageInfo?.totalPages).keys())?.map((page, index) => {
							return (
								<div
									onClick={() => setPage(index)}
									className={classNames(
										"cursor-pointer",
										index === pageInfo?.currentPage
											? "relative z-10 inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-600 border border-indigo-500 bg-indigo-50 focus:z-20"
											: 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
									)}
								>
									{index + 1}
								</div>
							)
						})} */}
					{React.Children.map(children, (child, index) => {
						console.log('sup?=?')
						return (
							<div
								onClick={() => updateIndex(index)}
								className={classNames(
									"cursor-pointer",
									index === activeIndex
										? "relative z-10 inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-600 border border-indigo-500 bg-indigo-50 focus:z-20"
										: 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
								)}
							>
								{index + 1}
							</div>
						);
					})}

					<div
						onClick={() => updateIndex(activeIndex + 1)}
						className={classNames(
							activeIndex !== 0 ? 'cursor-pointer' : 'cursor-default bg-gray-50',
							"relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-20"
						)}
					>
						<span className="sr-only">Næste</span>
						<ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
					</div>
				</div>
			}
		</div >
	);
};
