/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { classNames } from '../../utils/tailwindUtils'


export default function KSelectBox({ title, list, className, handleSelect, selected, error }) {
	const [selectedMailingLists, setSelectedMailingLists] = useState()

	useEffect(() => {
		if (list) {
			setSelectedMailingLists(list[0])
		}
	}, [list])

	return (
		<RadioGroup value={selected} onChange={(e) => handleSelect(e)}>
			<RadioGroup.Label className="text-base font-medium text-gray-900">{title}</RadioGroup.Label>

			<div className={classNames(
				className ? className : 'sm:grid-cols-4',
				"grid grid-cols-1 mt-4 gap-y-6 xs:grid-cols-1 sm:gap-x-4"
			)}>
				{list.map((mailingList) => (
					<RadioGroup.Option
						onClick={() => handleSelect(mailingList)}
						key={mailingList.id}
						value={mailingList}
						className={({ checked, active }) =>
							classNames(
								checked ? 'border-transparent' : 'border-gray-300',
								active ? 'border-indigo-500 ring-2 ring-darkPurple' : '',
								'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
							)
						}
					>
						{({ checked, active }) => (
							<>
								<span className="flex flex-1">
									<span className="flex flex-col">
										<RadioGroup.Label as="span" className="block text-sm font-bold text-gray-700">
											{mailingList.title}
										</RadioGroup.Label>
										<RadioGroup.Description as="span" className="flex items-center mt-1 text-sm text-gray-500">
											{mailingList.description}
										</RadioGroup.Description>
										<RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
											{mailingList.users}
										</RadioGroup.Description>
									</span>
								</span>
								<CheckCircleIcon
									className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-darkPurple')}
									aria-hidden="true"
								/>
								<span
									className={classNames(
										active ? 'border' : 'border-2',
										error ? 'border-red-400' : checked ? 'border-darkPurple' : 'border-transparent',
										'absolute -inset-px rounded-lg pointer-events-none'
									)}
									aria-hidden="true"
								/>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
			<p className="mt-1 ml-1 text-sm text-red-600" id="email-error">
				{error}
			</p>
		</RadioGroup>
	)
}