import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import KDateTimePicker from '../../components/pickers/dateTimePicker/KDateTimePicker2'

import KPageWrapper from '../../components/KPageWrapper'
import { GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID, GET_ALL_CALENDAR_ACTIVITIES_IN_DRAFT_BY_CREATOR_ID, GET_CALENDAR_ACTIVITY_BY_ID } from '../../graphql/graphqlQueries'
import { assignmentRoute, assignmentsRoute, calendarActivityRoute, calendarRoute } from '../../routes/mainRoutes'
import { getResidentFullName, residentStatusOptions } from '../../utils/residentUtils'
import moment from 'moment';
import { CREATE_CALENDAR_ACTIVITY, UPDATE_CALENDAR_ACTIVITY } from '../../graphql/graphqlMutations'
import { repeatOptions, repeatOptionsList } from '../../utils/calendarUtils'
import { toDatabaseFormat } from '../../utils/dateUtils'
import KSection from '../../components/cards/KSection'
import KInput from '../../components/inputs/KInput'
import KTextArea from '../../components/inputs/KTextArea'
import KSelect from '../../components/selectors/KSelect'
import KImageForm from '../../components/forms/KImageForm'
import KFileForm from '../../components/forms/KFileForm'
import KButton from '../../components/KButton'
import KRecipientForm from '../../components/forms/KRecipientForm'
import KCheckbox from '../../components/inputs/KCheckbox'
import KModal from '../../components/modals/KModal'
import KTitle from '../../components/KTitle'
import { validateNewActivity } from '../../utils/validators/calendarValidator'
import { useFileUtils } from '../../graphql/useFileUtils'
import { alertWarningMessages } from '../../utils/globalTypes'
import KSnackbar from '../../components/KSnackbar'
import KConfirmModal from '../../components/modals/KConfirmModal'


export default function CreateCalendarActivity({ match, history }) {
	const user = useSelector(state => state.user);

	const { updateFiles, updateImages } = useFileUtils();

	const emptyTemplate = { title: "", description: "", actionRequired: false };

	const [title, setTitle] = useState("");
	const [template, setTemplate] = useState(emptyTemplate);
	const [description, setDescription] = useState("");
	const [workerNote, setWorkerNote] = useState();
	const [deadlineDate, setDeadlineDate] = useState();
	const [errors, setErrors] = useState();
	const [selectedResidents, setSelectedResidents] = useState([]);
	const [taggedResidents, setTaggedResidents] = useState([])
	const [residents, setResidents] = useState([]);
	const [actionRequired, setActionRequired] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [repeat, setRepeat] = useState(repeatOptions.NEVER)
	const [repeatDate, setRepeatDate] = useState(null)
	const [defaultStartDate, setDefaultStartDate] = useState();
	const [defaultEndDate, setDefaultEndDate] = useState();
	const [repetitionId, setRepetitionId] = useState()
	const [isDraft, setIsDraft] = useState();

	const [images, setImages] = useState();
	const [imagesCopy, setImagesCopy] = useState();
	const [files, setFiles] = useState();
	const [filesCopy, setFilesCopy] = useState();
	const [showAddModal, setShowAddModal] = useState();
	const [createLoading, setCreateLoading] = useState();

	const [message, setMessage] = useState({ msg: "", type: "" });

	const [calendarActivityTemplates, setCalendarActivityTemplates] = useState([]);

	const { data: calendarActivityTemplatesData, error: templateError, loading: calendarActivityTemplatesLoading } = useQuery(GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID, { variables: { id: user?.data?.organization?.id } });

	const { data, loading } = useQuery(GET_CALENDAR_ACTIVITY_BY_ID, { variables: { id: match?.params?.id }, fetchPolicy: 'no-cache' })

	const [doUpdateActivity, { loading: submitLoading }] = useMutation(UPDATE_CALENDAR_ACTIVITY)

	const { data: residentsData, loading: residentsLoading } = useQuery(GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, {
		variables: { id: user.data.organization.id, size: 999, filters: residentStatusOptions.ACTIVE.id },
	});

	useEffect(() => {
		if (calendarActivityTemplatesData) {
			setCalendarActivityTemplates(calendarActivityTemplatesData.getAllCalendarActivityTemplatesByOrganizationId)
		}
	}, [calendarActivityTemplatesData])

	const handleChange = (key, setter, value) => {
		setErrors({
			...errors,
			[key]: null
		});

		setter(value)
	}

	console.log(data)
	useEffect(() => {
		setResidents(residentsData?.getAllResidentsByOrganizationId?.results)
	}, [residentsData])

	useEffect(() => {
		if (data) {
			const activity = data?.getCalendarActivityById;
			setTitle(activity?.title)
			setDescription(activity?.description)
			setActionRequired(activity?.actionRequired)
			setSelectedResidents(residents?.filter(r => activity?.participants?.map(e => e?.resident?.id)?.includes(r?.id)))
			setStartDate(moment(parseInt(activity?.startDate)).format('DD-MM-YYYY HH:mm'))
			setDefaultStartDate(moment(parseInt(activity?.startDate)))
			setDefaultEndDate(moment(parseInt(activity?.endDate)))
			setEndDate(moment(parseInt(activity?.endDate)).format('DD-MM-YYYY HH:mm'))
			setRepetitionId(activity?.repetitionId)
			setRepeat(activity?.repetitionType !== null ? repeatOptions[activity?.repetitionType] : repeatOptions.NEVER)
			setRepeatDate(activity?.repetitionDate ? moment(parseInt(activity?.repetitionDate)) : null)
			setIsDraft(activity?.isDraft)
			setWorkerNote(activity?.note)
			setImages(activity?.images)
			setImagesCopy(activity?.images)
			setFiles(activity?.files)
			setFilesCopy(activity?.files)
		}
	}, [data, residents])

	const getRepeatDateString = () => {
		const startTime = moment(startDate, 'DD-MM-YYYY HH:mm').format('HH:mm')
		const repeatD = moment(repeatDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
		return `${repeatD} ${startTime}`
	}

	const updateCalendarActivity = async (isDraft, all) => {
		try {
			const imgs = await updateImages(images, imagesCopy)
			const fls = await updateFiles(files, filesCopy)

			await doUpdateActivity({
				variables: {
					input: {
						id: match?.params?.id,
						creatorId: user?.data?.id,
						departmentId: 1, // TODO
						organizationId: user?.data?.organization?.id,
						title: title,
						description: description,
						startDate: moment(startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ssZ'),
						endDate: moment(endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ssZ'),
						calendarActivityParticipants: selectedResidents?.map(r => r.id),
						actionRequired: actionRequired,
						editAll: all,
						repetitionId: repetitionId,
						withRepetition: repeat?.value !== repeatOptions.NEVER.value ? true : false,
						repetitionType: repeat?.value !== repeatOptions.NEVER.value ? repeat?.value : null,
						repetitionDate: repeat?.value !== repeatOptions.NEVER.value ? toDatabaseFormat(getRepeatDateString()) : null,
						isDraft: isDraft,
						note: workerNote,
						images: imgs,
						files: fls
						// imageUrl: url
					}
				}
			})

			history.goBack()
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		}
	};


	const postActivity = (isDraft, all) => {
		const validated = validateNewActivity({
			title,
			description,
			selectedResidents,
			startDate,
			endDate,
			repeat,
			repeatDate
		});

		if (!validated.success) {
			setShowAddModal(false)
			return setErrors(validated.errors)
		}

		updateCalendarActivity(isDraft, all)
	};

	const TopBar = () => (
		<div className="flex items-center justify-end h-16 px-6 bg-white border-b">
			<div className="flex gap-x-4">
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton className="min-w-full" label="GEM AKTIVITET" onClick={() => setShowAddModal(true)} />
				</div>
			</div>

		</div>
	)

	return (
		<KPageWrapper
			paths={[{
				title: 'Kalender',
				route: calendarRoute
			}, {
				title: 'Rediger aktivitet'
			}]}
			loading={[createLoading].some(val => val === true)}
			contentContainerStyle={{ padding: 0 }}
			TopBar={TopBar}
		>
			<div className="p-6">
				{(isDraft || repeat?.value !== repeatOptions.NEVER.value) &&
					<KModal open={showAddModal} setOpen={setShowAddModal}>
						<div className="p-5">
							<div>
								<div className="flex flex-col items-center text-center">
									<KTitle className="text-center" title="Gem aktivitet" />
									<div className="mt-3">
										<p className="text-gray-600">
											Vælg venligst hvordan du vil gemme aktiviteten
										</p>
									</div>
								</div>
							</div>
							{isDraft &&
								<div className="grid grid-cols-3 mt-5 gap-x-4 ">
									<KButton className="w-full" label="Udgiv aktivitet" onClick={() => postActivity(false)} />
									<KButton className="w-full" label="Gem som kladde" onClick={() => postActivity(true)} />
									<KButton className="w-full" label="Annuller" onClick={() => setShowAddModal(false)} />
								</div>
							}
							{!isDraft &&
								<div className="grid grid-cols-3 mt-5 gap-x-4">
									<KButton className="w-full" label="Gem kun denne" onClick={() => postActivity(false, false)} />
									<KButton className="w-full" label="Gem alle fremtidige begivenheder" onClick={() => postActivity(false, true)} />
									<KButton className="w-full" label="Annuller" onClick={() => setShowAddModal(false)} />
								</div>}
						</div>
					</KModal>
				}
				{!isDraft && repeat?.value === repeatOptions.NEVER.value &&
					<KConfirmModal
						title="Gem aktivitet"
						confirmText="Gem aktivitet"
						description="Er du sikker på du vil gemme aktiviteten?"
						open={showAddModal}
						setOpen={setShowAddModal}
						handleConfirm={() => postActivity()}
					/>
				}

				<div className="grid grid-cols-1 gap-y-6">
					<KSection
						title="Oplysninger"
					>
						<div className="grid grid-cols-1 divide-y gap-y-3">
							<div className="grid grid-cols-1 divide-y">
								<div className="grid grid-cols-1 pt-4 mb-4 gap-y-4">
									<KInput
										error={errors?.title}
										value={title}
										label="Titel"
										required
										onChange={(value) => handleChange('title', setTitle, value)}
									/>
									<KTextArea
										error={errors?.description}
										value={description}
										label="Beskrivelse"
										required
										onChange={(value) => handleChange('description', setDescription, value)}
										multiline
										rows={5}
									/>
									<KTextArea
										value={workerNote}
										label="Medarbejder note (Vil ikke blive vist for pårørende)"
										onChange={(value) => handleChange('workerNote', setWorkerNote, value)}
										multiline
										rows={5}
									/>
								</div>
								<div className="flex items-start justify-start pt-4 gap-x-2">
									<KCheckbox checked={actionRequired} setChecked={setActionRequired} />
									<div style={{ marginTop: -1.5 }} className="flex-1 min-w-0 text-sm">
										<label className="font-medium text-gray-700">
											Kræver svar fra pårørende
										</label>
										<p className="text-gray-500">Kryds dette felt af, hvis de pårørende skal fortælle, om de deltager i den aktivitet, du er ved at oprette.</p>
									</div>

								</div>
							</div>
						</div>
					</KSection>
					<KSection
						title="Dato og gentagelse"
					>
						<div className="grid grid-cols-2 gap-x-4 gap-y-4">
							<KDateTimePicker
								label="Start dato"
								onSubmit={(e) => handleChange('startDate', setStartDate, e)}
								error={errors?.startDate}
								defaultValue={defaultStartDate}
								required
							/>
							<KDateTimePicker
								label="Slut dato"
								onSubmit={(e) => handleChange('endDate', setEndDate, e)}
								error={errors?.endDate}
								defaultValue={defaultEndDate}
								required
							/>
							<KSelect
								label="Gentagelse"
								selected={repeat}
								elements={repeatOptionsList}
								handleSelect={(value) => setRepeat(value)}
								elementKey="label"
							/>
							{repeat?.value !== repeatOptions.NEVER.value &&
								<KDateTimePicker
									label="Gentag indtil"
									onSubmit={(e) => handleChange('repeatDate', setRepeatDate, e)}
									error={errors?.repeatDate}
									required
									disableTime
									defaultValue={repeatDate}
								/>
							}
						</div>
					</KSection>
					<KRecipientForm
						selectedResidents={selectedResidents}
						setSelectedResidents={setSelectedResidents}
						errors={errors}
						setErrors={setErrors}
					/>
					<KImageForm
						images={images}
						setImages={setImages}
						taggedResidents={taggedResidents}
						setTaggedResidents={setTaggedResidents}
						selectedResidents={selectedResidents}
					/>
					<KFileForm
						files={files}
						setFiles={setFiles}
					/>

				</div>

			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KPageWrapper >
	)
}
