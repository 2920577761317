import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($loginInput: UserLoginInput!) {
    login(input: $loginInput) {
      token
      user {
        municipalityId
        role {
          id
          title
        }
        firstName
        lastName
        fullName
        imageUrl
        id
        organization {
          id
          name
          municipality {
            id
            kmdAuthEndpoint
            kmdRootEndpoint
            kmdClientId
          }
        }
        relatives {
          resident {
            id
            firstName
            middleName
            lastName
            imageUrl
          }
        }
        municipality {
            id
            kmdAuthEndpoint
            kmdRootEndpoint
            kmdClientId
          }
      }
    }
  }
`;

export const UPDATE_USER_BY_ID = gql`
  mutation UpdateUserMutation($input: UserUpdateInput!) {
    updateUser(input: $input) {
      success
      message
    }
  }
`;

export const CREATE_NEWS_COMMNT = gql`
  mutation CreateNewsCommentMutation(
    $createNewsCommentInput: NewsCommentCreateInput!
  ) {
    createNewsComment(input: $createNewsCommentInput) {
      updatedAt
    }
  }
`;

export const CREATE_NEWS_LIKE = gql`
  mutation CreateNewsLikeByUserIdMutation(
    $input: NewsLikeCreateInput!
  ) {
    createNewsLikeByUserId(input: $input) {
      id
    }
  }
`;

export const UPDATE_TODOSTATUS = gql`
  mutation UpdateTodoStatusForTodoMutation(
    $updateTodoStatusForTodoInput: TodoStatusUpdateInput!
  ) {
    updateTodoStatusForTodo(input: $updateTodoStatusForTodoInput) {
      success
    }
  }
`;

export const DELETE_NEWS_LIKE = gql`
  mutation DeleteNewsLikeByUserIdMutation($userId: ID!, $newsId: ID!) {
    deleteNewsLikeByUserId(userId: $userId, newsId: $newsId) {
      success
    }
  }
`;

export const DELETE_TODO = gql`
  mutation DeleteTodoByIdMutation($deleteTodoByIdId: ID!) {
    deleteTodoById(id: $deleteTodoByIdId) {
      success
    }
  }
`;

export const DELETE_MULTIPLE_TODO = gql`
  mutation DeleteMultipleTodoById($ids: [ID!]!) {
    deleteMultipleTodoById(ids: $ids) {
      success
    }
  }
`;

export const UPDATE_RESIDENT_CONSENT = gql`
  mutation UpdateResidentConsentMutation($input: ResidentConsentUpdateInput!) {
    updateResidentConsent(input: $input) {
      success
    }
  }
`;

export const UPDATE_RESIDENT = gql`
	mutation UpdateResident($input: ResidentUpdateInput!) {
		updateResident(input: $input) {
			message
		}
	}
`;

export const RESTORE_RESIDENT = gql`
	mutation RestoreResident($input: ResidentUpdateInput!) {
		restoreResident(input: $input) {
			message
		}
	}
`;

export const UPLOAD_IMAGE = gql`
  mutation ImageUploadSingleMutation($file: Upload!) {
    imageUploadSingle(file: $file) {
      url
    }
  }
`;

export const CREATE_NEWS = gql`
  mutation CreateNewsMutation($input: NewsCreateInput!) {
    createNews(input: $input) {
      updatedAt
    }
  }
`;

export const CREATE_USER_FIRST_TIME_PIN = gql`
  mutation CreateUserFirstTimePinMutation($pin: String!, $id: ID!) {
    createUserFirstTimePin(pin: $pin, userId: $id) {
      success
      message
    }
  }
`;

export const LOGOUT_BY_ID = gql`
  mutation LogoutMutation($id: ID!) {
    logout(id: $id) {
      success
    }
  }
`;

export const DELETE_NEWS_BY_ID = gql`
  mutation DeleteNewsByIdMutation($id: ID!) {
    deleteNewsById(id: $id) {
      success
      message
    }
  }
`;

export const CREATE_TODO = gql`
  mutation CreateTodoMutation($input: TodoCreateInput!) {
    createTodo(input: $input) {
      updatedAt
    }
  }
`;

export const CREATE_MULTIPLE_TODO = gql`
	mutation CreateMultipleTodo($input: TodoCreateMultipleInput!) {
		createMultipleTodo(input: $input) {
      updatedAt
		}
	}
`;

export const UPDATE_NEWS_BY_ID = gql`
  mutation UpdateNewsMutation($input: NewsUpdateInput!) {
    updateNews(input: $input) {
      updatedAt
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation UpdateTodoMutation($input: TodoUpdateInput!) {
    updateTodo(input: $input) {
      updatedAt
    }
  }
`;

export const CREATE_CALENDAR_ACTIVITY = gql`
  mutation CreateCalendarActivityMutation(
    $input: CalendarActivityCreateInput!
  ) {
    createCalendarActivity(input: $input) {
      updatedAt
    }
  }
`;

export const CREATE_CALENDAR_ACTIVITY_TEMPLATE = gql`
  mutation CreateCalendarActivityTemplate(
    $input: CalendarActivityTemplateCreateInput!
  ) {
    createCalendarActivityTemplate(input: $input) {
      updatedAt
    }
  }
`;

export const DELETE_CALENDAR_ACTIVITY_TEMPLATE_BY_ID = gql`
  mutation DeleteCalendarActivityTemplateById($id: ID!) {
    deleteCalendarActivityTemplateById(id: $id) {
      success
    }
  }
`;

export const DELETE_MULTIPLE_CALENDAR_ACTIVITY_TEMPLATES_BY_ID = gql`
	mutation DeleteMultipleCalendarActivityTemplatesById($ids: [ID!]) {
    deleteMultipleCalendarActivityTemplatesById(ids: $ids) {
      message
      success
    }
  }
`;


export const UPDATE_CALENDAR_ACTIVITY_TEMPLATE = gql`
  mutation UpdateCalendarActivityTemplate(
    $input: CalendarActivityTemplateUpdateInput!
  ) {
    updateCalendarActivityTemplate(input: $input) {
      message
			success
    }
  }
`;

export const CREATE_RESIDENT = gql`
  mutation CreateResident($input: ResidentCreateInput!) {
    createResident(input: $input) {
      id
    }
  }
`;
export const REGISTER_USER = gql`
  mutation Register(
    $input: UserRegisterInput!
    $residentName: String
    $primaryName: String
  ) {
    register(
      input: $input
      residentName: $residentName
      primaryName: $primaryName
    ) {
      id
    }
  }
`;

export const CREATE_RESIDENT_RELATIVE = gql`
  mutation CreateResidentRelative($input: ResidentRelativeCreateInput!) {
    createResidentRelative(input: $input) {
      userId
    }
  }
`;

export const CREATE_MULTIPLE_RESIDENT_RELATIVE = gql`
  mutation CreateMultipleResidentRelative($input: [ResidentRelativeCreateInput!]) {
    createMultipleResidentRelative(input: $input) {
      userId
    }
  }
`;

export const CREATE_RESIDENT_WITH_RELATIVES = gql`
  mutation CreateResidentWithRelatives($input: ResidentWithRelativesCreateInput!) {
    createResidentWithRelatives(input: $input) {
      id
    }
  }
`;

export const CHANGE_TODO_STATUS = gql`
	mutation UpdateTodoStatusForTodoMutation(
		$updateTodoStatusForTodoInput: TodoStatusUpdateInput!
	) {
		updateTodoStatusForTodo(input: $updateTodoStatusForTodoInput) {
			success
		}
	}
`;

export const UPDATE_TODO_PARTICIPANT_STATUS = gql`
	mutation UpdateTodoParticipantStatus($input: TodoParticipantStatusUpdateInput!) {
    updateTodoParticipantStatus(input: $input) {
      accepted
    }
  }
`;

export const UPDATE_TODO_PARTICIPANT_STATUS_BY_TODO = gql`
	mutation UpdateTodoParticipantStatusByTodo($input: TodoParticipantStatusByTodoUpdateInput!) {
    updateTodoParticipantStatusByTodo(input: $input) {
      accepted
    }
  }
`;

export const UPDATE_WORKER_ORGANIZATION = gql`
	mutation UpdateWorkerOrganization($input: WorkerOrganizationUpdateInput!) {
		updateWorkerOrganization(input: $input) {
      token
      user {
        role {
          id
          title
        }
        firstName
        lastName
        imageUrl
        id
        organization {
          id
          name
          municipality {
            id
            kmdAuthEndpoint
            kmdRootEndpoint
            kmdClientId
          }
        }
        relatives {
          resident {
            id
            firstName
            middleName
            lastName
            imageUrl
          }
        }
      }
    }
	}
`

export const RESET_WORKER_ORGANIZATION = gql`
	mutation ResetWorkerOrganization($id: ID!) {
		resetWorkerOrganization(id: $id) {
      success
      message
    }
	}
`

export const SEND_INVITATION_MAIL = gql`
	mutation SendInvitationMail($input: InvitationMailInput) {
  sendInvitationMail(input: $input) {
    message
    success
  }
}
`
export const UPDATE_RESIDENT_RELATIVE = gql`
	mutation UpdateResidentRelative($input: ResidentRelativeUpdateInput!) {
		updateResidentRelative(input: $input) {
			message
			success
		}
	}
`;

export const DELETE_RESIDENT_BY_ID = gql`
	mutation DeleteResidentById($input: ResidentDeleteInput!) {
    deleteResidentById(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_CALENDAR_ACTIVITY = gql`
	mutation UpdateCalendarActivity($input: CalendarActivityUpdateInput!, $kmdToken: String, $kmdEndpoint: String) {
		updateCalendarActivity(input: $input, kmdToken: $kmdToken, kmdEndpoint: $kmdEndpoint) {
			message
			success
		}
	}
`

export const DELETE_CALENDAR_ACTIVITY = gql`
	mutation DeleteCalendarActivityById($input: CalendarActivityDeleteInput!) {
		deleteCalendarActivityById(input: $input) {
			message
			success
		}
	}
`

export const REGISTER_RELATIVE = gql`
	mutation RegisterRelative($input: RelativeRegisterInput!) {
		registerRelative(input: $input) {
			id
		}
	}
`;


export const CREATE_INTEREST = gql`
	mutation CreateInterest($input: InterestCreateInput!) {
    createInterest(input: $input) {
      id
      description
      title
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_MULTIPLE_INTEREST_BY_ID = gql`
	mutation DeleteMultipleInterestById($ids: [ID!]) {
    deleteMultipleInterestById(ids: $ids) {
      message
      success
    }
  }
`;

export const CREATE_ROOM = gql`
  mutation CreateRoom($input: RoomCreateInput!) {
    createRoom(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_ROOM_BY_ID = gql`
  mutation DeleteRoomById($id: ID!) {
    deleteRoomById(id: $id) {
      success
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation UpdateRoom($input: RoomUpdateInput!) {
    updateRoom(input: $input) {
      success
    }
  }
`;

export const DELETE_MULTIPLE_ROOMS_BY_ID = gql`
	mutation DeleteMultipleRoomsById($ids: [ID!]) {
    deleteMultipleRoomsById(ids: $ids) {
      message
      success
    }
  }
`;

export const DELETE_MULTIPLE_DEPARTMENTS_BY_ID = gql`
	mutation DeleteMultipleDepartmentsById($ids: [ID!]!) {
		deleteMultipleDepartmentsById(ids: $ids) {
			message
			success
		}
	}
`;

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment($input: DepartmentCreateInput!) {
    createDepartment(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($input: DepartmentUpdateInput!) {
    updateDepartment(input: $input) {
      message
      success
    }
  }
`;

export const DELETE_DEPARTMENT_BY_ID = gql`
  mutation DeleteDepartmentById($id: ID!) {
    deleteDepartmentById(id: $id) {
      message
      success
    }
  }
`;

export const CREATE_RESIDENT_INTEREST = gql`
  mutation CreateResidentInterest($input: ResidentInterestCreateInput!) {
    createResidentInterest(input: $input) {
      id
    }
  }
`;
export const CREATE_MULTIPLE_RESIDENT_INTEREST = gql`
  mutation CreateMultipleResidentInterest($input: ResidentInterestMultipleCreateInput!) {
    createMultipleResidentInterest(input: $input) {
      success
    }
  }
`;

export const DELETE_RESIDENT_INTEREST = gql`
  mutation DeleteResidentInterestForResident($input: ResidentInterestDeleteInput!) {
    deleteResidentInterestForResident(input: $input) {
      success
    }
  }
`;

export const DELETE_USER_INTEREST = gql`
  mutation DeleteUserInterestByUser($input: UserInterestDeleteInput!) {
    deleteUserInterestByUser(input: $input) {
      success
    }
  }
`;

export const CREATE_USER_INTEREST = gql`
  mutation CreateUserInterest($input: UserInterestCreateInput!) {
    createUserInterest(input: $input) {
      id
    }
  }
`;

export const CREATE_MULTIPLE_USER_INTEREST = gql`
  mutation CreateMultipleUserInterest($input: UserInterestMultipleCreateInput!) {
    createMultipleUserInterest(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_RESIDENT_KMD = gql`
  mutation UpdateResidentKMD($input: KMDResidentInput!) {
    updateResidentKMD(input: $input) {
      success
      message
    }
  }
`;

export const CREATE_RESIDENT_DOCUMENT_FOLDER_BY_ID = gql`
mutation CreateResidentDocumentFolder($input: ResidentDocumentFolderCreateInput!) {
  createResidentDocumentFolder(input: $input) {
    name
    id
    residentId
  }
}
`;

// delet multiple resident document folder by id
export const DELETE_MULTIPLE_RESIDENT_DOCUMENT_FOLDERS_BY_ID = gql`
    mutation DeleteMultipleResidentDocumentFoldersById($ids: [ID!]!) {
      deleteMultipleResidentDocumentFoldersById(ids: $ids) {
        success
        message
      }
    }
`;

// delete resident document folder by id
export const DELETE_RESIDENT_DOCUMENT_FOLDER_BY_ID = gql`
    mutation DeleteResidentDocumentFolderById($id: ID!) {
      deleteResidentDocumentFolderById(id: $id) {
        success
        message
      }
    }
`;

// update resident document folder 
export const UPDATE_RESIDENT_DOCUMENT_FOLDER = gql`
mutation UpdateResidentDocumentFolder($input: ResidentDocumentFolderUpdateInput!) {
  updateResidentDocumentFolder(input: $input) {
    success
    message
  }
}
`;


// delete resident document folder by id
export const DELETE_RESIDENT_DOCUMENT_BY_ID = gql`
    mutation DeleteResidentDocumentById($id: ID!) {
      deleteResidentDocumentById(id: $id) {
        success
        message
      }
    }
`;

// update resident document folder 
export const UPDATE_RESIDENT_DOCUMENT = gql`
mutation UpdateResidentDocumentFolder($input: ResidentDocumentUpdateInput!) {
  updateResidentDocument(input: $input) {
    success
    message
  }
}
`;

// create resident document
export const CREATE_RESIDENT_DOCUMENT = gql`
mutation CreateResidentDocument($input: ResidentDocumentCreateInput!) {
  createResidentDocument(input: $input) {
    id
    name
    url
  }
}
`;

export const CREATE_ORGANIZATION_DOCUMENT_FOLDER_BY_ID = gql`
mutation CreateOrganizationDocumentFolder($input: OrganizationDocumentFolderCreateInput!) {
  createOrganizationDocumentFolder(input: $input) {
    name
    id
    organizationId
  }
}
`;

// delet multiple organization document folder by id
export const DELETE_MULTIPLE_ORGANIZATION_DOCUMENT_FOLDERS_BY_ID = gql`
    mutation DeleteMultipleOrganizationDocumentFoldersById($ids: [ID!]!) {
      deleteMultipleOrganizationDocumentFoldersById(ids: $ids) {
        success
        message
      }
    }
`;

// delete organization document folder by id
export const DELETE_ORGANIZATION_DOCUMENT_FOLDER_BY_ID = gql`
    mutation DeleteOrganizationDocumentFolderById($id: ID!) {
      deleteOrganizationDocumentFolderById(id: $id) {
        success
        message
      }
    }
`;

// update organization document folder 
export const UPDATE_ORGANIZATION_DOCUMENT_FOLDER = gql`
mutation UpdateOrganizationDocumentFolder($input: OrganizationDocumentFolderUpdateInput!) {
  updateOrganizationDocumentFolder(input: $input) {
    success
    message
  }
}
`;


// delete organization document folder by id
export const DELETE_ORGANIZATION_DOCUMENT_BY_ID = gql`
    mutation DeleteOrganizationDocumentById($id: ID!) {
      deleteOrganizationDocumentById(id: $id) {
        success
        message
      }
    }
`;

// update organization document folder 
export const UPDATE_ORGANIZATION_DOCUMENT = gql`
mutation UpdateOrganizationDocumentFolder($input: OrganizationDocumentUpdateInput!) {
  updateOrganizationDocument(input: $input) {
    success
    message
  }
}
`;

// create organization document
export const CREATE_ORGANIZATION_DOCUMENT = gql`
mutation CreateOrganizationDocument($input: OrganizationDocumentCreateInput!) {
  createOrganizationDocument(input: $input) {
    id
    name
    url
  }
}
`;

export const DELETE_USER = gql`
	mutation DeleteUserById($id: ID!) {
		deleteUserById(id: $id) {
			success
			message
		}
	}
`;

export const VALIDATE_EMAIL = gql`
	mutation ValidateEmail($email: String!, $userId: ID) {
  validateEmail(email: $email, userId: $userId) {
      valid
      validators {
        disposable {
          valid
        }
        mx {
          valid
        }
        smtp {
          valid
        }
        typo {
          valid
        }
        regex {
          valid
        }
      }
  }
}
`;


export const UPLOAD_IMAGE_MULTIPLE = gql`
	mutation ImageUploadMultipleMutation($file: [Upload]!) {
		imageUploadMultiple(file: $file) {
			url
			key
			filename
			mimetype
		}
	}
`;

export const DELETE_S3_FILES = gql`
	mutation DeleteMultipleFiles($keys: [String!]!) {
		deleteMultipleFiles(keys: $keys) {
			success
			message
		}
	}
`;

export const DELETE_USER_IMAGE_BY_IDS = gql`
	mutation DeleteMultipleImagesById($ids: [ID!]!) {
		deleteMultipleImagesById(ids: $ids) {
			success
			message
		}
	}
`;

export const DELETE_USER_FILES_BY_IDS = gql`
	mutation DeleteMultipleFilesById($ids: [ID!]!) {
		deleteMultipleFilesById(ids: $ids) {
			success
			message
		}
	}
`;