import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createFilter from "redux-persist-transform-filter";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import uiSlice from "./slices/uiSlice";
import userSlice from "./slices/userSlice";
import globalFilterSlice from "./slices/globalFilterSlice";
import calendarSlice from "./slices/calendarSlice";
import globalFilterQuerySlice from "./slices/globalFilterQuerySlice";
import residentsFilterSlice from "./slices/residentsFilterSlice";

const userSubsetFilter = createFilter("user", ["data", "token"]);
const gfSubsetFilter = createFilter("globalFilter", []);

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	transforms: [userSubsetFilter, gfSubsetFilter],
	blacklist: ['globalFilterQuery'], // will not be persisted
	serializableCheck: false

};

const reducers = combineReducers({
	user: userSlice,
	ui: uiSlice,
	globalFilter: globalFilterSlice,
	calendar: calendarSlice,
	globalFilterQuery: globalFilterQuerySlice,
	residentsFilter: residentsFilterSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);
