import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { getResidentFullName } from '../utils/residentUtils';
import KTitle from './KTitle';
import KButton from './KButton';
import KTextField from './KTextField';
import KLoader from './KLoader';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headers, } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>
				</TableCell>
				{headers.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const EnhancedTableToolbar = (props) => {
	const { numSelected, title, RightAction, selected, handleDelete, withSearch, searchValue, setSearchValue } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} valgt
				</Typography>
			) : (
				<div style={{ flex: 1 }}>
					<KTitle title={title} />
				</div>
			)}

			{numSelected > 0 ? (
				<Tooltip onClick={() => handleDelete(selected)} title="Slet">
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				RightAction && <RightAction />
			)}

			{withSearch &&
				<KTextField value={searchValue} onChange={(e) => { setSearchValue(e) }} placeholder="Søg" />
			}
		</Toolbar>
	);
};


export default function KDataGrid({ title, headers, rows, handleDelete, RightAction, RenderItem, withSearch, searchField, onClick, loading }) {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState(searchField ? searchField : 'fullName');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [searchValue, setSearchValue] = React.useState('');
	const [filteredRows, setFilteredRows] = React.useState([]);
	const [savedPage, setSavedPage] = React.useState(0); // saves the page when the user searches in order to fix search results when searching from other page than 0

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => searchField ? n[searchField] : n.fullName);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};



	React.useEffect(() => {
		if (searchValue) setPage(0)
		if (!searchValue) setPage(savedPage)
		setFilteredRows(rows?.filter(e => searchField ? e[searchField]?.toLowerCase()?.includes(searchValue?.toLowerCase()) : e?.fullName?.toLowerCase().includes(searchValue?.toLowerCase())))
	}, [searchValue])

	React.useEffect(() => {
		setFilteredRows(rows)
	}, [rows])

	const handleClick = (event, name) => {
		event.stopPropagation()
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		if (!searchValue) {
			setSavedPage(newPage)
		}
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0); todo
	};

	const onHandleDelete = (e) => {
		console.log('Calling onHandleDelete')
		handleDelete(e)
		// setSelected([])
	}

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar title={title} numSelected={selected.length} RightAction={RightAction} selected={selected} handleDelete={onHandleDelete} withSearch={withSearch} searchValue={searchValue} setSearchValue={setSearchValue} searchField={searchField} />
				{loading &&
					<KLoader maxHeight={50} />
				}
				{!loading &&
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size={'small'}
						>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={filteredRows?.length}
								headers={headers}
							/>
							<TableBody>
								{stableSort(filteredRows, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(searchField ? row[searchField] : row.fullName);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												onClick={(event) => onClick ? onClick(row?.id) : handleClick(event, searchField ? row[searchField] : row.fullName)}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.name}
												selected={isItemSelected}
												style={{ cursor: 'pointer' }}
											>
												<TableCell padding="checkbox">
													<Checkbox
														onClick={(event) => handleClick(event, searchField ? row[searchField] : row.fullName)}
														color="primary"
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
												<RenderItem row={row} />
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: (dense ? 33 : 53) * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				}
				<TablePagination
					rowsPerPageOptions={[5]}
					component="div"
					count={filteredRows?.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"Antal pr. side"}
					labelDisplayedRows={
						({ from, to, count }) => {
							return '' + from + '-' + to + ' af ' + count
						}
					}
				/>
			</Paper>
		</Box>
	);
}
