import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KPageWrapper from '../../components/KPageWrapper'

import { useQuery } from '@apollo/client';
import { GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, GET_CORE_STATISTICS_BY_ORGANIZATION_ID } from '../../graphql/graphqlQueries';
import { useSelector } from 'react-redux';
import { statisticLevels, statisticLevelsList, statisticTypes, statisticTypesList, statisticTypesNoUsers, statisticTypesNoUsersList } from '../../utils/dashboardUtils';
import KDateRangePicker from '../../components/KDateRangePicker';
import moment from 'moment';
import KTextField from '../../components/KTextField';
import KLineChart from '../../components/charts/KLineChart';
import KSelect from '../../components/KSelect';
import { dashboardRoute } from '../../routes/mainRoutes';
import KModal from '../../components/KModal';
import { getResidentFullName } from '../../utils/residentUtils';

export default function Statistics({ location }) {
	const user = useSelector(state => state.user);

	const [showDateFilter, setShowDateFilter] = useState(false)
	const [selectedChartLevel, setSelectedChartLevel] = useState(statisticLevels.ALL)
	const [selectedChartType, setSelectedChartType] = useState(statisticTypes.USER)
	const [selectedResident, setSelectedResident] = useState()
	const [residents, setResidents] = useState([]);

	const [selectedData, setSelectedData] = useState()
	const [dateSearchSelection, setDateSearchSelection] = useState({
		startDate: moment.utc().subtract(1, 'month').toDate(),
		endDate: moment.utc().add(1, 'month').toDate(),
		key: 'selection'
	})

	const [coreStatistics, setCoreStatistics] = useState()

	const { data, loading } = useQuery(GET_CORE_STATISTICS_BY_ORGANIZATION_ID, { 
		variables: { 
			input: {
				organizationId: user?.data?.organization?.id, 
				startDate: moment(dateSearchSelection.startDate).format('DD-MM-YYYY 00:00:00'), 
				endDate: moment(dateSearchSelection.endDate).format('DD-MM-YYYY 23:59:59'),
				level: selectedChartLevel.value,
				type: selectedChartType.value,
				residentId: selectedChartLevel.value === statisticLevels.RESIDENT.value ? selectedResident.id : null
			}

		},
		fetchPolicy: "no-cache",
	})

	const { data: residentsData, loading: residentsLoading } = useQuery(GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, { 
		variables: { id: user.data.organization.id, size: 999 } 
	});
	
	useEffect(() => {
		if(data) {
			setCoreStatistics(data?.getCoreStatisticsByOrganizationId)
		}
	}, [data])

	useEffect(() => {
		if(residentsData) {
			const residents = residentsData[Object.keys(residentsData)[0]]?.results;
			const formatted = residents?.map(r => ({ 
				...r, 
				fullName: `${getResidentFullName(r)}`
			}));

			setResidents(formatted)
			setSelectedResident(formatted[0])
		}
	}, [residentsData])

	useEffect(() => {
		setSelectedData(getCurrentData())
	}, [coreStatistics, selectedChartType, selectedChartLevel])

	useEffect(() => {
		if(location?.state?.date) {
			const { start, end } = location.state.date;

			setDateSearchSelection({
				startDate: start,
				endDate: end,
				key: 'selection'
			})
		} else {
			setDateSearchSelection({
				startDate: moment.utc().subtract(1, 'month').toDate(),
				endDate: moment.utc().add(1, 'month').toDate(),
				key: 'selection'
			})
		}
	}, [])
	
	const onDateChange = (e) => {
		setDateSearchSelection(e)
		setShowDateFilter(false)
	}

	
	const getCurrentData = () => {
		if(coreStatistics?.items) {
			const items = coreStatistics?.items;
			const dates = items[0]?.items;
	
			const chartLabel = getChartLabel();

			const test = dates?.map(item => {
				return {
					date: item.date,
					[chartLabel.RELATIVES]: items?.find(e => e?.type === 'RELATIVES')?.items?.find(e => e?.date === item?.date)?.totalCount,
					[chartLabel.WORKERS]: items?.find(e => e?.type === 'WORKERS')?.items?.find(e => e?.date === item?.date)?.totalCount,
				}
			})
	
			return { items: test }

		}
	}

	const getTotalLabel = () => {
		if(selectedChartLevel.value === statisticLevels.RESIDENT.value) {
			return 'Antal oprettet af pårørende i den valgte periode'
		}

		return 'Antal oprettet i den valgte periode'
	}

	const getActiveLabel = () => {
		if(selectedChartLevel.value === statisticLevels.RESIDENT.value) {
			return 'Antal oprettet af personale i den valgte periode'
		}

		if(selectedChartType.value === statisticTypes.USER.value) {
			return 'Antal aktive i den valgte periode'
		} else if(selectedChartType.value === statisticTypes.CALENDAR.value) {
			return 'Antal afholdte i den valgte periode'
		}  else if(selectedChartType.value === statisticTypes.TODO.value) {
			return 'Antal løste i den valgte periode'
		}
	}

	const getChartLabel = () => {
		if(selectedChartType.value === statisticTypes.USER.value) {
			return {
				RELATIVES: 'Oprettet pårørende',
				WORKERS: 'Oprettet personale'
			}
		} 

		return {
			RELATIVES: 'Oprettet af pårørende',
			WORKERS: 'Oprettet af personale'
		}
	}

	const handleLevelChange = (e) => {
		if(e.value === statisticLevels.RESIDENT.value) {
			setSelectedChartType(statisticTypesNoUsers.CALENDAR)
		}

		if(selectedChartLevel.value === statisticLevels.RESIDENT.value) {
			setSelectedChartType(statisticTypes.USER)
		}

		setSelectedChartLevel(e)
	}
	return (
		<KPageWrapper
			paths={[{
				title: 'Overblik',
				route: dashboardRoute
			}, {
				title: 'Statistik'
			}]}
			loading={[loading, residentsLoading].some(e => e === true)}
		>
			<div style={{ marginTop: 20 }}>
				<div style={{ width: '100%'}}>
					<div style={{ display: 'flex', gap: 20 }}>
						<div style={{ flex: 1}}>
							<KSelect 
								options={statisticLevelsList}
								optionKey="label"
								value={selectedChartLevel}
								handleChange={(e) => handleLevelChange(e)}
								label="Niveau"
							/>
						</div>
						{selectedChartLevel.value === statisticLevels.RESIDENT.value && 
							<div style={{ flex: 1 }}>
								<KSelect 
									label="Borger"
									options={residents}
									optionKey="fullName"
									value={selectedResident}
									handleChange={(e) => setSelectedResident(e)}
								/>
							</div>
						}
						<div style={{ flex: 1 }}>
							<KSelect 
								options={selectedChartLevel.value === statisticLevels.RESIDENT.value ? statisticTypesNoUsersList : statisticTypesList}
								optionKey="label"
								value={selectedChartType}
								handleChange={(e) => setSelectedChartType(e)}
								label="Område"
							/>
						</div>
						<div onClick={() => setShowDateFilter(true)} style={{ flex: 1, cursor: 'pointer' }}>
							<div style={{ pointerEvents: 'none'}}>
								<KTextField label="Start dato" value={moment(dateSearchSelection.startDate).format('DD-MM-YYYY')} />
							</div>
						</div>
						<div onClick={() => setShowDateFilter(true)} style={{ flex: 1 }}>
							<div style={{ pointerEvents: 'none'}}>
								<KTextField label="Slut dato" value={moment(dateSearchSelection.endDate).format('DD-MM-YYYY')} />
							</div>
						</div>
					</div>
					{true &&
						<div style={{ display: 'flex', marginTop: 20, gap: 20, marginBottom: 20	 }}>
							<div style={{ flex: 1 }}>
								<Card>
									<div style={{ padding: '10px 15px', height: 80 }}>
										<p>{getTotalLabel()}</p>
										<p style={{ fontSize: 30, fontFamily: 'MetricSemiBold'}}>{coreStatistics?.totalCount}</p>		
									</div>
								</Card>
							</div>
							<div style={{ flex: 1}}>
								{coreStatistics?.activeCount !== null && 
										<Card>
											<div style={{ padding: '10px 15px', height: 80 }}>
												<p>{getActiveLabel()}</p>
												<p style={{ fontSize: 30, fontFamily: 'MetricSemiBold'}}>{coreStatistics?.activeCount}</p>
												
											</div>
										</Card>
								}
							</div>
							
						</div>
					}
			
					<KLineChart 
						newKey={'Total'}
						originalKey="totalCount"
						data={selectedData?.items} 
						width={1000} 
					/>
				</div>
				<div style={{ display: 'flex', gap: 20, marginTop: 20  }}>
					<KModal handleChange={() => setShowDateFilter(false)} containerStyles={{ width: '50%'}} open={showDateFilter}>
				        <KDateRangePicker
							setOpen={setShowDateFilter}
							dateSelection={dateSearchSelection}
							onChange={onDateChange}
						/>
					</KModal>
				</div>
			</div>
		</KPageWrapper>
	)
}
