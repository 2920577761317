import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Colors from '../../../styles/Colors';
import KModal from '../../../components/KModal';
import KTextField from '../../../components/KTextField';
import { departmentGroupRoute } from '../../../routes/mainRoutes';
import { useSelector } from 'react-redux';
import { DELETE_DEPARTMENT_BY_ID, UPDATE_DEPARTMENT } from '../../../graphql/graphqlMutations';
import {
	GET_ALL_RESIDENTS_BY_ORGANIZATION_ID,
	GET_ALL_RESIDENTS_BY_DEPARTMENT_ID,
	GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID,
	GET_ALL_ROOMS_BY_ORGANZATION_ID,
	GET_DEPARTMENT_BY_ID,
	GET_ALL_ROOMS_BY_DEPARTMENT_ID,
} from '../../../graphql/graphqlQueries';
import KPageWrapper from '../../../components/KPageWrapper';
import KButton from '../../../components/KButton';
import { validateNewDepartment } from '../../../utils/validators';
import KTitle from '../../../components/KTitle';
import GlobalFilterTable from '../../../graphql/GlobalFilterTable';

import { customQuery } from '../../../utils/globalFilterUtils';
import ResidentRow from './ResidentTableRow';
import RoomRow from './RoomTableRow';
import { residentColumns } from '../../../utils/residentUtils';

export default function Department({ match, history }) {

	const user = useSelector(state => state.user);
	const organizationId = user?.data?.organization?.id;

	const [department, setDepartment] = useState({});
	const [originalDepartment, setOriginalDepartment] = useState();
	const [errors, setErrors] = useState({});

	const { data: departmentData, loading } = useQuery(GET_DEPARTMENT_BY_ID, { variables: { id: match?.params?.id } })

	const [doDeleteDepartment, { loading: deleteLoading }] = useMutation(DELETE_DEPARTMENT_BY_ID, {
		refetchQueries: [GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID]
	})
	const [doUpdateDepartment, { loading: updateLoading }] = useMutation(UPDATE_DEPARTMENT, {
		refetchQueries: [GET_ALL_DEPARTMENTS_BY_ORGANIZATION_ID]
	})

	const [modalTitle, setModalTitle] = useState('')
	const [modalSubTitle, setModalSubTitle] = useState('')
	const [showModal, setShowModal] = useState('')
	const [modalType, setModalType] = useState('')

	const action = {
		delete: "Slet",
		update: "Opdater",
	}

	const handleChange = (key, value) => {
		console.log(key, value);
		setDepartment({
			...department,
			[key]: value,
		})
	}

	useEffect(() => {
		setDepartment(departmentData?.getDepartmentById)
		setOriginalDepartment(departmentData?.getDepartmentById)
		console.log('Got department data', departmentData?.getDepartmentById);
	}, [departmentData?.getDepartmentById])

	const handleModalText = (action, modalTitle, subTitle) => {
		setModalType(action)
		setModalTitle(modalTitle)
		setModalSubTitle(subTitle)
		setShowModal(true)
	}

	const handleDelete = async () => {
		await doDeleteDepartment({ variables: { id: department.id } })
		history.goBack()
	}
	const handleUpdate = () => {
		const validated = validateNewDepartment({
			title: department.name,
			organizationId: organizationId,
		})

		if (!validated.success) {
			return setErrors(validated.errors)
		}
		doUpdateDepartment({
			variables: {
				input: {
					id: department.id,
					name: department.name,
					organizationId: organizationId,
				}
			}
		}).then(res => {
			history.goBack();
		}).catch(err => {
			console.log(JSON.stringify(err, 2, null))
			setErrors({ ...errors, serverError: err?.message })
		})
	}

	// handle accept action for modal
	const handleAccept = async (type) => {
		if (type === action.delete) await handleDelete()
		if (type === action.update) handleUpdate()
		setShowModal(false)
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'Alle afsnit',
				route: departmentGroupRoute,
			}, {
				title: 'Afsnit'
			}]
			}
			errors={errors?.serverError}
			loading={[loading, deleteLoading, updateLoading].some(e => e === true)}
			buttons={[
				!_.isEqual(department, originalDepartment) && <KButton
					label="GEM"
					onClick={() => handleModalText(action.update, "Gem ændringer", `Er du sikker på at du vil gemme dine ændringer for '${originalDepartment.name}'?`)}
				/>,
				<KButton
					label="SLET AFSNIT"
					onClick={() => handleModalText(action.delete, department?.residents ? "OBS" : "Slet afsnit", department?.residents?.length ? "Du kan ikke slette et afsnit med beboere" : `Er du sikker på at du vil slette '${originalDepartment.name}'?`)}
				/>
			]}
		>
			<div>
				<KModal
					open={showModal}
					title={modalTitle}
					subTitle={modalSubTitle}
					handleChange={() => setShowModal(false)}
				>
					<ModalContainer>
						{department?.residents?.length ? modalType != action.delete && <ModalButton fullWidth onClick={() => handleAccept(modalType)}>{modalType}</ModalButton> : <ModalButton fullWidth onClick={() => handleAccept(modalType)}>{modalType}</ModalButton>}
						<ModalButton fullWidth onClick={() => setShowModal(false)} >Annuller</ModalButton>
					</ModalContainer>
				</KModal>
			</div>

			<div className='flex flex-col pt-5 ' >
				<KTitle title={"Oplysninger"} />

				<div className='flex flex-col flex-1 max-w-md gap-8 my-6' >
					{department && <KTextField
						error={errors?.title}
						value={department?.name}
						required
						fullWidth={true}
						label="Afsnittets navn"
						onChange={(value) => handleChange('name', value)}
					/>}
				</div>

				{department?.id && < Item >
					<KTitle title={"Borgere"} />
					<GlobalFilterTable
						columns={residentColumns}
						RenderItem={ResidentRow}
						organizationQuery={
							GET_ALL_RESIDENTS_BY_ORGANIZATION_ID
						}
						customQuery={customQuery(GET_ALL_RESIDENTS_BY_DEPARTMENT_ID, department.id)}
						pageSize={999}
						ordering={[["firstName", "asc"]]}
						filters={['ACTIVE']}
					/>
				</Item>}

				{department?.id && <Item>
					{/* show rooms of department */}
					<KTitle title={"Boliger"} />
					<GlobalFilterTable
						columns={['Afsnit', 'Bolignavn', 'Borger', 'Senest opdateret']}
						RenderItem={RoomRow}
						organizationQuery={
							GET_ALL_ROOMS_BY_ORGANZATION_ID
						}
						customQuery={customQuery(GET_ALL_ROOMS_BY_DEPARTMENT_ID, parseInt(department.id))}
						pageSize={999}
						// ordering={["firstName", "asc"]}
						sortMethod={(a, b) => {
							return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
						}}
					/>
				</Item>}
			</div>
		</KPageWrapper >
	)
}

const Item = ({ children }) => {
	return (
		<div className='flex flex-col gap-6 mb-6 flex-1gap-8' >
			{children}
		</div>
	)
}

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,
		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))


