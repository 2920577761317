import _ from 'lodash'
import { isValidPhoneNumber } from 'react-phone-number-input'
import moment from 'moment';
import { repeatOptions } from './calendarUtils';

export const isEmpty = (input) => _.isEmpty(input);

export const checkIfUrlInText = (textToCheck) => {
	var expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;
	var regex = new RegExp(expression);
	var match = ''; var splitText = []; var startIndex = 0;
	while ((match = regex.exec(textToCheck)) != null) {

		splitText.push({ text: textToCheck.substr(startIndex, (match.index - startIndex)), type: 'text' });

		var cleanedLink = textToCheck.substr(match.index, (match[0].length));
		cleanedLink = cleanedLink.replace(/^\n?:\/\//, '<br />');
		splitText.push({ text: cleanedLink, type: 'link' });

		startIndex = match.index + (match[0].length);
	}
	if (startIndex < textToCheck.length) splitText.push({ text: textToCheck.substr(startIndex), type: 'text' });
	return splitText;
}


export function isValidURL(str) {
	var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(str);
}

export const isValidEmail = (email) => {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

function isValidDate(d) {
	if (d === "Invalid date") return false;
	return true
}

export const validateNewResident = (resident) => {
	console.log(resident)
	let errors = {};

	isEmpty(resident?.firstName) ? errors.firstName = true : errors.firstName = null;
	isEmpty(resident?.lastName) ? errors.lastName = true : errors.lastName = null;
	// isEmpty(room) ? errors.room = true : errors.room = null;
	// isEmpty(department) ? errors.department = true : errors.department = null;

	// relatives?.map((relative, index) => {
	// 	errors.relatives[index] = {};
	// 	isEmpty(relative?.email) ? errors.relatives[index].email = true : errors.relatives[index].email = null;
	// 	!isValidEmail(relative?.email) ? errors.relatives[index].email = true : errors.relatives[index].email = null;
	// 	(!relative.mobile || !isValidPhoneNumber(`+${relative.mobile}`)) ? errors.relatives[index].mobile = true : errors.relatives[index].mobile = null;
	// 	isEmpty(relative?.loginMethod) ? errors.relatives[index].loginMethod = true : errors.relatives[index].loginMethod = null;
	// })

	// const formatted = errors?.relatives?.map(relative => {
	// 	return Object.values(relative)?.every(x => x === null || x === '')
	// })

	// if (formatted?.every(e => e === true)) {
	// 	delete errors?.relatives;
	// }

	// if (relatives?.findIndex(e => e?.role?.id === 3) === -1) {
	// 	errors.primaryRelative = true
	// }

	// const relativeEmails = relatives.map(relative => relative.email)
	// if (new Set(relativeEmails).size !== relativeEmails.length) {
	// 	errors.relativeEmails = true;
	// }

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateNewTodo = (todo) => {
	console.log('øtod', isEmpty(todo?.deadline))
	let errors = {};

	isEmpty(todo?.title) ? errors.title = true : errors.title = null;
	isEmpty(todo?.description) ? errors.description = true : errors.description = null;
	!todo?.deadline ? errors.deadline = true : errors.deadline = null;
	todo?.residents?.length === 0 ? errors.selectedResidents = 'Minimum 1 modtager påkrævet.' : null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateCreateNews = (news) => {
	let errors = {};

	isEmpty(news?.title) ? errors.title = true : errors.title = null;
	isEmpty(news?.description) ? errors.description = true : errors.description = null;

	news.selectedResidents.length <= 0 ? errors.selectedResidents = true : null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}



export const validateActivity = (input) => {
	let errors = {};

	isEmpty(input?.title) ? errors.title = true : errors.title = null;
	isEmpty(input.description) ? errors.description = true : errors.description = null;
	isEmpty(input.startDate) || !isValidDate(input.startDate) ? errors.startDate = true : errors.startDate = null;
	isEmpty(input.endDate) || !isValidDate(input.endDate) ? errors.endDate = true : errors.endDate = null;

	if (!errors?.startDate && !errors?.endDate) {
		const start = moment(input.startDate, 'DD-MM-YYYY HH:mm')
		const end = moment(input.endDate, 'DD-MM-YYYY HH:mm')

		if (end.isSameOrBefore(start)) {
			errors.duration = true;
		} else {
			errors.duration = null;
		}
	}

	if (input.repeat && input.repeat?.value !== repeatOptions.NEVER.value) {
		!input.repeatDate ? errors.repeatDate = true : errors.repeatDate = null;
	}

	console.log(input.repeat, input.repeatDate)
	input.selectedResidents.length <= 0 ? errors.selectedResidents = true : errors.selectedResidents = null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}


export const validateActivityTemplate = (input) => {
	let errors = {};

	isEmpty(input?.title) ? errors.title = true : errors.title = null;
	isEmpty(input.description) ? errors.description = true : errors.description = null;
	isEmpty(input?.templateName) ? errors.templateName = true : errors.templateName = null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}



export const validateNewRelative = (input) => {
	let errors = {};

	console.log(input)
	!isValidEmail(input.email) ? errors.email = true : null;
	(!input.mobile || !isValidPhoneNumber(`+${input.mobile}`)) ? errors.mobile = true : errors.mobile = null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateUpdateRelative = (input) => {
	let errors = {};

	!isValidEmail(input.email) ? errors.email = true : null;
	(!input.mobile || !isValidPhoneNumber(`+${input.mobile}`)) ? errors.mobile = true : errors.mobile = null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateNewInterest = (input) => {
	let errors = {};

	isEmpty(input?.title) ? errors.title = true : errors.title = null;

	if (input.options.every(e => e === false)) {
		errors.options = true
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}


export const validateNewRoom = (input) => {
	let errors = {};

	isEmpty(input?.title) ? errors.title = true : errors.title = null;
	isEmpty(input?.department) ? errors.department = true : errors.department = null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateNewDepartment = (input) => {
	let errors = {};

	isEmpty(input?.title) ? errors.title = true : errors.title = null;
	isEmpty(input?.organizationId) ? errors.organizationId = true : errors.organizationId = null;

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}