import { createSlice } from "@reduxjs/toolkit";

export const calendarSlice = createSlice({
	name: "user",
	initialState: {
		currentView: "month",
	},
	reducers: {
		setCurrentView: (state, action) => {
			state.currentView = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCurrentView } = calendarSlice.actions;

export default calendarSlice.reducer;
