/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
	globalFilterTypes,
	getGlobalFilterType,
} from "../../utils/globalFilterUtils";
import { useSelector } from "react-redux";

import KLoader from "../KLoader";
import KTable from "../tables/KTable";
import KPagination from "../pagination/KPagination";

export default function KGlobalFilterTable({
	RenderItem,
	residentQuery,
	departmentQuery,
	organizationQuery,
	pageSize,
	setPageSize,
	ordering,
	setOrdering,
	headers,
	filterMethod,
	sortMethod,
	customQuery,
	hidePagination,
	searchValue,
	filters,
	type,
	onNewData,
	handleDelete
}) {
	const user = useSelector((state) => state.user);
	const globalFilter = useSelector((state) => state.globalFilter);

	const [page, setPage] = useState(0);
	const [size, setSize] = useState(pageSize ? pageSize : 25);
	var gType = "";

	const [pageInfo, setPageInfo] = useState()
	const [rows, setRows] = useState()
	const [rowsPerPage] = useState(5)
	const [tablePage, setTablePage] = useState(0);

	const changePageSize = (e) => {
		if (setPageSize)
			setPageSize(e);
		setSize(e);
	}

	const getId = () => {
		if (customQuery) return customQuery?.id;
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return globalFilter.residentFilter.id;
			case globalFilterTypes.DEPARTMENT:
				return globalFilter.departmentFilter.id;
			case globalFilterTypes.ORGANIZATION:
				return user.data.organization.id;
		}
	};

	const pickQurey = () => {
		if (customQuery) return customQuery?.query;
		else {
			gType = getGlobalFilterType(globalFilter);
			switch (gType) {
				case globalFilterTypes.RESIDENT:
					if (residentQuery !== null) {
						return residentQuery;
					}
					return organizationQuery;
				case globalFilterTypes.DEPARTMENT:
					return departmentQuery;
				case globalFilterTypes.ORGANIZATION:
					return organizationQuery;
			}
		}
	};


	const { loading, data, error } = useQuery(pickQurey(), {
		variables: { size: size, page: page, order: ordering?.id, id: getId(), search: searchValue, filters: filters },
		fetchPolicy: 'no-cache'
	});


	useEffect(() => {
		if (data) {
			const dataObj = data[Object.keys(data)[0]];
			if (dataObj?.results) {
				setRows(dataObj?.results)
				setPageInfo(dataObj?.pageInfo)
				onNewData && onNewData(dataObj)
			} else {
				setRows([dataObj])
			}
		}
	}, [data])

	const getList = () => {
		const filtered = filterMethod
			? rows?.filter((item) => filterMethod(item))
			: rows;


		sortMethod && filtered?.sort((a, b) => sortMethod(a, b));

		return filtered
	};

	// if (loading) {
	// 	return <KLoader />
	// }

	if (error) {
		return <p>{JSON.stringify(error, null, 2)}</p>;
	}

	return (
		<div>
			{!hidePagination &&
				<KPagination
					pageInfo={pageInfo}
					pageSize={size}
					setPageSize={changePageSize}
					setPage={setPage}
					page={page}
					type={type}
				/>
			}

			{loading &&
				<KLoader maxHeight={70} />
			}

			{!loading &&
				<KTable
					sorting={ordering}
					headers={headers}
					RenderItem={RenderItem}
					list={getList()}
					headerClick={(h) => setOrdering(h?.sorting)}
					handleDelete={handleDelete}
				/>

			}
		</div>
	);
}
