import React from "react";
// import { residentInfomationRoute } from "../../routes/residentRoutes";
import { roomRoute } from "../../../routes/mainRoutes";
import styled from 'styled-components';
import moment from 'moment';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Colors from '../../../styles/Colors';
import { useHistory } from "react-router-dom";

import {
	getResidentFullName,
} from "../../../utils/residentUtils";

export default function RoomRow({ data }) {
	const history = useHistory();
	return (
		<StyledTableRow
			key={data.id}
			sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			onClick={() =>
				history.push(`${roomRoute(data.id)}`, { from: history.location })
			}
			className="cursor-pointer"
		>
			<StyledCell align="left">{data.department.name}</StyledCell>
			<StyledCell align="left">{data.name}</StyledCell>
			<StyledCell align="left">{data?.resident ? getResidentFullName(data.resident) : "-"}</StyledCell>
			<StyledCell align="left">{moment(parseInt(data?.updatedAt)).format('DD-MM-YYYY HH:mm')}</StyledCell>
		</StyledTableRow>
	)
}

const StyledTableRow = styled(TableRow)(() => ({
	'&:nth-of-type(odd)': {
		backgroundColor: Colors.tableLight
	},
	'&:nth-of-type(even)': {
		backgroundColor: Colors.tableDark
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const StyledCell = styled(TableCell)(() => ({
	width: 50,
	padding: '8px 10px !important'
}));