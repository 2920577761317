import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import KDateTimePicker from '../../components/pickers/dateTimePicker/KDateTimePicker2';
import KPageWrapper from '../../components/KPageWrapper'
import { assignmentsRoute } from '../../routes/mainRoutes';
import { assignmentConsentStatus, consentOptions, consentOptionsList, responsible, responsibleList } from '../../utils/assignmentUtils';

import { GET_ALL_ASSIGNMENTS_BY_DEPARMENT_ID, GET_ALL_ASSIGNMENTS_BY_ORGANIZATION_ID, GET_ALL_ASSIGNMENTS_BY_RESIDENT_ID, GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, GET_ASSIGNMENT_BY_ID } from "../../graphql/graphqlQueries";
import { CREATE_MULTIPLE_TODO, UPDATE_TODO } from "../../graphql/graphqlMutations";
import KButton from '../../components/KButton';
import KSection from '../../components/cards/KSection';
import KInput from '../../components/inputs/KInput';
import KTextArea from '../../components/inputs/KTextArea';
import KRecipientForm from '../../components/forms/KRecipientForm';
import KSelect from '../../components/selectors/KSelect';
import KImageForm from '../../components/forms/KImageForm';
import KFileForm from '../../components/forms/KFileForm';
import KConfirmModal from '../../components/modals/KConfirmModal';
import { validateNewTodo } from '../../utils/validators/todoValidator';
import { useFileUtils } from '../../graphql/useFileUtils';
import { alertWarningMessages } from '../../utils/globalTypes';
import moment from 'moment';
import { residentStatusOptions } from '../../utils/residentUtils';
import KSnackbar from '../../components/KSnackbar';
import KModal from '../../components/modals/KModal';
import KTitle from '../../components/KTitle';

export default function EditTodo({ match }) {
	const history = useHistory();
	const user = useSelector(state => state.user);

	const { updateFiles, updateImages } = useFileUtils();

	const [todo, setTodo] = useState();
	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [deadline, setDeadline] = useState();
	const [images, setImages] = useState();
	const [imagesCopy, setImagesCopy] = useState();
	const [files, setFiles] = useState();
	const [filesCopy, setFilesCopy] = useState();
	const [selectedResponsible, setSelectedResponsible] = useState(responsible.WORKER);
	const [consentRequired, setConsentRequired] = useState(consentOptions.NO);
	const [showAddModal, setShowAddModal] = useState(false);

	const [selectedResidents, setSelectedResidents] = useState([])
	const [taggedResidents, setTaggedResidents] = useState([])

	const [errors, setErrors] = useState();
	const [createLoading, setCreateLoading] = useState(false);
	const [message, setMessage] = useState({ msg: "", type: "" });

	const [updateTodo, { loading: updateLoading }] = useMutation(UPDATE_TODO);

	const { data: todoData, loading: todoLoading, refetch } = useQuery(GET_ASSIGNMENT_BY_ID, {
		variables: { id: match?.params?.id },
		fetchPolicy: 'no-cache'
	});

	useEffect(() => {
		const fTodo = todoData?.getTodoById
		setTodo(fTodo)
		setTitle(fTodo?.title)
		setDescription(fTodo?.description)
		setSelectedResponsible(responsible[fTodo?.responsible])
		setConsentRequired(consentOptionsList?.find(e => e?.value === fTodo?.consentRequired))
		setSelectedResidents(fTodo?.participants?.map(p => p.resident))
		setImages(fTodo?.images)
		setImagesCopy(fTodo?.images)
		setFiles(fTodo?.files)
		setFilesCopy(fTodo?.files)

		if (fTodo?.deadline) {
			setDeadline(fTodo?.deadline ? moment(fTodo?.deadline, 'DD-MM-YYYY').toDate() : null)
		} else {
			setDeadline(null)
		}

	}, [todoData])


	console.log(selectedResidents, 'HERE')
	const makeTodo = async (saveAsNew) => {
		setCreateLoading(true)

		try {
			const imgs = await updateImages(images, imagesCopy)
			const fls = await updateFiles(files, filesCopy)

			await updateTodo({
				variables: {
					input: {
						id: todo.id,
						title: title,
						description: description,
						deadline: deadline ? moment(deadline, 'DD-MM-YYYY').format('DD-MM-YYYY') : null,
						residentId: parseInt(todo?.resident?.id),
						residentIds: selectedResidents.map(e => parseInt(e.id)),
						responsible: selectedResponsible.value,
						consentRequired: consentRequired?.value,
						images: imgs,
						files: fls,
						saveAsNew
					},
				},
			});

			history.push(assignmentsRoute)
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		} finally {
			setCreateLoading(false)
		}
	};

	const postTodo = async (saveAsNew) => {
		const validated = validateNewTodo({
			title,
			description,
			selectedResidents,
			deadline: deadline
		});

		if (!validated.success) {
			setShowAddModal(false)
			return setErrors(validated.errors)
		}

		makeTodo(saveAsNew)
	};

	const handleChange = (key, setter, value) => {
		setErrors({
			...errors,
			[key]: null
		});
		setter(value)
	}

	const getResponsibleDisabled = () => {
		const { status } = todo;

		if (status?.accepted || (status?.consent === assignmentConsentStatus.GIVEN.value && status?.consentBy !== null)) {
			return true
		}

		return false;
	}

	const getConsentDisabled = () => {
		const { status } = todo;

		if (status?.consent === assignmentConsentStatus.GIVEN.value && status?.consentBy !== null) {
			return true;
		}

		return false;
	}


	const TopBar = () => (
		<div className="flex items-center justify-end h-16 px-6 bg-white border-b">
			<div className="flex gap-x-4">

				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton className="min-w-full" label="GEM OPGAVE" onClick={() => setShowAddModal(true)} />
				</div>
			</div>

		</div>
	)

	return (
		<KPageWrapper
			paths={[{
				title: 'Opgaver',
				route: assignmentsRoute
			}, {
				title: 'Rediger opgave'
			}]}
			loading={[createLoading, todoLoading, updateLoading].some(e => e === true)}
			contentContainerStyle={{ padding: 0 }}
			TopBar={TopBar}
		>
			<KModal open={showAddModal} setOpen={setShowAddModal}>
				<div className="p-5">
					<div>
						<div className="items-center ">
							<KTitle className="" title="Gem opgave" />
							<div className="mt-1">
								<p className="text-gray-600">
									Vælg venligst hvordan du vil gemme opgaven.
								</p>
							</div>
							<div className="grid grid-cols-2 mt-2 text-left gap-x-5">
								<div>
									<p className="font-medium">Gem opgave med nuværende status</p>
									<p className="mb-6 text-gray-500">Anvend muligheden, hvis du fx har lavet en ændring i beskrivelsen af opgaven. Eventuelt løste opgaver eller ændringer vedr. en enkelt borger vil ikke blive ændret.</p>
									<KButton className="w-full" label="Gem opgave med nuværende status" onClick={() => postTodo(false)} />

								</div>
								<div>
									<p>Gem som ny opgave</p>
									<p className="mb-6 text-gray-500">Anvend muligheden, hvis opgaven skal løses på ny af alle modtagere. Eventuelt løste opgaver eller ændringer, som er udført, vil blive overskrevet.</p>
									<KButton className="w-full" label="Gem som ny opgave" onClick={() => postTodo(true)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</KModal>
			<div className="grid grid-cols-1 p-6 gap-x-4">
				<div className="grid grid-cols-1 pb-6 cols-span-2 gap-y-6">
					<KSection
						title="Oplysninger"
					>
						<div className="grid grid-cols-1 divide-y gap-y-3">
							<div className="grid grid-cols-1 gap-y-2">
								<KInput
									error={errors?.title}
									value={title}
									label="Titel"
									required
									onChange={(value) => handleChange('title', setTitle, value)}
								/>
								<KTextArea
									error={errors?.description}
									value={description}
									label="Beskrivelse"
									required
									onChange={(value) => handleChange('description', setDescription, value)}
									multiline
									rows={5}
								/>
							</div>
							<div className="grid grid-cols-3 pt-4 mt-4 gap-x-4">
								<KSelect
									disabled={todo && getResponsibleDisabled()}
									label="Vælg ansvarlig for udførelse"
									selected={selectedResponsible}
									elements={responsibleList}
									handleSelect={(value) => setSelectedResponsible(value)}
								/>
								{selectedResponsible?.name === responsible.WORKER.name &&
									<KSelect
										label="Kræver samtykke"
										selected={consentRequired}
										elements={consentOptionsList}
										handleSelect={(value) => setConsentRequired(value)}
										disabled={todo && getConsentDisabled()}
									/>
								}
								<KDateTimePicker
									label="Frist"
									onSubmit={setDeadline}
									disableTime={true}
									error={errors?.deadline}
									defaultValue={deadline ? moment(deadline, 'DD/MM/YYYY') : null}
									withClear
								/>
							</div>
						</div>
					</KSection>
					<KRecipientForm
						selectedResidents={selectedResidents}
						setSelectedResidents={setSelectedResidents}
						errors={errors}
						setErrors={setErrors}
					/>
					<KImageForm
						images={images}
						setImages={setImages}
						taggedResidents={taggedResidents}
						setTaggedResidents={setTaggedResidents}
						selectedResidents={selectedResidents}
					/>
					<KFileForm
						files={files}
						setFiles={setFiles}
					/>

				</div>
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KPageWrapper>
	)
}
