import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { allowedVideoFiles, isImageObj, isImageType } from '../../utils/fileUtils';
import PDFIcon from '../../assets/pdf-icon.png';

export default function KFileCard({ handleRemove, file, isImage }) {
	const [imageSize, setImageSize] = useState()
	const [imageSizeType, setImageSizeType] = useState('mb');
	const [filename, setFilename] = useState();

	useEffect(() => {
		if (file?.file?.size > 1000000) {
			setImageSize((file?.file?.size / 1000000)?.toFixed(1))
		} else if (file?.file?.size < 1000000 && file?.file?.size > 1000) {
			setImageSize((file?.file?.size / 1000)?.toFixed(0))
			setImageSizeType('kb')
		} else {
			setImageSize(file?.file?.size?.toFixed(0))
			setImageSizeType('b')
		}


		if (file) {
			if (file?.file?.path) {
				setFilename(file?.file?.path)
			}

			if (file?.key) {
				const name = file?.key?.split('images/')
				setFilename(name[1])
			}
		}
	}, [file])

	// console.log(file?.key?.split('images/'))

	const isVideo = (file) => {
		return allowedVideoFiles.includes(file.type)
	}

	return (
		<div className="relative flex p-2 border border-gray-300 rounded gap-x-4">
			{handleRemove &&
				<IconButton style={{ top: 0, right: 0, position: "absolute" }} onClick={handleRemove}>
					<CancelIcon style={{ height: 15, width: 15 }} />
				</IconButton>
			}

			{isImage
				? <img className="w-full h-full" style={{ maxHeight: '50px', maxWidth: '40px', objectFit: 'contain' }} src={file?.obj ? file?.obj : file?.url} />
				: <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded">
					<img className="w-7 h-7" src={PDFIcon} />
				</div>
			}

			<div className="mr-4">
				<p className="text-sm text-ellipsis ">{filename}</p>
				<p className="text-xs text-gray-500">{imageSize} {imageSize ? imageSizeType : null}</p>

			</div>
		</div>
	)
}
