import _ from "lodash";
import { isValidPhoneNumber } from "react-phone-number-input";
import { authenticationOptions } from "../residentUtils";
import { isValidEmail } from "../validators";

export const validateNewRelative = ({ relative, relatives }) => {
	let errors = {};

	if (_.isEmpty(relative?.firstName)) {
		errors.firstName = 'Feltet kan ikke være tomt.';
	} else {
		errors.firstName = null;
	}

	if (_.isEmpty(relative?.lastName)) {
		errors.lastName = 'Feltet kan ikke være tomt.';
	} else {
		errors.lastName = null;
	}

	if (!relative?.role) {
		errors.role = 'Feltet kan ikke være tomt.'
	}

	if (relatives?.some(e => e?.user?.role?.id == 3) && relative?.role?.id == 3) {
		errors.role = 'Der kan maks være 1 primær pårørende tilknyttet.'
	}

	if (relative?.sendInvitation) {
		if (relative?.loginMethod.id === authenticationOptions[1].id) {
			if (relative?.mobile && relative?.mobile?.value?.length !== relative?.mobile?.country?.format?.length && !relative?.mobile?.isEmpty) {
				errors.mobile = `Mobilnummer skal være korrekt formateret.`
			}

			if (!relative?.mobile?.value) {
				errors.mobile = 'Feltet kan ikke været tomt ved den valgte login metode, hvis der skal sendes invitation med det samme.'
			}
		}

		if (!isValidEmail(relative?.email)) {
			errors.email = 'Email skal være korrekt formateret'
		}

		if (!relative?.email) {
			errors.email = 'Feltet kan ikke være tomt hvis der skal sendes invitation med det samme.'
		}
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const softValidateNewRelative = ({ relative, relatives, activeIndex }) => {
	let errors = {};

	if (_.isEmpty(relative?.firstName)) {
		errors.firstName = 'Feltet kan ikke være tomt.';
	} else {
		errors.firstName = null;
	}

	if (_.isEmpty(relative?.lastName)) {
		errors.lastName = 'Feltet kan ikke være tomt.';
	} else {
		errors.lastName = null;
	}

	if (relative?.sendInvitation && relative?.email && !isValidEmail(relative?.email)) {
		errors.email = 'Email skal være korrekt formateret for at der kan sendes invitations afsted.'
	}

	if (!relative?.sendInvitation && relative?.email && !isValidEmail(relative?.email)) {
		errors.email = 'Email skal være korrekt formateret. Kan efterlades tomt.'
	}

	if (relative?.sendInvitation && !relative?.email) {
		errors.email = "Der kan ikke sendes invitation afsted uden email."
	}

	if (!relative?.role) {
		errors.role = 'Feltet kan ikke være tomt.'
	}

	if (relatives?.some((e, index) => e?.role?.id == 3 && index !== activeIndex) && relative?.role?.id == 3) {
		errors.role = 'Der kan maks være 1 primær pårørende tilknyttet.'
	}

	if (relative?.mobile && relative?.mobile?.value?.length !== relative?.mobile?.country?.format?.length && !relative?.mobile?.isEmpty) {
		errors.mobile = `Mobilnummer skal være korrekt formateret. Kan efterlades tomt.`
	}

	if (relative?.sendInvitation && !relative?.mobile && relative?.loginMethod?.id === 'FA2') {
		errors.mobile = "Der kan ikke sendes invitation ud med det samme uden et mobilnummer, ved den valgte login metode."
	}


	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateUpdateRelative = ({ relative, relatives }) => {
	let errors = {};

	if (_.isEmpty(relative?.user?.firstName)) {
		errors.firstName = 'Feltet kan ikke være tomt.';
	} else {
		errors.firstName = null;
	}

	if (_.isEmpty(relative?.user?.lastName)) {
		errors.lastName = 'Feltet kan ikke være tomt.';
	} else {
		errors.lastName = null;
	}

	if (!relative?.user?.role) {
		errors.role = 'Feltet kan ikke være tomt.'
	}

	if (relative?.users?.some(e => e?.user?.role?.id == 3 && e?.user?.id !== relative?.user?.user?.id) && relative?.user?.role?.id == 3) {
		errors.role = 'Der kan maks være 1 primær pårørende tilknyttet.'
	}

	if (relative?.user?.sendInvitation) {
		if (relative?.user?.loginMethod === authenticationOptions[1].id) {
			if (relative?.user?.mobile && (relative?.user?.mobile?.value?.includes('+45') && relative?.user?.mobile?.value?.length != 15)) {
				errors.mobile = `Mobilnummer skal være korrekt formateret.`
			}

			if (!relative?.user?.mobile?.value) {
				errors.mobile = 'Feltet kan ikke været tomt ved den valgte login metode.'
			}
		}

		if (!isValidEmail(relative?.user?.email)) {
			errors.email = 'Email skal være korrekt formateret.'
		}

		if (!relative?.user?.email) {
			errors.email = 'Feltet kan ikke være tomt.'
		}
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}

export const validateSendInvitation = ({ relative }) => {
	let errors = {};

	if (!isValidEmail(relative?.user?.email)) {
		errors.email = 'Email skal være korrekt formateret for at der kan sendes invitation.'
	}

	if (!relative?.user?.email) {
		errors.email = 'Feltet kan ikke være tomt for at sende invitation.'
	}

	if (relative?.user?.loginMethod === authenticationOptions[1].id) {
		if (relative?.user?.mobile && relative?.user?.mobile?.value?.length !== relative?.user?.mobile?.country?.format?.length && !relative?.user?.mobile?.isEmpty) {
			errors.mobile = `Mobilnummer skal være korrekt formateret for at sende invitation.`
		}

		if (!relative?.user?.mobile?.value) {
			errors.mobile = 'Feltet kan ikke været tomt for at sende invitation med den valgte login metode.'
		}
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}