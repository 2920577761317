import { useState, useRef, useEffect } from 'react'

import { ChevronLeftIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/24/solid';
import Colors from '../../../styles/Colors'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'
import Carousel from './Carousel';
import KModal from '../../modals/KModal';
import { MediaRender } from './MediaRender';
import { SmartDisplay } from '@mui/icons-material';
import { classNames } from '../../../utils/tailwindUtils';

const isVideo = (media) => {
	const url = new URL(media);
	const lcPathname = url.pathname?.toLowerCase();
	return lcPathname?.endsWith('.mp4') || lcPathname?.endsWith('.mov') || media?.startsWith('data:video');
};

export default function KImageCarousel({
	images = imgs,
	setImages = () => { },
	editable = true,
	thumbnailSize
}) {

	const [slideNumber, setSlideNumber] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const thumbnailContainer = useRef(null);
	const previewRef = useRef(null);

	const { width, height } = useWindowDimensions();

	const VIEW_HEIGHT = Math.min(width * 0.3, height * 0.5)
	const THUMBNAIL_SIZE = thumbnailSize ? thumbnailSize : VIEW_HEIGHT / 5


	useEffect(() => {
		scrollToThumbnail(slideNumber)
	}, [slideNumber])


	const scrollToThumbnail = (index) => {
		const thumbnail = thumbnailContainer?.current?.children[index];
		// thumbnail?.scrollIntoView({ behavior: 'smooth', });
	};

	const handleOpenModal = (index) => {
		setSlideNumber(index)
		setOpenModal(true)
	}

	const handleTNClick = (index) => {
		setSlideNumber(index)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const prevSlide = (e) => {
		e?.stopPropagation();
		setSlideNumber((prev) => (prev === 0 ? images.length - 1 : prev - 1));
	}

	const nextSlide = (e) => {
		e?.stopPropagation();
		setSlideNumber((slideNumber + 1) % images.length)
	}


	if (!images || images.length <= 0) return null

	return (
		<div className='flex pt-1'>
			<KModal open={openModal} setOpen={setOpenModal} >
				<div
					onClick={handleCloseModal}
					className='flex items-center justify-center w-full h-full bg-black select-none bg-opacity-90'
				>
					<ChevronLeftIcon color={Colors.lightGrey} className='absolute w-16 cursor-pointer left-5' onClick={prevSlide} />
					<ChevronRightIcon color={Colors.lightGrey} className='absolute w-16 cursor-pointer right-5' onClick={nextSlide} />
					<div className='flex flex-col items-center'>
						<MediaRender
							onClick={(e) => e.stopPropagation()}
							media={images[slideNumber]}
							objectFit='contain'
							height={height * 0.8}
						/>
						{/* <img className='pb-4'
							src={images[slideNumber].url} alt=''
							onClick={(e) => e.stopPropagation()}
						/> */}
						<div className='absolute bottom-0 right-0 px-2 py-1 text-xl text-white bg-black opacity-80 rounded-br-md rounded-tl-md'>
							{slideNumber + 1}/{images.length}
						</div>
						<ImageSliderBullets visible={true} length={images.length} currentIdx={slideNumber} onClickBullets={handleTNClick} />
						<DisplayIndex index={slideNumber} length={images.length} />
					</div>
				</div>
			</KModal>


			{/* Image preview  */}
			<Carousel
				activeIndex={slideNumber}
				setActiveIndex={setSlideNumber}
				className='w-full pt-2 rounded-md cursor-pointer'
				auto={false}
			>
				{
					images.map((img, i) =>
						<Carousel.Item key={i}
							className='w-full h-full rounded-md bg-gray-50'
							style={{
								// background: "white",
								minHeight: VIEW_HEIGHT,
								position: 'relative',
								width: '100%'
							}}
							onClick={() => handleOpenModal(slideNumber)}
						>
							<MediaRender 
								media={img} 
								style={{
									maxHeight: VIEW_HEIGHT,
									objectFit: 'contain',
								}}
							/>
							{/* <img
										src={img.url} alt=''
										style={{
											maxHeight: VIEW_HEIGHT,
											objectFit: 'contain',
										}}
									/> */}
						</Carousel.Item>
					)}
			</Carousel>

			{/* Thumbail list */}
			{images.length > 1 && (
				<div
					className='pt-2 pl-4 pr-8 overflow-x-hidden overflow-y-scroll'
					ref={thumbnailContainer}
					style={{
						maxHeight: VIEW_HEIGHT,
					}}
				>
					{images.map((slide, index) => {
						return (
							<div
								key={index}
								className={`rounded-lg transition-all transform cursor-pointer hover:scale-105 ${index === images.length - 1 ? "mb-none" : "mb-4"}`}

								onClick={() => { handleTNClick(index) }}
							>
								{!isVideo(slide?.url) ? (
									<img
										className={`${slideNumber == index && "border-lightPurple"}  hover:shadow-lg rounded-lg border border-gray-200`}
										src={slide.url}
										alt=''
										style={{
											minWidth: THUMBNAIL_SIZE, width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE, objectFit: 'cover',
										}}
									/>
								) : (
									<span className='relative'>
										<SmartDisplay 
											className='absolute text-white opacity-80'
											style={{
												minWidth: THUMBNAIL_SIZE, width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE, 
												transform: 'translate(-50%, -50%)',
											}}
										/>
										<video
											src={slide.url}
											className={`${slideNumber == index && "border-lightPurple"}  hover:shadow-lg rounded-lg border border-gray-200`}
											style={{
												minWidth: THUMBNAIL_SIZE, width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE, 
												objectFit: 'cover',
											}}
										/>
									</span>
								)}
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}

const DisplayIndex = ({ index, length }) => (
	<div className='absolute bottom-0 right-0 px-2 py-1 text-xl text-white bg-black opacity-80 rounded-br-md rounded-tl-md'>
		{index + 1}/{length}
	</div>
);


const ImageSliderBullets = ({ length, currentIdx, onClickBullets }) => {
	return (
		<>
			<div style={basic}>
				{Array.from(Array(length).keys()).map((idx) => (
					<button
						key={`bullet-${idx}`}
						type="button"
						data-id={`bullet-${idx}`}
						style={idx === currentIdx ? BulletActive : BulletNormal}
						onClick={(e) => {
							e.stopPropagation()
							onClickBullets(idx)
						}}
					/>
				))}
			</div>
		</>
	);
};


const bulletSize = 18;
const bulletMargin = 3;
const basicBullet = {
	display: 'inline-block',
	cursor: 'pointer',
	outline: 'none',
	background: 'none',
	boxShadow: '1px 1px 1px 0px #1a1a1a',
	borderRadius: '50%',
	border: '1px solid #FFFFFF',
	width: `${bulletSize}px`,
	height: `${bulletSize}px`,
	marginLeft: `${bulletMargin}px`,
	marginRight: `${bulletMargin}px`
};

const basic = {
	display: 'block',
	margin: '0',
	padding: '0',
	border: '0'
};

const BulletNormal = { ...basic, ...basicBullet };
const BulletActive = { ...basic, ...basicBullet, background: '#FFF' };


const imgs = [

	{
		id: Math.random(),

		url: 'https://picsum.photos/id/1015/1000/600/',
		img: 'https://picsum.photos/id/1015/1000/600/',
		thumbnail: 'https://picsum.photos/id/1015/250/150/',
	},
	{
		url: 'https://picsum.photos/id/1018/300/100/',
		id: Math.random(),
		img: 'https://picsum.photos/id/1018/300/400/',
		thumbnail: 'https://picsum.photos/id/1018/250/150/',
	},
	{
		id: Math.random(),

		url: 'https://picsum.photos/id/1019/400/1000/',
		img: 'https://picsum.photos/id/1019/1000/600/',
		thumbnail: 'https://picsum.photos/id/1019/250/150/',
	},
	{
		id: Math.random(),

		url: 'https://picsum.photos/id/1011/1000/600/',
		img: 'https://picsum.photos/id/1018/1000/600/',
		thumbnail: 'https://picsum.photos/id/1018/250/150/',
	},
	{
		id: Math.random(),

		url: 'https://picsum.photos/id/1015/1000/600/',
		img: 'https://picsum.photos/id/1015/1000/600/',
		thumbnail: 'https://picsum.photos/id/1015/250/150/',
	},
	{
		url: 'https://picsum.photos/id/1018/1000/600/',

		img: 'https://picsum.photos/id/1018/1000/600/',
		thumbnail: 'https://picsum.photos/id/1018/250/150/',
	},
	// {
	//  id: Math.random(),

	//  url: 'https://picsum.photos/id/1015/1000/600/',
	//  img: 'https://picsum.photos/id/1015/1000/600/',
	//  thumbnail: 'https://picsum.photos/id/1015/250/150/',
	// },
	// {
	//  id: Math.random(),

	//  url: 'https://picsum.photos/id/1019/1000/600/',
	//  img: 'https://picsum.photos/id/1019/1000/600/',
	//  thumbnail: 'https://picsum.photos/id/1019/250/150/',
	// },
	// {
	//  id: Math.random(),

	//  url: 'https://picsum.photos/id/1011/1000/600/',
	//  img: 'https://picsum.photos/id/1018/1000/600/',
	//  thumbnail: 'https://picsum.photos/id/1018/250/150/',
	// },
	// {
	//  id: Math.random(),

	//  url: 'https://picsum.photos/id/1015/1000/600/',
	//  img: 'https://picsum.photos/id/1015/1000/600/',
	//  thumbnail: 'https://picsum.photos/id/1015/250/150/',
	// },

];
