import React from 'react'
import ReactPlayer from 'react-player'
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

export default function KVideoPlayer({ open, setOpen, url, children }) {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '70%',
		height: '70%',
		bgcolor: 'background.paper',
	};

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<div>
				<Box sx={{ ...style }}>
					<ReactPlayer
						className='react-player fixed-bottom'
						url={url}
						width='100%'
						height='100%'
						controls = {true}
					/> 
					{children}
				</Box>
			</div>
		</Modal>
	)
}
