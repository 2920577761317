import { useMutation } from '@apollo/client';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import KSection from '../../../../components/cards/KSection';
import KInput from '../../../../components/inputs/KInput';
import KPhoneInput from '../../../../components/inputs/KPhoneInput';
import KButton from '../../../../components/KButton';
import KLoader from '../../../../components/KLoader';
import KSnackbar from '../../../../components/KSnackbar';
import KTitle from '../../../../components/KTitle';
import KConfirmModal from '../../../../components/modals/KConfirmModal';
import KSelect from '../../../../components/selectors/KSelect';
import KSelectBox from '../../../../components/selectors/KSelectBox';
import { DELETE_RESIDENT_BY_ID, SEND_INVITATION_MAIL, UPDATE_RESIDENT_RELATIVE, UPDATE_USER_BY_ID, VALIDATE_EMAIL } from '../../../../graphql/graphqlMutations';
import { GET_RESIDENT_BY_ID } from '../../../../graphql/graphqlQueries';
import { residentsRoute } from '../../../../routes/mainRoutes';
import { unixToDateTimeString } from '../../../../utils/dateUtils';
import { alertWarningMessages } from '../../../../utils/globalTypes';
import { uploadMobileFormat } from '../../../../utils/KmdUtils';
import { relationArray } from '../../../../utils/relationUtil';
import { authenticationOptions, relativeClassifications, relativeInvitationTypes, residentStatusOptions } from '../../../../utils/residentUtils';
import { validate } from '../../../../utils/validators/index';
import { validateSendInvitation, validateUpdateRelative } from '../../../../utils/validators/relativeValidators';

export default function EditRelative({ resident, relative, setOpen, setSnackbarMessage }) {
	const user = useSelector(state => state.user);

	const [message, setMessage] = useState({ msg: "", type: "", timeStamp: null });

	const [fRelative, setFRelative] = useState();

	const [showResend, setShowResend] = useState();
	const [showInvite, setShowInvite] = useState();

	const [errors, setErrors] = useState();

	const [resendInvitation, { loading: invitationLoading }] = useMutation(SEND_INVITATION_MAIL, {
		refetchQueries: [GET_RESIDENT_BY_ID]
	});

	const [doUserUpdate, { loading: updateLoading }] = useMutation(UPDATE_USER_BY_ID, {
		refetchQueries: [GET_RESIDENT_BY_ID]
	});

	const [doUpdateRelation] = useMutation(UPDATE_RESIDENT_RELATIVE);

	const handleRelativeChange = (key, value) => {
		setFRelative({
			...fRelative,
			user: {
				...fRelative.user,
				[key]: value
			}
		})
	}

	const onResend = async () => {
		try {

			const validated = validate(
				validateSendInvitation,
				{ relative: relative },
				setErrors
			)

			if (!validated) {
				setShowInvite(false)
				return setShowResend(false)
			}

			await resendInvitation({
				variables: {
					input: {
						userId: fRelative?.user?.id,
						residentName: resident?.fullName,
						residentId: resident.id,
					}
				}
			})

			setMessage(alertWarningMessages.SUCCESS("Email blev sendt til pårørende"))
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		} finally {
			setShowResend(false)
			setShowInvite(false)
		}
	}

	const hasChanges = () => {
		if (fRelative?.user?.email != relative?.user?.email) {
			return true
		} else if (fRelative?.user?.firstName != relative?.user?.firstName) {
			return true
		} else if (fRelative?.user?.lastName != relative?.user?.lastName) {
			return true
		} else if (fRelative?.user?.mobile != relative?.user?.mobile) {
			return true
		} else if (fRelative?.user?.loginMethod != relative?.user?.loginMethod) {
			return true
		} else {
			return false
		}
	}

	console.log(fRelative)

	const onUserUpdate = async () => {
		try {
			const userInformation = {
				firstName: fRelative.user?.firstName,
				lastName: fRelative.user?.lastName,
				email: fRelative.user?.email ? fRelative?.user?.email : null,
				mobile: fRelative?.user?.mobile?.value ? uploadMobileFormat(fRelative?.user?.mobile?.value) : null,
				loginMethod: fRelative?.user?.loginMethod,
				roleId: Number(fRelative?.user?.role?.id),
				sendInvitation: fRelative?.user?.sendInvitation
			};

			const validated = validate(
				validateUpdateRelative,
				{ relative: fRelative },
				setErrors
			)

			if (!validated) {
				return;
			}

			if (fRelative?.relation !== relative?.relation) {
				await doUpdateRelation({
					variables: {
						input: {
							executorId: user.data.id,
							userId: fRelative?.user?.id,
							relation: fRelative?.relation,
							residentId: parseInt(resident?.id),
						},
					},
				})
			}


			await doUserUpdate({
				variables: {
					input: { id: fRelative.user.id, ...userInformation },
				},
			});

			setSnackbarMessage(alertWarningMessages.UPDATED('Pårørende'))
			setOpen(false)
		} catch (err) {
			if (err?.message === 'Email already in use') {
				setErrors({ email: 'Email er allerede i brug af en anden pårørende.' })
			} else {
				setMessage(alertWarningMessages.UNKNOWN)
			}
		}
	}

	useEffect(() => {
		setFRelative(relative)
	}, [relative])

	const checkIfClassificationDisabled = () => {
		if (resident?.status === 'ACTIVE') {
			return true;
		} else if (fRelative?.user?.roleId === relativeClassifications[0].id && resident?.relatives?.map(e => e.user?.roleId)?.includes(relativeClassifications[0].id)) {
			return false
		} else if (fRelative?.user?.roleId === relativeClassifications[1].id && !resident?.relatives?.map(e => e.user?.roleId)?.includes(relativeClassifications[0].id)) {
			return false
		} else {
			return true
		}
	}

	return (
		<div className="w-full">
			{updateLoading || invitationLoading &&
				<div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
					<KLoader loading={updateLoading || invitationLoading} />
				</div>
			}
			<div>
				<div className="flex items-center justify-between px-4 py-4 border-b">
					<KTitle title="Rediger pårørende" />
					<XMarkIcon className="w-6 h-6 cursor-pointer stroke-2" onClick={() => setOpen(false)} />
				</div>

				<KConfirmModal
					open={showResend}
					setOpen={setShowResend}
					title="Gensend invitation til Pårørende"
					description="Ved at klikke på send vil du gensende en invitations-mail til pårørende, hvor de vil modtage en kode til at logge på Kintella med."
					handleConfirm={() => onResend()}
					confirmText="SEND"
				/>
				<KConfirmModal
					open={showInvite}
					setOpen={setShowInvite}
					title="Inviter pårørende til at bruge Kintella"
					description="Ved at klikke på send vil du sende en invitations-mail til pårørende, hvor de vil modtage en kode til at logge på Kintella med."
					handleConfirm={() => onResend()}
					confirmText="SEND"
				/>
				<div style={{ height: window.innerHeight * 0.75, overflow: 'scroll' }} className="px-6 pt-6 pb-10 bg-gray-50 gap-y-6">
					<KSection
						title="Stamdata"
					>
						<div className="grid grid-cols-2 gap-x-4 gap-y-4">
							<KSelect
								error={errors?.role}
								label="Klassifikation"
								required
								elements={relativeClassifications}
								handleSelect={(value) => handleRelativeChange('role', value)}
								selected={relativeClassifications?.find(e => e.id == fRelative?.user?.role?.id)}
								checkDisabled={() => checkIfClassificationDisabled()}
							/>
							<KSelect
								label="Relation"
								selected={relationArray.find(e => e === fRelative?.relation)}
								elements={relationArray}
								handleSelect={(value) => setFRelative({ ...fRelative, relation: value })}
							/>
							<KInput
								value={fRelative?.user?.firstName}
								required
								fullWidth={true}
								label="Fornavn"
								onChange={(value) => handleRelativeChange('firstName', value)}
								error={errors?.firstName}
							/>

							<KInput
								value={fRelative?.user?.lastName}
								required
								fullWidth={true}
								label="Efternavn"
								onChange={(value) => handleRelativeChange('lastName', value)}
								error={errors?.lastName}
							/>
							<KInput
								value={fRelative?.user?.email}
								fullWidth={true}
								label="Email"
								onChange={(value) => handleRelativeChange('email', value)}
								error={errors?.email}
							/>
							<KPhoneInput
								showInfoText={fRelative?.user?.loginMethod === authenticationOptions[1].id}
								mobile={fRelative?.user?.mobile}
								error={errors?.mobile}
								label="Mobilnummer"
								onChange={(value) => handleRelativeChange('mobile', value)}
							/>
						</div>
					</KSection>
					<div className="mt-6">
						<KSection
							title="Status"
						>
							<KSelect
								label="Login metode"
								required
								elements={authenticationOptions}
								handleSelect={(value) => handleRelativeChange('loginMethod', value.id)}
								selected={authenticationOptions?.find(e => e.id === fRelative?.user?.loginMethod)}
							/>
							{!fRelative?.user?.invitationSend && resident?.status != residentStatusOptions.ACTIVE.id &&
								<div>
									<KSelectBox
										error={errors?.invitation}
										handleSelect={(e) => handleRelativeChange('sendInvitation', e.id === relativeInvitationTypes[0].id ? true : false)}
										className="grid-cols-2"
										list={relativeInvitationTypes}
										selected={fRelative?.user?.sendInvitation ? relativeInvitationTypes[0] : relativeInvitationTypes[1]}
									/>
								</div>
							}
							<div className="grid grid-cols-2 mt-4 gap-x-3 gap-y-4">
								{fRelative?.user?.invitationSend &&
									<KInput
										label="Pårørende invitation"
										value="Pårørende inviteret til at bruge Kintella"
										disabled
										Icon={() => (
											<div>
												<CheckCircleIcon className="w-6 h-6 text-green-500" />
											</div>
										)}
									/>
								}
								{!fRelative?.user?.invitationSend && resident?.status === residentStatusOptions.ACTIVE.id &&
									<KInput
										label="Pårørende invitation"
										value="Afventer invitation til at bruge Kintella"
										disabled
										Icon={() => (
											<div>
												<ExclamationCircleIcon className="w-6 h-6 text-yellow-400" />
											</div>
										)}
									/>
								}

								{fRelative?.user?.invitationSend &&
									<div>
										<KInput
											label="Invitation sendt til pårørende d."
											value={fRelative?.user?.invitationSend}
											disabled

										/>
									</div>
								}

								{fRelative?.user?.active &&
									<KInput
										label="Pårørende aktivitet"
										value="Pårørende er aktiv på Kintella"
										disabled
										Icon={() => (
											<div>
												<CheckCircleIcon className="w-6 h-6 text-green-500" />
											</div>
										)}
									/>
								}
								{fRelative?.user?.lastActive &&
									<div>
										<KInput
											label="Senest aktiv"
											value={unixToDateTimeString(fRelative?.user?.lastActive)}
											disabled
										/>
									</div>
								}
								{!fRelative?.user?.lastActive && !fRelative?.user?.invitationSend && resident?.status === residentStatusOptions.ACTIVE.id &&
									<KButton
										disabled={hasChanges()}
										disabledText="Du skal gemme dine ændringer, før du kan sende invitationen"
										onClick={() => setShowInvite(true)}
										className="w-full h-10 mt-6 shadow-sm"
										label="Send invitations-mail til pårørende"

									/>
								}

								{fRelative?.user?.invitationSend && resident?.status === residentStatusOptions.ACTIVE.id &&
									<div>
										<KButton
											disabled={hasChanges()}
											disabledText="Du skal gemme dine ændringer, før du kan gensende invitationen"
											onClick={() => setShowResend(true)}
											className="w-full h-10 mt-2 shadow-sm"
											label="Gensend invitations-mail til pårørende"
										/>
									</div>
								}
							</div>
						</KSection>
					</div>
				</div>
				<div className="flex items-center justify-center py-4">
					<KButton onClick={() => onUserUpdate()} label="Gem pårørende" />
				</div>
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
				key={message.timeStamp}
			/>
		</div>
	)
}
