import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import KDateTimePicker from '../../components/pickers/dateTimePicker/KDateTimePicker2'

import KPageWrapper from '../../components/KPageWrapper'
import { GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID, GET_ALL_CALENDAR_ACTIVITIES_IN_DRAFT_BY_CREATOR_ID } from '../../graphql/graphqlQueries'
import { assignmentRoute, assignmentsRoute, calendarActivityRoute, calendarRoute } from '../../routes/mainRoutes'
import { getResidentFullName, residentStatusOptions } from '../../utils/residentUtils'
import moment from 'moment';
import { CREATE_CALENDAR_ACTIVITY } from '../../graphql/graphqlMutations'
import { validateActivity } from '../../utils/validators'
import { repeatOptions, repeatOptionsList } from '../../utils/calendarUtils'
import { toDatabaseFormat } from '../../utils/dateUtils'
import ImageUploadQuery from '../../graphql/ImageUploadQuery'
import { UPLOAD_IMAGE } from '../../graphql/graphqlMutations';
import KSection from '../../components/cards/KSection'
import KInput from '../../components/inputs/KInput'
import KTextArea from '../../components/inputs/KTextArea'
import KSelect from '../../components/selectors/KSelect'
import KImageForm from '../../components/forms/KImageForm'
import KFileForm from '../../components/forms/KFileForm'
import KButton from '../../components/KButton'
import KRecipientForm from '../../components/forms/KRecipientForm'
import KCheckbox from '../../components/inputs/KCheckbox'
import KModal from '../../components/modals/KModal'
import KTitle from '../../components/KTitle'
import { validateNewActivity } from '../../utils/validators/calendarValidator'
import { useFileUtils } from '../../graphql/useFileUtils'
import { alertWarningMessages } from '../../utils/globalTypes'
import KSnackbar from '../../components/KSnackbar'


export default function CreateCalendarActivity({ match, history }) {
	const user = useSelector(state => state.user);

	const { createFiles, createImages } = useFileUtils();

	const emptyTemplate = { title: "", description: "", actionRequired: false };

	const [title, setTitle] = useState("");
	const [template, setTemplate] = useState(emptyTemplate);
	const [description, setDescription] = useState("");
	const [workerNote, setWorkerNote] = useState();
	const [deadlineDate, setDeadlineDate] = useState();
	const [errors, setErrors] = useState();
	const [selectedResidents, setSelectedResidents] = useState([]);
	const [taggedResidents, setTaggedResidents] = useState([])
	const [residents, setResidents] = useState([]);
	const [actionRequired, setActionRequired] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [repeat, setRepeat] = useState(repeatOptions.NEVER)
	const [repeatDate, setRepeatDate] = useState(null)

	const [images, setImages] = useState();
	const [files, setFiles] = useState();
	const [showAddModal, setShowAddModal] = useState();
	const [createLoading, setCreateLoading] = useState();

	const [message, setMessage] = useState({ msg: "", type: "" });

	const [calendarActivityTemplates, setCalendarActivityTemplates] = useState([]);

	const { data: calendarActivityTemplatesData, error: templateError, loading: calendarActivityTemplatesLoading } = useQuery(GET_ALL_CALENDAR_ACTIVITY_TEMPLATES_BY_ORGANIZATION_ID, { variables: { id: user?.data?.organization?.id } });

	const [doCreateActivity] = useMutation(CREATE_CALENDAR_ACTIVITY, {
		refetchQueries: [GET_ALL_CALENDAR_ACTIVITIES_IN_DRAFT_BY_CREATOR_ID]
	})

	useEffect(() => {
		if (calendarActivityTemplatesData) {
			setCalendarActivityTemplates(calendarActivityTemplatesData.getAllCalendarActivityTemplatesByOrganizationId)
		}
	}, [calendarActivityTemplatesData])


	const handleChange = (key, setter, value) => {
		setErrors({
			...errors,
			[key]: null
		});

		setter(value)
	}

	const handleChangeStartdate = (value) => {
		setErrors({
			...errors,
			['startDate']: null
		});

		setStartDate(value);
		if (endDate && endDate < value)
			setEndDate(value)
	}

	const handleTemplateChange = (templateName) => {
		const tp = calendarActivityTemplates.find(t => t.templateName === templateName)

		if (tp) {
			setTemplate(tp)
			setTitle(tp.title)
			setDescription(tp.description)
			setActionRequired(tp.actionRequired)
			setWorkerNote(tp?.note)
			setImages(tp?.imageUrl ? [{ obj: tp?.imageUrl }] : undefined)

			if (tp.startDate) setStartDate(formatDate(tp?.startDate))
			if (tp.endDate) setEndDate(formatDate(tp?.endDate))
		} else {
			// resetting fields if template is cleared and no fields is changed
			if (actionRequired === template?.actionRequired) setActionRequired(false)
			if (description === template?.description) setDescription("")
			if (title === template?.title) setTitle("")
			if (workerNote === template?.note) setWorkerNote("")
			if (startDate === formatDate(template?.startDate)) setStartDate("")
			if (endDate === formatDate(template?.endDate)) setEndDate("")
			if (images?.length > 0 && template?.imageUrl === images[0]?.obj) setImages()

			setTemplate(emptyTemplate)
		}
	}

	const getRepeatDateString = () => {
		const startTime = moment(startDate, 'DD-MM-YYYY HH:mm').format('HH:mm')
		const repeatD = moment(repeatDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
		const test = `${repeatD} ${startTime}`
		return test;
	}

	const formatDate = (d) => {
		return moment(new Date(Number(d))).format('DD-MM-YYYY HH:mm')
	}

	const makeCalendarActivity = async (isDraft) => {
		setCreateLoading(true)

		try {

			const imgs = await createImages(images?.map(e => e.file))
			const fls = await createFiles(files?.map(e => e.file))

			await doCreateActivity({
				variables: {
					input: {
						creatorId: user?.data?.id,
						departmentId: 1, // TODO
						organizationId: user?.data?.organization?.id,
						title: title,
						description: description,
						startDate: moment(startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ssZ'),
						endDate: moment(endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ssZ'),
						calendarActivityParticipants: selectedResidents?.map(r => r.id),
						actionRequired: actionRequired,
						withRepetition: repeat?.value !== repeatOptions.NEVER.value ? true : false,
						repetitionType: repeat?.value !== repeatOptions.NEVER.value ? repeat?.value : null,
						repetitionDate: repeat?.value !== repeatOptions.NEVER.value ? toDatabaseFormat(getRepeatDateString()) : null,
						isDraft: isDraft,
						note: workerNote,
						images: imgs,
						files: fls
					}
				}
			})

			history.push(calendarRoute, { isDraft: isDraft })
		} catch (err) {
			setMessage(alertWarningMessages.UNKNOWN)
		} finally {
			setCreateLoading(false)
		}
	}

	const postActivity = (isDraft) => {
		const validated = validateNewActivity({
			title,
			description,
			selectedResidents,
			startDate,
			endDate,
			repeat,
			repeatDate
		});

		if (!validated.success) {
			setShowAddModal(false)
			return setErrors(validated.errors)
		}

		makeCalendarActivity(isDraft)
		// images && images?.some(obj => Object.keys(obj).includes('file'))
		// 	? ImageUploadQuery(makeCalendarActivity, images[0]?.file, doUploadImage, isDraft)
		// 	: makeCalendarActivity(images ? images[0]?.obj : null, isDraft)

	};

	const TopBar = () => (
		<div className="flex items-center justify-end h-16 px-6 bg-white border-b">
			<div className="flex gap-x-4">
				{/* <div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton disabledText="Nyheden kan ikke vises, før alle påkrævet felter er udfyldt." disabled={!title || !description || selectedResidents?.length === 0} className="min-w-full" label="FORHÅNDVISNING" onClick={() => setShowPreview(true)} />
				</div> */}
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton className="min-w-full" label="OPRET AKTIVITET" onClick={() => setShowAddModal(true)} />
				</div>
			</div>

		</div>
	)

	console.log(startDate, 'here')
	return (
		<KPageWrapper
			paths={[{
				title: 'Kalender',
				route: calendarRoute
			}, {
				title: 'Opret aktivitet'
			}]}
			loading={[createLoading].some(val => val === true)}
			contentContainerStyle={{ padding: 0 }}
			TopBar={TopBar}
		>
			<div className="p-6">
				<KModal open={showAddModal} setOpen={setShowAddModal}>
					<div className="p-5">
						<div>
							<div className="flex flex-col items-center text-center">
								<KTitle className="text-center" title="Opret aktivitet" />
								<div className="mt-3">
									<p className="text-gray-600">
										Vælg venligst hvordan du vil oprette aktiviteten
									</p>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-3 mt-5 gap-x-4 ">
							<KButton className="w-full" label="Udgiv aktivitet" onClick={() => postActivity(false)} />
							<KButton disableText={true} disabled={repeat?.value !== repeatOptions.NEVER.value} className="w-full" label="Opret som kladde" onClick={() => postActivity(true)} />
							<KButton className="w-full" label="Annuller" onClick={() => setShowAddModal(false)} />
						</div>
					</div>
				</KModal>
				<div className="grid grid-cols-1 gap-y-6">
					<KSection
						title="Oplysninger"
					>
						<div className="grid grid-cols-1 divide-y gap-y-3">
							<div className="grid grid-cols-3 mb-2 gap-x-4">
								<KSelect
									label="Skabelon"
									selected={template?.templateName}
									elements={calendarActivityTemplates.map(t => t?.templateName)}
									handleSelect={(value) => handleTemplateChange(value)}
									withClear
								/>
							</div>
							<div className="grid grid-cols-1 divide-y">
								<div className="grid grid-cols-1 pt-4 mb-4 gap-y-4">
									<KInput
										error={errors?.title}
										value={title}
										label="Titel"
										required
										onChange={(value) => handleChange('title', setTitle, value)}
									/>
									<KTextArea
										error={errors?.description}
										value={description}
										label="Beskrivelse"
										required
										onChange={(value) => handleChange('description', setDescription, value)}
										multiline
										rows={5}
									/>
									<KTextArea
										value={workerNote}
										label="Medarbejder note (Vil ikke blive vist for pårørende)"
										onChange={(value) => handleChange('workerNote', setWorkerNote, value)}
										multiline
										rows={5}
									/>
								</div>
								<div className="flex items-start justify-start pt-4 gap-x-2">
									<KCheckbox setChecked={setActionRequired} />
									<div style={{ marginTop: -1.5 }} className="flex-1 min-w-0 text-sm">
										<label className="font-medium text-gray-700">
											Kræver svar fra pårørende
										</label>
										<p className="text-gray-500">Kryds dette felt af, hvis de pårørende skal fortælle, om de deltager i den aktivitet, du er ved at oprette.</p>
									</div>

								</div>
							</div>
						</div>
					</KSection>
					<KSection
						title="Dato og gentagelse"
						warning={repeat?.value !== repeatOptions.NEVER.value
							? "Obs! Du kan ikke oprette en kladde, der indeholder gentagelser. Hvis aktiviteten skal gentages, skal du tilføje gentagelsen, når du udgiver aktiviteten."
							: ""
						}
						hideWarningHeader
					>
						<div className="grid grid-cols-2 gap-x-4 gap-y-4">
							<KDateTimePicker
								label="Start dato"
								onSubmit={(e) => handleChangeStartdate(e)}
								error={errors?.startDate}
								required
								defaultValue={startDate}
							/>
							<KDateTimePicker
								label="Slut dato"
								onSubmit={(e) => handleChange('endDate', setEndDate, e)}
								error={errors?.endDate}
								required
								defaultValue={endDate ?? startDate}
							/>
							<KSelect
								label="Gentagelse"
								selected={repeat}
								elements={repeatOptionsList}
								handleSelect={(value) => setRepeat(value)}
								elementKey="label"
							/>
							{repeat?.value !== repeatOptions.NEVER.value &&
								<KDateTimePicker
									label="Gentag indtil"
									onSubmit={(e) => handleChange('repeatDate', setRepeatDate, e)}
									error={errors?.repeatDate}
									required
									disableTime
								/>
							}
						</div>
					</KSection>
					<KRecipientForm
						selectedResidents={selectedResidents}
						setSelectedResidents={setSelectedResidents}
						errors={errors}
						setErrors={setErrors}
					/>
					<KImageForm
						images={images}
						setImages={setImages}
						taggedResidents={taggedResidents}
						setTaggedResidents={setTaggedResidents}
						selectedResidents={selectedResidents}
					/>
					<KFileForm
						files={files}
						setFiles={setFiles}
					/>

				</div>

			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KPageWrapper>
	)
}

const Container = styled.div({
	display: 'flex',
	gap: 20,
	paddingTop: 20
})

const Content = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	gap: 20
})