import React, { useState } from 'react'
import KButton from '../KButton'
import KLoader from '../KLoader'
import KConfirmModal from '../modals/KConfirmModal'

export default function KSubWrapper({ title, children, TopBar, onSubmit, showSubmit, loading, height, hideSubmit }) {
	const [showModal, setShowModal] = useState(false)

	const handleModalSubmit = () => {
		setShowModal(true)
	}

	const handleSubmit = () => {
		onSubmit()
		setShowModal(false)
	}

	return (
		<React.Fragment>
			{TopBar && <TopBar />}
			{!TopBar &&
				<div className={title
					? `flex justify-between h-12 pr-4 items-center border-b`
					: `flex justify-end h-12 pr-4 items-center border-b`
				}>
					{title && <p className="ml-4 text-lg font-bold text-gray-600">{title}</p>}
					{!TopBar && !loading && !hideSubmit &&
						<div style={{ width: 150, display: 'flex', alignItems: 'center' }}>
							<KButton disabled={!showSubmit} className="min-w-full" label="GEM" onClick={() => handleModalSubmit()} />
						</div>
					}
				</div>
			}
			<KConfirmModal
				open={showModal}
				setOpen={setShowModal}
				title="Gem oplysninger"
				description="Er du sikker på, at du vil gemme ændringer?"
				confirmText="GEM"
				handleConfirm={handleSubmit}
			/>
			<div style={{ height: height ? height : 'calc(100% - 48px)' }} className="p-4 overflow-scroll">
				{loading &&
					<KLoader />
				}
				{!loading && children}
			</div>
		</React.Fragment>

	)
}
