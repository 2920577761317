import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import KSection from '../../components/cards/KSection'
import KInput from '../../components/inputs/KInput'
import KTextArea from '../../components/inputs/KTextArea'
import KButton from '../../components/KButton'
import KPageWrapper from '../../components/KPageWrapper'
import { UPDATE_NEWS_BY_ID } from '../../graphql/graphqlMutations'
import { GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, GET_NEWS_BY_ID, GET_ALL_NEWS_BY_ORGANIZATION_ID, GET_ALL_NEWS_BY_DEPARTMENT_ID, GET_ALL_NEWS_BY_RESIDNT_ID } from '../../graphql/graphqlQueries'
import { newsRoute } from '../../routes/mainRoutes'
import { residentStatusOptions } from '../../utils/residentUtils'
import Story from './Story'
import KModal from '../../components/modals/KModal'
import KConfirmModal from '../../components/modals/KConfirmModal'
import { useFileUtils } from '../../graphql/useFileUtils';
import { alertWarningMessages } from '../../utils/globalTypes'
import KRecipientForm from '../../components/forms/KRecipientForm'
import KImageForm from '../../components/forms/KImageForm'
import KFileForm from '../../components/forms/KFileForm'
import KSnackbar from '../../components/KSnackbar'
import { validateNewNews } from '../../utils/validators/newsValidator'

import { clearState } from '../../redux/slices/globalFilterQuerySlice'
import { newsKey } from '../../redux/reduxUtils'


export default function EditNews({ history, match }) {
	const user = useSelector(state => state.user);

	const { updateFiles, updateImages } = useFileUtils();

	const [newsId, setNewsId] = useState();
	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [images, setImages] = useState();
	const [files, setFiles] = useState();
	const [showAddModal, setShowAddModal] = useState(false)
	const [showPreview, setShowPreview] = useState(false)

	const [imagesCopy, setImagesCopy] = useState();
	const [filesCopy, setFilesCopy] = useState();

	const [residents, setResidents] = useState();
	const [selectedResidents, setSelectedResidents] = useState([]);
	const [taggedResidents, setTaggedResidents] = useState();
	const [chosenResidentIds, setChosenResidentIds] = useState();

	const [errors, setErrors] = useState();
	const [message, setMessage] = useState({ msg: "", type: "" });

	const dispatch = useDispatch();

	const [doUpdateNews] = useMutation(UPDATE_NEWS_BY_ID, {
		// refetchQueries: [
		// 	GET_ALL_NEWS_BY_ORGANIZATION_ID,
		// 	GET_ALL_NEWS_BY_DEPARTMENT_ID,
		// 	GET_ALL_NEWS_BY_RESIDNT_ID,
		// ]
	});

	const { data, loading } = useQuery(GET_NEWS_BY_ID, { variables: { id: match?.params?.id } });

	const { data: residentsData, loading: residentsLoading } = useQuery(GET_ALL_RESIDENTS_BY_ORGANIZATION_ID, {
		variables: { id: user.data.organization.id, size: 999, filters: residentStatusOptions.ACTIVE.id },
	});

	useEffect(() => {
		const news = data?.getNewsById;
		setNewsId(news?.id)
		setTitle(news?.title)
		setDescription(news?.description)
		setImages(news?.images)
		setImagesCopy(news?.images)
		setFiles(news?.files)
		setFilesCopy(news?.files)
		setChosenResidentIds(news?.newsReceivers?.map(r => r.residentId))
	}, [data])

	useEffect(() => {
		const foundResidents = residents?.filter(r => chosenResidentIds?.includes(r?.id));
		setSelectedResidents(foundResidents)
	}, [residents, chosenResidentIds])

	useEffect(() => {
		setResidents(residentsData?.getAllResidentsByOrganizationId?.results)
	}, [residentsData])


	const updateNews = async () => {
		try {
			const imgs = await updateImages(images, imagesCopy)
			const fls = await updateFiles(files, filesCopy)

			await doUpdateNews({
				variables: {
					input: {
						id: newsId,
						title: title,
						description: description,
						newsReceivers: selectedResidents?.map(r => r.id),
						images: imgs,
						files: fls
					},
				},
			})
			dispatch(clearState({ key: newsKey }));

			history.push(newsRoute)
		} catch (err) {
			console.log(err)
			setMessage(alertWarningMessages.UNKNOWN)
		}
	};


	const postNews = async () => {
		const validated = validateNewNews({ title, description, selectedResidents });
		if (!validated.success) {
			setShowAddModal(false)
			return setErrors(validated.errors)
		}

		updateNews();
	};

	const handleChange = (key, setter, value) => {
		setErrors({
			...errors,
			[key]: null
		});

		setter(value)
	}

	const TopBar = () => (
		<div className="flex items-center justify-end h-16 px-6 bg-white border-b">
			<div className="flex gap-x-4">
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton disabledText="Nyheden kan ikke vises, før alle påkrævet felter er udfyldt." disabled={!title || !description || selectedResidents?.length === 0} className="min-w-full" label="FORHÅNDVISNING" onClick={() => setShowPreview(true)} />
				</div>
				<div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
					<KButton className="min-w-full" label="GEM NYHED" onClick={() => setShowAddModal(true)} />
				</div>
			</div>

		</div>
	)

	return (
		<KPageWrapper
			title="Rediger nyhed"
			paths={[{
				title: 'NYHEDER',
				route: newsRoute
			}, {
				title: 'Opdater nyhed',
			}]}
			loading={[loading]?.some(e => e === true)}
			contentContainerStyle={{ padding: 0 }}
			TopBar={TopBar}
		>

			<div className="p-6">
				<KModal
					open={showPreview}
					setOpen={() => setShowPreview(!showPreview)}
				>
					<div className="p-10">
						<Story
							defaultNews={{
								title,
								description,
								creator: user.data,
								createdAt: new Date().valueOf(),
								images: images ? images : null,
								newsReceivers: selectedResidents?.map(r => ({ resident: r })),
								files: files ? files : null,
							}}
							isPreview={true}
						/>

					</div>
					<div>

					</div>
				</KModal>
				<KConfirmModal
					title="Gem nyhed"
					confirmText={"GEM NYHED"}
					description={"Er du sikker på du vil gemme nyheden?"}
					open={showAddModal}
					setOpen={setShowAddModal}
					handleConfirm={() => postNews()}
				/>
				<div style={{ height: 'calc(100vh - 281px)' }} className="grid grid-cols-1 gap-x-4">
					<div className="grid grid-cols-1 pb-6 cols-span-2 gap-y-6">
						<KSection
							title="Oplysninger"
						>
							<div className="grid grid-cols-1 gap-y-3">
								<KInput
									error={errors?.title}
									value={title}
									label="Titel"
									required
									onChange={(value) => handleChange('title', setTitle, value)}
								/>
								<KTextArea
									error={errors?.description}
									value={description}
									label="Beskrivelse"
									required
									onChange={(value) => handleChange('description', setDescription, value)}
									multiline
									rows={5}
								/>
							</div>
						</KSection>
						<KRecipientForm
							selectedResidents={selectedResidents}
							setSelectedResidents={setSelectedResidents}
							errors={errors}
							setErrors={setErrors}
						/>
						<KImageForm
							images={images}
							setImages={setImages}
							taggedResidents={taggedResidents}
							setTaggedResidents={setTaggedResidents}
							selectedResidents={selectedResidents}
						/>
						<KFileForm
							files={files}
							setFiles={setFiles}
						/>
					</div>
				</div>
			</div>
			<KSnackbar
				open={message.msg}
				setOpen={(v) => { setMessage({ ...message, msg: v }) }}
				title={message.msg}
				duration={6000}
				verticalPosition="bottom"
				horizontalPosition="center"
				type={message.type}
			/>
		</KPageWrapper>
	)
}
