import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import KInput from '../../../components/inputs/KInput';
import KPhoneInput from '../../../components/inputs/KPhoneInput';
import KButton from '../../../components/KButton';
import KSelect from '../../../components/selectors/KSelect';
import { relationArray } from '../../../utils/relationUtil';
import { authenticationOptions, relativeClassifications, relativeInvitationTypes } from '../../../utils/residentUtils';
import _ from 'lodash';
import KSelectBox from '../../../components/selectors/KSelectBox';
import { softValidateNewRelative } from '../../../utils/validators/relativeValidators';
import { validate } from '../../../utils/validators/index';

export default function EditRelative({ relatives, setRelatives, activeRelativeIndex, setShowEditRelativeModal }) {
	const user = useSelector(state => state.user);

	const [activeRelative, setActiveRelative] = useState();

	const [errors, setErrors] = useState();

	const updateRelative = () => {
		if (!validate(
			softValidateNewRelative,
			{ relative: activeRelative, relatives, activeIndex: activeRelativeIndex },
			setErrors
		)) {
			return;
		}

		const newRelatives = relatives.map((relative, i) => {
			if (i === activeRelativeIndex) {
				return activeRelative
			} else {
				return relative
			}
		})

		setRelatives(newRelatives)
		setShowEditRelativeModal(false)
	}

	const handleRelativeChange = (key, value) => {
		const test = {
			...activeRelative,
			[key]: value
		}

		setActiveRelative(test)
	}

	useEffect(() => {
		const activeRelative = relatives?.find((e, i) => i === activeRelativeIndex)
		setActiveRelative(activeRelative)
	}, [activeRelativeIndex, relatives])

	console.log(activeRelative)

	return (
		<div className="w-full">
			<div>
				<KSelect
					error={errors?.role}
					label="Klassifikation"
					required
					elements={relativeClassifications}
					handleSelect={(value) => handleRelativeChange('role', value)}
					selected={relativeClassifications?.find(e => e.id == activeRelative?.role?.id)}
				/>
				<div className="grid grid-cols-2 mt-3 gap-y-3 gap-x-3">
					<KSelect
						label="Login metode"
						required
						elements={authenticationOptions}
						handleSelect={(value) => handleRelativeChange('loginMethod', value)}
						selected={authenticationOptions?.find(e => e.id === activeRelative?.loginMethod?.id)}
					/>
					<KSelect
						label="Relation"
						selected={relationArray.find(e => e === activeRelative?.relation)}
						elements={relationArray}
						handleSelect={(value) => handleRelativeChange('relation', value)}
					/>
					<KInput
						value={activeRelative?.firstName}
						required
						fullWidth={true}
						label="Fornavn"
						onChange={(value) => handleRelativeChange('firstName', value)}
						error={errors?.firstName}
					/>

					<KInput
						value={activeRelative?.lastName}
						required
						fullWidth={true}
						label="Efternavn"
						onChange={(value) => handleRelativeChange('lastName', value)}
						error={errors?.lastName}
					/>
					<KInput
						value={activeRelative?.email}
						fullWidth={true}
						label="Email"
						onChange={(value) => handleRelativeChange('email', value)}
						error={errors?.email}
					/>
					<KPhoneInput
						showInfoText={activeRelative?.loginMethod?.id === authenticationOptions[1].id}
						mobile={activeRelative?.mobile}
						error={errors?.mobile}
						label="Mobilnummer"
						onChange={(value) => handleRelativeChange('mobile', value)}

					/>
				</div>

				<KSelectBox
					error={errors?.invitation}
					handleSelect={(e) => handleRelativeChange('sendInvitation', e.id === relativeInvitationTypes[0].id ? true : false)}
					className="sm:grid-cols-2"
					list={relativeInvitationTypes}
					selected={activeRelative?.sendInvitation ? relativeInvitationTypes[0] : relativeInvitationTypes[1]}
				/>

				<div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
					<KButton onClick={() => updateRelative()} label="Gem pårørende" />
				</div>
			</div>

		</div>
	)
}
