import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import KButton from '../../components/KButton'
import { GET_ALL_CALENDAR_ACTIVITIES_IN_DRAFT_BY_CREATOR_ID } from '../../graphql/graphqlQueries';
import moment from 'moment';
import _ from 'lodash';
import { loginKmd } from '../../utils/KmdUtils';
import { useKMD } from '../../config.js'
import KTable from '../../components/KTable';
import { TableCell, TableRow } from '@mui/material';
import { UPDATE_CALENDAR_ACTIVITY } from '../../graphql/graphqlMutations';

export default function CalendarPlanning({ history }) {
	const user = useSelector(state => state.user)

	const [activities, setActivities] = useState();

	const printRef = useRef();

	const [doUpdateActivity, { loading: submitLoading }] = useMutation(UPDATE_CALENDAR_ACTIVITY)

	const { loading, data, refetch } = useQuery(GET_ALL_CALENDAR_ACTIVITIES_IN_DRAFT_BY_CREATOR_ID, {
		variables: {
			id: user?.data?.id,
			order: ["startDate", "asc"],
		},
		fetchPolicy: 'no-cache'
	})

	useEffect(() => {
		if (data) {
			const events = data[Object.keys(data)[0]].results;

			const formatted = events?.map(event => ({
				...event,
				start: moment(parseInt(event?.startDate)).toDate(),
				end: moment(parseInt(event?.endDate)).toDate(),
			}));

			setActivities(formatted)
		}
	}, [data])

	useEffect(() => {
		if (!_.isEmpty(user.KMDAuth)) {
			const expireAt = moment(user.KMDAuth.createdAt).add(user.KMDAuth.expires, 'seconds')
			if (expireAt.isBefore(moment())) {
				handleKMDLogin();
			}
		} else {
			handleKMDLogin();
		}
	}, []);

	const handleKMDLogin = () => {
		useKMD && loginKmd(user.data.organization.municipality)
	}

	const handleRelease = (e, activity) => {
		e.stopPropagation();

		const variables = {
			input: {
				id: activity?.id,
				creatorId: user?.data?.id,
				organizationId: user?.data?.organization?.id,
				isDraft: false
			}
		}

		doUpdateActivity({
			variables
		}).then(res => {
			refetch()
		}).catch(err => {
			console.log(JSON.stringify(err, 2, null))
		})
	}

	const OverviewItem = ({ row }) => {
		return (
			<TableRow style={{ cursor: 'pointer' }} onClick={() => history.push('/aktivitet/rediger/' + row?.id)}>
				<TableCell width={'15%'}>
					<p>{row?.title}</p>
				</TableCell>
				<TableCell width={'15%'}>
					<p>{moment(parseInt(row?.startDate))?.format('DD-MM-YYYY HH:mm')}</p>
				</TableCell>
				<TableCell width={'15%'}>
					<p>{moment(parseInt(row?.endDate))?.format('DD-MM-YYYY HH:mm')}</p>
				</TableCell>
				<TableCell width={'15%'}>
					<p>{row?.actionRequired ? 'Ja' : 'Nej'}</p>
				</TableCell>
				<TableCell width={'15%'}>
					<p>Kladde</p>
				</TableCell>
				<TableCell width={'10%'}>
					<KButton onClick={(e) => handleRelease(e, row)} label="UDGIV" />
				</TableCell>
			</TableRow>
		)
	}


	return (
		<div>
			<div style={{ marginBottom: 20 }}>
				<p>Her kan du se, planlægge og udgive aktiviteter, som har status som kladde.</p>
			</div>
			<div style={{ width: '100%' }} ref={printRef}>
				<KTable
					style={{ width: '100%' }}
					headers={['Titel', 'Start', 'Slut', 'Kræver svar', 'Status', 'Udgiv']}
					data={activities}
					RenderItem={OverviewItem}
				/>
				{!activities || activities?.length === 0 &&
					<div style={{ marginTop: 10 }}>
						<p>Ingen kladder fundet.</p>
					</div>
				}
			</div>
		</div>
	)
}
