import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react'
import { useState } from 'react';
import styled from 'styled-components';
import KButton from '../../components/KButton';
import KPageWrapper from '../../components/KPageWrapper';
import { GET_ASSIGNMENT_BY_ID } from '../../graphql/graphqlQueries';
import { convertToNewsDate } from '../../utils/dateUtils';
import Colors from '../../styles/Colors';
import { getResidentFullName } from '../../utils/residentUtils';
import { assignmentConsentStatus, getAssigmentStatus, responsible } from '../../utils/assignmentUtils';
import Button from '@mui/material/Button';
import { canEditAndDelete, isLeader } from '../../utils/roles';
import { useSelector } from 'react-redux';
import { CHANGE_TODO_STATUS, DELETE_TODO } from '../../graphql/graphqlMutations';
import { assignmentsRoute, editAssignmentRoute } from '../../routes/mainRoutes';
import KModal from '../../components/KModal';
import { borderRadius } from '@mui/system';
import { useHistory } from 'react-router-dom';
import KFileViewer from '../../components/KFileViewer';
import { checkIfUrlInText } from '../../utils/validators';

export default function Assignment({ match }) {
	const history = useHistory()
	const user = useSelector(state => state.user);

	const [assignment, setAssignment] = useState();
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const { data, loading, refetch } = useQuery(GET_ASSIGNMENT_BY_ID, { variables: { id: match?.params?.id } });

	const [changeTodo] = useMutation(CHANGE_TODO_STATUS);
	const [deleteTodo] = useMutation(DELETE_TODO);

	const updateTodo = (variables) => {
		changeTodo({ variables }).then(() => {
			refetch();
		}).catch(err => {
			console.log(err)
		})
	}

	const updateAccepted = (status) => {
		updateTodo({
			updateTodoStatusForTodoInput: {
				todoId: String(assignment.id),
				accepted: status,
				acceptedBy: status ? parseInt(user.data.id) : null,
			},
		})
	}

	const updateSolved = (solved) => {
		updateTodo({
			updateTodoStatusForTodoInput: {
				todoId: String(assignment.id),
				solved: solved,
				solvedBy: solved ? parseInt(user.data.id) : null,
			},
		})
	}

	const deleteAssignment = () => {
		deleteTodo({
			variables: {
				deleteTodoByIdId: assignment?.id,
			},
		})
			.then(() => {
				history.goBack()
			})
			.catch((err) => console.log(JSON.stringify(err)));
	};

	useEffect(() => {
		setAssignment(data?.getTodoById);
	}, [data]);

	return (
		<KPageWrapper
			paths={[{
				title: 'Opgaver',
				route: assignmentsRoute
			}, {
				title: 'Opgave'
			}]}
			buttons={[
				canEditAndDelete(user, assignment?.creator) &&
				<KButton
					label="REDIGER OPGAVE"
					onClick={() => history.push(`/opgave/rediger/${assignment.id}`)}
				/>,
				canEditAndDelete(user, assignment?.creator) &&
				<KButton
					label="SLET OPGAVE"
					onClick={() => setShowDeleteModal(true)}
				/>
			]}
			loading={loading}
		>
			<Container>
				<KModal
					open={showDeleteModal}
					title="Slet opgave"
					subTitle="Er du sikker på at du vil slette opgaven?"
					handleChange={() => setShowDeleteModal(false)}
				>
					<ModalContainer>
						<ModalButton fullWidth onClick={() => deleteAssignment()}>Slet</ModalButton>
						<ModalButton fullWidth onClick={() => setShowDeleteModal(false)}>Annuller</ModalButton>
					</ModalContainer>
				</KModal>
				<Content>
					<DateWrapper>{convertToNewsDate(assignment?.createdAt)}</DateWrapper>
					<TitleWrapper>
						{assignment?.title}
					</TitleWrapper>
					{assignment?.description &&
						<DescriptionWrapper>
							{checkIfUrlInText(assignment?.description)?.map(e => {
								if (e?.type === 'link') {
									return <a target="_blank" style={{ textDecoration: 'auto', color: Colors.darkPurple }} href={e?.text}>{e?.text}</a>
								}
								return (
									<span>{e?.text}</span>
								)
							})}
						</DescriptionWrapper>
					}
					<InfoWrapper>
						<Content>
							<InfoText>OPRETTET AF: <ContentText>{getResidentFullName(assignment?.creator)}</ContentText></InfoText>
						</Content>
						<Content>
							<InfoText>OMHANDLER: <ContentText>{getResidentFullName(assignment?.resident)}</ContentText></InfoText>
						</Content>
					</InfoWrapper>
					<InfoWrapper>
						<Content>
							<InfoText>SAMTYKKE STATUS: <ContentText>{assignmentConsentStatus[assignment?.status.consent]?.name}</ContentText></InfoText>
						</Content>
						<Content>
							<InfoText>ANSVARLIG FOR UDFØRELSE: <ContentText>{responsible[assignment?.responsible]?.name}</ContentText></InfoText>
						</Content>
					</InfoWrapper>
					<InfoWrapper>
						<Content>
							<InfoText>OPGAVE STATUS: <ContentText>{getAssigmentStatus(assignment)}</ContentText></InfoText>
						</Content>
						<Content>
							<InfoText>FRIST: <ContentText>{assignment?.deadline}</ContentText></InfoText>
						</Content>
					</InfoWrapper>
					{assignment?.responsible === responsible.WORKER.value
						&& (assignment?.status?.consent === assignmentConsentStatus.GIVEN.value || assignment?.status?.consent === assignmentConsentStatus.NOT_REQUIRED.value)
						&& assignment?.status.accepted && !assignment?.status.solved
						&& (assignment?.status?.acceptedBy?.id === user?.data?.id || isLeader(user)) &&

						<InfoWrapper>
							<Content>
								<GreenButton fullWidth onClick={() => updateSolved(true)} variant="contained">Løs opgave</GreenButton>
							</Content>
							<Content>
								<RedButton fullWidth onClick={() => updateAccepted(false)} variant="contained">Fortryd opgave</RedButton>
							</Content>
						</InfoWrapper>
					}

					{assignment?.responsible === responsible.WORKER.value
						&& (assignment?.status?.consent === assignmentConsentStatus.GIVEN.value || assignment?.status?.consent === assignmentConsentStatus.NOT_REQUIRED.value)
						&& !assignment?.status.accepted &&
						<InfoWrapper>
							<GreenButton fullWidth onClick={() => updateAccepted(true)} variant="contained">Accepter opgave</GreenButton>
						</InfoWrapper>
					}

					{assignment?.responsible === responsible.WORKER.value
						&& assignment?.status.solved &&
						<InfoWrapper>
							<Button fullWidth onClick={() => updateSolved(false)} variant="contained">Genåben opgave</Button>
						</InfoWrapper>
					}
				</Content>
				<ImageContent>
					<KFileViewer
						file={assignment?.imageUrl}
					/>
					{/* {assignment?.imageUrl && <Image alt="opgave" src={assignment?.imageUrl} />} */}
				</ImageContent>
			</Container>
		</KPageWrapper>
	)
}

const DateWrapper = styled.p({
	fontSize: 12,
	fontFamily: 'MetricSemiBold',
	color: Colors.lightBlack
})

const TitleWrapper = styled.p({
	fontSize: 24,
	fontFamily: 'MetricBold',
	color: Colors.mainGreen
});

const DescriptionWrapper = styled.p({
	fontSize: 14,
	fontFamily: 'MetricRegular',
	whiteSpace: 'pre-wrap'
});

const InfoWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	marginTop: 30,
	gap: 20,
	paddingRight: 20
})

const InfoText = styled.p({
	fontFamily: 'MetricBold',
	fontSize: 12,
	color: Colors.lightBlack
});

const ContentText = styled.p({
	fontFamily: 'MetricRegular',
	fontSize: 20,
	color: Colors.lightBlack
})

const Content = styled.div({
	flex: 1,
})

const ImageContent = styled.div({
	flex: 1,
	maxHeight: 'calc(100vh - 220px)',
	display: 'flex',
	justifyContent: 'flex-end'
})

const Image = styled.img({
	maxWidth: '100%',
	maxHeight: '100%',
	objectFit: 'contain'
})

const Container = styled.div({
	// backgroundColor: '#fff',
	// height: '100%'
	display: 'flex'
})

const GreenButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundImage: `linear-gradient(0deg, ${Colors.greenB[1]}, ${Colors.greenB[0]} 50%)`
	}
}))

const RedButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundImage: `linear-gradient(0deg, ${Colors.redB[1]}, ${Colors.redB[0]} 50%)`
	}
}))

const ModalContainer = styled.div({
	display: 'flex',
	paddingTop: 20,
	justifyContent: 'space-between',
	gap: 20
})

const ModalButton = styled(Button)(() => ({
	'&.MuiButton-root': {
		backgroundColor: 'transparent',
		border: `2px solid ${Colors.lightGrey}`,
		color: Colors.mainGreen,
		height: 30,
		fontFamily: 'MetricRegular',
		borderRadius: 0,

		'&:hover': {
			border: `2px solid ${Colors.mainGreen}`
		}
	}
}))