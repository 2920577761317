import { createSlice } from "@reduxjs/toolkit";
import { globalFilterInitialState } from "../../utils/globalFilterUtils";

export const globalFilterSlice = createSlice({
	name: "globalFilter",
	initialState: { ...globalFilterInitialState },
	reducers: {
		setGlobalFilter: (state, action) => {
			state.residentFilter = action.payload.residentFilter;
			state.departmentFilter = action.payload.departmentFilter;
		},
		setDepartmentFilter: (state, action) => {
			state.residentFilter = action.payload;
		},
		setResidentFilter: (state, action) => {
			state.residentFilter = action.payload;
		},
		clearGlobalFilter: (state) => {
			state.departmentFilter = {
				...globalFilterInitialState.departmentFilter,
			};
			state.residentFilter = {
				...globalFilterInitialState.residentFilter,
			};
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setGlobalFilter,
	clearGlobalFilter,
	setResidentFilter,
	setDepartmentFilter,
} = globalFilterSlice.actions;

export default globalFilterSlice.reducer;
