import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import KTitle from './KTitle';
import styled from 'styled-components';
import Close from '@mui/icons-material/Close';


export default function KModal({ open, handleChange, title, subTitle, children, containerStyles, closeIcon, width }) {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: width ? width : 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		borderRadius: "0.25rem",
	};

	return (
		<div>
			<Modal
				open={open ? open : false}
				onClose={() => handleChange(false)}
			>
				<Box sx={{ ...style, ...containerStyles }}>
					{closeIcon &&
						<Close style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} onClick={() => handleChange(false)} />
					}
					{title && <KTitle title={title} />}
					{subTitle && <Subtitle style={{ fontFamily: 'MetricRegular' }}>{subTitle}</Subtitle>}
					{children}
				</Box>
			</Modal>
		</div>
	);
}

const Subtitle = styled.p({
	fontFamily: 'MetricRegular'
})