import React from 'react'

export default function KCheckbox({ ref, checked, setChecked }) {
	const handleClick = (e) => {
		e.stopPropagation()
		setChecked(e.target.checked)
	}
	return (
		<div className="flex items-center">
			<input
				onChange={(e) => handleClick(e)}
				type="checkbox"
				className="w-4 h-4 border-gray-300 rounded text-darkPurple focus:ring-darkPurple"
				checked={checked}
				ref={ref}
			/>
		</div>
	)
}
