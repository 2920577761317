import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client';
import _ from 'lodash'

import { useParams } from "react-router-dom";
import { GET_RESIDENT_BY_ID } from "../../graphql/graphqlQueries";
import { residentsRoute } from '../../routes/mainRoutes';
import { useSelector } from "react-redux";

import KPageWrapper from '../../components/KPageWrapper'
import KInnerSidebar from '../../components/navbars/KInnerSidebar';

import Profile from './profileSubmenus/Profile';
import ConsentSub from './profileSubmenus/settings/Consent';
import Relatives from './profileSubmenus/relatives/Relatives';
import Lifestory from './profileSubmenus/Lifestory';
import Onboarding from './profileSubmenus/Onboarding';
import Documents from './profileSubmenus/documents/Documents'

import { getResidentWarnings, residentNavigation, residentStatusOptions } from '../../utils/residentUtils';
import { getMobileFormat } from '../../utils/KmdUtils';
import DeleteResident from './DeleteResident';

export default function ResidentInformation2({ history, location }) {
	const user = useSelector(state => state.user);

	const { residentId } = useParams();
	const [resident, setResident] = useState({});
	const [warnings, setWarnings] = useState();

	const [activeMenu, setActiveMenu] = useState(residentNavigation[0])
	const [loaded, setLoaded] = useState(false)

	const { data, loading: residentLoading } = useQuery(GET_RESIDENT_BY_ID, {
		variables: { id: residentId },
	});

	useEffect(() => {
		if (data?.getResidentById) {
			const resident = data?.getResidentById;
			setResident({
				...resident,
				mobile: resident?.mobile ? getMobileFormat(resident?.mobile) : null,
				phone: resident?.phone ? getMobileFormat(resident?.phone) : null,
			})

			setWarnings(getResidentWarnings(data?.getResidentById))
		}
	}, [data])

	useEffect(() => {
		if (resident && !_.isEmpty(resident) && !loaded) {
			if (resident?.status === residentStatusOptions.ACTIVE.id) {
				if (location?.state?.relatives) {
					return setActiveMenu(residentNavigation[2])
				}

				setActiveMenu(residentNavigation[1])
			} else {
				setActiveMenu(residentNavigation[0])
			}
			setLoaded(true)
		}
	}, [resident])

	function getComponent() {
		switch (activeMenu.id) {
			case 0:
				return <Onboarding setActiveMenu={setActiveMenu} warnings={warnings} resident={resident} user={user} />
			case 1:
				return <Profile warnings={warnings} residentParent={resident} user={user} />
			case 2:
				return <Relatives warnings={warnings} resident={resident} user={user} />
			case 3:
				return <Lifestory resident={resident} user={user} />
			case 4:
				return <ConsentSub resident={resident} user={user} />
			case 7:
				return <DeleteResident resident={resident} user={user} history={history} />
			case 6:
				return <Documents resident={resident} user={user} />
			default:
				return <div>Vi arbejder stadig på denne side..</div>
		}
	}

	return (
		<KPageWrapper
			contentContainerStyle={containerStyles}
			paths={[{
				title: "Borgeroversigt",
				route: residentsRoute
			}, {
				title: `${resident?.fullName ? resident?.fullName : ''}`
			}]}
			loading={residentLoading}
		>
			<div className="flex h-full">
				<div style={{ minWidth: 250 }} className="h-full">
					<KInnerSidebar
						warnings={warnings}
						onChange={setActiveMenu}
						activeElement={activeMenu}
						elements={residentNavigation}
					/>
				</div>
				<div className="flex-1 flex-grow">
					{getComponent()}
				</div>
			</div>
		</KPageWrapper>
	)
}

const containerStyles = {
	paddingTop: '0px',
	paddingLeft: '0px',
	paddingBottom: '0px',
	paddingRight: '0px',
	overflow: 'hidden'
}
