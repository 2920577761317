import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../utils/tailwindUtils'

export default function KAccordion({ Title, children, titleClassName }) {
	return (
		<div className="w-full border-t">
			<div className="w-full h-full p-2 ">
				<Disclosure>
					{({ open }) => (
						<>
							<Disclosure.Button className="flex items-center justify-between w-full h-full px-2 py-1 rounded-lg group focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
								{/* <span className={classNames(
									titleClassName ? titleClassName : "font-bold text-left text-gray-600 "
								)}>{title}</span> */}
								<Title />


								<ChevronUpIcon
									className={classNames(
										open ? 'rotate-180 transform' : '',
										"h-4 w-4 stroke-2 "
									)}
								/>
							</Disclosure.Button>
							<div className="">
								<Disclosure.Panel>
									{children}
								</Disclosure.Panel>

							</div>
						</>
					)}
				</Disclosure>
			</div>
		</div>
	)
}