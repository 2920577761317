import _ from "lodash";

export const validateNewTodo = (input) => {
	let errors = {};

	if (_.isEmpty(input?.title)) {
		errors.title = 'Feltet kan ikke være tomt.';
	} else {
		errors.title = null;
	}

	if (_.isEmpty(input?.description)) {
		errors.description = 'Feltet kan ikke være tomt.';
	} else {
		errors.description = null;
	}

	if (input.selectedResidents.length <= 0) {
		errors.selectedResidents = 'Minimum 1 modtager er påkrævet.'
	} else {
		errors.selectedResidents = null;
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}