import _ from "lodash";
import moment from "moment";
import { repeatOptions } from "../calendarUtils";

function isValidDate(d) {
	if (d === "Invalid date") return false;
	return true
}

export const validateNewActivity = (input) => {
	let errors = {};

	if (_.isEmpty(input?.title)) {
		errors.title = 'Feltet kan ikke være tomt.';
	} else {
		errors.title = null;
	}

	if (_.isEmpty(input?.description)) {
		errors.description = 'Feltet kan ikke være tomt.';
	} else {
		errors.description = null;
	}

	if (_.isEmpty(input.startDate) || !isValidDate(input.startDate)) {
		errors.startDate = 'Feltet kan ikke være tomt'
	} else {
		errors.startDate = null;
	}

	if (_.isEmpty(input.endDate) || !isValidDate(input.endDate)) {
		errors.endDate = 'Feltet kan ikke være tomt'
	} else {
		errors.endDate = null;
	}

	if (input.repeat?.value !== repeatOptions.NEVER.value && (_.isEmpty(input.repeatDate) || !isValidDate(input.repeatDate))) {
		errors.repeatDate = 'Feltet kan ikke være tomt'
	} else {
		errors.repeatDate = null;
	}


	if (!errors?.startDate && !errors?.endDate) {
		const start = moment(input.startDate, 'DD-MM-YYYY HH:mm')
		const end = moment(input.endDate, 'DD-MM-YYYY HH:mm')

		if (end.isSame(start)) {
			errors.endDate = "Slut dato kan ikke være samme som start dato.";
		} else if (end.isBefore(start)) {
			errors.endDate = "Slut dato kan ikke være før start dato.";

		} else {
			errors.endDate = null;
		}
	}

	if (input.selectedResidents.length <= 0) {
		errors.selectedResidents = 'Minimum 1 modtager er påkrævet.'
	} else {
		errors.selectedResidents = null;
	}

	return {
		errors: errors,
		success: Object.values(errors).every(x => x === null || x === '')
	}
}