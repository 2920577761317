import { getResidentFullName } from "./residentUtils";

export const assignmentConsentStatus = {
	GIVEN: { name: "Samtykke givet", value: "GIVEN" },
	REFUSED: { name: "Samtykke afvist", value: "REFUSED" },
	NOT_REQUIRED: { name: "Kræver ikke samtykke", value: "NOT_REQUIRED" },
	UNDEFINED: { name: "udefineret", value: "UNDEFINED" },
	REQUIRED: { name: "Afventer samtykke", value: "REQUIRED" },
};

export const assigmentConsentTypes = {
	NOT_REQUIRED: { name: 'Ikke påkrævet', value: 'NOT_REQUIRED' },
	REQUIRED: { name: 'Påkrævet', value: 'NOT_REQUIRED' }
}

export const assignmentStatus = {
	OPEN: { name: "Åbne", value: "OPEN", checked: false, displayName: 'Åben' },
	AWAITING: { name: 'Afventer samtykke', value: 'AWAITING', checked: false, displayName: 'Afventer samtykke' },
	ACCEPTED: { name: 'Accepterede', value: "ACCEPTED", checked: false, displayName: 'Accepteret' },
	REFUSED: { name: "Afviste", value: "REFUSED", checked: false, displayName: 'Afvist' },
	SOLVED: { name: 'Løste', value: "SOLVED", checked: false, displayName: 'Løst' },
	ALL: { name: "Alle", value: "ALL", checked: true },
};

export const assignmentResponsibleStatus = {
	ALL: { name: "Alle", value: "ALL", checked: true },
	RELATIVE: { name: "Pårørende", value: 'RELATIVE', checked: false },
	WORKER: { name: "Personale", value: 'WORKER', checked: false },
};

export const assignmentSortStatus = {
	DEADLINE: { name: "Snarest Frist", checked: false },
	CREATED_AT: { name: "Senest oprettet", checked: true },
};

export const getAssigmentStatus = (assignment) => {
	if (assignment) {
		const { status } = assignment;
		const { consent, accepted, solved } = assignment.status;

		if (solved) {
			return `${assignmentStatus.SOLVED.displayName} af ${getResidentFullName(status?.solvedBy)}`
		} else if (consent === assignmentConsentStatus.GIVEN.value && !accepted) {
			return assignmentStatus.OPEN.displayName
		} else if (consent === assignmentConsentStatus.GIVEN.value && accepted) {
			return `${assignmentStatus.ACCEPTED.displayName} af ${getResidentFullName(status?.acceptedBy)}`
		} else if (consent === assignmentConsentStatus.REQUIRED.value) {
			return assignmentStatus.AWAITING.displayName
		} else if (consent === assignmentConsentStatus.REFUSED.value) {
			return assignmentStatus.SOLVED.displayName
		} else if (consent === assignmentConsentStatus.NOT_REQUIRED.value && accepted) {
			return `${assignmentStatus.ACCEPTED.displayName} af ${getResidentFullName(status?.acceptedBy)}`
		} else {
			return assignmentStatus.OPEN.displayName
		}
	}
}

export const getAssigmentParticipantStatus = (status) => {
	if (status) {
		const { consent, accepted, solved } = status;

		if (solved) {
			return `${assignmentStatus.SOLVED.displayName} af ${getResidentFullName(status?.solvedBy)}`
		} else if (consent === assignmentConsentStatus.GIVEN.value && !accepted) {
			return assignmentStatus.OPEN.displayName
		} else if (consent === assignmentConsentStatus.GIVEN.value && accepted) {
			return `${assignmentStatus.ACCEPTED.displayName} af ${getResidentFullName(status?.acceptedBy)}`
		} else if (consent === assignmentConsentStatus.REQUIRED.value) {
			return assignmentStatus.AWAITING.displayName
		} else if (consent === assignmentConsentStatus.REFUSED.value) {
			return assignmentStatus.SOLVED.displayName
		} else if (consent === assignmentConsentStatus.NOT_REQUIRED.value && accepted) {
			return `${assignmentStatus.ACCEPTED.displayName} af ${getResidentFullName(status?.acceptedBy)}`
		} else {
			return assignmentStatus.OPEN.displayName
		}
	}
}

export const getSimpleAssignmentStatus = (assignment) => {
	if (assignment) {
		return null;
		const { status } = assignment;
		const { consent, accepted, solved } = assignment?.status;

		if (solved) {
			return `${assignmentStatus.SOLVED.displayName}`
		} else if (consent === assignmentConsentStatus.GIVEN.value && !accepted) {
			return assignmentStatus.OPEN.displayName
		} else if (consent === assignmentConsentStatus.GIVEN.value && accepted) {
			return `${assignmentStatus.ACCEPTED.displayName}`
		} else if (consent === assignmentConsentStatus.REQUIRED.value) {
			return assignmentStatus.AWAITING.displayName
		} else if (consent === assignmentConsentStatus.REFUSED.value) {
			return assignmentStatus.SOLVED.displayName
		} else if (consent === assignmentConsentStatus.NOT_REQUIRED.value && accepted) {
			return `${assignmentStatus.ACCEPTED.displayName}`
		} else {
			return assignmentStatus.OPEN.displayName
		}
	}
}

export const responsible = {
	RELATIVE: { name: "Pårørende", value: "RELATIVE" },
	WORKER: { name: "Personale", value: "WORKER" },
};

export const consentOptions = {
	YES: { name: 'Ja', value: 'REQUIRED' },
	NO: { name: 'Nej', value: 'NOT_REQUIRED' }
}

export const consentOptionsList = [consentOptions.YES, consentOptions.NO]

export const responsibleList = [responsible.RELATIVE, responsible.WORKER];

export const todoFilterOptions = {
	ALL: { name: 'Alle' },
	SOLVED: { name: 'Løst' },
	OPEN: { name: 'Åben' },
	ACCEPTED: { name: 'Accepteret' },
	AWAITING_CONSENT: { name: 'Afventer samtykke' },
	CONSENT_REJECTED: { name: 'Afvist samtykke' },
}

export const todoFilterOptionsList = [
	todoFilterOptions.ALL,
	todoFilterOptions.SOLVED,
	todoFilterOptions.OPEN,
	todoFilterOptions.ACCEPTED,
	todoFilterOptions.AWAITING_CONSENT,
	todoFilterOptions.CONSENT_REJECTED,
]