import React from 'react'
import { alertWarningTypes } from '../../utils/globalTypes'
import { classNames } from '../../utils/tailwindUtils'
import KAlert from '../alerts/KAlert'
import KLoader from '../KLoader'

export default function KSection({ title, description, children, warning, info, Action, loading, Content, hideTopbar, hideWarningHeader, rightText }) {
	const AlertItem = () => (
		<div>
			{!hideWarningHeader && <h3 className="text-sm font-medium text-yellow-800">Handling påkrævet</h3>}
			<div className={classNames(
				!hideWarningHeader ? 'mt-2' : '',
				"text-sm text-yellow-700"
			)}>
				<p>
					{warning}
				</p>
			</div >
		</div >
	)

	const InfoItem = () => (
		<div>
			<h3 className="text-sm font-medium text-blue-800">{info}</h3>
		</div>
	)

	return (
		<div>
			<div className="bg-white shadow sm:rounded-lg">
				{!hideTopbar &&
					<div className="relative px-4 py-5 sm:px-6">
						<div className="flex justify-between">
							<h2 id="applicant-information-title" className="text-xl font-semibold leading-6 text-gray-700">
								{title}
							</h2>
							{rightText &&
								<p className="text-gray-500">{rightText}</p>
							}
						</div>
						<p className="max-w-2xl mt-1 text-base text-gray-500">{description}</p>
						{warning &&
							<div className="mt-2">
								<KAlert RenderItem={AlertItem} />
							</div>
						}
						{info &&
							<div className="mt-2">
								<KAlert type={alertWarningTypes.INFO} RenderItem={InfoItem} />
							</div>
						}
						{Action &&
							<div className="absolute right-5 top-5">
								<Action />
							</div>
						}
					</div>
				}
				<div className="px-4 py-5 border-t border-gray-200 sm:px-6">
					<dl className="grid grid-cols-1 gap-x-4 gap-y-8">
						{loading &&
							<KLoader />
						}
						<div className={classNames(
							loading ? 'invisible' : 'visible',
						)}>
							{children}
							{Content && <Content />}
						</div>
					</dl>
				</div>
				<div className="block h-6 px-4 py-4 text-sm font-medium text-center text-gray-500 bg-gray-50 hover:text-gray-700 sm:rounded-b-lg">
				</div>
			</div>
		</div>
	)
}
