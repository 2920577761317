import { ExclamationCircleIcon, } from '@heroicons/react/24/solid'
import React from 'react'
import { classNames } from '../../utils/tailwindUtils'

export default function KInput({ label, Icon, value, onChange, error, type, placeholder, required, warning, disabled, className }) {
	return (
		<div>
			<div className="flex justify-between">
				<label htmlFor={type} className="block text-sm font-medium text-gray-700">
					{label}

				</label>
				<span className="text-sm text-gray-500" id="email-optional">
					{required ? 'Påkrævet' : ''}
				</span>
			</div>
			<div className={classNames(
				"relative h-10 mt-1 rounded-md shadow-sm"
			)}>
				<input
					type={type ? type : 'text'}
					onChange={(e) => onChange(e.target.value)}
					value={value}
					className={classNames(
						className,
						error ? 'border-red-300' : 'border-gray-300',
						warning ? 'border-yellow-400' : 'border-gray-300',
						'block h-full w-full pt-2 pb-2 pl-2 bg-gray-100 rounded-md focus:ring-darkPurple focus:border-darkPurple sm:text-sm border',
					)}
					placeholder={placeholder}
					aria-invalid="true"
					aria-describedby={`${type}-error"`}
					readOnly={disabled}
				/>
				{!error && !warning && Icon &&
					<div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
						<Icon />
					</div>
				}
				{error &&
					<div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
						<ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
					</div>
				}
				{warning &&
					<div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
						<ExclamationCircleIcon className="w-5 h-5 text-yellow-500" aria-hidden="true" />
					</div>
				}
			</div>
			{error &&
				<p className="mt-1 ml-1 text-sm text-red-600" id="email-error">
					{error}
				</p>
			}
			{warning &&
				<p className="mt-1 ml-1 text-sm text-yellow-600" id="email-error">
					{warning}
				</p>
			}
		</div>
	)
}
