import { ChevronRightIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { classNames } from '../../../utils/tailwindUtils';
import KDropdown from './KDropdown'

export default function KDocumentDropdown({ element, elements, handleDelete, handleEdit, handleUpdate, handleReset, hideMoveTo }) {
	const [moveToDropDownOpen, setMoveToDropDownOpen] = useState(false);

	const Item = ({ className, show, title, children, onClick }) => (
		<div
			className={classNames(
				className,
				"pl-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
			)}
			onMouseOver={() => setMoveToDropDownOpen(show)}
			onClick={onClick ? onClick : () => { }}
		>
			<div className="flex items-center justify-between">
				{title}
				{children}
			</div>
		</div>
	)

	return (
		<KDropdown
			Action={({ onClick }) => (
				<button class="inline-flex items-center p-2 -mt-5  text-sm font-medium text-center text-gray-400  rounded-lg" onClick={(e) => onClick(e)} type="button">
					<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg>
				</button>
			)}
			containerClass="absolute right-1 mt-1 flex items-center h-5 cursor-pointer top-3"
			contentClass="absolute left-0 w-full flex align-start rounded-md divide-y divide-gray-100 dark:divide-gray-600 top-8"
			reset={() => handleReset()}
		>
			<div className='absolute z-50 flex gap-x-2'>
				<div className='z-50 bg-white shadow w-44' >
					<Item
						title="Omdøb"
						show={false}
						onClick={handleEdit}
					/>
					{!hideMoveTo &&
						<Item
							title="Flyt til"
							show={true}
						>
							<ChevronRightIcon className="w-3 h-3 mr-2 text-darkPurple" />
						</Item>
					}
					<Item
						title="Slet"
						show={false}
						onClick={() => handleDelete(element)}
						className="text-red-700"
					/>
				</div>

				{moveToDropDownOpen &&
					<div className={classNames(
						'absolute z-50 bg-white shadow -right-32'
					)}>
						<div>
							{element?.parentId &&
								<div
									className='flex items-center justify-between hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
									onMouseOver={() => setMoveToDropDownOpen(true)}
									onClick={() => handleUpdate({ ...element, parentId: null })}
								>
									<div class="py-2 px-4">Dokumenter</div>
								</div>
							}

							{elements?.map((e) => {
								if (e?.id !== element?.id) {
									return (
										<div className='flex items-center justify-between hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
											onMouseOver={() => setMoveToDropDownOpen(true)}
											onClick={() => handleUpdate({ ...e, parentId: e.id })}
										>
											<a href="#" class=" py-2 px-4 ">{e.name}  </a>
										</div>

									)
								}
							})}
						</div>
					</div>
				}
			</div>
		</KDropdown >
	)
}
