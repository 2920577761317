import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import KButton from '../../components/KButton'
import KCalendar from '../../components/KCalendar'
import KPageWrapper from '../../components/KPageWrapper'
import { GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_DEPARTMENT_ID, GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_ORGANIZATION_ID, GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_RESIDENT_ID, GET_KMD_EVENTS_BY_DEPARTMENT_ID, GET_KMD_EVENTS_BY_ORGANIZATION_ID, GET_KMD_EVENTS_BY_RESIDENT_ID } from '../../graphql/graphqlQueries';
import { getGlobalFilterType, globalFilterTypes } from '../../utils/globalFilterUtils';
import moment from 'moment';
import { createCalendarActivityRoute } from '../../routes/mainRoutes';
import { setCurrentView } from '../../redux/slices/calendarSlice';
import _ from 'lodash';
import { loginKmd } from '../../utils/KmdUtils';
import { useKMD } from '../../config.js'
import CalendarOverview from './CalendarOverview';
import CalendarPlanning from './CalendarPlanning';
import KCalendarColorGuide from '../../components/guides/KCalendarColorGuide';

export default function Calendar({ history, location }) {
	const user = useSelector(state => state.user)
	const globalFilter = useSelector((state) => state.globalFilter);
	const calendar = useSelector(state => state.calendar)

	const [activities, setActivities] = useState();
	const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
	const [endDate, setEndDate] = useState(moment().add(1, 'month'));
	const [activeSection, setActiveSection] = useState('Kalender')
	// const [currentView, setCurrentView] = useState('month');

	const dispatch = useDispatch()

	const pickQuery = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_RESIDENT_ID;
			case globalFilterTypes.DEPARTMENT:
				return GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_DEPARTMENT_ID;
			case globalFilterTypes.ORGANIZATION:
				return GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_ORGANIZATION_ID;
		}
	};

	const kmdPickQuery = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return GET_KMD_EVENTS_BY_RESIDENT_ID;
			case globalFilterTypes.DEPARTMENT:
				return GET_KMD_EVENTS_BY_DEPARTMENT_ID;
			case globalFilterTypes.ORGANIZATION:
				return GET_KMD_EVENTS_BY_ORGANIZATION_ID;
		}
	}

	const getId = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return globalFilter.residentFilter.id;
			case globalFilterTypes.DEPARTMENT:
				return globalFilter.departmentFilter.id;
			case globalFilterTypes.ORGANIZATION:
				return user.data.organization.id;
		}
	};

	const { loading, data, error, refetch } = useQuery(pickQuery(), {
		variables: {
			id: getId(),
			// order: ["startDate", "asc"],
			page: 0,
			size: 99999,
			dateRange: [
				startDate?.format("yyyy-MM-DD"),
				endDate?.format("yyyy-MM-DD"),
			],
		},
		fetchPolicy: 'cache-and-network'
	})

	const { data: kmdData, refetch: kmdRefetch, loading: kmdLoading } = useQuery(kmdPickQuery(), {
		variables: {
			id: String(getId()),
			token: user?.KMDAuth?.token,
			endpoint: user?.data?.organization?.municipality?.kmdRootEndpoint
		},
		fetchPolicy: 'no-cache'
	})

	useEffect(() => {
		if (data) {
			const events = data[Object.keys(data)[0]].results;
			let kmdEvents = [];

			if (kmdData) {
				kmdEvents = kmdData[Object.keys(kmdData)[0]];
			}

			const eventsToFormat = [].concat(events).concat(kmdEvents);

			const formatted = eventsToFormat?.map(event => ({
				...event,
				start: moment(parseInt(event?.startDate)).toDate(),
				end: moment(parseInt(event?.endDate)).toDate(),
			}));

			setActivities(formatted)
		}
	}, [data, kmdData])

	useEffect(() => {
		console.log(location)
		if (location?.state?.isDraft) {
			setActiveSection('Planlægning')
		}
	}, [])

	const onDateRangeChange = (dates) => {
		if (dates.constructor.name === 'Object') { // Month
			setStartDate(moment(dates.start).subtract(7, 'days'))
			setEndDate(moment(dates.end).add(7, 'days'))
		} else if (dates.constructor.name === 'Array') { // Week / Day
			if (dates.length > 1) {
				console.log('Here', dates)
				setStartDate(moment(dates[0]).subtract(2, 'week'))
				setEndDate(moment(dates[dates.length - 1]).add(2, 'week'))
			} else {
				// console.log('Day')
			}
		}
	}

	useEffect(() => {
		if (!_.isEmpty(user.KMDAuth)) {
			const expireAt = moment(user.KMDAuth.createdAt).add(user.KMDAuth.expires, 'seconds')
			if (expireAt.isBefore(moment())) {
				handleKMDLogin();
			}
		} else {
			handleKMDLogin();
		}
	}, []);

	const handleKMDLogin = () => {
		useKMD && loginKmd(user.data.organization.municipality)
	}


	const KSubMenuItem = ({ title, active, onClick }) => {
		return (
			<div style={{ cursor: 'pointer' }} onClick={() => onClick(title)}>
				<p>{title}</p>
				{active &&
					<div style={{ height: '1.5px', width: '100%', backgroundColor: '#3A3A3A' }} />
				}
			</div>
		)
	}

	return (
		<KPageWrapper
			paths={[{
				title: 'KALENDER'

			}]}
			buttons={[
				<KButton
					label="OPRET AKTIVITET"
					onClick={() => history.push(createCalendarActivityRoute)}
				/>
			]}
		>
			<div className='flex items-center justify-between' >
				<div style={{ display: 'flex', gap: 20, marginTop: 10, marginBottom: 20 }}>
					<KSubMenuItem
						title="Kalender"
						active={activeSection === 'Kalender'}
						onClick={setActiveSection}
					/>
					<KSubMenuItem
						title="Oversigt"
						active={activeSection === 'Oversigt'}
						onClick={setActiveSection}
					/>
					<KSubMenuItem
						title="Planlægning"
						active={activeSection === 'Planlægning'}
						onClick={setActiveSection}
					/>
				</div>
				{activeSection === 'Kalender' &&
					<span className='mb-2 ' >
						<KCalendarColorGuide />
					</span>
				}
			</div>
			{activeSection === 'Kalender' &&
				<KCalendar
					loading={[loading]}
					currentView={calendar.currentView}
					onViewChange={(e) => dispatch(setCurrentView(e))}
					onDateRangeChange={onDateRangeChange}
					history={history}
					activities={activities}
				/>
			}
			{activeSection === 'Oversigt' &&
				<div>
					<CalendarOverview history={history} />
				</div>
			}
			{activeSection === 'Planlægning' &&
				<div>
					<CalendarPlanning history={history} />
				</div>
			}
		</KPageWrapper>
	)
}
