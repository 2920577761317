import { useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_DEPARTMENT_ID, GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_ORGANIZATION_ID, GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_RESIDENT_ID, GET_KMD_EVENTS_BY_DEPARTMENT_ID, GET_KMD_EVENTS_BY_ORGANIZATION_ID, GET_KMD_EVENTS_BY_RESIDENT_ID } from '../../graphql/graphqlQueries';
import { getGlobalFilterType, globalFilterTypes } from '../../utils/globalFilterUtils';
import moment from 'moment';
import { calendarActivityRoute } from '../../routes/mainRoutes';
import _ from 'lodash';
import { loginKmd } from '../../utils/KmdUtils';
import { useKMD } from '../../config.js'
import KTable from '../../components/KTable';
import { TableCell, TableRow } from '@mui/material';
import Colors from '../../styles/Colors';
import { getResidentFullName } from '../../utils/residentUtils';

export default function CalendarOverview({ history }) {
	const user = useSelector(state => state.user)
	const globalFilter = useSelector((state) => state.globalFilter);

	const [activities, setActivities] = useState();
	const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
	const [endDate, setEndDate] = useState(moment().add(1, 'month'));

	const printRef = useRef();

	const dispatch = useDispatch()

	const pickQuery = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_RESIDENT_ID;
			case globalFilterTypes.DEPARTMENT:
				return GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_DEPARTMENT_ID;
			case globalFilterTypes.ORGANIZATION:
				return GET_ALL_CALENDAR_ACTIVITIES_BETWEEN_INTERVAL_BY_ORGANIZATION_ID;
		}
	};

	const kmdPickQuery = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return GET_KMD_EVENTS_BY_RESIDENT_ID;
			case globalFilterTypes.DEPARTMENT:
				return GET_KMD_EVENTS_BY_DEPARTMENT_ID;
			case globalFilterTypes.ORGANIZATION:
				return GET_KMD_EVENTS_BY_ORGANIZATION_ID;
		}
	}

	const getId = () => {
		const gType = getGlobalFilterType(globalFilter);
		switch (gType) {
			case globalFilterTypes.RESIDENT:
				return globalFilter.residentFilter.id;
			case globalFilterTypes.DEPARTMENT:
				return globalFilter.departmentFilter.id;
			case globalFilterTypes.ORGANIZATION:
				return user.data.organization.id;
		}
	};

	const { loading, data, error, refetch } = useQuery(pickQuery(), {
		variables: {
			id: getId(),
			order: ["startDate", "asc"],
			page: 0,
			size: 99999,
			dateRange: [
				startDate?.format("yyyy-MM-DD"),
				endDate?.format("yyyy-MM-DD"),
			],
		},
		fetchPolicy: 'no-cache'
	})

	const { data: kmdData, refetch: kmdRefetch, loading: kmdLoading } = useQuery(kmdPickQuery(), {
		variables: {
			id: String(getId()),
			token: user?.KMDAuth?.token,
			endpoint: user?.data?.organization?.municipality?.kmdRootEndpoint
		},
		fetchPolicy: 'no-cache'
	})

	useEffect(() => {
		if (data) {
			const events = data[Object.keys(data)[0]].results;
			let kmdEvents = [];

			if (kmdData) {
				kmdEvents = kmdData[Object.keys(kmdData)[0]];
			}

			const eventsToFormat = [].concat(events).concat(kmdEvents);

			const formatted = eventsToFormat?.map(event => ({
				...event,
				start: moment(parseInt(event?.startDate)).toDate(),
				end: moment(parseInt(event?.endDate)).toDate(),
			}));

			setActivities(formatted)
		}
	}, [data, kmdData])

	useEffect(() => {
		if (!_.isEmpty(user.KMDAuth)) {
			const expireAt = moment(user.KMDAuth.createdAt).add(user.KMDAuth.expires, 'seconds')
			if (expireAt.isBefore(moment())) {
				handleKMDLogin();
			}
		} else {
			handleKMDLogin();
		}
	}, []);

	const handleKMDLogin = () => {
		useKMD && loginKmd(user.data.organization.municipality)
	}

	const getDates = () => {
		const fromDate = moment().startOf('week').subtract('1', 'day');
		const lastDate = moment().add(4, 'weeks').endOf('week')

		const dates = [];

		while (fromDate.add(1, 'days').diff(lastDate) < 0) {
			const date = fromDate.clone();

			const foundActivities = activities?.filter(e => moment(parseInt(e?.startDate)).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'));

			var dayOfWeek = date.day();
			var isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);

			if (!isWeekend) {
				dates.push({
					date,
					activities: foundActivities
				});
			}
		}

		return dates;
	}

	const OverviewItem = ({ row }) => {
		const isToday = moment().format('DD-MM-YYYY') === row?.date?.format('DD-MM-YYYY')
		const lastDay = row?.date.day() === 5;

		return (
			<TableRow style={{ borderBottom: lastDay ? '2px solid #000' : null }}>
				<TableCell width={'5%'}>
					{isToday &&
						<div style={{ height: 30, width: 70, backgroundColor: Colors.darkPurple, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff', fontFamily: 'MetricSemiBold' }}>
							<p>I DAG</p>
						</div>
					}
				</TableCell>
				<TableCell width={'10%'}>
					<p style={{ textTransform: 'capitalize', fontFamily: 'MetricSemiBold' }}>{row?.date?.format('dddd')}</p>
					{row?.date?.format('DD/MM')}
				</TableCell>
				<TableCell width={'50%'}>
					{row?.activities?.map(activity => (
						<div onClick={() => history.push(calendarActivityRoute(activity?.id))} style={{ padding: 2, cursor: 'pointer' }}>
							<div style={{ display: 'flex', gap: 5 }}>
								<p style={{ fontFamily: 'MetricSemiBold', fontSize: 14 }}>kl. {moment(parseInt(activity?.startDate))?.format('HH:mm')}</p>
								<p>{activity?.title}</p>
							</div>
						</div>
					))}
				</TableCell>
				{globalFilter?.departmentFilter?.name && !globalFilter?.residentFilter?.name
					? <TableCell width={'10%'}>{globalFilter?.departmentFilter?.name}</TableCell>
					: <TableCell width={'10%'}>{globalFilter?.departmentFilter?.name}</TableCell>
				}
				{!globalFilter?.departmentFilter?.name && globalFilter?.residentFilter?.name &&
					<TableCell width={'10%'}>{getResidentFullName(globalFilter?.residentFilter)}</TableCell>
				}
			</TableRow>
		)
	}


	return (
		<div>
			<div style={{ marginBottom: 20 }}>
				<p>Her er en oversigt over kalenderaktiviteter den næste måned. Klik på filtret Afsnit, hvis du vil ændre visning til hele centret eller afsnit</p>
			</div>
			<div style={{ width: '100%' }} ref={printRef}>
				<KTable
					style={{ width: '100%' }}
					headers={['', 'Dato', 'Aktiviteter', 'Afsnit/Sal']}
					data={getDates()}
					RenderItem={OverviewItem}
				/>
			</div>
		</div>
	)
}
