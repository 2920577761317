import React from 'react'
import KPageWrapper from '../../components/KPageWrapper'
import { dashboardRoute } from '../../routes/mainRoutes'

export default function OverdueAssignments() {
	return (
		<KPageWrapper
			paths={[{
				title: 'Overblik',
				route: dashboardRoute
			}, {
				title: 'Forfaldne opgaver'
			}]}
		>
			<p>Hello</p>
		</KPageWrapper>
	)
}
